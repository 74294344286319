import { type ApolloClient, useApolloClient } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import type { ViewQuery } from '@salescore/core'
import { logger, LoggerEvents } from '@salescore/frontend-common'
import { message } from 'antd'
import { t } from 'i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { searchRelation } from '../../../recoil/hooks/searchRelation'
import {
  type FeedbackMessage,
  feedbackMessagesAtom,
  organizationIdAtom,
  recordsAtom,
} from '../../../recoil/records/atoms'
import type { RecordChange } from '../../../recoil/records/mutations/upsertViewRecords'
import { upsertViewRecordsMutation } from '../../../recoil/records/mutations/upsertViewRecordsMutation'
import { isPastingAtom, listQueryAtom, meAtom, viewAbilityAtom, viewAtom } from '../../../recoil/view/atoms'
import { getNode } from '../../../state/nodeUtil'
import { copy } from '../../action/copy'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import { columnsModel } from '../models/propModels'
import { generateRecordChangesByCopiedValues } from '../mutations/changeAreaMutation'
import { pasteMutation } from '../mutations/pasteMutation'
import { useCursorMutation } from '../mutations/useCursorMutation'
import { type ExpandArea, useCursorSelector } from '../selectors/cursorSelector'

export const usePaste = () => {
  const view = useRecoilValue(viewAtom)
  const data = useRecoilValue(recordsAtom)
  const columns = useRecoilValue(columnsModel)
  const query = useRecoilValue(listQueryAtom)
  const me = useRecoilValue(meAtom)
  const { cursor, selectedArea, copiedArea } = useCursorSelector()
  const ability = useRecoilValue(viewAbilityAtom)
  const client = useApolloClient()
  const organizationId = useRecoilValue(organizationIdAtom)
  const setter = useSetRecoilState(upsertViewRecordsMutation)
  const newPaste = useSetRecoilState(pasteMutation)
  const { hideCopyingArea } = useCursorMutation()
  const setFeedbackMessages = useSetRecoilState(feedbackMessagesAtom)
  const setIsPasting = useSetRecoilState(isPastingAtom)
  const feedback = (x: FeedbackMessage) => {
    setFeedbackMessages([x])
  }

  // eslint-disable-next-line complexity
  const paste = async () => {
    if (!ability.canSaveRecord) {
      void message.warning(t(`このシートのレコードを編集する権限がありません`))
      return
    }
    if (cursor === undefined) {
      return
    }
    setIsPasting(true)
    const currentAreaText = cursor.copying === undefined ? undefined : copy(data, columns, cursor.copying)
    const clipboardText = await readTextFromClipboard()

    const logData = {
      type: `paste`,
      isNewImplementationTarget: isNewImplementationTarget(selectedArea, copiedArea, columns),
      expandArea: selectedArea ?? {},
      copiedArea: copiedArea ?? {},
      text: clipboardText.text ?? '',
      currentAreaText: currentAreaText?.text ?? '',
      isSameText: currentAreaText?.text === clipboardText.text,
      view: {
        id: view.id,
        name: view.name,
      },
      organization: {
        id: me.myUser.organization.id,
        name: me.myUser.organization.name,
      },
      identity: {
        name: me.myIdentity.name,
        email: me.myIdentity.email,
      },
    }
    logger.info(
      LoggerEvents.SHEET_PASTE,
      `${me.myUser.organization.name} ${me.myIdentity.name} ${me.myIdentity.email}`,
      logData,
    )
    // 2022/11/16 不具合対応のため、ブラウザでもログを残しておく
    logger.debug(logData)

    // 特殊なケースへの対応
    // 1. クリップボートからのコピーに失敗した場合は、常にSALESCORE内のコピー情報を使う
    if (organizationId === 'c5e4f7c6-a2be-4e00-9477-b8ab11fc746a' || !clipboardText.success) {
      // SO様対応
      logger.debug(`pasting from source area for SO`)
      newPaste()
      hideCopyingArea()
      return
    }

    if (isNewImplementationTarget(selectedArea, copiedArea, columns) && currentAreaText?.text === clipboardText.text) {
      logger.debug(`pasting from source area`)
      newPaste()
      hideCopyingArea()
      return
    }

    // 旧ロジック。clipboardからペーストする。
    void message.info(t(`クリップボードからペーストします`)) // 意図せずクリップボードペーストが発生し、特に参照が大量に含まれている際に問題になったため、不具合の原因が分かり易いようmessageを表示しておく
    const values = clipboardText.text
      .replace(/(\r)?\n$/, '')
      .split('\n')
      .map((x) => x.split('\t')) // 2023/06 Excelなどからコピーするとき、1セルのみのコピーでも末尾に余計な改行が入るので、改行を除去してからsplitする
    const recordChanges = generateRecordChangesByCopiedValues({
      copiedValues: values,
      data,
      columns,
      cursor,
      maxRowIndex: data.length,
      feedback,
    })
    logger.debug({
      values,
      recordChanges,
    })

    const searchingRecords = recordChanges.map((recordChange) => ({
      recordChange,
      searchRelated: prepareSearchKey(recordChange, query),
    }))
    // 検索リクエストをする回数を最小限にするために、同じ検索についてはグルーピングする
    const searchingRecordsGroups = searchingRecords.groupBy((x) => JSON.stringify(x.searchRelated ?? '')).values()
    if (recordChanges.length > 50 || searchingRecordsGroups.length > 3) {
      void message.warning(
        t(`更新するレコード数が多い場合や、参照列へのペーストには時間がかかります。完了後に保存してください。`),
      )
    }
    for (const searchingRecordsGroup of searchingRecordsGroups) {
      const recordChangesWithSearchResult = await searchAndMergeRelationField({
        client,
        organizationId,
        sql: searchingRecordsGroup.first()!.searchRelated?.sql,
        searchKey: searchingRecordsGroup.first()!.searchRelated?.searchKey,
        recordChanges: searchingRecordsGroup.map((y) => y.recordChange),
      })

      setter({
        recordChanges: recordChangesWithSearchResult.compact(),
        newRecordChanges: recordChangesWithSearchResult
          .compact()
          .filter((x) => x.rowIndex >= data.length)
          .uniqueBy((x) => x.rowIndex)
          .map((x) => ({
            rowIndex: x.rowIndex,
            innerRowIndex: 0,
            node: query.tree,
          })),
      })
    }
    hideCopyingArea()
  }

  return paste
}

// eslint-disable-next-line complexity
function prepareSearchKey(recordChange: RecordChange, query: ViewQuery) {
  const { field } = recordChange
  const searchKey = recordChange.value // valueにペーストした検索用の文字列が入っている
  if (field.meta.fieldMetaType !== 'relation' || field.read.labelNodePath === undefined) {
    return
  }

  const referencedNode = getNode(query.tree, field.read.labelNodePath)
  if (referencedNode === undefined) {
    logger.debug(`referencedNode not found`)
    return
  }

  if (searchKey === undefined || searchKey === null) {
    logger.debug(`searchKey is null`)
    return
  }
  // 検索を実行した上でコピー
  if (typeof searchKey !== 'string') {
    void message.warning(t(`参照項目に対して、文字列以外はペーストできません`))
    return
  }
  const sql = field.read.searchSql
  if (isNull(sql)) {
    return
  }

  return {
    sql,
    searchKey,
  }
}

// eslint-disable-next-line complexity
async function searchAndMergeRelationField({
  sql,
  searchKey,
  recordChanges,
  client,
  organizationId,
}: {
  sql: string | undefined
  searchKey: string | undefined
  recordChanges: RecordChange[]
  client: ApolloClient<object>
  organizationId: string
}): Promise<RecordChange[]> {
  if (searchKey === undefined || sql === undefined) {
    return recordChanges
  }

  const searchResult = await searchRelation(client, organizationId)(sql, searchKey)
  const results = searchResult?.values ?? []
  if (results.length === 0 && searchKey !== undefined) {
    void message.warning(t(`{{searchKey}}は見つかりませんでした`, { searchKey }))
    return []
  }
  const result = results.mySortBy((x) => x.label.length).first()! // LIKE検索しているため、複数のレコードがヒットしうる。文字数が最小のものが最適と仮定して、これを選択する
  return recordChanges.map((recordChange) => ({
    ...recordChange,
    value: result.value,
    label: result.label,
  }))
}

function isNewImplementationTarget(
  expandArea: ExpandArea | undefined,
  copiedArea: ExpandArea | undefined,
  columns: Array<RSheetColumn<RSheetRecordNode>>,
) {
  if (expandArea === undefined || copiedArea === undefined) {
    logger.debug(`newImplementation is not called because copiedArea or expandArea not defined`)
    return false
  }

  // 残りのバリデーションはmutation側で行う

  return true
}

export async function readTextFromClipboard() {
  try {
    // eslint-disable-next-line unicorn/no-await-expression-member
    const text = (await navigator.clipboard.readText()).replaceAll('\r', '').replace(/\n$/, '') // windows対応のため、\rの除去が必要
    return {
      success: true as const,
      text,
    }
  } catch (error) {
    // iframeで使っている際などで、権限が足りず失敗することがある
    // https://sites.google.com/a/chromium.org/dev/Home/chromium-security/deprecating-permissions-in-cross-origin-iframes
    if (error instanceof Error) {
      logger.debug(`readTextFromClipboard() failed. error: ${error.name}: ${error.message}`)
    }
    return {
      success: false as const,
      error,
    }
  }
}
