import { getTailwindColor, type TailwindCssColor } from '@salescore/client-base'
import { Tooltip } from 'antd'
import type { CSSProperties } from 'react'

import { useNavigationModal } from '../../../recoil/navigation/hooks'
import { useViewSelector } from '../../../recoil/view/selectors/viewSelector'
import { useContextValue } from '../../recoil/models/propModels'
import {
  type RSheetsNodeBlockCell,
  type RSheetsNodeBlockRow,
  useColumnBlocksRelatedSelector,
} from '../../recoil/selectors/columnBlockRelatedSelector'
import { RSheetsStyle } from '../../util/RSheetsStyle'

const TOP_RATIO = 0.1

export function RSheetsNodeBlockHeader({ leftFixed }: { leftFixed: boolean }) {
  const { leftFixedNodeBlockHeader, notFixedNodeBlockHeader, maxDepth } = useColumnBlocksRelatedSelector()
  const nodeBlockHeader = leftFixed ? leftFixedNodeBlockHeader : notFixedNodeBlockHeader
  const { treeHeaderHeightPerNode } = useColumnBlocksRelatedSelector()
  if (nodeBlockHeader.isBlank()) {
    return (
      <div
        style={{
          height: maxDepth * treeHeaderHeightPerNode,
          width: '100%',
          backgroundColor: RSheetsStyle.color.backgroundColor,
        }}
      />
    )
  }

  return (
    <>
      {nodeBlockHeader.map((headerRow, depth) => (
        <NodeBlockHeaderRow
          row={headerRow}
          height={(maxDepth - depth) * treeHeaderHeightPerNode}
          depth={depth}
          key={depth}
        />
      ))}
    </>
  )
}

function NodeBlockHeaderRow({ row, height, depth }: { row: RSheetsNodeBlockRow; height: number; depth: number }) {
  const { treeHeaderHeightPerNode } = useColumnBlocksRelatedSelector()
  return (
    <>
      <div
        style={{
          width: row.map((x) => x.width).sum(),
          height: height + depth * treeHeaderHeightPerNode,
          top: depth * treeHeaderHeightPerNode * (1 - TOP_RATIO),
        }}
        className="absolute flex overflow-hidden"
      >
        <>
          {row.map((cell, index) => (
            <Block cell={cell} depth={depth} index={index} key={index} />
          ))}
          {/* TODO: stickyっぽく表示が必要。スクロールをハンドリングするしかなさそう */}
          {/* <div
      className="absolute left-0"
    >
      sticky
    </div> */}
        </>
      </div>
    </>
  )
}

function Block({ cell, depth, index }: { cell: RSheetsNodeBlockCell; depth: number; index: number }): JSX.Element {
  const { nodeModal } = useNavigationModal()
  const { getNode } = useViewSelector()
  const context = useContextValue()
  return (
    <div
      style={{
        position: 'sticky',
        left: 0,
        width: cell.width,
        padding: 4,
        margin: '0px',
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        ...getHeaderColorStylesWithCell(cell, depth, index),
        paddingTop: 5,
        fontSize: 10,
        ...(context.asKpiTable === true
          ? {
              paddingLeft: 8,
              paddingTop: 12,
              fontSize: 14,
            }
          : {}),
      }}
    >
      <Tooltip title={cell.tooltipText} placement="right">
        <span
          className="cursor-pointer"
          onClick={() => {
            const nodeName = cell.block.nodePath.last()
            if (nodeName !== undefined) {
              const configNode = getNode(nodeName)
              if (configNode !== undefined) {
                nodeModal.showModal(configNode)
              }
            }
          }}
        >
          {cell.label}
        </span>
      </Tooltip>
      {cell.smallLabel !== undefined && (
        <div
          className="absolute top-0"
          style={{
            fontSize: 10,
            transform: `scale(0.8)`,
            left: 0,
            color: getHeaderColor(cell.color).textColor,
            opacity: 0.7,
          }}
        >
          {cell.smallLabel}
        </div>
      )}
    </div>
  )
}

function getHeaderColorStylesWithCell(cell: RSheetsNodeBlockCell, depth: number, index: number): CSSProperties {
  if (cell.label === '-' || cell.color === undefined) {
    return {
      opacity: 0,
    }
  }

  return {
    color: getHeaderColor(cell.color).textColor,
    border: `1px ${getHeaderColor(cell.color).borderColor} solid`,
    backgroundColor: getHeaderColor(cell.color).bgColor,
  }
}

// eslint-disable-next-line complexity
export function getHeaderColor(color: TailwindCssColor | undefined) {
  return {
    cellColor: getTailwindColor(color ?? 'gray', 25),
    bgColor: getTailwindColor(color ?? 'gray', 100),
    bgColorDark: getTailwindColor(color ?? 'gray', 500),
    borderColor: getTailwindColor(color ?? 'gray', 200),
    textColor: getTailwindColor(color ?? 'gray', 700),
    textColorWhite: `white`,
  }
}
