import { isNull, isSome, snakeToPascal } from '@salescore/buff-common'

import { CORE_CONSTANT } from '../../../../constant'
import { CoreDsl } from '../../../../dsl/coreDsl'
import { convertFormToAst } from '../../../../dsl/ui/convert'
import type { ModelProperty } from '../../../../schemas/model/modelProperty'
import type {
  ConditionalEffect,
  NodePath,
  SnapshotConfig,
  ViewQueryField,
  ViewQueryNode,
} from '../../../../schemas/query'
import type { ViewUiSheetColumn } from '../../../../schemas/ui/ui'
import type {
  ConditionalEffectConfig,
  ViewConfigField,
  ViewConfigSheet,
  ViewConfigTreeNode,
} from '../../../../schemas/view_config'
import { generateSalesforceHistoryModelName } from '../../../query/snapshots/generateSnapshotsFromHistory'
import { POSTGRES_COLUMN_LENGTH_LIMIT } from '../../../query/types'
import { isRequiredProperty } from '../../../util/isRequiredProperty'
import { makeIdentifiable } from '../../../util/makeIdentifiable'
import type { CompileContext } from '../../common'
import { generateFieldName } from './generateFieldName'

export interface FieldAndColumn {
  queryField: ViewQueryField
  configField: ViewConfigField
  column: ViewUiSheetColumn
  snapshotConfigs: SnapshotConfig[]
}

export function compileSheetViewConfigFields(
  fields: ViewConfigSheet['fields'],
  queryNodes: ViewQueryNode[],
  configNodes: ViewConfigTreeNode[],
  context: CompileContext,
): FieldAndColumn[] {
  if (fields === undefined) {
    return []
  }

  const xs = fields
    // eslint-disable-next-line complexity
    .map((field, index): Omit<FieldAndColumn, 'measureQueryFields'> => {
      const queryNode = queryNodes.find((node) => node.name === field.property.nodeName)
      const configNode = configNodes.find((node) => node.name === field.property.nodeName)
      const model = context.modelSearcher.searchModel(field.property.modelName)
      const property = context.modelSearcher.searchPropertyWithModel(
        field.property.modelName,
        field.property.propertyName,
      )?.property
      if (queryNode === undefined || configNode === undefined) {
        context.logs.warn(`[compileSheetViewConfigFields] node not found. nodeName: ${field.property.nodeName}`)
        return getNotFoundField(field)
      }
      if (property === undefined || model === undefined) {
        context.logs.warn(
          `[compileSheetViewConfigFields] property or model not found. ${field.property.modelName}, ${field.property.propertyName}`,
        )
        return getNotFoundField(field)
      }

      const name = generateFieldName({ nodeName: queryNode.name, propertyName: property.name })
      const effects = [
        ...(field.override?.conditionalEffects ?? []), // deprecated
        ...(field.conditionalEffects ?? []),
      ]
      const conditionalEffects = effects.isPresent()
        ? effects.map((x) => compileConditionalEffects(x)).compact()
        : undefined
      const queryField: ViewQueryField = {
        name,
        nodePath: queryNode.path,
        read: {
          sql: generatePropertySql({ nodeName: queryNode.name, propertyName: property.name }),
          ...getReferenceRelatedSql(configNode, queryNode, property, model.name, context),
          ...getIdRelatedSql(queryNode, property, model.name, context),
          ...field.override?.queryField?.read,
        },
        write:
          isNull(property.write) || (property.creatable === false && property.updatable === false)
            ? undefined
            : {
                streamName: field.property.modelName,
                propertySourceName: property.write.sourcePropertyName,
              },
        meta: {
          label: property.label,
          fieldType: property.type,
          fieldMetaType: property.meta ?? undefined,
          required: isRequiredProperty(property),
          defaultValue: undefined,
          searchable: property.meta === 'name',
          conditionalEffects,
          dependedPropertyNamesWithStreamName: [
            {
              streamName: field.property.modelName,
              propertyName: field.property.propertyName,
            },
          ],
          ...field.override?.queryField?.meta,
          // 以下、単純にoverrideさせない項目
          creatable:
            (field.override?.queryField?.meta?.creatable ?? true) &&
            (property.creatable ?? true) &&
            isSome(property.write),
          updatable:
            (field.override?.queryField?.meta?.updatable ?? true) &&
            (property.updatable ?? true) &&
            isSome(property.write),
        },
      }
      const override = field.override?.uiColumn
      const column: ViewUiSheetColumn = {
        fieldName: name,
        label: property.label,
        description: property.description,
        rank: index,
        width: override?.width ?? calculateWidth(property),
        fieldType: property.type,
        fieldMetaType: (property.referenceTo?.isPresent() ?? false) ? 'relation' : (property.meta ?? undefined), // TODO: 単純にmetaを適用するだけにしたい。
        required: isRequiredProperty(property),
        ...override,
        // 以下、単純にoverrideさせない項目
        selectOptions:
          // TODO: nullで上書きできるように、nullableにするか？
          override?.selectOptions === null
            ? undefined
            : (override?.selectOptions ?? generateSelectOptionsWithColor(property.selectOptions)),
        visible: override?.visible ?? true,
        creatable: (override?.creatable ?? true) && (property.creatable ?? true) && isSome(property.write),
        updatable: (override?.updatable ?? true) && (property.updatable ?? true) && isSome(property.write),
        measures: field.measures
          ?.map((x) => ({
            label: x.label ?? x.name,
            name: x.name,
          }))
          .compact(),
      }
      const snapshotConfigs = getSnapshotConfigs(conditionalEffects ?? [], field, context)
      return {
        queryField,
        column,
        configField: field,
        snapshotConfigs,
      }
    })
    .compact()

  return xs
}

export function generatePropertySql({ nodeName, propertyName }: { nodeName: string; propertyName: string }) {
  return `"${nodeName}"."${propertyName}"` // TODO: このレイヤーでSQL変換したくない
}

// eslint-disable-next-line @typescript-eslint/max-params
function getReferenceRelatedSql(
  configNode: ViewConfigTreeNode,
  queryNode: ViewQueryNode,
  property: ModelProperty,
  propertyModelName: string,
  context: CompileContext,
): { labelSql: string; searchSql: string; labelNodePath: NodePath } | undefined {
  // TODO: polymorphicのときにどうすべきか、現状解がない
  // eslint-disable-next-line complexity
  const xs = (property.referenceTo ?? []).map((reference) => {
    const childConfigNode = (configNode.children ?? []).find(
      (childNode) =>
        childNode.referenceToProperty?.propertyName === property.name &&
        childNode.referenceToProperty.modelName === propertyModelName &&
        childNode.referenceToProperty.referenceTo.modelName === reference.modelName,
    )
    const childQueryNode = (queryNode.children ?? []).find((x) => x.name === childConfigNode?.name)
    if (childQueryNode === undefined || childConfigNode === undefined) {
      return
    }

    const childModel = context.modelSearcher.searchModel(childConfigNode.modelName)
    const nameProperty = childModel?.properties.find((x) => x.meta === 'name')
    return {
      labelSql: `"${childQueryNode.name}"."${nameProperty?.name ?? 'id'}"`, // TODO: ここでSQLを組み立てない
      labelNodePath: childQueryNode.path,
      searchSql: childQueryNode.read.join!.search.sql,
    }
  })
  return xs.compact().first()
}

function getIdRelatedSql(
  queryNode: ViewQueryNode,
  property: ModelProperty,
  propertyModelName: string,
  context: CompileContext,
): { labelSql: string; labelNodePath: NodePath } | undefined {
  if (property.meta !== 'id') {
    return undefined
  }
  const model = context.modelSearcher.searchModel(propertyModelName)
  const nameProperty = model?.properties.find((x) => x.meta === 'name')
  if (nameProperty === undefined) {
    return undefined
  }
  return {
    labelSql: `"${queryNode.name}"."${nameProperty.name}"`, // TODO: ここでSQLを組み立てない
    labelNodePath: queryNode.path,
  }
}

function calculateWidth(property: ModelProperty) {
  switch (property.meta) {
    case 'text': {
      return 300
    }
    case 'record_url': {
      return 36
    }
    default: {
      break
    }
  }

  switch (property.type) {
    case 'string': {
      return 200
    }
    case 'numeric': {
      return 100
    }
    case 'boolean': {
      return 100
    }
    case 'date': {
      return 150
    }
    case 'datetime': {
      return 200
    }
    default: {
      break
    }
  }

  return 200
}

const COLORS = ['blue', 'green', 'gold', 'volcano', 'magenta', 'purple', 'geekblue', 'cyan', 'lime', 'orange', 'red']

export function generateSelectOptionColor(index: number) {
  return COLORS[index % COLORS.length]
}

export function generateSelectOptionsWithColor(selectOptions: ModelProperty['selectOptions']) {
  return selectOptions?.map((x, index) => ({
    ...x,
    color: x.color ?? generateSelectOptionColor(index),
  }))
}

function compileConditionalEffects(effect: ConditionalEffectConfig): ConditionalEffect | undefined {
  switch (effect.type) {
    case 'form': {
      const ast = convertFormToAst(effect.ast)
      if (ast === undefined) {
        return undefined
      }
      return {
        condition: ast,
        effect: effect.effect,
        highlightColor: effect.highlightColor?.color,
      }
    }
    default: {
      // expressionTextのはず
      const parsed = CoreDsl.parse(effect.expression)
      if (!parsed.success) {
        return undefined
      }
      return {
        condition: parsed.data,
        effect: effect.effect,
      }
    }
  }
}

export const NOT_FOUND_FIELD_NAME_PREFIX = `_not_found`

function getNotFoundField(field: ViewConfigField): FieldAndColumn {
  const name = makeIdentifiable(
    [NOT_FOUND_FIELD_NAME_PREFIX, field.property.nodeName, field.property.propertyName].join('_'),
    POSTGRES_COLUMN_LENGTH_LIMIT,
  )
  return {
    configField: field,
    queryField: {
      name,
      nodePath: [],
      read: {
        sql: 'NULL',
      },
      meta: {
        label: field.property.propertyName,
        fieldType: 'string',
        dependedPropertyNamesWithStreamName: [],
      },
    },
    column: {
      fieldName: name,
      rank: 0,
      label: '不明',
      width: 100,
      fieldType: 'notFound',
    },
    snapshotConfigs: [],
  }
}

//
// SnapshotConfig、すなわち「どのモデルの何日分のスナップショットが必要か」を取得する
// snapshotはeffectの計算で必要であり、effectのvariableに必要な値の情報が含まれているので、これをパースする
//
function getSnapshotConfigs(
  effects: ConditionalEffect[],
  field: ViewConfigField,
  context: CompileContext,
): SnapshotConfig[] {
  if (effects.isBlank()) {
    return []
  }
  const asts = effects
    .map((effect) => effect.condition)
    .compact()
    .flatMap((x) => CoreDsl.flat(x))
  const variableNodes = asts.flatMap((x) => (x.type === 'variable' ? [x] : [])).uniqueBy((x) => x.path.join('.'))
  // snapshot.day0.name のようなパスになっている変数が、スナップショットを期待している値
  // これが含まれているかノードを探して、あった場合は日数とプロパティ名を取得する
  return (
    variableNodes
      // eslint-disable-next-line complexity
      .map((x): SnapshotConfig | undefined => {
        const [s, day, propertyName] = x.path
        if (s === undefined || day === undefined || propertyName === undefined) {
          return undefined
        }
        if (s !== CORE_CONSTANT.SNAPSHOT_VARIABLE_NAME || !day.startsWith(CORE_CONSTANT.SNAPSHOT_VARIABLE_DAY_PREFIX)) {
          return undefined
        }
        const propertyWithModel = context.modelSearcher.searchPropertyWithModel(
          field.property.modelName,
          field.property.propertyName,
        )
        if (propertyWithModel === undefined) {
          return undefined
        }
        const { property, model } = propertyWithModel
        // TODO: propertyが元のfieldNameを持っていないので辛い
        const fieldName = property.write?.sourcePropertyName ?? snakeToPascal(property.name)
        const historyModel = context.modelSearcher.searchModel(generateSalesforceHistoryModelName(model.name))
        if (historyModel === undefined) {
          return undefined
        }
        const historyModelParentIdProperty = historyModel.properties.find(
          (x) => x.name.endsWith('_id') && x.name !== 'created_by_id',
        )
        if (historyModelParentIdProperty === undefined) {
          return undefined
        }

        return {
          nodeName: field.property.nodeName,
          modelName: field.property.modelName,
          propertyName,
          fieldName,
          historyModelName: historyModel.name,
          historyModelParentIdColumnName: historyModelParentIdProperty.name,
          day: Number.parseInt(day.replace('day', '')),
        }
      })
      .compact()
  )
}
