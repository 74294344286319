import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { isNull } from '@salescore/buff-common'
import type { UserGroup } from '@salescore/client-api'
import { notifyBugsnag, Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import type { GoalConfig, User } from '@salescore/features'
import { normalizeGoalCsvRecord } from '@salescore/features'
import { Button, message, Modal, Table, Upload, type UploadFile } from 'antd'
import { parse } from 'csv-parse/sync'
import { t } from 'i18next'
import { useState } from 'react'

export const UploadGoalDimensionCsvFileButton = ({
  goalConfig,
  users,
  userGroups,
  onLoad,
  loading,
}: {
  goalConfig: GoalConfig
  users: User[]
  userGroups: UserGroup[]
  onLoad: (base64: string, decoded: string) => void
  loading?: boolean
}) => {
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([])
  const [modalVisibility, setModalVisibility] = useState<boolean>(false)
  const [base64Data, setBase64Data] = useState<string>('')
  const [decodedData, setDecodedData] = useState<string>('')
  const reader = new FileReader()
  // eslint-disable-next-line complexity
  reader.addEventListener('load', (onloadEvent) => {
    try {
      if (typeof onloadEvent.target?.result !== 'string') {
        void message.error(t(`エラーが発生しました。ファイルが文字列になっていません`)) // ありえないはず
        return
      }
      const base64 = onloadEvent.target.result
      if (!base64.startsWith('data:text/csv;base64,')) {
        // csvじゃないデータを選択した時
        void message.error(t(`ファイルがCSVではありません`))
      }
      const decoded = Buffer.from(base64.replace('data:text/csv;base64,', ''), 'base64').toString('utf8')

      // バリデーションチェックするため一時保存
      setBase64Data(base64)
      setDecodedData(decoded)
    } catch (error) {
      if (error instanceof Error) {
        notifyBugsnag({ error })
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  })

  if (loading === true) {
    return <Button loading={true} />
  }

  const dataSource = convertDataToRecords(decodedData, goalConfig, users, userGroups)
  const errors = dataSource.filter((data) => !data.success)

  return (
    <>
      <Upload
        fileList={uploadFiles}
        maxCount={1}
        onChange={(info) => {
          setUploadFiles(info.fileList)

          const file = info.fileList[0]?.originFileObj
          if (file !== undefined) {
            reader.readAsDataURL(file)
            setModalVisibility(true)
          }
        }}
      >
        <Button icon={<UploadOutlined />}>{t(`ファイルを選択`)}</Button>
      </Upload>
      <Modal
        open={modalVisibility}
        onOk={() => {
          const file = uploadFiles[0]
          Posthog.track(POSTHOG_EVENTS.upload_goal_csv, { goalConfigId: goalConfig.id })
          try {
            if (isNull(file)) {
              // ありえないはずだが一応
              void message.error(t(`ファイルが添付されていません`))
              return
            }

            if (isNull(file.originFileObj)) {
              void message.error(t(`ファイルが添付されていません`))
              return
            }

            onLoad(base64Data, decodedData)
            setModalVisibility(false)
          } catch (error) {
            if (error instanceof Error) {
              notifyBugsnag({ error })
              void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
            } else {
              void message.error(t(`エラーが発生しました`))
            }
          }
        }}
        onCancel={() => {
          setModalVisibility(false)
        }}
        width={'60%'}
        okText={t(`アップロード`)}
        cancelText={t(`キャンセル`)}
        // okButtonProps={{ style: { display: 'none' } }}
        title={<div className="text-2xl">{t(`確認`)}</div>}
        style={{ top: '3%', maxHeight: '70%' }}
      >
        <Table
          title={() => <>{t(`{{name}}をアップロードします。よろしいですか？`, { name: uploadFiles[0]?.name })}</>}
          dataSource={dataSource}
          rowClassName={(record, index) => (record.success ? '' : 'bg-red-300')}
          columns={[
            {
              key: `index`,
              dataIndex: `index`,
              title: t(`行番号`),
              render: (value, record) => <div>{value}</div>,
            },
            {
              key: `kpi_id`,
              dataIndex: `kpi_id`,
              title: `KPI`,
              render: (value, record) => (
                <div>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                    (record.record as Record<string, string>).kpi_id
                  }
                </div>
              ),
            },
            {
              key: `user_id`,
              dataIndex: `user_id`,
              title: t(`ユーザーID`),
              render: (value, record) => (
                <div>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                    (record.record as Record<string, string>).user_id
                  }
                </div>
              ),
            },
            {
              key: `date`,
              dataIndex: `date`,
              title: t(`日付`),
              render: (value, record) => (
                <div>
                  {// eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                  (record.record as Record<string, string>).date?.split('T').first()}
                </div>
              ),
            },
            {
              key: `value`,
              dataIndex: `value`,
              title: t(`目標`),
              render: (value, record) => (
                <div>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                    (record.record as Record<string, string>).value
                  }
                </div>
              ),
            },
          ]}
        />
        {errors.length > 0 && (
          <div className="flex flex-row items-start">
            <ExclamationCircleOutlined className="mx-2.5 my-1 text-lg text-red-500" />
            <div className="flex flex-col overflow-hidden">
              {errors.map((error) => (
                <div>
                  {t(`{{index}}{error.index}行目: {{message}}`, { index: error.index, message: error.message })}
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

// CSVデータをバリデーションチェック用に変換
function convertDataToRecords(data: string, goalConfig: GoalConfig, users: User[], userGroups: UserGroup[]) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const records = parse(data.trim(), {
    columns: true,
    skipEmptyLines: true,
  }) as Array<Record<string, unknown>>

  return records.map((record, index) =>
    normalizeGoalCsvRecord({ record, goalConfig, index: index + 1, users, userGroups }),
  )
}
