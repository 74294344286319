import clone from 'just-clone'
// import compare from "just-compare"
import filter from 'just-filter-object'
import mapValues from 'just-map-values'
import merge from 'just-merge'
import omit from 'just-omit'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import isEqual from 'lodash.isequal'

import { isSome, objectToArray, toObject } from './util' // ldoashにあまり依存したくない気持ちで開発を続けていたが、結局インストールされてしまったので、一旦使うことにした

// propertyがundefined,nullだった場合にneverとする
type FilterBlank<Base> = {
  [Key in keyof Base]: Base[Key] extends undefined | null ? never : Key
}

// undefined,null以外のkeyのunion型を生成
type AllowedNames<Base> = FilterBlank<Base>[keyof Base]

// 上記をもとに、元の型からPickする
type PickPresentType<Base> = Pick<Base, AllowedNames<Base>>

// ここまでだとstring | nullのようなunionは残ってしまうため、以下でNonNullableする
// TODO: 本来はoptional扱いにするのが正しいが、string | nullのようなunionを検知する方法が
type PickPresentTypeWithNonNullable<Base> = {
  [Key in keyof Base]: NonNullable<Base[Key]>
}

//
// sample
//
// type Person = {
//   a: string
//   b: undefined
//   c: boolean
//   d: any
// }
// type JsonPrimitive = SubType<Person, string>;
//

type valueOf<T> = T[keyof T]

type MapValueType<Base, NewType> = {
  [Key in keyof Base]: NewType
}

export class MRecord<T extends Record<string, unknown>> {
  public readonly data: T

  public constructor(data: T) {
    this.data = data
  }

  public compact() {
    const newData = compact(this.data)
    return new MRecord(newData)
  }

  public compactAsNonNullableProperty() {
    const newData = compactAsNonNullableProperty(this.data)
    return new MRecord(newData)
  }

  public toArray() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    return Object.entries(this.data).map(([key, value]) => [key, value] as [keyof T, valueOf<T>])
  }

  public map<U>(f: (key: keyof T, value: valueOf<T>, index: number) => U) {
    return Object.entries(this.data).map(([key, value], index) => f(key, value as valueOf<T>, index))
  }

  public keys() {
    return Object.entries(this.data).map(([key, _value]) => key as keyof T)
  }

  public values() {
    return Object.entries(this.data).map(([_key, value]) => value as valueOf<T>)
  }

  //
  // justのラッパー
  //
  public clone() {
    const newData = clone(this.data)
    return new MRecord(newData)
  }

  // public compare() {
  //   const newData = compare(this.data)
  //   return new MRecord(newData)
  // }

  public filter(function_: (key: keyof T, value: T[keyof T]) => boolean) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-type-assertion
    const newData = filter(this.data as any, function_)
    return new MRecord(newData)
  }

  public merge<K extends Record<string, unknown>>(x: K) {
    const newData: T & K = merge(this.data, x)
    return new MRecord(newData)
  }

  public omit<K extends string>(properties: K[]) {
    const newData = omit(this.data, properties)
    return new MRecord(newData)
  }

  public pick<K extends string>(properties: K[]) {
    const newData = pick(this.data, properties)
    return new MRecord(newData)
  }

  public transformKeys(f: (key: keyof T, value: valueOf<T>, object: T) => string | undefined) {
    const xs = Object.entries(this.data).map(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-type-assertion
      ([key, value]) => [f(key, value as any, this.data), value] as [string | undefined, valueOf<T>],
    )
    const newData = xs.reduce<Record<string, valueOf<T>>>((accumulator, x) => {
      const [key, value] = x
      if (key === undefined) {
        return accumulator
      }
      return {
        ...accumulator,
        [key]: value,
      }
    }, {})
    return new MRecord(newData)
  }

  public transformValues<K>(f: (value: valueOf<T>, key: keyof T, object: T) => K) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-type-assertion
    const newData = mapValues(this.data as any, f as any)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    return new MRecord(newData as MapValueType<T, K>)
  }

  public dig(path: string) {
    return safeGet(this.data, path) as valueOf<T> | undefined
  }

  public isEqual(x: Record<string, unknown>) {
    return isEqual(this.data, x) // TODO: 一旦これで実装
  }
}

export function toRecord<T extends Record<string, unknown>>(x: T) {
  return new MRecord(x)
}

function compact<T extends Record<string, unknown>>(x: T) {
  const xs = objectToArray(x).filter(([_key, value]) => isSome(value))
  const y = toObject(xs)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  return y as PickPresentType<T>
}

// string | nullのようなプロパティに対してoptionalにするのを実現できなかったため、必要な時のみこちらを使うのを想定
function compactAsNonNullableProperty<T extends Record<string, unknown>>(x: T) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  return compact(x) as PickPresentTypeWithNonNullable<T>
}

export function findValueInRecord<T>(attributes: Record<string, T>, pred: (k: string) => boolean): T | undefined {
  const pair = Object.entries(attributes).find(([key, value]) => pred(key))
  return pair?.[1]
}
