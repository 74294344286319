import { SALESCORE_GOAL_MODEL } from '../../../constant/models/SALESCORE_GOAL_MODEL'
import { SALESCORE_USER_GROUP_MODEL } from '../../../constant/models/SALESCORE_USER_GROUP_MODEL'
import { getSalescoreUserGroupsWithParentsModel } from '../../../constant/models/SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL'
import { SALESCORE_USER_MODEL } from '../../../constant/models/SALESCORE_USER_MODEL'
import { getSalescoreUserWithGroupModel } from '../../../constant/models/SALESCORE_USER_WITH_GROUP_MODEL'
import { PRESET_MODELS } from '../../../constant/presetModels'
import type { CoreModel } from '../../../schemas/model/model'
import type { ModelProperty } from '../../../schemas/model/modelProperty'

type PropertyMapper = Record<string, ModelProperty>
type Mapper = Record<
  string,
  {
    model: CoreModel
    propertyMapper: PropertyMapper
  }
>

export class ModelSearcher {
  public readonly models: CoreModel[]
  private readonly mapper: Mapper

  public constructor(models: CoreModel[], userGroupCategoryNames?: string[]) {
    this.models = [
      ...models,
      SALESCORE_GOAL_MODEL,
      SALESCORE_USER_MODEL,
      SALESCORE_USER_GROUP_MODEL,
      // TODO
      // ここで2つのモデルを動的に生成するために、userGroupCategoryNamesを引数にとっている
      // この実装やめたいので、普通にモデル側に以下相当のモデルを永続化して持ちたい
      getSalescoreUserWithGroupModel(userGroupCategoryNames ?? []),
      getSalescoreUserGroupsWithParentsModel(userGroupCategoryNames ?? []),
    ]
    this.mapper = this.models
      .groupBy((x) => x.name)
      .transformValues((ms) => {
        const model = ms.first()! // 必ず1つのみ存在する

        return {
          model,
          propertyMapper: model.properties.groupBy((x) => x.name).transformValues((x) => x.first()!).data,
        }
      }).data
  }

  public searchModel(modelName: string | undefined): CoreModel | undefined {
    if (modelName === undefined) {
      return undefined
    }
    return this.mapper[modelName]?.model
  }

  // deprecated. withModel側を使うのを推奨
  // eslint-disable-next-line complexity
  public searchProperty(modelName: string | undefined, propertyName: string | undefined): ModelProperty | undefined {
    if (modelName === undefined || propertyName === undefined) {
      return undefined
    }
    // TODO: idというプロパティは現状存在しないが、salescore_usersだけは無理やり生やしている。
    if (modelName === 'salescore_users' && propertyName === 'id') {
      return PRESET_MODELS.USER.ID_PROPERTY
    }
    if (modelName === 'salescore_user_groups' && propertyName === 'id') {
      return PRESET_MODELS.USER_GROUP.ID_PROPERTY
    }
    // TODO: 2022/09/26 移行の関連で、全てのモデルについてidプロパティを生やしてしまうことにした。これで問題ないか調査
    if (propertyName === 'id') {
      return PRESET_MODELS.USER_GROUP.ID_PROPERTY
    }
    const propertyMapper = this.mapper[modelName]?.propertyMapper
    if (propertyMapper === undefined) {
      return
    }
    return propertyMapper[propertyName]
  }

  // eslint-disable-next-line complexity
  public searchPropertyWithModel(
    modelName: string | undefined,
    propertyName: string | undefined,
  ): { model: CoreModel; property: ModelProperty } | undefined {
    if (modelName === undefined || propertyName === undefined) {
      return undefined
    }
    // TODO: idというプロパティが存在しないが、salescore_usersだけは無理やり生やしている。
    if (modelName === 'salescore_users' && propertyName === 'id') {
      return {
        model: PRESET_MODELS.USER.MODEL,
        property: PRESET_MODELS.USER.ID_PROPERTY,
      }
    }
    if (modelName === 'salescore_user_groups' && propertyName === 'id') {
      return {
        model: PRESET_MODELS.USER_GROUP.MODEL,
        property: PRESET_MODELS.USER_GROUP.ID_PROPERTY,
      }
    }
    const model = this.mapper[modelName]
    if (model === undefined) {
      return
    }
    const property = model.propertyMapper[propertyName]
    if (property === undefined) {
      return
    }
    return {
      model: model.model,
      property,
    }
  }
}
