import { CORE_CONSTANT } from '../../../../constant'
import type { ModelProperty } from '../../../../schemas/model/modelProperty'
import type { ViewQueryField, ViewQueryFilter, ViewQueryFilterNode, ViewQueryNode } from '../../../../schemas/query'
import type {
  ViewConfigCustomFilter,
  ViewConfigDimensionFilter,
  ViewConfigFieldFilter,
  ViewConfigFilter,
  ViewConfigKpi,
  ViewConfigPropertyFilter,
  ViewConfigSheet,
  ViewConfigTreeNode,
} from '../../../../schemas/view_config'
import type { CompileContext } from '../../common'
import { generateFilterSql } from './filter/generateFilterSql'
import { generateDimensionField } from './measure/generateDimensionField'
import { searchFieldAndPropertyForDimension } from './searchFieldAndPropertyForDimension'

// eslint-disable-next-line @typescript-eslint/max-params
export function compileSheetViewConfigFilterTree(
  filterTree: ViewConfigSheet['filterTree'],
  nodes: ViewQueryNode[],
  configNodes: ViewConfigTreeNode[],
  fields: ViewQueryField[],
  context: CompileContext,
  config?: ViewConfigSheet, // TODO
  dimensionAliases?: ViewConfigKpi['dimensionAliases'], // TODO
): ViewQueryFilterNode {
  if (filterTree === undefined) {
    // TODO: undefinedにしたい
    return {
      logicalOperator: 'and',
      leafs: [],
      children: [],
    }
  }

  return rec(filterTree, nodes, configNodes, fields, context, config, dimensionAliases)
}

// eslint-disable-next-line @typescript-eslint/max-params
function rec(
  filterTree: Exclude<ViewConfigSheet['filterTree'], undefined>,
  nodes: ViewQueryNode[],
  configNodes: ViewConfigTreeNode[],
  fields: ViewQueryField[],
  context: CompileContext,
  config?: ViewConfigSheet, // TODO
  dimensionAliases?: ViewConfigKpi['dimensionAliases'], // TODO
): ViewQueryFilterNode {
  return {
    logicalOperator: filterTree.logicalOperator,
    leafs: filterTree.leafs
      .map((leaf) => convertLeaf(leaf, nodes, configNodes, fields, context, config, dimensionAliases))
      .compact(),
    children: filterTree.children.map((child) =>
      rec(child, nodes, configNodes, fields, context, config, dimensionAliases),
    ),
  }
}

// eslint-disable-next-line @typescript-eslint/max-params
function convertLeaf(
  leaf: ViewConfigFilter,
  nodes: ViewQueryNode[],
  configNodes: ViewConfigTreeNode[],
  fields: ViewQueryField[],
  context: CompileContext,
  config?: ViewConfigSheet, // TODO
  dimensionAliases?: ViewConfigKpi['dimensionAliases'], // TODO
): ViewQueryFilter | undefined {
  const { type } = leaf
  switch (type) {
    case 'property': {
      return convertLeafForProperty(leaf, nodes, configNodes, context)
    }
    case 'field': {
      return convertLeafForField(leaf, fields, context)
    }
    case 'dimension': {
      return convertLeafForDimension(leaf, nodes, configNodes, context, config, dimensionAliases)
    }
    case 'custom': {
      return convertLeafForCustom(leaf, context)
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = type
      throw new Error(`対応していない絞り込み種別です`)
    }
  }
}

// eslint-disable-next-line complexity,@typescript-eslint/max-params
function convertLeafForDimension(
  leaf: ViewConfigDimensionFilter,
  queryNodes: ViewQueryNode[],
  configNodes: ViewConfigTreeNode[],
  context: CompileContext,
  config?: ViewConfigSheet, // TODO
  dimensionAliases?: ViewConfigKpi['dimensionAliases'], // TODO
): ViewQueryFilter | undefined {
  if (config === undefined) {
    context.logs.warn(`軸フィルタ: configが見つかりません`)
    return undefined
  }
  if (leaf.dimension.key === CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_DIMENSION().key) {
    // KPIグループのフィルタはcompileKpiPivotViewConfig内で処理する
    return undefined
  }

  // TODO
  const previousConfig = context.additionalConfig?.previousConfig
  const previousKpiConfig = previousConfig?.type === 'kpi' ? previousConfig : undefined
  const dimensionWithProperty = searchFieldAndPropertyForDimension(
    leaf.dimension,
    config,
    [
      ...(dimensionAliases ?? []),
      previousKpiConfig?.date === undefined
        ? undefined
        : {
            alias: CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION().key, // TODO: 各所でこういうdimensionAliasの処理をしている？
            property: previousKpiConfig.date.property,
          },
    ].compact(),
    context,
  )
  const fieldSql = generateDimensionField(
    dimensionWithProperty,
    { queryNodes, configNodes },
    context,
    true,
    [],
  )?.fieldSql
  if (fieldSql === undefined || fieldSql === 'NULL') {
    context.logs.warn(
      `軸「${leaf.dimension.label}」はこのビューに含まれないため、フィルタはできません。${leaf.dimension.key}`,
    )
    // 指定された軸フィルタに相当する軸がない場合
    // ・ドリルダウンによって生成された軸フィルタのとき：常にFALSEを返す
    //   (例) ダッシュボードで「リードソース」を軸に集計して、「商談数」KPIを表示しているが、
    //       商談数にはリードソースの項目が含まないため、すべて「-」で表示されているときに、「-」をクリックした時。
    // ・それ以外（ピボットテーブル側で指定した軸フィルタ）：フィルタを無視する
    //   商談数KPIにリードソースがある時、KPIダッシュボード上で「リードソース: Facebook広告」などでフィルタしたとき
    //   （やや違和感はあるが、このときはフィルタを無視したいらしい）
    if (leaf.option?.isDrillDownFilter === true) {
      return {
        nodePaths: [],
        read: {
          sql: `FALSE`, // 本来は、そもそもSQLの実行をしないのが望ましい
        },
      }
    }
    return undefined
  }

  const valueSql =
    leaf.filterValueParameterName === undefined ? leaf.filterValue : `{{ ${leaf.filterValueParameterName} }}` // TODO: validation
  // dimensionの時は常に文字列のはず
  const sql = generateFilterSql({
    fieldSql,
    fieldType: 'string' as const,
    filterType: leaf.filterType,
    filterValue: valueSql,
    dateTimeType: leaf.filterDateTimeType,
    context,
  })
  if (sql === undefined) {
    context.logs.warn(`軸フィルタ: 絞り込みSQLの生成に失敗しました。${leaf.dimension.label}`) // ありえないはず？
    return undefined
  }

  return {
    nodePaths: [], // TODO
    read: {
      sql,
      dependedParameters: leaf.filterValueParameterName === undefined ? undefined : [leaf.filterValueParameterName],
    },
  }
}

// eslint-disable-next-line complexity
function convertLeafForField(
  leaf: ViewConfigFieldFilter,
  fields: ViewQueryField[],
  context: CompileContext,
): ViewQueryFilter | undefined {
  const field = fields.find((x) => x.name === leaf.fieldName)
  if (field === undefined) {
    // TODO: 2022/09/02 adhocな対応。idというpropertyが存在しないせいで、idを指定するとエラーになる
    if (leaf.fieldName === `salescore_users_id`) {
      return {
        nodePaths: [],
        read: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          sql: `salescore_users.id = '${leaf.filterValue as string}'`,
        },
      }
    }
    context.logs.warn(
      `[convertLeafForField] フィールドが見つかりません。${leaf.fieldName}, fields: ${fields
        .map((x) => x.name)
        .join(',')}`,
    )
    return undefined
  }
  const fieldSql = field.read.sql
  const valueSql =
    leaf.filterValueParameterName === undefined ? leaf.filterValue : `{{ ${leaf.filterValueParameterName} }}` // TODO: validation
  const sql = generateFilterSql({
    fieldSql,
    fieldType: field.meta.fieldType,
    filterType: leaf.filterType,
    filterValue: valueSql,
    dateTimeType: leaf.filterDateTimeType,
    context,
  })
  if (sql === undefined) {
    context.logs.warn(`絞り込みSQLの生成に失敗しました。${leaf.fieldName}`) // ありえないはず？
    return undefined
  }

  return {
    nodePaths: [field.nodePath],
    read: {
      sql,
      dependedParameters: leaf.filterValueParameterName === undefined ? undefined : [leaf.filterValueParameterName],
    },
  }
}

// eslint-disable-next-line complexity
function convertLeafForProperty(
  leaf: ViewConfigPropertyFilter,
  nodes: ViewQueryNode[],
  configNodes: ViewConfigTreeNode[],
  context: CompileContext,
): ViewQueryFilter | undefined {
  const node = nodes.find((x) => x.name === leaf.property.nodeName)
  if (node === undefined) {
    context.logs.warn(`絞り込み用のノードが見つかりません。${leaf.property.nodeName}.${leaf.property.propertyName}`)
    return undefined
  }

  const property = context.modelSearcher.searchProperty(leaf.property.modelName, leaf.property.propertyName)
  if (property === undefined) {
    context.logs.warn(`絞り込み用のプロパティが見つかりません。${leaf.property.nodeName}.${leaf.property.propertyName}`)
    return undefined
  }

  // 基本的にはpropertyに対してフィルタを適用するが、オプション設定がされていればnameの値を用いる
  // 2022/07 移行状況がやや怪しく、equal_meの時(=必ずIDのはず)のときにnameを使ってしまうケースがあったため、equal_meであれば強制的にIDを使うようにする
  const shouldFilterToReferencedEntityName = leaf.option?.shouldFilterToReferencedEntityName ?? true // デフォルトはtrue
  const fieldSql =
    shouldFilterToReferencedEntityName && leaf.filterType !== 'equal_me'
      ? (getNamePropertySql(node, property, configNodes, context) ?? `"${node.name}"."${property.name}"`)
      : `"${node.name}"."${property.name}"`
  const valueSql = generateValueSql(leaf, context)
  const sql = generateFilterSql({
    fieldSql,
    fieldType: property.type,
    filterType: leaf.filterType,
    filterValue: valueSql,
    dateTimeType: leaf.filterDateTimeType,
    context,
    shouldConsiderCase: leaf.option?.shouldConsiderCase,
  })
  if (sql === undefined) {
    context.logs.warn(`絞り込みSQLの生成に失敗しました。${leaf.property.propertyName} ${JSON.stringify(leaf)}`) // ありえないはず？
    return undefined
  }

  return {
    nodePaths: [node.path],
    read: {
      sql,
      dependedParameters: leaf.filterValueParameterName === undefined ? undefined : [leaf.filterValueParameterName],
    },
  }
}

function convertLeafForCustom(leaf: ViewConfigCustomFilter, context: CompileContext): ViewQueryFilter | undefined {
  return {
    nodePaths: [],
    read: {
      sql: leaf.filterValue,
    },
  }
}

function generateValueSql(leaf: ViewConfigPropertyFilter, context: CompileContext) {
  if (leaf.filterValueParameterName === undefined) {
    return leaf.filterValue
  }
  const property = context.modelSearcher.searchProperty(leaf.property.modelName, leaf.property.propertyName)
  if (property === undefined) {
    return `'{{ ${leaf.filterValueParameterName} }}'`
  }
  if (property.type === 'numeric' || property.type === 'integer') {
    return `{{ ${leaf.filterValueParameterName} }}`
  }
  return `'{{ ${leaf.filterValueParameterName} }}'`
}

// eslint-disable-next-line complexity
function getNamePropertySql(
  node: ViewQueryNode,
  property: ModelProperty,
  configNodes: ViewConfigTreeNode[],
  context: CompileContext,
): string | undefined {
  const configNode = configNodes.find((x) => x.name === node.name)
  if (configNode === undefined) {
    // ありえないはず
    return undefined
  }

  const childConfigNode = (configNode.children ?? []).find(
    (childNode) =>
      childNode.referenceToProperty?.propertyName === property.name &&
      childNode.referenceToProperty.modelName === configNode.modelName &&
      property.referenceTo?.some((x) => x.modelName === childNode.referenceToProperty?.referenceTo.modelName),
  )

  if (childConfigNode === undefined) {
    // ありえないはず
    return undefined
  }

  const referencingModel = context.modelSearcher.searchModel(childConfigNode.modelName)
  const nameProperty = referencingModel?.properties.find((x) => x.meta === 'name')
  if (nameProperty === undefined) {
    return undefined
  }

  return `"${childConfigNode.name}"."${nameProperty.name}"`
}
