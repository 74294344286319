import { PageHeader } from '@ant-design/pro-layout'
import { useQuery } from '@apollo/client'
import { isSome } from '@salescore/buff-common'
import { FetchJobQueueDocument, type JobQueueFieldsFragment } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { JobQueueStatusTag } from '@salescore/client-common'
import { datetimeFormat } from '@salescore/frontend-common'
import { Alert, Descriptions, Skeleton, Space } from 'antd'
import { t } from 'i18next'
import type { ReactNode } from 'react'

import { JobQueueLogTable } from './JobQueueLogTable'

// eslint-disable-next-line complexity
export const JobQueueDetail = ({
  queueId,
  detail,
}: {
  queueId: string
  detail?: (queue: JobQueueFieldsFragment) => ReactNode
}): ReactNode => {
  // TODO
  const { data } = useQuery(FetchJobQueueDocument, {
    variables: {
      id: queueId,
      organizationId: getOrganizationIdFromPath(),
    },
    pollInterval: 1000,
  })

  const jobQueue = data?.jobQueue
  if (jobQueue === undefined) {
    return <Skeleton />
  }
  return (
    <PageHeader
      className="overflow-scroll"
      title={
        <Space>
          {jobQueue.jobName} <JobQueueStatusTag status={jobQueue.status} />
        </Space>
      }
      // extra={[
      //   <KillQueueButton id={queue.id} status={queue.status} />,
      //   <RetryQueueButton
      //     id={queue.id}
      //     onFinish={async (queue) => {
      //       if (isSome(changeQueue)) {
      //         await changeQueue(queue)
      //       }
      //     }}
      //   />,
      // ]}
    >
      {isSome(jobQueue.message) && (
        <Alert
          message={jobQueue.message}
          type={jobQueue.status === 'error' ? 'error' : 'info'}
          showIcon
          className="mb-4"
        />
      )}
      <Descriptions title={t(`詳細`)} column={4}>
        <Descriptions.Item label="id">{jobQueue.id}</Descriptions.Item>
        <Descriptions.Item span={2} label="jobArg">
          {JSON.stringify(jobQueue.jobArg)}
        </Descriptions.Item>
        {/* <Descriptions.Item label="実行時間">{durationJa(queue.startedAt, queue.stoppedAt)}</Descriptions.Item> */}
        <Descriptions.Item label={t(`開始時刻`)}>{datetimeFormat(jobQueue.startedAt)}</Descriptions.Item>
        <Descriptions.Item label={t(`終了時刻`)}>{datetimeFormat(jobQueue.stoppedAt)}</Descriptions.Item>
      </Descriptions>
      {detail?.(jobQueue)}
      <JobQueueLogTable jobQueueLogs={jobQueue.jobQueueLogs} />
    </PageHeader>
  )
}
