import { mutation, wrapKeyboardEvent } from '@salescore/frontend-common'
import type { KeyboardEvent } from 'react'
import { useSetRecoilState } from 'recoil'

import { backHistoryMutation, forwardHistoryMutation } from '../../records/mutations/changesMutation'

// rsheet以外での使用を想定したhandleKeyDown
const handleKeyDownMutation = mutation({
  key: `navigation/handleKeyDownMutation`,
  // eslint-disable-next-line complexity
  set({ get, set }, event: KeyboardEvent<HTMLDivElement>) {
    const wrapedEvent = wrapKeyboardEvent(event)

    switch (event.key) {
      case 'z': {
        if (wrapedEvent.commandKey && wrapedEvent.shiftKey) {
          set(backHistoryMutation, 1)
          event.preventDefault()
          return
        }
        if (wrapedEvent.commandKey) {
          set(backHistoryMutation, 1)
          event.preventDefault()
          return
        }
        break
      }
      case 'y': {
        if (wrapedEvent.commandKey) {
          set(forwardHistoryMutation, undefined)
          event.preventDefault()
          return
        }
        break
      }
      case 's': {
        if (wrapedEvent.commandKey) {
          // void recordsAndChangesMutation.onSave() // TODO: async setはrecoilで未実装なため、mutationとして実装できない。ここだけuseAsyncHandleKeyDownで実装している。
          event.preventDefault()
          return
        }
        break
      }
    }

    // 特に必要ないが、unnecessary returnと言われないように定義しておく
    return false
  },
})

export const useHandleKeyDownMutation = () => useSetRecoilState(handleKeyDownMutation)
