import type { ViewUIFormItem } from '@salescore/core'
import { Form } from 'antd'
import { useRecoilValue } from 'recoil'

import { validationErrorVisibilityAtom } from '../../recoil/view/atoms'
import { useViewUIRecordNodeContext } from './contexts'
import { ViewUIComponentC } from './ViewUIComponent'
import { viewUiValidation } from './viewUiValidation'

export function ViewUIFormItemC({ component }: { component: ViewUIFormItem }) {
  const { getValue } = useViewUIRecordNodeContext()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const value = getValue(component.fieldName) as string
  const validationErrorVisibility = useRecoilValue(validationErrorVisibilityAtom)
  const errors = validationErrorVisibility ? viewUiValidation(value, component) : []

  return (
    <Form.Item
      label={<span>{component.label}</span>}
      // name={formListField === undefined ? component.path : [formListField.name, ...component.path]}
      style={{
        marginBottom: 12,
      }}
      // Salesforceで真偽値の場合、必ず必須項目になっている？が、チェックボックスを必須と表示すると見た目的にわかりづらいので一旦表示させない
      // 問題が起きるようであればSalesforce側での根本的な対応を検討
      // rules={isFormItemForCheckbox ? [] : component.validationRules ?? []}
      required={component.required}
    >
      <ViewUIComponentC component={component.child} />
      {errors.isPresent() && (
        <div className="text-red-500">
          {errors.map((error) => (
            <span>{error.message}</span>
          ))}
        </div>
      )}
    </Form.Item>
  )
}
