// 選択肢系の_label, _valueのような現在不要な派生プロパティを削除する

import { z } from 'zod'

import type { CoreModel } from '../../schemas/model/model'
import type { ModelProperty } from '../../schemas/model/modelProperty'
import type {
  ViewConfig,
  ViewConfigField,
  ViewConfigFilterNode,
  ViewConfigSheet,
  ViewConfigSorter,
} from '../../schemas/view_config'
import { ModelSearcher } from '../view/compileViewConfig/ModelSearcher'

type Change = string

export function removeDerivedProperties(config: ViewConfig, models: CoreModel[]): ViewConfig | undefined {
  const searcher = new ModelSearcher(models)

  switch (config.type) {
    case 'sheet': {
      return removeDerivedPropertiesFromSheet(config, searcher)
    }
    // TODO
    default: {
      return undefined
    }
  }
}

function removeDerivedPropertiesFromSheet(
  config: ViewConfigSheet,
  searcher: ModelSearcher,
): ViewConfigSheet | undefined {
  const changes: Change[] = []
  const fields = removeDerivedPropertiesFromFields(config, searcher, changes)
  const filterTree = removeDerivedPropertiesFromFilter(config, searcher, changes)
  const sorters = removeDerivedPropertiesFromSorters(config, searcher, changes)

  if (changes.isPresent()) {
    return {
      ...config,
      fields,
      filterTree,
      sorters,
    }
  }
}

function removeDerivedPropertiesFromFields(config: ViewConfigSheet, searcher: ModelSearcher, changes: Change[]) {
  return config.fields?.map((field): ViewConfigField => {
    const property = findOriginalPropertyForSelectOptions(field.property, searcher)
    if (property === undefined) {
      return field
    }
    changes.push(`${property.property.name}を${property.originalProperty.name}に変換`)

    return {
      ...field,
      property: {
        ...field.property,
        propertyName: property.originalProperty.name,
      },
    }
  })
}

// eslint-disable-next-line complexity
function findOriginalPropertyForSelectOptions(
  { modelName, propertyName }: { modelName: string; propertyName: string },
  searcher: ModelSearcher,
) {
  const property = searcher.searchProperty(modelName, propertyName)
  const selectOptions = property?.selectOptions ?? []
  if (property === undefined || selectOptions.isBlank()) {
    return
  }
  if (!property.name.endsWith(`_label`) && !property.name.endsWith(`_value`)) {
    return
  }
  const originalPropertyName = property.name.replace(/_label$/, '').replace(/_value$/, '')
  const originalProperty = searcher.searchProperty(modelName, originalPropertyName)
  if (originalProperty === undefined) {
    return
  }
  return {
    property,
    originalProperty,
  }
}

function removeDerivedPropertiesFromFilter(config: ViewConfigSheet, searcher: ModelSearcher, changes: Change[]) {
  if (config.filterTree === undefined) {
    return
  }
  return removeDerivedPropertiesFromFilterRec(config.filterTree, searcher, changes)
}

function removeDerivedPropertiesFromFilterRec(
  tree: ViewConfigFilterNode,
  searcher: ModelSearcher,
  changes: Change[],
): ViewConfigFilterNode {
  return {
    ...tree,
    children: tree.children.map((child) => removeDerivedPropertiesFromFilterRec(child, searcher, changes)),
    leafs: tree.leafs.map((leaf) => {
      if (leaf.type !== 'property') {
        return leaf
      }
      const property = findOriginalPropertyForSelectOptions(leaf.property, searcher)
      if (property === undefined) {
        return leaf
      }
      changes.push(`${property.property.name}を${property.originalProperty.name}に変換`)
      return {
        ...leaf,
        filterValue: convertFilterValue(leaf.filterValue, property.originalProperty, property.property, changes),
        property: {
          ...leaf.property,
          propertyName: property.originalProperty.name,
        },
      }
    }),
  }
}

// eslint-disable-next-line complexity
function convertFilterValue(
  filterValue: unknown,
  originalProperty: ModelProperty,
  property: ModelProperty,
  changes: Change[],
): unknown {
  if (!property.name.endsWith(`_label`)) {
    return filterValue
  }
  const labelToValue = (originalProperty.selectOptions ?? []).toObject((x) => [x.label, x.value])
  const stringValue = z.string().safeParse(filterValue)
  const stringsValue = z.string().array().safeParse(filterValue)
  if (stringValue.success) {
    const converted = labelToValue[stringValue.data] ?? stringValue.data
    if (converted !== filterValue) {
      changes.push(`絞り込み選択肢の値 ${stringValue.data} を ${converted} に変換`)
    }
    return converted
  }
  if (stringsValue.success) {
    const converted = stringsValue.data.map((x) => labelToValue[x] ?? x)
    if (!converted.isEqual(stringsValue.data)) {
      changes.push(`絞り込み選択肢の値 ${stringsValue.data.join(',')} を ${converted.join(',')} に変換`)
    }
    return converted
  }
  return filterValue
}

function removeDerivedPropertiesFromSorters(config: ViewConfigSheet, searcher: ModelSearcher, changes: Change[]) {
  // fieldsと同じように変換するだけ(並び順が変わってしまうことはあるが、許容する)
  return config.sorters?.map((sorter): ViewConfigSorter => {
    const property = findOriginalPropertyForSelectOptions(sorter.property, searcher)
    if (property === undefined) {
      return sorter
    }
    changes.push(`${property.property.name}を${property.originalProperty.name}に変換`)

    return {
      ...sorter,
      property: {
        ...sorter.property,
        propertyName: property.originalProperty.name,
      },
    }
  })
}
