import type { FetchMeQuery } from '@salescore/client-api'
import { notifyBugsnag } from '@salescore/client-base'
import { logger } from '@salescore/frontend-common'
import * as React from 'react'

import { Error, ErrorContext } from '../components/Error'

interface Properties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router: any
  myUser?: Exclude<FetchMeQuery['myUser'], undefined | null>
  myIdentity?: FetchMeQuery['myUser']['identity']
}

export class ErrorProvider extends React.Component<Properties, { errors: Error[] }> {
  constructor(properties: Properties) {
    super(properties)
    this.state = { errors: [] }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override componentDidCatch(error: Error, _errorInfo: any) {
    notifyBugsnag({
      error,
    })

    if (process.env.NODE_ENV === 'development') {
      throw error
    } else {
      this.setState({ errors: [error] })
    }
  }

  override render() {
    // Error handler
    // eslint-disable-next-line complexity
    const throwErrors = (errorOrErrors: Error | Error[]) => {
      const errors: Error[] = Array.isArray(errorOrErrors) ? errorOrErrors : [errorOrErrors]

      const authorizationErrors = errors.filter(
        (x) => x.message.includes('Unauthorized: ') || x.message.includes('Forbidden: '),
      )
      if (authorizationErrors.length > 0) {
        // 現在のエラーと同じだったらセットしなおさない（無駄なレンダリングを防ぐ）
        if (JSON.stringify(this.state.errors) !== JSON.stringify(authorizationErrors)) {
          this.setState({ errors: authorizationErrors })
        }
      } else {
        for (const e of errors) {
          logger.debug(e)
        }
        if (JSON.stringify(this.state.errors) !== JSON.stringify(errors)) {
          this.setState({ errors })
        }
      }
    }

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const clearErrors = () => {
      this.setState({ errors: [] })
    }

    return (
      <ErrorContext.Provider value={{ throwErrors, clearErrors, myIdentity: this.props.myIdentity }}>
        {this.state.errors.length > 0 ? (
          <Error errors={this.state.errors} myUser={this.props.myUser} />
        ) : (
          this.props.children
        )}
      </ErrorContext.Provider>
    )
  }
}
