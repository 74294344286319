import { countHankakuAndZenkaku, isSome } from '@salescore/buff-common'
import { DelayWrapper } from '@salescore/client-common'
import { CORE_CONSTANT, type ViewUiKpiPivot } from '@salescore/core'
import { logger } from '@salescore/frontend-common'
import { Table } from 'antd'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { CLIENT_VIEW_CONSTANT } from '../../../constants/constants'
import { useNavigationModal } from '../../../recoil/navigation/hooks'
import { viewQueryResultAtom } from '../../../recoil/records/atoms'
import { useViewQueryResult } from '../../../recoil/records/hooks'
import { pivotColumnsSelector } from '../../../recoil/selectors/pivotColumnsSelector'
import { autoDrillDownParameterAtom, loadingProgressAtom } from '../../../recoil/view/atoms'
import { useMeValue, useUiValue, useViewValue } from '../../../recoil/view/hooks'
import { RSheet } from '../../../rsheet/components/RSheet'
import { ViewLoading } from '../../ViewLoading'

export const ViewUIKpiPivotBody = ({ component }: { component: ViewUiKpiPivot }) => {
  const me = useMeValue()
  const ui = useUiValue()
  const view = useViewValue()
  const queryResult = useViewQueryResult()
  const { columns } = useRecoilValue(pivotColumnsSelector)
  const [autoDrillDownParameter, setAutoDrillDownParameter] = useRecoilState(autoDrillDownParameterAtom)
  const { drillDownModal } = useNavigationModal()
  const setLoadingProgress = useSetRecoilState(loadingProgressAtom)
  const isEmptyTable = isSome(queryResult) && columns.isBlank()

  useEffect(() => {
    if (isEmptyTable) {
      setLoadingProgress((progress) => ({
        ...progress,
        completed: true,
      }))
    }
  }, [isEmptyTable])

  // 2023/04/10 不具合調査のため、ログを仕込む
  const viewQueryResult = useRecoilValue(viewQueryResultAtom)
  useEffect(() => {
    logger.debug(`ViewUIKpiPivotSheetBody ${me.organization.name}: ${me.myUser.name}`, {
      columnsCount: columns.length,
      organizationId: me.organization.id,
      organizationName: me.organization.name,
      userName: me.myUser.name,
      email: me.myIdentity.email,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-type-assertion
      ui: ui as any,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-type-assertion
      viewQueryResult: viewQueryResult as any,
    })
    if (!columns.isBlank() && autoDrillDownParameter !== undefined) {
      drillDownModal.showModal(autoDrillDownParameter)
      setAutoDrillDownParameter(undefined)
    }
  }, [columns])

  const headerWithBigIcon =
    // component.pivot.columns.length === 1 &&
    component.pivot.columns.last()?.key === CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME

  return (
    <div className="view-ui-sheet h-full">
      {isEmptyTable ? (
        <DelayWrapper
          delay={CLIENT_VIEW_CONSTANT.RENDER_DELAY_AFTER_LOAD}
          fallback={<ViewLoading context={{ view }} />}
        >
          {/* ローディング画面で読み込みが完了したことを通知してからViewを表示するために、描画タイミングを読み込み完了からごく短時間遅らせている */}
          <Table />
        </DelayWrapper>
      ) : (
        <RSheet
          height="100%" // TODO
          columns={columns}
          context={{
            kpis: component.kpis,
            pivot: component.pivot,
            asKpiTable: true,
            headerWithBigIcon,
            headerTitleRowNum: headerWithBigIcon
              ? columns.map((x) => calculateHeaderTitleRowNumber(x.title ?? '', x.width ?? 100)).max()
              : 1,
            fixedLeftColumnIndex: component.pivot.rows.length + 1,
          }}
        />
      )}

      {/* TODO */}
      {/* <ViewRecordDetailModal
        previousView={{
          ...view,
          config,
          queries: [query],
          ui,
        }}
        onFinish={async () => {
          await recordsMutation.refetch()
        }}
      />
      <Modal
        visible={initializeModal.isModalVisible}
        onCancel={initializeModal.hideModal}
        width={'60%'}
        cancelText={'閉じる'}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div></div>}
        style={{ top: '3%' }}
        destroyOnClose
      >
        <PropertySelectorTableForContextMenu initialNodePath={[]} fieldIndex={0} />
      </Modal> */}
    </div>
  )
}

// かなりざっくり
const HANKAKU_CHAR_WIDTH = 7.5
const ZENKAKU_CHAR_WIDTH = 12.5

function calculateHeaderTitleRowNumber(title: string, width: number): number {
  const result = countHankakuAndZenkaku(title)
  const widthForTitle =
    result.hankakuChars.length * HANKAKU_CHAR_WIDTH + result.zenkakuChars.length * ZENKAKU_CHAR_WIDTH
  const rowNumber = Math.ceil(widthForTitle / width)
  return rowNumber
}
