import {
  ApartmentOutlined,
  AuditOutlined,
  BankOutlined,
  DollarCircleOutlined,
  MailOutlined,
  MoneyCollectOutlined,
  PhoneOutlined,
  StarOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import {
  faAddressCard,
  faAngry,
  faBells,
  faBuilding,
  faCalendar,
  faCalendarPlus,
  faChartArea,
  faChartBar,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChessKing,
  faChessQueen,
  faChild,
  faCoins,
  faComment,
  faCommentAlt,
  faComments,
  faDatabase,
  faDesktop,
  faDoorClosed,
  faDoorOpen,
  faDownload,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenDollar,
  faEnvelopeOpenText,
  faExclamationTriangle,
  faFileContract,
  faFlag,
  faFlagCheckered,
  faHandshake,
  faHome,
  faHotel,
  faHouse,
  faHouseLeave,
  faHouseReturn,
  faHouseUser,
  faIdCard,
  faIdCardAlt,
  faIndustry,
  faMailbox,
  faMailBulk,
  faMoneyBill,
  faMoneyCheckEdit,
  faPhone,
  faPhonePlus,
  faPhoneVolume,
  faSitemap,
  faStamp,
  faStar,
  faToolbox,
  faTools,
  faUser,
  faUserChart,
  faUserEdit,
  faUserFriends,
  faUsers,
  faWalking,
  faYenSign,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import type { CSSProperties } from 'react'

const GROUP_NAME = {
  RECOMMEND: t(`おすすめ`),
}

export const KPI_ICONS = [
  {
    name: 'faAddressCard',
    icon: faAddressCard,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faAngry',
    icon: faAngry,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faBells',
    icon: faBells,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faBuilding',
    icon: faBuilding,
    label: t(`会社`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faCalendar',
    icon: faCalendar,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faCalendarPlus',
    icon: faCalendarPlus,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faChartArea',
    icon: faChartArea,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faChartBar',
    icon: faChartBar,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faChartPie',
    icon: faChartPie,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faCheck',
    icon: faCheck,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faCheckCircle',
    icon: faCheckCircle,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faCheckSquare',
    icon: faCheckSquare,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faChessKing',
    icon: faChessKing,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faChessQueen',
    icon: faChessQueen,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faChild',
    icon: faChild,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faCoins',
    icon: faCoins,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faComment',
    icon: faComment,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faCommentAlt',
    icon: faCommentAlt,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faComments',
    icon: faComments,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faDatabase',
    icon: faDatabase,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faDesktop',
    icon: faDesktop,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faDoorClosed',
    icon: faDoorClosed,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faDoorOpen',
    icon: faDoorOpen,
    label: t(`訪問`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faDownload',
    icon: faDownload,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faEnvelope',
    icon: faEnvelope,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faEnvelopeOpen',
    icon: faEnvelopeOpen,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faEnvelopeOpenDollar',
    icon: faEnvelopeOpenDollar,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faEnvelopeOpenText',
    icon: faEnvelopeOpenText,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faExclamationTriangle',
    icon: faExclamationTriangle,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faFileContract',
    icon: faFileContract,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faFlag',
    icon: faFlag,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faFlagCheckered',
    icon: faFlagCheckered,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faHandshake',
    icon: faHandshake,
    label: t(`契約締結`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faHome',
    icon: faHome,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faHotel',
    icon: faHotel,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faHouse',
    icon: faHouse,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faHouseLeave',
    icon: faHouseLeave,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faHouseReturn',
    icon: faHouseReturn,
    label: t(`訪問`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faHouseUser',
    icon: faHouseUser,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faIdCard',
    icon: faIdCard,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faIdCardAlt',
    icon: faIdCardAlt,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faIndustry',
    icon: faIndustry,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faMailBulk',
    icon: faMailBulk,
    label: t(`メール`),
    groupName: undefined,
  },
  {
    name: 'faMailbox',
    icon: faMailbox,
    label: t(`メール`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faMoneyBill',
    icon: faMoneyBill,
    label: t(`売上`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faMoneyCheckEdit',
    icon: faMoneyCheckEdit,
    label: t(`契約締結`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faPhone',
    icon: faPhone,
    label: t(`電話`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faPhonePlus',
    icon: faPhonePlus,
    label: t(`電話`),
    groupName: undefined,
  },
  {
    name: 'faPhoneVolume',
    icon: faPhoneVolume,
    label: t(`電話`),
    groupName: undefined,
  },
  {
    name: 'faSitemap',
    icon: faSitemap,
    label: t(`部署`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faStamp',
    icon: faStamp,
    label: t(`契約締結`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faStar',
    icon: faStar,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faToolbox',
    icon: faToolbox,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faTools',
    icon: faTools,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faUser',
    icon: faUser,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faUserEdit',
    icon: faUserEdit,
    label: undefined,
    groupName: undefined,
  },
  {
    name: 'faUserFriends',
    icon: faUserFriends,
    label: t(`ペア`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faUsers',
    icon: faUsers,
    label: t(`チーム`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faUserChart',
    icon: faUserChart,
    label: t(`デモ`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faWalking',
    icon: faWalking,
    label: t(`訪問`),
    groupName: GROUP_NAME.RECOMMEND,
  },
  {
    name: 'faYenSign',
    icon: faYenSign,
    label: t(`売上`),
    groupName: GROUP_NAME.RECOMMEND,
  },
]

export const GROUPED_KPI_ICONS = KPI_ICONS.groupBy((x) => x.groupName ?? '')
  .toArray()
  .mySortBy(([groupName, icons]) => (groupName === undefined ? 1 : 0))

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const KPI_ICONS_OLD = {
  seika: <DollarCircleOutlined />,
  tanka: <MoneyCollectOutlined />,
  jutyu: <FontAwesomeIcon icon={faHandshake} />,
  tenkai: <FontAwesomeIcon icon={faHandshake} />,
  demo: <FontAwesomeIcon icon={faUserChart} />,
  houmon: <FontAwesomeIcon icon={faWalking} />,
  shokai: <FontAwesomeIcon icon={faUserFriends} />,
  pair: <UsergroupAddOutlined />,
  kpi: <StarOutlined />,
  telpr: <PhoneOutlined />,
  mail: <MailOutlined />,
  syanai: <BankOutlined />,
  sinbusho: <ApartmentOutlined />,
  sintantosha: <AuditOutlined />,
  default: <StarOutlined />,
} as Record<string, JSX.Element>

// eslint-disable-next-line complexity
export const KpiIcon = ({
  imageName,
  className,
  style,
}: {
  imageName?: string | null
  className?: string
  style?: CSSProperties
}) => {
  const icon = KPI_ICONS.find((x) => x.name === imageName)
  if (imageName === undefined) {
    return <StarOutlined style={style} />
  }
  if (icon) {
    return (
      <span className={`mb-0 ${className ?? ''}`} style={style}>
        <FontAwesomeIcon icon={icon.icon} fixedWidth={true} />
      </span>
    )
  }
  return (
    <span className={`mb-0 ${className ?? ''}`} style={style}>
      {KPI_ICONS_OLD[imageName ?? ''] ?? <StarOutlined />}
    </span>
  )
}
