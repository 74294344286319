import { DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { isSome, treeUtil } from '@salescore/buff-common'
import { type FetchUserGroupsQuery, UpsertUserGroupsDocument } from '@salescore/client-api'
import {
  convertTreeToItems,
  type ExtendedDataNodeTree,
  getNewTree,
  getOrganizationIdFromPath,
  UserAvatar,
} from '@salescore/client-common'
import { FEATURE_CONSTANT } from '@salescore/features'
import { useModal } from '@salescore/frontend-common'
import { Button, message, Popconfirm, Row, Space, Tree } from 'antd'
import Modal from 'antd/es/modal/Modal'
import type { DataNode } from 'antd/es/tree'
import { t } from 'i18next'
import { type ReactNode, useEffect, useState } from 'react'

import { UpsertUserGroupForm } from './UpsertUserGroupForm'

type UserGroup = FetchUserGroupsQuery['userGroups'][0]

type NodeTree = ExtendedDataNodeTree<UserGroup>

export const UserGroupsTree = ({
  userGroups,
  userGroupCategoryNames,
  onUpdateUserGroupSort = undefined,
  onAfterFinish = undefined,
  onSort = undefined,
}: {
  userGroups: UserGroup[]
  userGroupCategoryNames: string[]
  onUpdateUserGroupSort?: () => void
  onAfterFinish?: () => void
  onSort?: (userGroups: UserGroup[]) => void
}): ReactNode => {
  // 木構造の初期化
  const [treeData, setTreeData] = useState<NodeTree>(() =>
    treeUtil.createTreesFromItems({
      items: userGroups,
      getId: (userGroup) => userGroup.id,
      getParentId: (userGroup) => userGroup.user_group_id ?? undefined,
    }),
  )
  useEffect(() => {
    const tree = treeUtil.createTreesFromItems({
      items: userGroups,
      getId: (userGroup) => userGroup.id,
      getParentId: (userGroup) => userGroup.user_group_id ?? undefined,
    })
    setTreeData(tree)
  }, [userGroups])

  const [deletingIds, setDeletingIds] = useState<string[]>([])
  const modal = useModal<UserGroup>()
  const [upsertUserGroupsMutation] = useMutation(UpsertUserGroupsDocument)

  const handleSort = (tree: NodeTree): void => {
    const newUserGroups = convertTreeToItems({
      tree,
      parentId: undefined,
      parentPropertyName: 'user_group_id',
      depth: 1,
    })
    onSort?.(newUserGroups)
  }

  const onAfterFinishAsTable = (): void => {
    modal.hideModal()
    onAfterFinish?.()
  }

  const onDestroy = async (userGroup: UserGroup): Promise<void> => {
    // TODO: destroyしたいだけなのに各値を明示するのが面倒
    try {
      setDeletingIds((oldids) => [...oldids, userGroup.id])
      await upsertUserGroupsMutation({
        variables: {
          organizationId: getOrganizationIdFromPath(),
          userGroups: [
            {
              id: userGroup.id,
              name: userGroup.name,
              depth: userGroup.depth,
              rank: userGroup.rank,
              userIds: [],
              deleted: true,
            },
          ],
        },
      })
      onUpdateUserGroupSort?.()
    } catch (error) {
      if (error instanceof Error) {
        void message.error(error.message)
      }
    } finally {
      setDeletingIds((oldids) => oldids.filter((x) => x !== userGroup.id))
    }
    onAfterFinish?.()
  }

  // 渡されたnode(グループ)を起点としそこから配下の深さを求める
  const calculateNodeHeight = (node: DataNode): number => {
    if (node.children?.length === 0) {
      return 1
    } else {
      const childDepths: number[] = node.children?.map((child: DataNode) => calculateNodeHeight(child)) ?? []
      return Math.max(...childDepths) + 1
    }
  }

  return (
    <>
      <Tree
        treeData={treeData}
        className="user-groups-tree p-4"
        blockNode
        autoExpandParent
        defaultExpandAll
        defaultExpandParent
        // 以下で常に全てのノードを表示することができるが、大規模な組織でtreeを折り畳みしたいニーズがありそうなのでこれを行わない
        // 問題点として、leafに対してnodeを追加したとき、デフォルトでexpandしてくれず多少微妙なUXになるが、これを許容する
        // （また、以下を実行した場合、expandのロジックをdisableする必要があるので注意）
        // expandedKeys={userGroups.map((x) => x.id)}
        draggable={(n) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          const node = n as unknown as NodeTree[0] // TODO: オブジェクト的にはこれで正しいが、antdからの型情報には存在しない。将来的に不具合になりそう
          return node.item.id !== 'no-team'
        }}
        // グループの階層は深さ5までしか許容しないため、ドラッグ中のグループ配下の階層数と
        // ドロップ先グループの階層の和が5を超過する場合D&Dによる操作を禁止する
        allowDrop={(node) => {
          const draggingNodeHeight = calculateNodeHeight(node.dragNode)
          const droppedNodeDepth = node.dropNode.item.depth
          const isWithinMaxDepth =
            draggingNodeHeight + droppedNodeDepth <= FEATURE_CONSTANT.USER_GROUP_CATEGORY_NAMES_MAX_LENGTH
          const isSameDepth = node.dragNode.item.depth === node.dropNode.item.depth
          return isWithinMaxDepth || isSameDepth
        }}
        showIcon={true}
        showLine={{
          showLeafIcon: false,
        }}
        onDrop={(info) => {
          // ライブラリの仕様上ドロップするまでドロップ先に対する子要素か同階層(真下に配置)か判別できない
          const draggingNodeHeight = calculateNodeHeight(info.dragNode)
          if (
            info.node.item.depth + draggingNodeHeight > FEATURE_CONSTANT.USER_GROUP_CATEGORY_NAMES_MAX_LENGTH &&
            !info.dropToGap
          ) {
            void message.warning(t('グループの階層は最大5階層までです'))
            return
          }
          const newTreeData = getNewTree(treeData, info)
          setTreeData(newTreeData)
          handleSort(newTreeData)
        }}
        titleRender={
          // eslint-disable-next-line complexity
          (n) => {
            const node = n
            const categoryName = userGroupCategoryNames[node.item.depth - 1]

            return (
              <Row
                key={node.key}
                className="my-1 w-full rounded border border-solid border-gray-300 px-4 py-2"
                justify="space-between"
                style={node.item.id === 'no-team' ? {} : { cursor: 'grab' }}
              >
                <Space className="mr-8">
                  <MenuOutlined />
                  <span>{node.item.id === 'no-team' ? t(`所属なし`) : node.item.name}</span>
                  {isSome(categoryName) && userGroupCategoryNames.length > 1 && (
                    <span className="text-gray-500">- {categoryName}</span>
                  )}
                </Space>
                <Space>
                  <Space className="mr-3">
                    {node.item.users
                      // .filter((user) => user.visibility)
                      .mySortBy((user) => user.rank)
                      .slice(0, 10)
                      .map((user) => (
                        <span key={user.id}>
                          <UserAvatar user={user} />
                        </span>
                      ))}
                    {node.item.users.length > 10 && <span>+{node.item.users.length - 10}</span>}
                  </Space>
                  <Button
                    disabled={node.item.id === 'no-team'}
                    icon={<EditOutlined />}
                    onClick={() => {
                      modal.showModal(node.item)
                    }}
                  >
                    {t(`編集`)}
                  </Button>
                  <Popconfirm
                    onConfirm={() => {
                      void onDestroy(node.item)
                    }}
                    title={`${t(`本当に削除しますか？`)}${
                      (node.children ?? []).length > 0 ? t(`(所属するチームも全て削除されます)`) : ''
                    }`}
                    disabled={node.item.id === 'no-team'}
                  >
                    <Button
                      disabled={node.item.id === 'no-team'}
                      icon={<DeleteOutlined />}
                      loading={deletingIds.includes(node.item.id)}
                    >
                      {t(`削除`)}
                    </Button>
                  </Popconfirm>
                </Space>
              </Row>
            )
          }
        }
      />
      <Modal
        key="editModal"
        open={modal.isModalVisible}
        onCancel={modal.hideModal}
        width={'70%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        style={{ top: '3%' }}
        destroyOnClose
      >
        {modal.content !== undefined && (
          <UpsertUserGroupForm
            key={modal.content.id}
            team={modal.content}
            allUserGroups={userGroups}
            allUsers={userGroups.flatMap((team) =>
              team.users.mySortBy((user) => user.rank).map((user) => ({ user, team })),
            )}
            onUpdateUserGroupSort={onUpdateUserGroupSort}
            onAfterFinish={onAfterFinishAsTable}
          />
        )}
      </Modal>
    </>
  )
}
