import {
  BankOutlined,
  BarChartOutlined,
  BranchesOutlined,
  FlagOutlined,
  HomeOutlined,
  KeyOutlined,
  PartitionOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { routes } from '@salescore/client-base'
import { t } from 'i18next'

import { checkSyncEnabled, checkVisualizeEnabled } from '../../functions/checkPlan'
import { useOrganizationId } from '../atoms'
import { useMe } from '../hooks'
import { useCanForFeatures } from '../policy/useCanForFeature'
import type { LauncherCommandV2 } from './launcherCommandV2'
// eslint-disable-next-line complexity
export const useMoveCommands = ({ redirect }: { redirect: (path: string) => void }): LauncherCommandV2[] => {
  const me = useMe()
  const organizationId = useOrganizationId()
  const ability = useCanForFeatures()
  const commands: Array<LauncherCommandV2 | undefined> = [
    checkSyncEnabled(me.myUser)
      ? {
          type: `page` as const,
          icon: <HomeOutlined />,
          title: t(`ホーム画面`),
          searchKeyword: 'home',
          shortcut: ['command', 'h'],
          method: () => {
            redirect(routes.topPathV2())
          },
        }
      : undefined,
    ...(checkVisualizeEnabled(me.myUser) && ability.canManageGoal
      ? [
          {
            type: `page` as const,
            icon: <FlagOutlined />,
            title: t(`目標設定`),
            searchKeyword: 'goal',
            shortcut: ['command', 'g'],
            method: () => {
              redirect(routes.goalConfigsPathV2())
            },
          },
          {
            type: `page` as const,
            icon: <PartitionOutlined />,
            title: t(`軸グループ設定`),
            searchKeyword: 'dimension group',
            method: () => {
              redirect(routes.dimensionGroupsPathV2())
            },
          },
          {
            type: `page` as const,
            icon: <PartitionOutlined />,
            title: t(`目標軸設定`),
            searchKeyword: 'goal dimension',
            method: () => {
              redirect(routes.goalDimensionsPathV2())
            },
          },
        ]
      : []),
    ...(ability.canManageConnection
      ? [
          {
            type: `page` as const,
            icon: <BranchesOutlined />,
            title: t(`連携設定`),
            searchKeyword: 'connection',
            shortcut: ['command', 'c'],
            method: () => {
              redirect(routes.sourceSettingsPathV2())
            },
          },
        ]
      : []),
    ...(ability.canManageUsers
      ? [
          {
            type: `page` as const,
            icon: <TeamOutlined />,
            title: t(`ユーザー一覧`),
            searchKeyword: 'user',
            shortcut: ['command', 'u'],
            method: () => {
              redirect(routes.usersSettingsPathV2())
            },
          },
          {
            type: `page` as const,
            icon: <TeamOutlined />,
            title: t(`グループ一覧`),
            searchKeyword: 'group',
            // shortcut: ['command', 'g'],
            method: () => {
              redirect(routes.userGroupsSettingsPathV2())
            },
          },
          {
            type: `page` as const,
            icon: <BankOutlined />,
            title: t(`組織設定`),
            searchKeyword: 'organization',
            shortcut: ['command', 'o'],
            method: () => {
              redirect(routes.organizationSettingsPathV2())
            },
          },
        ]
      : []),
    ...(ability.canReadUserActivity
      ? [
          {
            type: `page` as const,
            icon: <BarChartOutlined />,
            title: t(`利用状況`),
            searchKeyword: 'tracking',
            shortcut: ['command', 't'],
            method: () => {
              redirect(routes.userActivitiesPathV2())
            },
          },
        ]
      : []),
    {
      type: `page` as const,
      icon: <UserOutlined />,
      title: t(`個人設定`),
      searchKeyword: 'me',
      shortcut: ['command', 'm'],
      method: () => {
        redirect(routes.mePathV2())
      },
    },
    me.isAdmin // TODO: 本来はIdentity.roleにすべき
      ? {
          type: `page` as const,
          icon: <KeyOutlined />,
          title: '管理者ページ',
          searchKeyword: 'admin',
          shortcut: ['command', 'a'],
          method: () => {
            // XXX: ユーザー環境にURLを露出させたくない
            const host = location.hostname === `localhost` ? `http://localhost:5001` : `https://admin-bifa.salescore.jp`
            if (organizationId === '') {
              window.open(host)
            } else {
              window.open(`${host}/o/${organizationId}`)
            }
          },
        }
      : undefined,
  ]

  return commands.compact()
}
