import { isPresent, isTruthy, range } from '@salescore/buff-common'

import { CORE_CONSTANT } from '../../../constant'
import type {
  ViewQueryAggregation,
  ViewQueryDimensionField,
  ViewQueryFilterNode,
  ViewQueryList,
  ViewQueryNode,
  ViewQueryNodeReadJoinOn,
  ViewQuerySorter,
} from '../../../schemas/query'
import { makeIdentifiable } from '../../util/makeIdentifiable'
import type { ViewResultCursorOrInitialCursor } from '../types'
import { flatNodes, generateAbsoluteFieldAsName, generateAbsoluteFields, getLabeledName } from './util'

// eslint-disable-next-line complexity
export function generateSql(
  view: ViewQueryList,
  cursor: ViewResultCursorOrInitialCursor,
  mustacheParameter: Record<string, unknown>,
  option?: { shouldSkipRootSort?: boolean; shouldGetAllFields?: boolean },
): string {
  const withSql = generateWithSql(view)
  const fields = generateFields(view)
  const filterSql = generateFilterSql(view.filterTree, 0, mustacheParameter)
  const joins = flatNodes(view.tree).map((node) => generateJoinSql(node, mustacheParameter))

  // 初回のoffsetは0、次回以降は前回取得分のレコードが含まれている可能性があるレコード分を取り直す形でoffsetを設定
  const offset = Math.max(cursor.pageSize * (cursor.page - 1) - cursor.chunkSize, 0)
  const limitAndOffset = `OFFSET ${offset} LIMIT ${cursor.pageSize + cursor.chunkSize}`
  const sorters = generateSorters(view.sorters, view.tree, option)
  const orderBy = sorters.isPresent() ? `ORDER BY ${sorters.join(', ')}` : ``
  const tableSqlWithAs =
    view.tree.read.tableType === 'subquery'
      ? quote(view.tree.name)
      : view.tree.read.tableSql === view.tree.name
        ? quote(view.tree.read.tableSql)
        : `${quote(view.tree.read.tableSql)} AS ${quote(view.tree.name)}`

  const sql = `${withSql}
SELECT
${isTruthy(option?.shouldGetAllFields) ? '*' : fields.join(`,\n`)}
FROM ${tableSqlWithAs}
${joins.join('\n')} ${isPresent(filterSql) ? `\nWHERE\n${filterSql}` : ``}
${view.extra?.asSubQuery === true ? `` : [orderBy, limitAndOffset].join('\n')}
`.replaceAll('\n\n', '\n')

  return sql
}

// eslint-disable-next-line complexity
export function generateAggregationSql(view: ViewQueryAggregation, mustacheParameter: Record<string, unknown>): string {
  const withSql = generateWithSql(view)
  const fields = generateMeasures(view)
  const filterSql = generateFilterSql(view.filterTree, 0, mustacheParameter)
  const joins = flatNodes(view.tree)
    .map((node) => generateJoinSql(node, mustacheParameter))
    .compact()
  const dimensions = generateDimensions(view)
  const orderBy = view.sorters.isPresent() ? `\nORDER BY ${generateSorters(view.sorters).join(', ')}` : ``
  const tableSqlWithAs =
    view.tree.read.tableType === 'subquery'
      ? quote(view.tree.name)
      : view.tree.read.tableSql === view.tree.name
        ? quote(view.tree.read.tableSql)
        : `${quote(view.tree.read.tableSql)} AS ${quote(view.tree.name)}`

  // 後続の処理で、 ${CORE_CONSTANT.AGGREGATION_QUERY_LIMIT} よりも多いレコードがあるかどうかを判定するため、
  // LIMIT ${CORE_CONSTANT.AGGREGATION_QUERY_LIMIT} + 1
  // としている。
  // 後続の処理でちゃんと ${CORE_CONSTANT.AGGREGATION_QUERY_LIMIT} 個でフィルターする。
  const sql = `${withSql}
SELECT
${[...dimensions.fields, ...fields].join(`,\n`)}
FROM ${tableSqlWithAs}
${joins.isPresent() ? joins.join('\n') : ''}${isPresent(filterSql) ? `\nWHERE\n${filterSql}` : ``}${
    dimensions.groupBy.isPresent() ? `\nGROUP BY ${dimensions.groupBy.join(', ')}` : ''
  }${orderBy}
LIMIT ${CORE_CONSTANT.AGGREGATION_QUERY_LIMIT} + 1
`

  return sql.replaceAll('\n\n', '\n').replace(/^\n/, '').replace(/\n$/, '')
}

function generateWithSql(query: ViewQueryAggregation | ViewQueryList) {
  // サブクエリは見づらいので、WITHとして扱う
  const subQueryNodes = flatNodes(query.tree).filter((x) => x.read.tableType === 'subquery')
  if (subQueryNodes.isBlank()) {
    return ``
  }
  const queries = subQueryNodes.map((x) => `${x.name} AS (${x.read.tableSql})`)

  return `WITH ${queries.join(',\n')}\n`
}

// 現状、フロントエンドで親ノードのIDをkeyとしてレンダリングしており、keyが複数あることを許していない。
function generateSorters(
  sorters: ViewQuerySorter[],
  rootNode?: ViewQueryNode,
  option?: { shouldSkipRootSort?: boolean },
): string[] {
  const [rootSorters, childSorters] = (sorters ?? []).partition((sorter) => {
    if (sorter.nodePaths.length !== 1) {
      return false
    }
    const nodePath = sorter.nodePaths.first()!
    return nodePath.length === 1
  })
  const xs = [
    ...rootSorters.map((x) => `${x.read.sql} ${x.read.order}`),
    rootNode !== undefined && option?.shouldSkipRootSort !== true ? `"${rootNode.name}".id` : undefined, // 常にidでソートし、隣接するidを後からアプリケーションレイヤでグルーピングする
    ...childSorters.map((x) => `${x.read.sql} ${x.read.order}`),
  ].compact()
  return xs
}

export function generateLabelSqlFieldName(fieldName: string) {
  return `${fieldName}_label`
}

function anyAggregation(fieldName: string) {
  return `MIN(${fieldName})`
}

function dimensionToField(dimension: ViewQueryDimensionField): string[] {
  return [
    [dimension.sql, quote(dimension.name)].join(' AS '),
    dimension.labelSql === undefined
      ? undefined
      : [anyAggregation(dimension.labelSql), quote(generateLabelSqlFieldName(dimension.name))].join(' AS '),
  ].compact()
}

function generateRollup(ds: ViewQueryDimensionField[], withoutRollup?: boolean) {
  const sqls = ds.filter((x) => x.isScalar !== true).map((x) => x.fieldSql)
  if (sqls.isBlank()) {
    return
  }
  if (withoutRollup === true) {
    return sqls.join(', ')
  }
  return `ROLLUP(${sqls.join(', ')})`
}

// TODO: quote
// TODO: __NULL__, __TOTAL__
// eslint-disable-next-line complexity
function generateDimensions(view: ViewQueryAggregation) {
  const fields: string[] = [
    ...(view.pivot?.rows.flatMap((x): string[] => dimensionToField(x)) ?? []),
    ...(view.pivot?.columns.flatMap((x): string[] => dimensionToField(x)) ?? []),
    ...(view.pivot?.dimensionsWithoutRollup?.flatMap((x): string[] => dimensionToField(x)) ?? []),
  ]
  const groupBy: string[] = [
    generateRollup(view.pivot?.rows ?? []),
    generateRollup(view.pivot?.columns ?? []),
    generateRollup(view.pivot?.dimensionsWithoutRollup ?? [], true),
  ].compact()
  return {
    fields,
    groupBy,
  }
}

function generateMeasures(view: ViewQueryAggregation): string[] {
  return view.measures.map((x) => [x.sql, quote(x.name)].join(' AS '))
}

export function quote<T extends string | undefined>(x: T) {
  if (x === undefined) {
    return x
  }
  return `"${x ?? ''}"`
}

function generateFields(view: ViewQueryList): string[] {
  // writeのための、各テーブルのidのカラム
  const idFields =
    view.extra?.asSubQuery === true
      ? []
      : flatNodes(view.tree).map((node) => {
          if (node.read.idColumn === undefined) {
            return
          }

          return {
            sql: generateAbsoluteFields(node.name, node.read.idColumn),
            name: makeIdentifiable(generateAbsoluteFieldAsName(node.name, node.read.idColumn)),
          }
        })

  // joinで使っているフィールド
  const joinFields =
    view.extra?.asSubQuery === true
      ? []
      : flatNodes(view.tree).flatMap((node) => {
          if (node.read.join === undefined) {
            return
          }
          const { joinOn } = node.read.join
          if (joinOn.type === 'sql') {
            return
          }

          return [
            {
              sql: generateAbsoluteFields(joinOn.currentNodeName, joinOn.currentNodeColumnName),
              name: makeIdentifiable(generateAbsoluteFieldAsName(joinOn.currentNodeName, joinOn.currentNodeColumnName)),
            },
            {
              sql: generateAbsoluteFields(joinOn.parentNodeName, joinOn.parentNodeColumnName),
              name: makeIdentifiable(generateAbsoluteFieldAsName(joinOn.parentNodeName, joinOn.parentNodeColumnName)),
            },
          ]
        })

  // fieldsで指定されたフィールド
  const viewFields = view.fields.flatMap((field) =>
    [
      {
        // field.read.sqlは、テーブル名を含む完全パスであることを期待している
        sql: field.read.distinct === true ? `DISTINCT ${field.read.sql}` : field.read.sql, // 現状のdistinctのロジックがmeasuresのサブクエリ関連のみなので、雑に対応している
        name: field.name,
      },
      field.read.labelSql === undefined
        ? undefined
        : {
            sql: field.read.labelSql,
            name: getLabeledName(field.name),
          },
      ...(field.read.additionalFields ?? []).map((additionalField) => ({
        sql: additionalField.sql,
        name: additionalField.name,
      })),
    ].compact(),
  )

  const fields = [...idFields, ...joinFields, ...viewFields]
    .compact()
    .uniqueBy((x) => x.name)
    .map((x) => `${x.sql} AS ${quote(x.name)}`)

  return fields
}

function indent(n: number) {
  return range(0, n)
    .map((x) => '  ')
    .join('')
}

function wrapInParentheses(x: string) {
  return `(${x})`
}

function generateJoinSql(node: ViewQueryNode, mustacheParameter: Record<string, unknown>): string | undefined {
  const { tableSql, join } = node.read
  if (join === undefined) {
    return undefined
  }
  const filter = generateFilterSql(join.joinFilterTree, 0, mustacheParameter)
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getJoinOnSql = (joinOn: ViewQueryNodeReadJoinOn) => {
    if (joinOn.type === 'sql') {
      return joinOn.sql
    }
    return `"${joinOn.parentNodeName}"."${joinOn.parentNodeColumnName}" = "${joinOn.currentNodeName}"."${joinOn.currentNodeColumnName}"`
  }
  const on = `${getJoinOnSql(join.joinOn)}${isPresent(filter) ? ` AND ${wrapInParentheses(filter)}` : ``}`

  if (node.read.tableType === 'subquery') {
    // サブクエリは見づらいので、WITHで定義することにする。WITHのロジックは別で実行済みのはずなので、ここではnode.nameを呼ぶだけ
    return `${join.joinType} "${node.name}" ON ${on}`
  }

  const tableSqlWithAs = tableSql === node.name ? quote(tableSql) : `${quote(tableSql)} AS ${quote(node.name)}`
  return `${join.joinType} ${tableSqlWithAs} ON ${on}`
}

//
// 以下で「交換可能である」というのは、複数の式や項を論理演算子(AND/OR)で結合したとき、順番を並び替えても同じ結果が得られることを呼ぶ。
// （例1）式Aが「x = 1」、式Bが「y = 2」であるとき、 「A AND B」「B AND A」は同じ結果が得られるため、交換可能である。
// （例2）式Aが「x = 1 OR xx = 1」、式Bが「y = 2」であるとき、同じ結果は得られないため、交換可能ではない。
//       なぜなら、 x = 1 OR xx = 1 AND y = 2 は、 OR も AND も左結合のため (x = 1 OR xx = 1) AND y = 2 と解釈される一方、
//       y = 2 AND x = 1 OR xx = 1 は同じ理由で (y = 2 AND x = 1) OR xx = 1 と解釈されるからである。
//       参考: PostgreSQL 16 の演算子の結合方向と優先順位 https://www.postgresql.org/docs/16/sql-syntax-lexical.html#SQL-PRECEDENCE
// （例3）式Aが「(x = 1 OR xx = 1)」、式Bが「y = 2」であるとき、 交換可能である
//
// generateFilterSqlは、depth=1以上のときは常に交換可能な式を返す（＝括弧で括られたSQLを返す）
// NOTE: 以下の実装において、交換可能でない式が紛れ込んだ状態で、雑に論理演算子でjoinすると、上記の例2のようなケースが発生して不具合になりうるので注意すること
//
// eslint-disable-next-line complexity
export function generateFilterSql(
  filterNode: ViewQueryFilterNode | undefined,
  depth: number,
  mustacheParameter: Record<string, unknown>,
): string | undefined {
  if (filterNode === undefined) {
    return undefined
  }

  if (filterNode.leafs.length === 0 && filterNode.children.length === 0) {
    return undefined
  }
  const leafSqls = filterNode.leafs
    .map((x) => {
      // パラメーターに依存するSQLのとき、パラメーターが指定されていなければ条件を無視する
      if ((x.read.dependedParameters ?? []).some((x) => mustacheParameter[x] === undefined)) {
        return
      }
      return x.read.sql // read.sqlは交換可能であることを常に期待する（そうなっていない場合、compileConfig側のバグ）
    })
    .compact()

  const childrenSqls = filterNode.children.map((x) => generateFilterSql(x, depth + 1, mustacheParameter)).compact() // depth>=1のときのgenerateFilterSqlのレスポンスは常に交換可能

  const sqls = [...leafSqls, ...childrenSqls].compact()
  if (sqls.length === 0) {
    return undefined
  }
  // このsqlの中身は全て交換可能であり、ここで論理演算子で結合しても問題がない。leafなのかchildrenなのかは区別する必要がない。
  const sql = sqls.join(`\n${indent(depth)}${filterNode.logicalOperator.toUpperCase()} `)
  return depth === 0 ? sql : wrapInParentheses(sql) // depth >= 1のときは常に交換可能にするため、括弧に括る。無駄に括られることもありうるが、SQLの読みやすさよりも実装のシンプルさと堅牢さを優先する
}
