import { QuestionCircleOutlined } from '@ant-design/icons'
import { useSuspenseQuery } from '@apollo/client'
import { compareFunction } from '@salescore/buff-common'
import type { SourceFieldsFragment } from '@salescore/client-api'
import { FetchSourceEntityNamesDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { getColumnSearchProps } from '@salescore/frontend-common'
import { Button, Table, Tag, Tooltip } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'

export const EditConnectionStep1 = ({
  source,
  pickedEntityNames,
  setPickedEntityNames,
  onFinish,
}: {
  source: SourceFieldsFragment
  pickedEntityNames: string[]
  setPickedEntityNames: (entityNames: string[]) => void
  onFinish: () => void
}) => {
  const { data } = useSuspenseQuery(FetchSourceEntityNamesDocument, {
    variables: {
      // invalidateは不要だろうという読み
      organizationId: getOrganizationIdFromPath(),
      id: source.id,
    },
  })
  const { sourceEntityNames } = data

  // セットする際に、強制的にforcePickのentityを追加、またソートする
  const set = (entityNames: string[]) => {
    const requiredEntityNames = sourceEntityNames.filter((x) => x.forcePick === true).map((x) => x.name)
    setPickedEntityNames([...entityNames, ...requiredEntityNames].unique().sortBy((x) => x))
  }

  useEffect(() => {
    // 初期化
    set(pickedEntityNames)
  }, [sourceEntityNames])

  return (
    <div>
      <div className="mb-4">
        <p>{t(`同期するオブジェクトを選択してください。`)}</p>
        {source.provider === 'salesforce' && (
          <Tooltip
            className="text-gray-700"
            title={t(
              `Salesforce連携では表示されるオブジェクトは一部のもののみに制限しています。連携したいオブジェクトが表示されない場合は、右下のチャットボタンよりSALESCORE担当までお問い合わせください。`,
            )}
            placement="bottom"
          >
            <QuestionCircleOutlined /> {t(`オブジェクトが表示されないときは？`)}
          </Tooltip>
        )}
      </div>
      <Table
        dataSource={sourceEntityNames
          .map((x) => ({ ...x, key: x.name }))
          .sortBy((x) => [(x.forcePick ?? false) ? 0 : 1, x.name])}
        size="small"
        pagination={{ defaultPageSize: 100 }}
        className="components-table-demo-nested"
        scroll={{ y: 'min(65vh, calc(100vh - 400px))' }}
        columns={[
          {
            title: <div className=""></div>,
            width: 25,
            sorter: (a, b) => compareFunction(pickedEntityNames.includes(a.name), pickedEntityNames.includes(b.name)),
            render(_value, record) {
              return <div></div>
            },
          },
          {
            title: <></>,
            dataIndex: `forcePick`,
            width: 70,
            sorter: (a, b) => compareFunction(a.forcePick, b.forcePick),
            render(_value, record) {
              if (record.forcePick === true) {
                return <Tag color="blue">必須</Tag>
              }
              return <></>
            },
          },
          {
            title: t(`オブジェクト名`),
            sorter: (a, b) => compareFunction(a.label, b.label),
            render(_value, record) {
              return (
                <div>
                  {record.label}
                  {/* <span className="text-gray-500"> - {record.name}</span> */}
                </div>
              )
            },
            ...getColumnSearchProps((record: { name: string; label: string }) => record.label),
          },
          {
            title: <div className="">{t(`連携先内部名`)}</div>,
            sorter: (a, b) => compareFunction(a.name, b.name),
            render(_value, record) {
              return (
                <div>
                  <span className="text-gray-500">{record.name}</span>
                </div>
              )
            },
            ...getColumnSearchProps((record: { name: string; label: string }) => record.name),
          },
        ]}
        rowSelection={{
          selectedRowKeys: pickedEntityNames,
          getCheckboxProps(record) {
            return {
              disabled: record.forcePick === true,
            }
          },
          onChange: (names) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            set(names as string[])
          },
        }}
      />
      <div className="mb-8 mt-2 flex flex-row-reverse">
        <Button
          onClick={() => {
            onFinish()
          }}
          type="primary"
        >
          {t(`次へ`)}
        </Button>
      </div>
    </div>
  )
}
