import { DownloadOutlined, FileImageOutlined } from '@ant-design/icons'
import { Button, Row, Space } from 'antd'
import * as echarts from 'echarts'
import { useEffect, useRef } from 'react'

import { csvDownload, type CsvDownloadButtonArgument } from '../../form/CsvDownloadButton'
import { useResize } from '../../hooks/useResizeObserver'

export type ChartLabelDisplayModeValue = 'auto' | 'none' | 'showAll'
export type ChartLegendsDisplayModeValue = 'auto' | 'none' | 'show'

export interface EChartProperties {
  title: string // 見た目をよくするために必須とする
  option: echarts.EChartsOption
  csv?: CsvDownloadButtonArgument
  hideImageDownload?: boolean
  onSeriesClick?: (x: {
    key: string
    groupKey: string | undefined
    label: string
    groupLabel: string | undefined
    value: unknown
  }) => void
  onXAxisClick?: (x: { dataIndex: number; value: unknown }) => void
  onCsvDownloadClick?: () => void // posthogを仕込むためだけのプロパティ。CSVダウンロード自体は内部ロジックで持っている。
  onImageDownloadClick?: () => void // posthogを仕込むためだけのプロパティ。画像ダウンロード自体は内部ロジックで持っている。
}

// eslint-disable-next-line complexity
export function EChart(properties: EChartProperties & { width?: number; height?: number }) {
  const { ref, rect } = useResize()
  // width,heightは基本的に常に100%。EChartの都合で100%指定ができないので、useResizeを使って現在のサイズを求めている
  const width = properties.width ?? rect?.width ?? 800
  const height = properties.height ?? rect?.height ?? 600
  // 値が変わった際に毎回再レンダリングさせたいので、keyにpropsのjsonを指定している
  return (
    <div ref={ref} style={{ width: properties.width ?? `100%`, height: properties.height ?? `100%` }}>
      <Body {...properties} key={JSON.stringify({ ...properties, width, height })} width={width} height={height} />
    </div>
  )
}

function downloadBase64File(dataUrl: string, fileName: string) {
  const downloadLink = document.createElement('a')
  downloadLink.href = dataUrl
  downloadLink.download = fileName
  downloadLink.click()
}

function Body({
  width,
  height,
  option,
  title,
  csv,
  hideImageDownload,
  onSeriesClick,
  onXAxisClick,
  onCsvDownloadClick,
  onImageDownloadClick,
}: EChartProperties & { width: number; height: number }) {
  const reference = useRef<HTMLInputElement>(null)
  let instance = reference.current === null ? undefined : echarts.init(reference.current)
  useEffect(() => {
    if (reference.current === null) {
      return
    }
    instance = echarts.init(reference.current)
    instance.setOption(option)
    instance.on(
      `click`,
      // eslint-disable-next-line complexity
      (event) => {
        switch (event.componentType) {
          case 'series': {
            onSeriesClick?.({
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-type-assertion
              key: (event.data as any)?.key ?? event.name,
              label: event.name,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-type-assertion
              groupKey: (event.data as any)?.groupKey ?? event.seriesName,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-type-assertion
              groupLabel: (event.data as any)?.groupLabel ?? event.seriesName,
              value: event.value,
            })
            break
          }
          case 'xAxis': {
            onXAxisClick?.({
              dataIndex: event.dataIndex,
              value: event.value,
            })
            break
          }
          default: {
            break
          }
        }
      },
    )
  }, [reference])

  const withNavigation = true
  return (
    <div style={{ width, height }}>
      {withNavigation && (
        <Row justify="space-between" className="mb-2">
          <Space>
            <div className="font-bold">{title}</div>
          </Space>
          <Space size="small">
            {hideImageDownload !== true && (
              <Button
                type="text"
                className="opacity-80"
                icon={<FileImageOutlined />}
                onClick={() => {
                  if (instance === undefined) {
                    return
                  }
                  const dataUrl = instance.getDataURL()
                  downloadBase64File(dataUrl, `${title}.png`)
                  if (onImageDownloadClick === undefined) {
                    return
                  }
                  onImageDownloadClick()
                }}
              />
            )}
            {csv !== undefined && (
              <Button
                type="text"
                className="opacity-80"
                icon={<DownloadOutlined />}
                onClick={async () => {
                  // TODO: ユーザー制限?
                  await csvDownload(csv)
                  if (onCsvDownloadClick === undefined) {
                    return
                  }
                  onCsvDownloadClick()
                }}
              />
            )}
          </Space>
        </Row>
      )}
      <div ref={reference} style={{ width: '100%', height: '100%' }} />
    </div>
  )
}
