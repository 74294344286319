import {
  BarChartOutlined,
  PieChartOutlined,
  SettingOutlined,
  TableOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartMixed } from '@fortawesome/sharp-solid-svg-icons'
import { isNull, isTruthy } from '@salescore/buff-common'
import { POSTHOG_EVENTS } from '@salescore/client-base'
import { ClosableResizableSider } from '@salescore/client-common'
import { checkRiEnabled, checkVisualizeEnabled } from '@salescore/client-recoil'
import { CORE_CONSTANT, type ViewConfigKpi, type ViewUiKpi } from '@salescore/core'
import { Button, message } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { type KpiDisplayFormat, kpiDisplayFormatAtom } from '../../recoil/navigation/atoms'
import { usePosthogTrackView } from '../../recoil/usePosthogTrack'
import { useMeValue, useViewConfigKpi, useViewsContextValue, useViewValue } from '../../recoil/view/hooks'
import { useKpiFormSelectors } from '../../recoil/view/selectors/kpiFormSelector'
import { KpiForm } from '../../rsheet/components/footer/KpiForm'
import { ExpandableSider } from './ExpandableSider'
import { type PanelOption, VerticalPanelPicker } from './VerticalPanelPicker'
import { KpiTable, ViewUIKpiBody } from './ViewUIKpi/ViewUIKpiBody'
import { VisualizeUserPlanNotFound } from './ViewUIKpi/VisualizeUserPlanNotFound'
import { KpiParameterSider } from './ViewUIKpiPivot/KpiParameterSider'
import { KpiPivotNavigation } from './ViewUIKpiPivot/KpiPivotNavigation'

// eslint-disable-next-line complexity
export const ViewUIKpi = ({ component }: { component: ViewUiKpi }) => {
  const view = useViewValue()
  const context = useViewsContextValue()
  // const [visible, setVisibility] = useKpiPivotParameterDrawerVisibility()
  const me = useMeValue()
  const { format, sortedOptions, onClickPanel } = useKpiDisplayFormatPicker()

  if (!checkVisualizeEnabled(me.myUser) && !checkRiEnabled(me.myUser)) {
    return (
      <div className="view-ui-kpi-sheet size-full bg-white">
        <VisualizeUserPlanNotFound />
      </div>
    )
  }
  if (view.config.type !== 'kpi') {
    // ありえないはず
    return <></>
  }

  if (view.id.startsWith(CORE_CONSTANT.KPI_PREVIEW_VIEW_ID_PREFIX)) {
    return (
      <ClosableResizableSider
        siderKey="ViewUIKpiSheet"
        // kpiの場合は常にvisibleにする
        defaultVisibility={false}
        defaultWidth={380}
        sider={({ close }) => <KpiParameterSider component={component} closable={true} close={close} />}
        body={({ open, visibility }) => (
          <div className="flex h-full flex-col overflow-y-auto bg-white">
            <KpiPivotNavigation component={component} siderVisibility={visibility} siderOpen={open} />
            <div
              className="min-h-0 flex-1 border"
              // ここでkey指定するとviewの切り替え時に全レンダリングが走って良くないが、実装ミスでレンダリングが微妙になるのを防ぐのを優先する
              key={view.id}
            >
              <KpiTable component={component} />
            </div>
          </div>
        )}
      />
    )
  }

  return (
    <div className="view-ui-kpi-sheet size-full">
      <VerticalPanelPicker
        options={sortedOptions}
        selectedValue={format}
        hidePanel={context.prevView?.type === 'kpiTimeSeries'}
        onClick={(option) => {
          void onClickPanel(option)
        }}
        body={
          <div className="h-full">
            {format === 'sheet' ? (
              <ViewUIKpiBody component={component} />
            ) : format === 'setting' ? (
              <div className="mb-16 h-full overflow-y-hidden bg-white">
                <KpiForm />
              </div>
            ) : (
              <ExpandableSider
                // kpiの場合は常にvisibleにする
                visibility={true}
                width={380}
                sider={<KpiParameterSider component={component} closable={false} />}
                body={
                  <div className="flex h-full flex-col overflow-y-auto bg-white">
                    <KpiPivotNavigation component={component} />
                    <div
                      className="min-h-0 flex-1 border"
                      // ここでkey指定するとviewの切り替え時に全レンダリングが走って良くないが、実装ミスでレンダリングが微妙になるのを防ぐのを優先する
                      key={view.id}
                    >
                      <ViewUIKpiBody component={component} />
                    </div>
                  </div>
                }
              />
            )}
          </div>
        }
      />
    </div>
  )
}

type KpiDisplayFormatOption = PanelOption & { value: KpiDisplayFormat }

function useKpiDisplayFormatPicker() {
  const [format, setFormat] = useRecoilState(kpiDisplayFormatAtom)
  const [config] = useViewConfigKpi()
  const context = useViewsContextValue()
  const { setFormModified, formModified } = useKpiFormSelectors()
  const posthogTrackView = usePosthogTrackView()
  // 必要な設定が終わっていなければ、設定画面以外を表示しない
  const isSettingCompleted = checkSettingCompleted(config)
  const { hasChanges, isSaveConfigButtonActive, setIsSaveConfigButtonActive } = useViewsContextValue()

  const options: KpiDisplayFormatOption[] = [
    {
      value: 'setting',
      label: t(`KPI設定`),
      icon: <SettingOutlined className="" style={{ fontSize: 26 }} />,
      disabled: isTruthy(context.prevView?.id.startsWith(CORE_CONSTANT.KPI_PREVIEW_VIEW_ID_PREFIX)),
    },
    {
      value: 'sheet',
      label: t(`一覧`),
      icon: <UnorderedListOutlined className="" style={{ fontSize: 26 }} />,
      disabled: !isSettingCompleted,
    },
    {
      value: 'bar-time-series',
      label: t(`時系列推移`),
      icon: <BarChartOutlined className="" style={{ fontSize: 26 }} />,
      disabled: !isSettingCompleted,
      posthogEventName: POSTHOG_EVENTS.view_kpi_time_series_chart_panel,
    },
    {
      value: 'pie',
      label: t(`円グラフ`),
      icon: <PieChartOutlined className="" style={{ fontSize: 26 }} />,
      disabled: !isSettingCompleted,
      posthogEventName: POSTHOG_EVENTS.view_kpi_pie_chart_panel,
    },
    {
      value: 'table',
      label: t(`ピボット`),
      icon: <TableOutlined className="" style={{ fontSize: 26 }} />,
      disabled: !isSettingCompleted,
      posthogEventName: POSTHOG_EVENTS.view_kpi_pivot_table_panel,
    },
    {
      value: 'bar',
      label: t(`グラフ`),
      icon: <FontAwesomeIcon icon={faChartMixed} style={{ fontSize: 26 }} />,
      disabled: !isSettingCompleted,
      posthogEventName: POSTHOG_EVENTS.view_kpi_bar_chart_panel,
    },
  ]

  // ドリルダウンしてKPIを表示したときは一覧が一番上、そうでない時はKPI設定が上
  const sortedOptions =
    context.prevView === undefined ? options : options.sortBy((x) => (x.value === 'setting' ? 1 : 0))

  // eslint-disable-next-line complexity
  const onClickPanel = async (option: KpiDisplayFormatOption): Promise<void> => {
    if (
      formModified || // KPI設定パネルで変更があるとき
      (!isTruthy(context.prevView?.id.startsWith(CORE_CONSTANT.KPI_PREVIEW_VIEW_ID_PREFIX)) && hasChanges) || // KPIシートのレコードに変更があるとき(ただし KPI プレビューのセルをクリックした時を除く)
      isSaveConfigButtonActive // KPIシートの設定に変更があるとき
    ) {
      const messageKey = `move-panel-warning`
      await message.warning({
        key: messageKey,
        content: (
          <span>
            {t(`変更差分がある場合はタブを切り替えられません。`)}
            <br />
            {t(`差分を削除してタブを切り替えますか？`)}
            <Button
              className="ml-2"
              onClick={() => {
                setFormModified(false)
                setIsSaveConfigButtonActive(false)
                setFormat(option.value)
                message.destroy(messageKey)
                if (option.posthogEventName !== undefined) {
                  posthogTrackView(option.posthogEventName)
                }
              }}
            >
              {t(`はい`)}
            </Button>
          </span>
        ),
        duration: 3,
      })
      return
    }
    setFormat(option.value)
    if (option.posthogEventName !== undefined) {
      posthogTrackView(option.posthogEventName)
    }
  }

  useEffect(() => {
    const firstOption = sortedOptions.first()
    if (isNull(firstOption)) {
      // ありえないはず
      return
    }
    setFormat(firstOption.value)
  }, [])

  return {
    format,
    sortedOptions,
    onClickPanel,
  }
}

function checkSettingCompleted(config: ViewConfigKpi | undefined): boolean {
  return config?.sheet !== undefined && config.measure !== undefined
}
