import { between, CORE_CONSTANT } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'
import { useSetRecoilState } from 'recoil'

import { kpiParameterAtom } from '../../../recoil/navigation/atoms'
import { pivotColumnsSelector } from '../../../recoil/selectors/pivotColumnsSelector'
import { configAtom } from '../../../recoil/view/atoms'
import { moveFieldMutation } from '../../../recoil/view/mutations/field/moveFieldMutation'
import { setConfigMutation } from '../../../recoil/view/mutations/setConfigMutation'
import { columnsAtom } from '../atoms'

export type Side = 'left' | 'right'

function calcNewColumnIndex(oldColumnIndex: number, movingOntoIndex: number, side: Side) {
  if (movingOntoIndex >= oldColumnIndex) {
    const sideOffset = side === 'left' ? -1 : 0
    return movingOntoIndex + sideOffset
  } else {
    const sideOffset = side === 'left' ? 0 : 1
    return movingOntoIndex + sideOffset
  }
}

const moveColumnMutation = mutation({
  key: `rsheet/moveColumnMutation`,
  // eslint-disable-next-line complexity
  set(
    { get, set },
    { oldColumnIndex, movingOntoIndex, side }: { oldColumnIndex: number; movingOntoIndex: number; side: Side },
  ) {
    const newColumnIndex = calcNewColumnIndex(oldColumnIndex, movingOntoIndex, side)

    const config = get(configAtom)
    if (config.type === 'sheet') {
      const columns = get(columnsAtom)
      const oldFieldIndex = columns[oldColumnIndex]?.configIndex
      const newFieldIndex = columns[newColumnIndex]?.configIndex
      if (oldFieldIndex === undefined || newFieldIndex === undefined) {
        return
      }

      set(moveFieldMutation, { oldFieldIndex, newFieldIndex })
      return
    }
    if (config.type === 'kpiPivot' || config.type === 'kpi') {
      const { systemColumns, rowColumns, columnColumns, columnKeys } = get(pivotColumnsSelector)
      const oldIndexType = oldColumnIndex < systemColumns.length + rowColumns.length ? 'row' : 'column'
      const newIndexType = newColumnIndex < systemColumns.length + rowColumns.length ? 'row' : 'column'
      const parameter = get(kpiParameterAtom)

      // 行と行でドラッグしたケースでは、pivotのdimensionsを入れ替える
      if (oldIndexType === 'row' && newIndexType === 'row') {
        if (oldColumnIndex === newColumnIndex) {
          return
        }
        const oldRowIndex = rowColumns[oldColumnIndex - systemColumns.length]?.configIndex
        const newRowIndex = rowColumns[newColumnIndex - systemColumns.length]?.configIndex
        if (oldRowIndex === undefined || newRowIndex === undefined) {
          return
        }
        set(kpiParameterAtom, {
          ...parameter,
          pivot: {
            ...parameter.pivot,
            rows: parameter.pivot.rows.move(oldRowIndex, newRowIndex),
          },
        })
        return
      }

      // これより先では、列同士の移動のみを許可
      if (oldIndexType !== 'column' || newIndexType !== 'column') {
        return
      }

      // 列と列でドラッグしたケースでは、列のソートを設定する
      // ソートロジックが難解で不具合になりやすいので、一旦KPIしか表示していないケースを専用で処理する
      const isKpiOnly =
        parameter.pivot.columns.last()?.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key &&
        parameter.pivot.columns.length === 1
      const isKpiAndKpiGroupOnly =
        parameter.pivot.columns.last()?.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key &&
        parameter.pivot.columns.first()?.key === CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_DIMENSION().key &&
        parameter.pivot.columns.length === 2
      if (config.type === 'kpiPivot' && (isKpiOnly || isKpiAndKpiGroupOnly)) {
        const oldConfigIndex = columnColumns[oldColumnIndex - systemColumns.length - rowColumns.length]?.configIndex
        const newConfigIndex = columnColumns[newColumnIndex - systemColumns.length - rowColumns.length]?.configIndex
        const movingOntoConfigIndex =
          columnColumns[
            between(movingOntoIndex - systemColumns.length - rowColumns.length, 0, columnColumns.length - 1)
          ]?.configIndex

        if (oldConfigIndex === undefined || newConfigIndex === undefined) {
          return
        }
        // 同じ親を持つkeyの配列を取得、そのkeyの中でまず並び替えする
        const sortedKpis = config.kpis.move(oldConfigIndex, newConfigIndex)

        const afterSortNewIndexKpi = sortedKpis[newConfigIndex]
        if (afterSortNewIndexKpi === undefined) {
          // ありえないはず
          return
        }

        if (movingOntoConfigIndex !== undefined) {
          const movingOntoKpi = config.kpis[movingOntoConfigIndex]
          set(setConfigMutation, {
            ...config,
            kpis: [
              ...sortedKpis.slice(0, newConfigIndex),
              {
                viewId: afterSortNewIndexKpi.viewId,
                kpiGroupName: movingOntoKpi?.kpiGroupName ?? afterSortNewIndexKpi.kpiGroupName,
              },
              ...sortedKpis.slice(newConfigIndex + 1),
            ],
          })
        }
        return
      }

      if (
        config.type === 'kpiPivot' &&
        parameter.pivot.columns.last()?.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key
      ) {
        // KPI が分析軸の一番下にある場合はここに到達する
        // XXX: 例えば、ダッシュボードのうち、ある KPI が未設定で分析列を 部 > KPI にすると挙動がおかしくなる。
        // その原因は config.kpis で configIndex が 1 から始まってしまうからである。
        // このバグを直すためには config.kpis で configIndex が 0 から始まるように直すべきで、ここで対処すべきでない。

        // 分析列X > KPI グループ > KPI の順番の場合も、分析列X > KPI の順番の場合も、
        // 分析列X を基準に列の移動の可否を決定するため、 `parentOffset` を定義する
        const parentOffset =
          parameter.pivot.columns.at(-2)?.key === CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME ? -1 : -2
        const oldIndex = oldColumnIndex - systemColumns.length - rowColumns.length
        const newIndex = newColumnIndex - systemColumns.length - rowColumns.length
        const movingOntoIndexRounded = between(
          movingOntoIndex - systemColumns.length - rowColumns.length,
          -1, // 分析軸やシステムカラム上にドロップした場合は-1にしておくことで、後に oldParentsとmovingOntoParentsが異なると判定される
          columnColumns.length - 1,
        )
        const oldKey = columnKeys[oldIndex]
        const movingOntoKey = columnKeys[movingOntoIndexRounded]
        const oldParents = oldKey?.slice(0, parentOffset)
        const movingOntoParents = movingOntoKey?.slice(0, parentOffset)
        if (oldParents === undefined || movingOntoParents === undefined || !oldParents.isEqual(movingOntoParents)) {
          // oldParentsとmovingOntoParentsが異なる場合は、何もしない
          return
        }
        const oldConfigIndex = columnColumns[oldIndex]?.configIndex
        const newConfigIndex = columnColumns[newIndex]?.configIndex
        const movingOntoConfigIndex = columnColumns[movingOntoIndexRounded]?.configIndex
        if (oldConfigIndex === undefined || newConfigIndex === undefined) {
          return
        }
        // 同じ親を持つkeyの配列を取得、そのkeyの中でまず並び替えする
        const sameParentKeys = columnKeys.filter((x) => x.slice(0, parentOffset).isEqual(oldParents))
        const swapped = sameParentKeys.move(oldConfigIndex, newConfigIndex)
        const sortedKpis = config.kpis.sortBy((kpi) => swapped.findIndex((x) => x.last()?.value === kpi.viewId))

        const afterSortNewIndexKpi = sortedKpis[newConfigIndex]
        if (afterSortNewIndexKpi === undefined || movingOntoConfigIndex === undefined) {
          // ありえないはず
          return
        }
        const movingOntoIndexKpi = config.kpis[movingOntoConfigIndex]
        if (movingOntoIndexKpi === undefined) {
          // ありえないはず
          return
        }

        set(setConfigMutation, {
          ...config,
          kpis: [
            ...sortedKpis.slice(0, newConfigIndex),
            {
              viewId: afterSortNewIndexKpi.viewId,
              kpiGroupName: movingOntoIndexKpi.kpiGroupName ?? afterSortNewIndexKpi.kpiGroupName,
            },
            ...sortedKpis.slice(newConfigIndex + 1),
          ],
        })
      } else {
        // TODO: sorterとしてセット
      }
    }
  },
})

export const useMoveColumnMutation = () => useSetRecoilState(moveColumnMutation)
