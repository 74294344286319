import { PageHeader } from '@ant-design/pro-layout'
import { useMutation } from '@apollo/client'
import { cartesianProduct, dateUtil, isNull } from '@salescore/buff-common'
import {
  type GoalConfigFieldsFragment,
  UpsertGoalsByCsvV2Document,
  type UserGroupFieldsFragment,
} from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { getOrganizationIdFromPath, UploadGoalDimensionCsvFileButton } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { getUsersWithUserGroup, validateDateByGoalConfig } from '@salescore/features'
import { CsvDownloadButton, useBooleanState, useRedirect } from '@salescore/frontend-common'
import { Alert, message, Space, Table, Typography } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useState } from 'react'
import { Trans } from 'react-i18next'

// eslint-disable-next-line complexity
export const ImportGoalsFormV3 = ({
  goalConfig,
  userGroups,
}: {
  goalConfig: GoalConfigFieldsFragment
  userGroups: UserGroupFieldsFragment[]
}) => {
  const redirect = useRedirect()
  const me = recoil.global.useMe()
  const [upsertGoalsByCsvMutation] = useMutation(UpsertGoalsByCsvV2Document)
  const loading = useBooleanState()
  const [errors, setErrors] = useState<
    Array<{
      index: number
      message: string
      record: unknown
    }>
  >([])
  const [imported, setImported] = useState<
    Array<{
      index: number
      record: unknown
    }>
  >([])
  const USER_GROUP_LABEL = `ユーザーグループ名`
  const USER_LABEL = `ユーザー名`
  const KPI_LABEL = `KPI名`

  const columns = [
    goalConfig.userType === 'user' || goalConfig.userType === 'group' ? USER_GROUP_LABEL : undefined,
    goalConfig.userType === 'user' ? USER_LABEL : undefined,
    KPI_LABEL,
    'kpi_id',
    'user_id',
    'date',
    goalConfig.goalDimension1?.label,
    goalConfig.goalDimension2?.label,
    goalConfig.goalDimension3?.label,
    goalConfig.goalDimension4?.label,
    goalConfig.goalDimension5?.label,
    'value',
  ].compact()

  const users = userGroups.flatMap((x) => x.users)
  const usersWithUserGroup = getUsersWithUserGroup({
    goalConfig: {
      ...goalConfig,
      // TODO: 改修が中途半端になっており、プロパティ名が微妙に異なる。ここで詰め替えしている。統一したい
      // https://github.com/salescore-inc/salescore/pull/1995/files#r1434833345
      userIds: goalConfig.userIdsConfig,
      userGroupIds: goalConfig.userGroupIdsConfig,
    },
    users,
    userGroups,
  })

  const productedRecordLength = [
    goalConfig.userType === 'user' || goalConfig.userType === 'group' ? [usersWithUserGroup.length, 1].max()! : 1,
    goalConfig.userType === 'user' ? goalConfig.kpiViews.length : 1,
    [(goalConfig.goalDimension1?.selectOptionsV3 ?? []).length, 1].max()!,
    [(goalConfig.goalDimension2?.selectOptionsV3 ?? []).length, 1].max()!,
    [(goalConfig.goalDimension3?.selectOptionsV3 ?? []).length, 1].max()!,
    [(goalConfig.goalDimension4?.selectOptionsV3 ?? []).length, 1].max()!,
    [(goalConfig.goalDimension5?.selectOptionsV3 ?? []).length, 1].max()!,
  ].reduce((accumulator, x) => accumulator * x, 1)
  const tooManyRecords = productedRecordLength > 10 ** 3 * 5

  return (
    <PageHeader
      title={<div>{t(`「{{goalConfigName}}」の目標インポート`, { goalConfigName: goalConfig.name })}</div>}
      onBack={() => {
        redirect(routes.goalConfigPathV2(goalConfig.id))
      }}
    >
      <div>
        {t(`目標をcsv形式でインポートすることができます。`)}
        <br />
        {t(`以下よりサンプルファイルをダウンロードして、「value」の列を埋めてください。`)}
        <div className="mb-8 mt-2">
          <CsvDownloadButton
            text={t(`サンプルファイルをダウンロード{{label}}`, {
              label: tooManyRecords ? `(一部のユーザー、KPIのみが含まれます)` : ``,
            })}
            filename={t(`目標サンプル`)}
            data={
              // eslint-disable-next-line complexity
              () => {
                const dates = dateUtil
                  .range(dayjs().startOf('month'), dayjs().endOf('month')) // 今月を対象とする
                  .map((x) => validateDateByGoalConfig(x.toDate(), goalConfig.timeSpanType)) // timeSpanTypeに合わせた形にする
                  .compact()
                  .map((x) => dayjs(x).format('YYYY-MM-DD'))
                  .unique()
                  .sortBy((x) => x)
                  .slice(0, 6) // dayのときにcsvが重くならないよう、適当にsliceする

                const xss = cartesianProduct([
                  dates,
                  goalConfig.userType === 'group'
                    ? usersWithUserGroup
                        .uniqueBy((x) => x.group.id)
                        .filter((x) => goalConfig.userGroupIdsConfig.includes(x.group.id))
                        .sortBy((x) => x.group.rank)
                    : goalConfig.userType === 'user'
                      ? tooManyRecords
                        ? usersWithUserGroup.slice(0, 5)
                        : usersWithUserGroup.isPresent()
                          ? usersWithUserGroup
                          : [null]
                      : [null],
                  tooManyRecords ? goalConfig.kpiViews.slice(0, 5) : goalConfig.kpiViews,
                  // cartesianProductでの型情報を有効に使うために、カスタム軸については以下のように指定している。
                  goalConfig.goalDimension1?.selectOptionsV3 ?? [{ value: '', label: '' }],
                  goalConfig.goalDimension2?.selectOptionsV3 ?? [{ value: '', label: '' }],
                  goalConfig.goalDimension3?.selectOptionsV3 ?? [{ value: '', label: '' }],
                  goalConfig.goalDimension4?.selectOptionsV3 ?? [{ value: '', label: '' }],
                  goalConfig.goalDimension5?.selectOptionsV3 ?? [{ value: '', label: '' }],
                ])
                // eslint-disable-next-line complexity
                return xss.map((xs) => {
                  const [date, userWithUserGroup, kpi, d1, d2, d3, d4, d5] = xs
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-unsafe-type-assertion
                  const record = {
                    user_id: goalConfig.userType === 'group' ? userWithUserGroup?.group.id : userWithUserGroup?.user.id, // これで正しいが、分かりづらいのをどうするか検討
                    kpi_id: kpi.id,
                    date,
                    value: 0,
                    [USER_LABEL]: userWithUserGroup?.user.name,
                    [USER_GROUP_LABEL]: userWithUserGroup?.group.name,
                    [KPI_LABEL]: kpi.name,
                    [goalConfig.goalDimension1?.label ?? '']: d1.value, // labelはuniqueではない可能性がある
                    [goalConfig.goalDimension2?.label ?? '']: d2.value,
                    [goalConfig.goalDimension3?.label ?? '']: d3.value,
                    [goalConfig.goalDimension4?.label ?? '']: d4.value,
                    [goalConfig.goalDimension5?.label ?? '']: d5.value,
                  } as Record<string, string | number>
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                  return columns.map((column) => record[column] as string)
                })
              }
            }
            headers={[...columns]}
          />
        </div>
        {t(`手元のExcelなどで作業を行う場合は、以下より必要なデータをダウンロードし、`)}
        <br />
        {t(`テンプレートと同じ形式(kpi_id,user_id,date,valueが含まれた形式)のcsvを作成してください。`)}
        <br />
        <Space className="mb-8 mt-2">
          <CsvDownloadButton
            text={t(`KPI一覧をダウンロード`)}
            filename={t(`KPI一覧`)}
            data={() => goalConfig.kpiViews.map((kpi) => [kpi.name, kpi.id])}
            headers={[KPI_LABEL, `kpi_id`]}
          />
          <CsvDownloadButton
            text={t(`ユーザー一覧をダウンロード`)}
            filename={t(`ユーザー一覧`)}
            data={() =>
              usersWithUserGroup.map((userWithUserGroup) => [
                userWithUserGroup.group.name,
                userWithUserGroup.user.name,
                userWithUserGroup.user.id,
              ])
            }
            headers={[USER_GROUP_LABEL, USER_LABEL, `user_id`]}
          />
        </Space>
        <Alert
          // showIcon
          type="info"
          className="mb-4"
          description={
            <div>
              {t(`・文字コードをUTF-8にしてアップロードしてください。`)}
              <br />
              <Trans>
                ・日付は<Typography.Text code>2024-01-01</Typography.Text>または
                <Typography.Text code>2024/01/01</Typography.Text>の形式で入力し、保存してください。
              </Trans>
              <br />
              {t(
                `※特にExcelでファイルを編集している際に、表示されている値と保存される値が違うことがあるため、ご注意ください。`,
              )}
            </div>
          }
        />
        <Alert
          // showIcon
          type="warning"
          className="mb-4"
          description={
            <div>
              {t(`※旧機能との相違点`)}
              <br />
              <Trans>
                ・日付形式で<Typography.Text code>2022-01</Typography.Text>
                の形式を受け付けません。月の目標を入力したい場合は、毎月1日の目標として入力してください。
              </Trans>
              <br />
              {t(
                `・CSVインポート時に行われる動作は、目標レコードの新規作成と更新のみとなり、削除は行われません。削除を行いたい場合は、値を0で上書きしてください。`,
              )}
              <br />
              {t(
                `・テンプレートのcsvの列は並び替え可能です。列名は変えないでください。不要な列が入っているとエラーになります。`,
              )}
            </div>
          }
        />
        <div className="mt-4" style={{ width: 400 }}>
          <UploadGoalDimensionCsvFileButton
            goalConfig={{
              ...goalConfig,
              userIds: usersWithUserGroup.map((user) => user.user.id),
              userGroupIds: userGroups.map((group) => group.id),
            }}
            users={usersWithUserGroup.map((user) => user.user)}
            userGroups={userGroups}
            loading={loading.isTrue}
            onLoad={
              // eslint-disable-next-line complexity
              async (base64, data) => {
                void message.info(t(`目標のアップロードを開始しました`))
                try {
                  loading.setTrue()
                  const result = await upsertGoalsByCsvMutation({
                    variables: {
                      organizationId: getOrganizationIdFromPath(),
                      goalConfigId: goalConfig.id,
                      csv: data,
                    },
                  })
                  const res = result.data?.upsertGoalsByCsvV2
                  if (isNull(res)) {
                    void message.error(
                      t(`エラーが発生しました。{{message}}`, { message: JSON.stringify(result.errors) }),
                    )
                    return
                  }
                  if (res.successCount > 0) {
                    void message.success(
                      t(`{{successCount}}件のレコードを更新しました。`, { successCount: res.successCount.toString() }),
                    )
                  }
                  if (res.errors.length > 0) {
                    void message.error(
                      t(`{{errorsLength}}件のエラーが発生しました。`, { errorsLength: res.errors.length.toString() }),
                    )
                  }
                  setErrors(res.errors)
                  setImported(res.success)
                } catch (error) {
                  if (error instanceof Error) {
                    void message.error(t(`エラーが発生しました。 {{message}}`, { message: error.message }))
                  } else {
                    void message.error(t(`エラーが発生しました。`))
                  }
                } finally {
                  loading.setFalse()
                }
              }
            }
          />
        </div>
      </div>
      {errors.isPresent() && (
        <div>
          <Table
            title={() => <>{t(`エラー行(最大100行まで表示)`)}</>}
            dataSource={errors}
            columns={[
              {
                key: `index`,
                dataIndex: `index`,
                title: t(`行番号`),
              },
              {
                key: `message`,
                dataIndex: `message`,
                title: t(`エラー`),
              },
              {
                key: `record`,
                dataIndex: `record`,
                title: t(`該当行`),
                render: (value) => <div>{JSON.stringify(value)}</div>,
              },
            ]}
          />
        </div>
      )}
      {imported.isPresent() && (
        <div>
          <Table
            title={() => <>{t(`インポートした行(最大100行まで表示)`)}</>}
            dataSource={imported}
            columns={[
              {
                key: `index`,
                dataIndex: `index`,
                title: t(`行番号`),
              },
              {
                key: `kpi_id`,
                dataIndex: `kpi_id`,
                title: `KPI`,
                render: (value, record) => (
                  <div>
                    {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                      (record.record as Record<string, string>).kpi_id
                    }
                  </div>
                ),
              },
              {
                key: `user_id`,
                dataIndex: `user_id`,
                title: t(`ユーザーID`),
                render: (value, record) => (
                  <div>
                    {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                      (record.record as Record<string, string>).user_id
                    }
                  </div>
                ),
              },
              {
                key: `date`,
                dataIndex: `date`,
                title: t(`日付`),
                render: (value, record) => (
                  <div>
                    {// eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                    (record.record as Record<string, string>).date?.split('T').first()}
                  </div>
                ),
              },
              // {
              //   key: `dimension1`,
              //   dataIndex: `dimension1`,
              //   title: `軸1`,
              //   render: (value, record) => {
              //     return <div>{(record.record as Record<string, string>).dimension1}</div>
              //   },
              // },
              {
                key: `value`,
                dataIndex: `value`,
                title: t(`目標`),
                render: (value, record) => (
                  <div>
                    {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                      (record.record as Record<string, string>).value
                    }
                  </div>
                ),
              },
              ...(me.isAdmin
                ? [
                    {
                      key: `json`,
                      dataIndex: `json`,
                      title: t(`目標`),
                      render: (_: unknown, record: unknown) => <div>{JSON.stringify(record)}</div>,
                    },
                  ]
                : []),
            ]}
          />
        </div>
      )}
    </PageHeader>
  )
}
