import { isNull } from '@salescore/buff-common'

import {
  type GenerateSearchSqlForStreamArgument,
  orderByLength,
  SEARCH_RESULT_LABEL_COLUMN_NAME,
  SEARCH_RESULT_VALUE_COLUMN_NAME,
} from './common'

// eslint-disable-next-line complexity
export function generateSearchSqlForZohoLayout({ model, parentModel }: GenerateSearchSqlForStreamArgument) {
  const nameProperty = model.properties.find((x) => x.meta === 'name') // Layoutのname
  const labelField = nameProperty?.name ?? 'id'
  const layoutModuleApiName = model.properties.find((x) => x.name === `module_api_name`)
  const objectName = parentModel.write?.sourceEntityName // TODO sourceで判定
  if (isNull(nameProperty) || isNull(layoutModuleApiName) || isNull(objectName)) {
    return
  }

  const searchSql = `SELECT
layouts.id as ${SEARCH_RESULT_VALUE_COLUMN_NAME},
layouts."${labelField}" as ${SEARCH_RESULT_LABEL_COLUMN_NAME}
FROM "${model.name}" as layouts
WHERE layouts."${layoutModuleApiName.name}" = '${objectName}'
`
  return {
    sql: orderByLength(searchSql, `layouts."${labelField}"`),
    dependedPropertyNames: [
      {
        streamName: model.name,
        propertyName: nameProperty.name,
      },
      {
        streamName: model.name,
        propertyName: layoutModuleApiName.name,
      },
    ],
  }
}
