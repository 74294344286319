import { ArrowDownOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { isNull, toMap } from '@salescore/buff-common'
import {
  CopyViewDocument,
  FetchViewGroupsDocument,
  FetchViewsDocument,
  type ViewForSiderFieldsFragment,
} from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import type { ViewConfigKpiPivot } from '@salescore/core'
import { createDestroyColumn } from '@salescore/frontend-common'
import { Button, Col, Input, message, Row, Select, Table } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { useViewConfigKpiPivot, useViewsContextValue, useViewValue } from '../../../recoil/view/hooks'
import { useViewsRelated } from '../../../recoil/view/selectors/viewsRelatedSelector'
import { ViewGroupPicker } from '../../form/ViewGroupPicker'
import { ViewListItem } from './KpiForm/ListItem/ViewListItem'

interface KpiBulkDuplicateForm {
  viewId: string
  kpi: ViewForSiderFieldsFragment
  name: string
  viewGroupId: string | null
  dashboardIds: string[]
}

// eslint-disable-next-line complexity
export const KpiBulkDuplicateForm = ({ onFinish }: { onFinish: () => void }) => {
  const { kpiViews } = useViewsRelated()
  const kpiViewsMap = toMap(kpiViews.map((x) => [x.id, x]))
  const [config, setConfig] = useViewConfigKpiPivot()
  const context = useViewsContextValue()
  const view = useViewValue()
  const [kpis, setKpis] = useState<KpiBulkDuplicateForm[]>([])
  const [loading, setLoading] = useState(false)
  const [copyViewMutation] = useMutation(CopyViewDocument)
  const { updateView } = useViewsContextValue()
  const client = useApolloClient()
  const kpiPivots = useQuery(FetchViewsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
      type: 'kpiPivot',
    },
    fetchPolicy: 'cache-and-network',
  })
  const kpiPivotsMap = toMap(kpiPivots.data?.views.map((x) => [x.id, x]) ?? [])
  const viewGroups = useQuery(FetchViewGroupsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
    fetchPolicy: 'cache-and-network',
  })
  const privateViewGroupIds = viewGroups.data?.viewGroups.filter((x) => x.private).map((x) => x.id) ?? []

  // eslint-disable-next-line complexity
  const onClick = async () => {
    setLoading(true)
    for (const kpi of kpis) {
      if (kpi.name === '' || kpi.viewGroupId === '' || kpi.dashboardIds.length === 0) {
        void message.error(t(`未入力の項目があります`))
        setLoading(false)
        return
      }
    }

    // TODO: BulkDuplicate用mutationへの差し替え
    for (const kpi of kpis) {
      // KPIの複製
      const copyView = await copyViewMutation({
        variables: {
          organizationId: getOrganizationIdFromPath(),
          id: kpi.viewId,
          name: kpi.name,
          viewGroupId: kpi.viewGroupId,
          isPrivate: privateViewGroupIds.includes(kpi.viewGroupId ?? ''),
        },
      })
      // 複製したKPIをダッシュボードへ反映
      const { data: targetPivots } = await client.query({
        query: FetchViewsDocument,
        variables: {
          organizationId: getOrganizationIdFromPath(),
          ids: kpi.dashboardIds,
        },
      })
      for (const pivot of targetPivots.views) {
        // ありえないはず
        if (pivot.config.type !== 'kpiPivot' || copyView.data?.copyView.id === undefined) {
          continue
        }
        const newConfig: ViewConfigKpiPivot = {
          ...pivot.config,
          kpis: [
            ...pivot.config.kpis,
            {
              viewId: copyView.data.copyView.id,
            },
          ],
        }
        await updateView({
          id: pivot.id,
          config: newConfig,
        })
        // 閲覧中のダッシュボードに追加した場合は即時反映させる
        if (view.id === pivot.id) {
          await context.onAddResource({ viewIds: [copyView.data.copyView.id] })
          setConfig(newConfig)
        }
      }
    }

    setLoading(false)
    void message.info(t(`${kpis.length}件のKPIの複製が完了しました`))
    await context.refetchViews()
    onFinish()
  }

  return (
    <PageHeader title={t(`KPIの一括複製`)}>
      <div className="py-1">
        <Button
          type="text"
          style={{ fontWeight: 'bold' }}
          onClick={() => {
            setKpis(
              config?.kpis
                .map((x) => ({
                  viewId: x.viewId,
                  kpi: kpiViewsMap.get(x.viewId)!,
                  name: t(`{{label}}のコピー`, { label: kpiViewsMap.get(x.viewId)?.name }),
                  viewGroupId: '',
                  dashboardIds: [],
                }))
                .filter((x) => x.kpi !== undefined) ?? [],
            )
          }}
        >
          {t(`ダッシュボードに紐づくすべてのKPIを複製`)}
        </Button>
        <Button
          type="text"
          style={{ fontWeight: 'bold' }}
          onClick={() => {
            setKpis([])
          }}
        >
          {t(`すべて解除`)}
        </Button>
      </div>
      <Select
        showSearch
        allowClear
        placeholder={t(`KPIを検索`)}
        filterOption={(input, option) => (option?.key ?? '').toLowerCase().includes(input.toLowerCase())}
        onChange={(value) => {
          if (value === undefined) {
            return
          }
          if (kpis.some((x) => x.viewId === value)) {
            void message.warning(t(`既に複製対象に追加されているKPIです`))
            return
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const kpi = kpiViewsMap.get(value)!
          // フォルダ(viewGroupId)は所属無しの場合nullになるため空文字で初期化
          setKpis([
            ...kpis,
            {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              viewId: value,
              kpi,
              name: t(`{{label}}のコピー`, { label: kpi.name }),
              viewGroupId: '',
              dashboardIds: [],
            },
          ])
        }}
        options={kpiViews.map((x, index) => ({
          label: x.name,
          value: x.id,
          key: [x.name, x.createdBy?.name, index].join('-'),
        }))}
        optionRender={(option) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          const kpi = kpiViewsMap.get(option.value as string)
          if (isNull(kpi)) {
            return <></>
          }
          return <ViewListItem view={kpi} />
        }}
        style={{ width: '100%' }}
      />
      <Table
        className="py-6"
        rowKey="id"
        dataSource={kpis.map((x) => x.kpi)}
        columns={[
          {
            title: t(`KPI名`),
            dataIndex: 'name',
            key: 'name',
            render: (name: string, record: ViewForSiderFieldsFragment) => (
              <Input
                defaultValue={t(`{{label}}のコピー`, { label: name })}
                onChange={(value) => {
                  const targetKpi = kpis.find((x) => x.viewId === record.id)
                  if (targetKpi?.viewId === undefined) {
                    return
                  }
                  const previousKpiIndex = [kpis.findIndex((x) => x.viewId === record.id) ?? -1, -1].max()!
                  const newKpis = kpis.toSpliced(previousKpiIndex, 1, { ...targetKpi, name: value.target.value })
                  setKpis(newKpis)
                }}
              />
            ),
          },
          {
            title: (
              <>
                <Row>
                  <Col span={8}>{t(`フォルダ`)}</Col>
                  <Col span={8} offset={3}>
                    <Button
                      type="text"
                      icon={<ArrowDownOutlined />}
                      size="small"
                      onClick={() => {
                        if (kpis === undefined || kpis.length === 0) {
                          return
                        }
                        const newKpis = kpis.map((x) => ({ ...x, viewGroupId: kpis[0]!.viewGroupId }))
                        setKpis(newKpis)
                      }}
                    >
                      <span className="text-xs">{t(`1行目の値を全てに適用`)}</span>
                    </Button>
                  </Col>
                </Row>
              </>
            ),
            dataIndex: 'folder',
            key: 'folder',
            render: (name: string, record: ViewForSiderFieldsFragment) => (
              <ViewGroupPicker
                type="kpi"
                isPrivate={false}
                ignorePrivateFlag={true}
                archived={false}
                value={kpis.find((x) => x.viewId === record.id)?.viewGroupId}
                hideFolder={true}
                onChange={(value) => {
                  const targetKpi = kpis.find((x) => x.viewId === record.id)
                  if (targetKpi?.viewId === undefined) {
                    return
                  }
                  const previousKpiIndex = [kpis.findIndex((x) => x.viewId === record.id) ?? -1, -1].max()!
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                  const newKpis = kpis.toSpliced(previousKpiIndex, 1, { ...targetKpi, viewGroupId: value as string })
                  setKpis(newKpis)
                }}
              />
            ),
          },
          {
            title: (
              <>
                <Row>
                  <Col span={8}>{t(`反映ダッシュボード`)}</Col>
                  <Col span={8} offset={5}>
                    <Button
                      type="text"
                      icon={<ArrowDownOutlined />}
                      size="small"
                      onClick={() => {
                        if (kpis === undefined || kpis.length === 0) {
                          return
                        }
                        const newKpis = kpis.map((x) => ({ ...x, dashboardIds: kpis[0]!.dashboardIds }))
                        setKpis(newKpis)
                      }}
                    >
                      <span className="text-xs">{t(`1行目の値を全てに適用`)}</span>
                    </Button>
                  </Col>
                </Row>
              </>
            ),
            dataIndex: 'dashboard',
            key: 'dashboard',
            render: (name: string, record: ViewForSiderFieldsFragment) => (
              <Select
                options={kpiPivots.data?.views.map((x, index) => ({
                  label: x.name,
                  value: x.id,
                  key: [x.name, x.createdBy?.name, index].join('-'),
                }))}
                optionRender={(option) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                  const kpiPivot = kpiPivotsMap.get(option.value as string)
                  if (isNull(kpiPivot)) {
                    return <></>
                  }
                  return <ViewListItem view={kpiPivot} />
                }}
                value={kpis.find((x) => x.viewId === record.id)?.dashboardIds}
                filterOption={(input, option) =>
                  (option?.key ?? '').toLowerCase().includes(input.toLowerCase()) ?? false
                }
                mode="multiple"
                allowClear
                placeholder={t(`ダッシュボードを選択`)}
                style={{ width: '100%' }}
                onChange={(value) => {
                  const targetKpi = kpis.find((x) => x.viewId === record.id)
                  if (targetKpi?.viewId === undefined) {
                    return
                  }
                  const previousKpiIndex = [kpis.findIndex((x) => x.viewId === record.id) ?? -1, -1].max()!
                  const newKpis = kpis.toSpliced(previousKpiIndex, 1, { ...targetKpi, dashboardIds: value })
                  setKpis(newKpis)
                }}
              />
            ),
          },
          createDestroyColumn(
            loading,
            setLoading,
            // eslint-disable-next-line @typescript-eslint/require-await
            async (record) => {
              setKpis(kpis.filter((x) => x.viewId !== record.id))
            },
            undefined,
            true,
          ),
        ]}
        pagination={false}
      />
      {kpis.length > 0 && (
        <Row justify="end">
          <Button type="primary" loading={loading} onClick={onClick}>
            {t(`保存`)}
          </Button>
        </Row>
      )}
    </PageHeader>
  )
}
