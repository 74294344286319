import { useApolloClient, useMutation } from '@apollo/client'
import { FetchViewsDocument, MoveViewsDocument, ViewConfigTypeEnum } from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import type { ViewConfigKpiPivot } from '@salescore/core'
import { ButtonWithTooltip, useBooleanState } from '@salescore/frontend-common'
import { message } from 'antd'
import { t } from 'i18next'
import { useContext } from 'react'

import {
  useViewAbilityValue,
  useViewConfigKpi,
  useViewsContextValue,
  useViewValue,
} from '../../../../recoil/view/hooks'
import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'
import { ViewUIContext } from '../../ViewUIComponent'

export const useSaveKpiForm = () => {
  const { setFormModified, validatedFormValue } = useKpiFormSelectors()
  const client = useApolloClient()
  const view = useViewValue()
  const loading = useBooleanState()
  const { updateView } = useViewsContextValue()
  const [moveView] = useMutation(MoveViewsDocument)
  const [_, setKpiConfig] = useViewConfigKpi()
  const { onFinish } = useContext(ViewUIContext)

  // eslint-disable-next-line complexity
  return async () => {
    try {
      loading.setTrue()
      const { name, viewGroupId, dashboardIds, kpiConfig } = validatedFormValue
      const { data } = await client.query({
        query: FetchViewsDocument,
        variables: {
          organizationId: getOrganizationIdFromPath(),
          type: ViewConfigTypeEnum.KpiPivot,
        },
      })
      const dashboardViews = data.views

      if (!kpiConfig.success) {
        // TODO
        void message.warning(kpiConfig.error.message)
        return
      }
      if (name === undefined) {
        // TODO
        return
      }

      const saved = await updateView({
        id: view.id,
        name,
        config: kpiConfig.data,
      })
      if (viewGroupId !== undefined) {
        await moveView({
          variables: {
            organizationId: getOrganizationIdFromPath(),
            inputs: [
              {
                id: view.id,
                viewGroupId,
                rank: 0,
              },
            ],
          },
        })
      }
      const savedConfig = saved?.config
      if (savedConfig?.type === 'kpi') {
        setKpiConfig(savedConfig)
      }

      for (const dashboardView of dashboardViews) {
        const config = dashboardView.config
        if (config.type !== 'kpiPivot') {
          continue
        }
        // ダッシュボードがこのビューを含むとき
        if (config.kpis.map((x) => x.viewId).includes(view.id)) {
          // 今回の更新にも含まれていれば、何もしなくて良い
          if ((dashboardIds ?? []).includes(dashboardView.id)) {
            continue
          }
          // 今回の更新に含まれていなければ、削除する必要がある
          const newConfig: ViewConfigKpiPivot = {
            ...config,
            kpis: config.kpis.filter((x) => x.viewId !== view.id),
          }
          await updateView({
            id: dashboardView.id,
            config: newConfig,
          })
          continue
        }
        // 含まれていない時、今回の更新で含むようにしていれば、追加
        if ((dashboardIds ?? []).includes(dashboardView.id)) {
          const newConfig: ViewConfigKpiPivot = {
            ...config,
            kpis: [
              ...config.kpis,
              {
                viewId: view.id,
              },
            ],
          }
          await updateView({
            id: dashboardView.id,
            config: newConfig,
          })
        }
      }
      setFormModified(false)
      if (onFinish !== undefined) {
        onFinish()
      }
      void message.success(t(`設定を保存しました。`))
      Posthog.track(POSTHOG_EVENTS.save_kpi, { organizationId: getOrganizationIdFromPath(), config: saved?.config })
    } catch (error) {
      if (error instanceof Error) {
        void message.error(error.message)
      } else {
        void message.error(t(`エラーが発生しました。`))
      }
    } finally {
      loading.setFalse()
    }
  }
}

export function KpiFormSaveButton() {
  const ability = useViewAbilityValue()
  const { validatedFormValue } = useKpiFormSelectors()
  const loading = useBooleanState()
  const saveKpiForm = useSaveKpiForm()

  return (
    <>
      <ButtonWithTooltip
        tooltipTitle={t(`権限がありません`)}
        showTooltip={!ability.canUpdate}
        disabled={!validatedFormValue.kpiConfig.success || !ability.canUpdate}
        type="primary"
        htmlType="submit"
        loading={loading.isTrue}
        onClick={saveKpiForm}
      >
        <div data-e2e="kpi-form-step-2-save-button">{t(`保存`)}</div>
      </ButtonWithTooltip>
      {/* TODO: エラーメッセージの表示 */}
      {/* {!validatedFormValue.kpiConfig.success && validatedFormValue.kpiConfig.error.message } */}
    </>
  )
}
