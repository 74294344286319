import { TableOutlined } from '@ant-design/icons'
import { isDataExtensionModel } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { CORE_CONSTANT, type CoreModel, ViewConfigSheetValueObject, type ViewConfigTreeNode } from '@salescore/core'
import { Form, Select } from 'antd'
import { t } from 'i18next'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'

interface KpiFormRootNodeFormItemProperties {
  withLabelIcon?: boolean
  snapshotMode?: boolean
  sheetDefaultProperties?: 'all'
  onAfterChange?: (picked: CoreModel) => void
}

export const KpiFormRootNodeFormItem = (properties: KpiFormRootNodeFormItemProperties) => {
  const me = recoil.global.useMe()
  const haveDataExtensionLicense =
    me.isAdmin || me.organization.organizationPlans.some((x) => x.license === 'dataExtension')
  const { rootModelSelectOptions, currentSheet, setForm, form, setCallbackFlags, setFormModified } =
    useKpiFormSelectors()

  // NOTE: オブジェクトの順序を内部名の昇順で保ちつつprefixがsalescore_ or custom_の場合は末尾に配置する
  const sortedOptions = [...rootModelSelectOptions].sortBy((x) => {
    const [xPrefix] = x.value.split('_')
    return xPrefix === 'custom' || xPrefix === 'salescore' ? 1 : -1
  })

  const options = (
    properties.snapshotMode === true
      ? sortedOptions.filter(
          (x) =>
            x.value.startsWith(CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX) &&
            !x.value.endsWith(CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX),
        )
      : sortedOptions.filter((x) => !x.value.endsWith(CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX))
  ).filter((x) => haveDataExtensionLicense || !isDataExtensionModel(x.model))

  return (
    // 表示のためにForm.Itemを使うが、valueはmodelStateに基づき、formに状態やロジックは持たせない
    <Form.Item
      label={
        properties.withLabelIcon === true ? (
          <div>
            <TableOutlined /> {t(`集計のベースとなるオブジェクト`)}
          </div>
        ) : (
          <div>{t(`集計のベースとなるオブジェクト`)}</div>
        )
      }
      data-e2e="kpi-form-root-node-form-item"
      rules={[{ required: true }]}
    >
      <Select
        showSearch
        // placeholder={"集計のベースとなるオブジェクト"}
        filterOption={(input, option) => (option?.key ?? '').toLowerCase().includes(input.toLowerCase())}
        onChange={
          // eslint-disable-next-line complexity
          (value) => {
            const picked = rootModelSelectOptions.find((x) => x.value === value)
            if (picked === undefined) {
              // ありえないはず
              return
            }
            const { model } = picked
            if (currentSheet.tree?.modelName === model.name) {
              return
            }
            const tree: ViewConfigTreeNode = {
              type: `model`,
              name: model.name,
              modelName: model.name,
            }
            const sheet = new ViewConfigSheetValueObject({
              type: `sheet`,
              tree,
            })
            const sheetWithDefaults =
              properties.sheetDefaultProperties === 'all'
                ? sheet.addAllPropertyFields(tree, model)
                : sheet.addDefaultPropertyFields(tree, model)
            setForm((oldValue) => ({
              ...oldValue,
              // ベースオブジェクトが変更された時に、前のmeasureの値が残っていると不具合の原因になるのでリセットする
              measure: undefined,
              type: `kpi`,
              name: form.name,
              ui: form.ui,
              sheet: sheetWithDefaults.config,
            }))
            setFormModified(true)
            // setFormが実行された後のselectorの値を使ってコールバックを発火させたい
            // スマートなやり方がわからず、コールバックを発火させるためのatomをわざわざ用意している
            setCallbackFlags({ setDefaultByRoot: true })
            properties.onAfterChange?.(model)
          }
        }
        value={currentSheet.tree?.modelName}
        options={options}
        optionRender={(option) => <span className="whitespace-normal break-words ">{option.label}</span>}
      />
    </Form.Item>
  )
}
