import { isSome } from '@salescore/buff-common'
import {
  type ArrayFilterType,
  arrayFilterTypes,
  type BooleanFilterType,
  booleanFilterTypes,
  type DateFilterType,
  dateFilterTypes,
  type FieldType,
  fieldTypeSchema,
  type FilterType,
  type ModelProperty,
  type NumericFilterType,
  numericFilterTypes,
  type StringFilterType,
  stringFilterTypes,
  type TimeFilterType,
  timeFilterTypes,
} from '@salescore/core'
import { contextualizeDictionary } from '@salescore/frontend-common'
import { Form, Select } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'

import { getUserColumnNameFromReferenceTo } from '../../../../../recoil/view/functions/filterRelated'
import type { FilterFormItemState } from './common'

const fieldTypeToFilterTypes: Record<FieldType, FilterType[]> = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  string: stringFilterTypes.filter((x) => !['null', 'not_null'].includes(x)) as unknown as StringFilterType[],
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  integer: numericFilterTypes as unknown as NumericFilterType[],
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  numeric: numericFilterTypes as unknown as NumericFilterType[],
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  date: dateFilterTypes as unknown as DateFilterType[],
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  datetime: dateFilterTypes as unknown as DateFilterType[],
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  time: timeFilterTypes as unknown as TimeFilterType[],
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  boolean: booleanFilterTypes as unknown as BooleanFilterType[],
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  array: arrayFilterTypes as unknown as ArrayFilterType[],
  object: [],
}

const filterTypeJa: Record<FilterType, string> = {
  in: `次のどれか`,
  not_in: `次のどれでもない`,
  equal: `等しい`,
  not_equal: `異なる`,
  blank: `入力なし`,
  present: `入力あり`,
  null: `入力なし`,
  not_null: `入力あり`,
  include: `次の文字列を含む`,
  not_include: `次の文字列を含まない`,
  starts_with: `次の文字列から始まる`,
  not_starts_with: `次の文字列から始まらない`,
  overlap: `次の要素を含む`,
  not_overlap: `次の要素を含まない`,
  less_than: `より小さい`,
  less_than_or_equal: `以下`,
  greater_than: `より大きい`,
  greater_than_or_equal: `以上`,
  equal_me: `自分のユーザーID`,
}

// filterTypeJa の翻訳を、 コンテキスト (fieldType) 別に生成している
// 例えば日本語で翻訳する場合、
// {
//   date: {
//     ['<']: 'より前',
//     ['<=']: '以前',
//     ...
//   },
//   integer: {
//     ['<']: 'より小さい',
//     ['<=']: '以下',
//     ...
//   },
//   ...
// }
// eslint-disable-next-line unicorn/no-array-reduce
const filterTypeJaByType = fieldTypeSchema.options.reduce(
  (previous, fieldType) => Object.assign(previous, { [fieldType]: contextualizeDictionary(filterTypeJa, fieldType) }),
  // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter,@typescript-eslint/consistent-type-assertions,@typescript-eslint/no-unsafe-type-assertion
  {} as Record<FieldType, Record<FilterType, string>>,
)
// eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
const filterTypeNoTypeJa = contextualizeDictionary(filterTypeJa) as Record<FilterType, string>

export const ViewFilterTypeFormItem = ({
  filter,
  property,
  onChange,
}: {
  onChange: (x: FilterFormItemState, option?: { asInitialization?: boolean }) => void
  filter: FilterFormItemState
  property: ModelProperty | undefined
}) => {
  // if (filter.type === 'field') {
  //   return <></>
  // }

  const options = getOptions(property, filter)

  useEffect(() => {
    // 選択中のfilterTypeが、選択可能なオプションの中に含まれなかったらクリア
    if (isSome(filter.filterType) && !options.some((option) => option.value === filter.filterType)) {
      onChange(
        {
          ...filter,
          filterType: undefined,
          filterValue: undefined,
        },
        { asInitialization: true },
      )
    }
  }, [filter])

  return (
    <Form.Item label={<div>{t(`条件`)}</div>} rules={[{ required: true, message: t(`入力してください`) }]}>
      <Select
        disabled={options.length === 0}
        value={filter.filterType}
        onChange={
          // eslint-disable-next-line complexity
          (value) => {
            if (value === 'equal_me') {
              const columnName = getUserColumnNameFromReferenceTo(property?.referenceTo ?? [])
              if (columnName === undefined) {
                return
              }
              // TODO: フォーム側でこのロジックを持って良いだろうか？
              onChange({
                ...filter,
                filterType: value,
                filterValue: `{{ ${columnName} }}`,
              })
              return
            }
            onChange({
              ...filter,
              filterType: value,
              // 条件(Type)の変更に応じて条件の値(Value)の型を変える必要がある
              // e.g. 等しい(string) => 次のどれか(Array)、 次のどれでもない(Array) => 異なる(string)
              filterValue:
                typeof filter.filterValue === 'string' && ['in', 'not_in'].includes(value)
                  ? [filter.filterValue]
                  : Array.isArray(filter.filterValue) && !['in', 'not_in'].includes(value)
                    ? filter.filterValue.length > 0
                      ? filter.filterValue[0]
                      : undefined
                    : filter.filterValue,
            })
          }
        }
        options={options}
      />
    </Form.Item>
  )
}

function getOptions(property: ModelProperty | undefined, filter: FilterFormItemState) {
  // if (filter.type === 'field') {
  //   return []
  // }

  if (property === undefined) {
    return []
  }

  const selects = property.selectOptions ?? []

  const filterTypes = fieldTypeToFilterTypes[property.type]

  // 一部のフィルタ種別については、条件を満たしたときのみ表示する
  const fixedFilterTypes: FilterType[] = filterTypes.filter((filterType) => {
    switch (filterType) {
      // 選択肢があるときだけin,not_inを表示する
      case 'in': {
        return selects.length > 0
      }
      case 'not_in': {
        return selects.length > 0
      }
      case 'equal_me': {
        // 元項目がユーザーIDへの参照のとき、「自分」という動的フィルターを選択する
        return (
          filter.option?.shouldFilterToReferencedEntityName === false &&
          getUserColumnNameFromReferenceTo(property.referenceTo ?? []) !== undefined
        )
      }
      default: {
        break
      }
    }
    return true
  })
  const options = fixedFilterTypes.map((filterType, index) => ({
    label: getLabel(property.type, filterType),
    key: `${filterType}-${index}`, // filterTypeに重複がでてしまいkeyが重複すると表示がバグるので、iをいれておく
    value: filterType,
  }))

  return options
}

function getLabel(fieldType: FieldType | undefined, filterType: FilterType) {
  return fieldType === undefined ? filterTypeNoTypeJa[filterType] : filterTypeJaByType[fieldType][filterType]
}
