import { range } from '@salescore/buff-common'
import dayjs, { type Dayjs } from 'dayjs'
import { t } from 'i18next'

export interface Period {
  startAt?: Dayjs
  endAt?: Dayjs
  name: string
  nameLabel?: string
  groupName?: string
  groupNameLabel?: string
}

export interface OrganizationSetting {
  accountClosingMonth: number
  monthBeginningDay: number
}

export const getCustomPeriod = (): Period => ({
  name: 'カスタム',
  groupName: 'カスタム',
  nameLabel: t(`カスタム`),
  groupNameLabel: t(`カスタム`),
})

export const getAllPeriod = (): Period => ({
  name: '全期間',
  groupName: '全期間',
  nameLabel: t(`全期間`),
  groupNameLabel: t(`全期間`),
})

const getCustomMonthPeriod = (): Period => ({
  name: 'カスタム(月)',
  groupName: 'カスタム',
  nameLabel: t(`カスタム(月)`),
  groupNameLabel: t(`カスタム`),
})

interface MonthPeriodRaw {
  name: string
  nameLabel: string
  start: number
  end: number
}

const getBeginingOfCurrentMonth = (setting: OrganizationSetting) => {
  const current = dayjs().startOf('day')
  // currentが1日、dayが1日のとき、1日
  // currentが3日、dayが1日のとき、1日
  if (current.date() >= setting.monthBeginningDay) {
    return current.set('date', setting.monthBeginningDay)
  }

  // currentが1日、dayが3日のとき、前月の3日
  // currentが3日、dayが20日のとき、前月の20日
  return current.subtract(1, 'month').set('date', setting.monthBeginningDay)
}

const getBeginingOfCurrentYear = (setting: OrganizationSetting) => {
  const currentMonth = getBeginingOfCurrentMonth(setting)
  // currentが1月、closingMonthが1月のとき：年度の開始は2月であり、去年の2月が今年度の開始
  // currentが3月、closingMonthが1月のとき：年度の開始は2月であり、今年の2月が今年度の開始
  // currentが3月、closingMonthが2月のとき：年度の開始は3月であり、今年の3月が今年度の開始
  // currentが年度の開始月以上だったら、今年度の開始は今年
  if (currentMonth.month() + 1 >= setting.accountClosingMonth + 1) {
    // dayjsのmonthは常に0-11
    return currentMonth.set('month', setting.accountClosingMonth) // dayjsのmonthは0が1月。決算月の翌月が年度の開始なので、accountClosingMonthをsetするとその翌月となり正しい。
  }
  // currentが1月、closingMonthが3月のとき、前年の3月
  // currentが年度の開始月よりも前だったら、今年度の開始は去年
  return currentMonth.set('month', setting.accountClosingMonth).subtract(1, 'year') // dayjsのmonthは0が1月
}

export const getCurrentQuarters = (setting: OrganizationSetting) => {
  const beginningOfCurrentYear = getBeginingOfCurrentYear(setting)
  return range(1, 4).map((quarter) => ({
    quarter,
    beginningOfQuarter: beginningOfCurrentYear.add((quarter - 1) * 3, 'month'),
  }))
}

export const getCurrentHalfYears = (setting: OrganizationSetting) => {
  const beginningOfCurrentYear = getBeginingOfCurrentYear(setting)
  return range(1, 2).map((halfYear) => ({
    halfYear,
    beginningOfQuarter: beginningOfCurrentYear.add((halfYear - 1) * 6, 'month'),
  }))
}

const getMonthPeriods = (setting: OrganizationSetting, monthPeriodRaw: MonthPeriodRaw[]): Period[] => {
  const currentMonth = getBeginingOfCurrentMonth(setting)
  return monthPeriodRaw.map((x) => ({
    startAt: currentMonth.add(x.start, 'month').startOf('day'),
    endAt: currentMonth
      .add(x.end + 1, 'month')
      .subtract(1, 'day')
      .endOf('day'),
    name: x.name,
    nameLabel: x.nameLabel,
    groupName: '月',
    groupNameLabel: t(`月`),
  }))
}

const getPastMonthPeriods = (setting: OrganizationSetting): Period[] =>
  getMonthPeriods(setting, [
    {
      name: '今月',
      nameLabel: t(`今月`),
      start: 0,
      end: 0,
    },
    {
      name: '先月',
      nameLabel: t(`先月`),
      start: -1,
      end: -1,
    },
    {
      name: '先々月',
      nameLabel: t(`先々月`),
      start: -2,
      end: -2,
    },
    {
      name: '3ヶ月前',
      nameLabel: t(`3ヶ月前`),
      start: -3,
      end: -3,
    },
    {
      name: '今月・先月',
      nameLabel: t(`今月・先月`),
      start: -1,
      end: -0,
    },
    {
      name: '先月・先々月',
      nameLabel: t(`先月・先々月`),
      start: -2,
      end: -1,
    },
    {
      name: '今月・来月',
      nameLabel: t(`今月・来月`),
      start: 0,
      end: 1,
    },
  ])

const getFutureMonthPeriods = (setting: OrganizationSetting) => {
  const FUTURE_MONTH_PERIODS: MonthPeriodRaw[] = [
    {
      name: '来月',
      nameLabel: t(`来月`),
      start: 1,
      end: 1,
    },
    {
      name: '再来月',
      nameLabel: t(`再来月`),
      start: 2,
      end: 2,
    },
    {
      name: '3ヶ月後',
      nameLabel: t(`3ヶ月後`),
      start: 3,
      end: 3,
    },
  ]

  return getMonthPeriods(setting, FUTURE_MONTH_PERIODS)
}

// eslint-disable-next-line complexity
const getQuarterPeriods = (
  setting: OrganizationSetting,
  yearOffset: number,
  groupName: string,
  groupNameLabel: string,
): Period[] => {
  const quarters = getCurrentQuarters(setting).map((quarter) => {
    const startAt = quarter.beginningOfQuarter.add(yearOffset, 'year').startOf('day')
    const endAt = quarter.beginningOfQuarter.add(3, 'month').subtract(1, 'day').add(yearOffset, 'year').endOf('day')
    return {
      startAt,
      endAt,
      name: `${quarter.quarter}Q(${startAt.year()}/${startAt.month() + 1}-${endAt.month() + 1})`,
      nameLabel: `${quarter.quarter}Q(${startAt.year()}/${startAt.month() + 1}-${endAt.month() + 1})`,
      groupName,
      groupNameLabel,
    }
  })
  const currentQuarter: Period | undefined = quarters.find(
    (x) => x.startAt.isBefore(dayjs()) && x.endAt.isAfter(dayjs()),
  )
  const relativeQuarters =
    currentQuarter === undefined
      ? []
      : [
          {
            ...currentQuarter,
            name: '今四半期',
            nameLabel: t(`今四半期`),
          },
          {
            ...currentQuarter,
            startAt: currentQuarter.startAt?.subtract(3, 'month').startOf('month'),
            endAt: currentQuarter.endAt?.subtract(3, 'month').endOf('month'),
            name: '前四半期',
            nameLabel: t(`前四半期`),
          },
          {
            ...currentQuarter,
            startAt: currentQuarter.startAt?.add(3, 'month').startOf('month'),
            endAt: currentQuarter.endAt?.add(3, 'month').endOf('month'),
            name: '来四半期',
            nameLabel: t(`来四半期`),
          },
        ]

  return [...relativeQuarters, ...quarters].compact()
}

const getHalfYearPeriods = (
  setting: OrganizationSetting,
  yearOffset: number,
  groupName: string,
  groupNameLabel: string,
) =>
  getCurrentHalfYears(setting).map((halfYear) => {
    const startAt = halfYear.beginningOfQuarter.add(yearOffset, 'year').startOf('day')
    const endAt = halfYear.beginningOfQuarter.add(6, 'month').subtract(1, 'day').add(yearOffset, 'year').endOf('day')
    const name = halfYear.halfYear === 1 ? '上半期' : '下半期'
    const nameLabel = halfYear.halfYear === 1 ? t(`上半期`) : t(`下半期`)
    return {
      startAt,
      endAt,
      name: `${name}(${startAt.year()}/${startAt.month() + 1}-${endAt.month() + 1})`,
      nameLabel: `${nameLabel}(${startAt.year()}/${startAt.month() + 1}-${endAt.month() + 1})`,
      groupName,
      groupNameLabel,
    }
  })

const getPastYearPeriods = (setting: OrganizationSetting) => {
  const beginningOfCurrentYear = getBeginingOfCurrentYear(setting)
  const currentYear = {
    startAt: beginningOfCurrentYear,
    endAt: beginningOfCurrentYear.add(1, 'year').subtract(1, 'day').endOf('day'),
    name: '今年度',
    nameLabel: t(`当会計年度`),
    groupName: '会計年度',
    groupNameLabel: t(`会計年度`),
  }
  return [
    currentYear,
    {
      startAt: currentYear.startAt.add(-1, 'year'),
      endAt: currentYear.endAt.add(-1, 'year'),
      name: '前年度',
      nameLabel: t(`前会計年度`),
      groupName: '会計年度',
      groupNameLabel: t(`会計年度`),
    },
    {
      startAt: currentYear.startAt.add(1, 'year'),
      endAt: currentYear.endAt.add(1, 'year'),
      name: '来年度',
      nameLabel: t(`翌会計年度`),
      groupName: '会計年度',
      groupNameLabel: t(`会計年度`),
    },
  ]
}

const getYearPeriods = (setting: OrganizationSetting) => {
  const currentYear = dayjs().startOf('year')
  return [
    {
      startAt: currentYear,
      endAt: currentYear.endOf('year'),
      name: '今年',
      nameLabel: t(`今年`),
      groupName: '年',
      groupNameLabel: t(`年`),
    },
    {
      startAt: currentYear.subtract(1, 'year').startOf('year'),
      endAt: currentYear.subtract(1, 'year').endOf('year'),
      name: '昨年',
      nameLabel: t(`昨年`),
      groupName: '年',
      groupNameLabel: t(`年`),
    },
    {
      startAt: currentYear.add(1, 'year').startOf('year'),
      endAt: currentYear.add(1, 'year').endOf('year'),
      name: '来年',
      nameLabel: t(`来年`),
      groupName: '年',
      groupNameLabel: t(`年`),
    },
  ]
}

const getWeekPeriods = (setting: OrganizationSetting) => {
  // 期間絞り込みで週の集計は月曜始まりで統一する(月曜~日曜で1単位)
  // dayjsの仕様では週の始まりが日曜のため、今日が日曜日の場合は今週が翌週扱いになってしまう
  // よって1日前にしてstartOf('week')する
  const baseDate = dayjs().day() === 0 ? dayjs().subtract(1, 'day') : dayjs()
  const currentWeek = baseDate.startOf('week').add(1, 'day') // dayjsの週の始まりが日曜なので月曜に変える
  return [
    {
      startAt: currentWeek,
      endAt: currentWeek.add(1, 'week').subtract(1, 'day').endOf('day'),
      name: '今週',
      nameLabel: t(`今週`),
      groupName: '週',
      groupNameLabel: t(`週`),
    },
    {
      startAt: currentWeek.add(-1, 'week'),
      endAt: currentWeek.add(0, 'week').subtract(1, 'day').endOf('day'),
      name: '先週',
      nameLabel: t(`先週`),
      groupName: '週',
      groupNameLabel: t(`週`),
    },
    {
      startAt: currentWeek.add(-2, 'week'),
      endAt: currentWeek.add(-1, 'week').subtract(1, 'day').endOf('day'),
      name: '先々週',
      nameLabel: t(`先々週`),
      groupName: '週',
      groupNameLabel: t(`週`),
    },
    {
      startAt: currentWeek.add(1, 'week'),
      endAt: currentWeek.add(2, 'week').subtract(1, 'day').endOf('day'),
      name: '来週',
      nameLabel: t(`来週`),
      groupName: '週',
      groupNameLabel: t(`週`),
    },
  ]
}

const getDayPeriods = (setting: OrganizationSetting) => {
  const currentDay = dayjs().startOf('day')
  return [
    {
      startAt: currentDay,
      endAt: currentDay.endOf('day'),
      name: '今日',
      nameLabel: t(`今日`),
      groupName: '日',
      groupNameLabel: t(`日`),
    },
    {
      startAt: currentDay.add(-1, 'day'),
      endAt: currentDay.add(-1, 'day').endOf('day'),
      name: '昨日',
      nameLabel: t(`昨日`),
      groupName: '日',
      groupNameLabel: t(`日`),
    },
    {
      startAt: currentDay.add(-2, 'day'),
      endAt: currentDay.add(-2, 'day').endOf('day'),
      name: '一昨日',
      nameLabel: t(`一昨日`),
      groupName: '日',
      groupNameLabel: t(`日`),
    },
    {
      startAt: currentDay.add(1, 'day'),
      endAt: currentDay.add(1, 'day').endOf('day'),
      name: '明日',
      nameLabel: t(`明日`),
      groupName: '日',
      groupNameLabel: t(`日`),
    },
    ...range(2, 7).map((x) => ({
      startAt: currentDay.add(-x + 1, 'day'),
      endAt: currentDay.endOf('day'),
      name: `過去${x}日間`,
      nameLabel: t(`過去{{x}}日間`, { x }),
      groupName: '日',
      groupNameLabel: t(`日`),
    })),
    ...range(2, 7).map((x) => ({
      startAt: currentDay,
      endAt: currentDay.add(x - 1, 'day').endOf('day'),
      name: `${x}日間`,
      nameLabel: t(`{{x}}日間`, { x }),
      groupName: '日',
      groupNameLabel: t(`日`),
    })),
    ...range(2, 7).map((x) => ({
      startAt: currentDay.add(x, 'day'),
      endAt: currentDay.add(x, 'day').endOf('day'),
      name: `${x}日後`,
      nameLabel: t(`{{x}}日後`, { x }),
      groupName: '日',
      groupNameLabel: t(`日`),
    })),
  ]
}

const getCustomPeriods = (setting: OrganizationSetting) => [
  {
    ...getCustomPeriod(),
    groupName: 'カスタム',
  },
  {
    ...getCustomMonthPeriod(),
    groupName: 'カスタム',
  },
]

const getPeriodsForDashboard = (setting: OrganizationSetting): Period[] => {
  const periods = [
    ...getPastMonthPeriods(setting),
    ...getFutureMonthPeriods(setting).slice(0, 2),
    ...getQuarterPeriods(setting, 0, '四半期', t(`四半期`)),
    ...getQuarterPeriods(setting, -1, '四半期(前年)', t(`四半期(前年)`)),
    ...getQuarterPeriods(setting, 1, '四半期(来年)', t(`四半期(来年)`)),
    ...getHalfYearPeriods(setting, 0, '半期', t(`半期`)),
    ...getHalfYearPeriods(setting, -1, '半期(前年)', t(`半期(前年)`)),
    ...getHalfYearPeriods(setting, 1, '半期(来年)', t(`半期(来年)`)),
    ...getPastYearPeriods(setting),
    ...getYearPeriods(setting),
    ...getWeekPeriods(setting),
    ...getDayPeriods(setting),
    ...getCustomPeriods(setting),
    getAllPeriod(),
  ]

  return periods
}

const getPeriodsForGoalView = (setting: OrganizationSetting): Period[] => {
  const periods = [
    ...getPastMonthPeriods(setting),
    ...getFutureMonthPeriods(setting).slice(0, 2),
    ...getQuarterPeriods(setting, 0, '四半期', t(`四半期`)),
    ...getQuarterPeriods(setting, -1, '四半期(前年)', t(`四半期(前年)`)),
    ...getHalfYearPeriods(setting, 0, '半期', t(`半期`)),
    ...getHalfYearPeriods(setting, -1, '半期(前年)', t(`半期(前年)`)),
    ...getPastYearPeriods(setting),
    ...getYearPeriods(setting),
    ...getWeekPeriods(setting),
    ...getDayPeriods(setting),
    ...getCustomPeriods(setting),
  ]

  // 基本的にダッシュボードと同じだが、以下を変更
  // ・「全期間」をださない（やるなら「目標が設定されている全ての期間」など。分かりづらいのでなしでよさそう）
  // ・履歴を表示しない（やるならダッシュボード側とkeyを変える必要）
  return periods
}

const getPeriodsForKpiTimeSeriesKpiDateX = (setting: OrganizationSetting): Period[] => {
  const periods = [
    ...getPastMonthPeriods(setting),
    ...getFutureMonthPeriods(setting).slice(0, 2),
    ...getQuarterPeriods(setting, 0, '四半期', t(`四半期`)),
    ...getQuarterPeriods(setting, -1, '四半期(前年)', t(`四半期(前年)`)),
    ...getHalfYearPeriods(setting, 0, '半期', t(`半期`)),
    ...getHalfYearPeriods(setting, -1, '半期(前年)', t(`半期(前年)`)),
    ...getPastYearPeriods(setting),
    ...getYearPeriods(setting),
    ...getWeekPeriods(setting),
    ...getCustomPeriods(setting),
  ]
  // ・全期間を非表示
  // ・履歴を非表示
  // ・日を非表示
  return periods
}

const getPeriodsForKpiTimeSeriesKpiDateY = (setting: OrganizationSetting): Period[] => {
  const periods = [
    getAllPeriod(),
    ...getPastMonthPeriods(setting),
    ...getFutureMonthPeriods(setting).slice(0, 2),
    ...getQuarterPeriods(setting, 0, '四半期', t(`四半期`)),
    ...getQuarterPeriods(setting, -1, '四半期(前年)', t(`四半期(前年)`)),
    ...getHalfYearPeriods(setting, 0, '半期', t(`半期`)),
    ...getHalfYearPeriods(setting, -1, '半期(前年)', t(`半期(前年)`)),
    ...getPastYearPeriods(setting),
    ...getYearPeriods(setting),
    ...getWeekPeriods(setting),
    ...getCustomPeriods(setting),
  ]
  // ・履歴を非表示
  // ・日を非表示
  return periods
}

function getDefaultRecommended(setting: OrganizationSetting): Period[] {
  return [
    getPastMonthPeriods(setting)[0]!,
    getDayPeriods(setting)[0]!,
    getAllPeriod(),
    getCustomPeriod(),
    getCustomMonthPeriod(),
  ]
}

export const periodUtil = {
  getAllPeriod,
  getPeriodsForDashboard,
  getPeriodsForGoalView,
  getPeriodsForKpiTimeSeriesKpiDateX,
  getPeriodsForKpiTimeSeriesKpiDateY,
  getDefaultRecommended,
}
