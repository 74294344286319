import { castString, isNull, isPresent, normalizeDate, normalizeNumber, r } from '@salescore/buff-common'
import { z } from 'zod'

import type { User } from '../user/types'
import type { UserGroup } from '../user_group/types'
import { GOAL_DIMENSION_EMPTY_VALUE } from './constant'
import { getUserOrUserGroupIds } from './getUserOrUserGroupIds'
import type { GoalConfig } from './types'
import type { GoalChange } from './writeGoalChangesChunk'

// eslint-disable-next-line complexity
export function normalizeGoalCsvRecord({
  record,
  goalConfig,
  index,
  users,
  userGroups,
}: {
  record: Record<string, unknown>
  goalConfig: GoalConfig
  index: number
  users: User[]
  userGroups: UserGroup[]
}) {
  // すべて空行だったらスキップ
  if (
    r(record)
      .values()
      .every((x) => x === '')
  ) {
    return {
      success: false as const,
      isEmptyLine: true,
      message: `空行です。`,
      record,
      index,
    }
  }

  // keysに変なのがあったらエラーにする（ここでエラーを出してあげた方が、ユーザーに優しいため）
  const keys = r(record)
    .keys()
    .filter((x) => isPresent(x))
    .map((x) => x.trim()) // 表示上なにも問題なさそうでも、不可視文字が入っていることがある
  const defaultDimensions = [`date`, `user_id`]
  const goalConfigDimensions = [
    goalConfig.goalDimension1?.label,
    goalConfig.goalDimension2?.label,
    goalConfig.goalDimension3?.label,
    goalConfig.goalDimension4?.label,
    goalConfig.goalDimension5?.label,
  ].compact()
  const validKeys = new Set([
    `ユーザーグループ名`,
    `KPI名`,
    `ユーザー名`,
    `kpi_id`,
    `value`,
    ...defaultDimensions,
    ...goalConfigDimensions,
  ])
  const invalidKeys = keys.filter((key) => !validKeys.has(key))
  if (invalidKeys.isPresent()) {
    return {
      success: false as const,
      message: `不適切な列名が含まれています。${invalidKeys.join(',')}`,
      record,
      index,
    }
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { value, kpi_id, ...maybeDimensions } = record

  // valueが空文字の場合、normalizeNumberで0になるので手前で処理する
  if (value === '') {
    return {
      success: false as const,
      message: `valueに空白は指定できません`,
      record,
      index,
    }
  }

  // valueのnormalizeとvalidation
  const normalizedValue = normalizeNumber(value)
  if (normalizedValue === null) {
    return {
      success: false as const,
      message: `valueが正しく指定されていません`,
      record,
      index,
    }
  }

  // kpi_idのnormalizeとvalidation
  const normalizedKpiId = castString(kpi_id)
  if (normalizedKpiId === null || normalizedKpiId === undefined) {
    return {
      success: false as const,
      message: `kpi_idが正しく指定されていません`,
      record,
      index,
    }
  }

  // エラー文をまとめて表示するために、前もってvalidation（不具合の原因になるようならやめる）
  const dimensions = [...defaultDimensions, ...goalConfigDimensions]
    // eslint-disable-next-line complexity
    .map((key) => {
      const dimensionValue = maybeDimensions[key]
      if (key === 'date') {
        const normalizedDate = normalizeDate(dimensionValue)
        const isValid = isValidDateFormat(dimensionValue)

        // Invalid date形式はgetTime()したときにNaNかどうかで判定する
        if (normalizedDate === null || Number.isNaN(normalizedDate.getTime()) || !isValid) {
          return {
            success: false as const,
            message: `dateは2024-01-01、または2024/01/01の形式である必要があります。`,
            value: dimensionValue,
          }
        }

        return {
          success: true as const,
          key,
          value: normalizedDate.toISOString().split('T').first()!,
        }
      }

      const castedValue = castString(dimensionValue)
      if (castedValue === undefined) {
        return
      }

      return {
        success: true as const,
        key,
        value: castedValue,
      }
    })
    .compact()

  const errorDimensions = dimensions.map((x) => (x.success ? undefined : x)).compact()
  if (errorDimensions.isPresent()) {
    return {
      success: false as const,
      message: errorDimensions.map((x) => x.message).join(''),
      record,
      index,
    }
  }

  // const [goal_config_id, dateString, user_id, dimension1, dimension2, dimension3, dimension4, dimension5] =
  const date = normalizeDate(record.date)
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const user_id = record.user_id ?? ''
  const dimension1 = record[goalConfig.goalDimension1?.label ?? ''] ?? ''
  const dimension2 = record[goalConfig.goalDimension2?.label ?? ''] ?? ''
  const dimension3 = record[goalConfig.goalDimension3?.label ?? ''] ?? ''
  const dimension4 = record[goalConfig.goalDimension4?.label ?? ''] ?? ''
  const dimension5 = record[goalConfig.goalDimension5?.label ?? ''] ?? ''
  const key = {
    goal_config_id: goalConfig.id,
    kpi_id: normalizedKpiId,
    date,
    user_id,
    dimension1,
    dimension2,
    dimension3,
    dimension4,
    dimension5,
  }
  const validKey = z
    .object({
      goal_config_id: z.string(),
      kpi_id: z.string(),
      date: z.date(),
      user_id: z.string(),
      dimension1: z.string(),
      dimension2: z.string(),
      dimension3: z.string(),
      dimension4: z.string(),
      dimension5: z.string(),
    })
    .safeParse(key)
  if (!validKey.success) {
    // ありえないはず
    return {
      success: false as const,
      message: validKey.error.message,
      record,
      index,
    }
  }

  const goalChange: GoalChange = {
    value: normalizedValue,
    key: validKey.data,
  }
  const result = validateGoalChange({ change: goalChange, goalConfig, users, userGroups })

  if (!result.success) {
    return {
      success: false as const,
      message: result.message,
      record,
      index,
    }
  }

  return {
    success: true as const,
    record,
    index,
    goalChange,
  }
}

// eslint-disable-next-line complexity
function validateGoalChange({
  change,
  goalConfig,
  users,
  userGroups,
}: {
  change: GoalChange
  goalConfig: GoalConfig
  users: User[]
  userGroups: UserGroup[]
}) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { user_id, date, dimension1, dimension2, dimension3, dimension4, dimension5, kpi_id } = change.key

  const kpiIds = goalConfig.kpiViews.map((x) => x.id)
  if (!kpiIds.includes(kpi_id)) {
    return {
      success: false as const,
      message: `目標設定に含まれないKPIです。${kpi_id}`,
      // change,
    }
  }

  const userOrUserGroupIds = getUserOrUserGroupIds({ goalConfig, users, userGroups })
  if (goalConfig.userType !== 'none' && !userOrUserGroupIds.includes(user_id ?? '')) {
    return {
      success: false as const,
      message: `目標設定に含まれないユーザーです。${goalConfig.userType}, ${user_id ?? '(ユーザー指定なし)'}`,
      // change,
    }
  }

  // TODO
  // dateStringはYYYY-MM-DDフォーマットで、これをnew Dateするとタイムゾーンなしの時刻となる
  // validateDate側がタイムゾーンを考慮しており、この辺の整合性がうまくとれてないのでいったんvalidationしない
  // const validatedDate = validateDateByGoalConfig(date, goalConfig)
  // if (validatedDate === undefined) {
  //   return {
  //     success: false as const,
  //     message: `日付設定が不正です。${dateString}`,
  // change,
  //   }
  // }

  if (!isValidDimension(dimension1, goalConfig.goalDimension1)) {
    return {
      success: false as const,
      message: `目標軸1が不正です。${dimension1}`,
      // change,
    }
  }
  if (!isValidDimension(dimension2, goalConfig.goalDimension2)) {
    return {
      success: false as const,
      message: `目標軸2が不正です。${dimension2}`,
      // change,
    }
  }
  if (!isValidDimension(dimension3, goalConfig.goalDimension3)) {
    return {
      success: false as const,
      message: `目標軸3が不正です。${dimension3}`,
      // change,
    }
  }
  if (!isValidDimension(dimension4, goalConfig.goalDimension4)) {
    return {
      success: false as const,
      message: `目標軸4が不正です。${dimension4}`,
      // change,
    }
  }
  if (!isValidDimension(dimension5, goalConfig.goalDimension5)) {
    return {
      success: false as const,
      message: `目標軸5が不正です。${dimension5}`,
      // change,
    }
  }
  return {
    success: true as const,
    // change,
  }
}

function isValidDimension(dimension: string | undefined, goalDimension: GoalConfig['goalDimension1']) {
  if (isNull(goalDimension)) {
    return dimension === GOAL_DIMENSION_EMPTY_VALUE
  }
  return goalDimension.selectOptionsV3.map((x) => x.value).includes(dimension ?? '')
}

// validateDate関数だと`YYYY-MM-DD`フォーマット以外も許容してしまう可能性があるため別途用意する
function isValidDateFormat(value: unknown): boolean {
  if (typeof value !== 'string') {
    return false
  }

  // eslint-disable-next-line regexp/no-dupe-disjunctions
  const RFC_3339_REGEX = /^(\d{4}-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01]))/

  // RFCにそろえるため/を-に変更し、桁数が足りない場合は0詰めする
  const replaced = value.replaceAll('/', '-').replace(
    /^(\d{4})-(\d{1,2})-(\d{1,2})$/,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    (_, year, month, day) => `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`,
  )

  if (!RFC_3339_REGEX.test(replaced)) {
    return false
  }

  return true
}
