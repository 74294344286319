import { ArrowLeftOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { isPresent, isSome, isTruthy } from '@salescore/buff-common'
import { FetchImportButtonParamDocument, FetchUserSourcesDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath, Posthog, POSTHOG_EVENTS, routes } from '@salescore/client-base'
import { getActionMessage, getErrorTitle, useOpenAuthorizationUrl } from '@salescore/client-common'
import { CORE_CONSTANT, type EltChangeResult, type ViewQueryRecordNode } from '@salescore/core'
import { useRedirect } from '@salescore/frontend-common'
import { Badge, Button, Col, Collapse, Divider, Drawer, Flex, message, Row, Space, Tabs, Tag, Typography } from 'antd'
import { t } from 'i18next'
import { type Dispatch, type SetStateAction, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { aggregationErrorsAtom, sheetInSaveErrorsAtom } from '../../recoil/records/atoms'
import { useErrorsState, usePageSizeState, useRecordsValue } from '../../recoil/records/hooks'
import { compiledConfigSelector } from '../../recoil/view/atoms'
import { useViewValue } from '../../recoil/view/hooks'
import { selectRowCellMutation } from '../../rsheet/recoil/mutations/cursor/moveCursor'

export interface ViewUIErrorDrawerParameters {
  errorRaw: string
}

const { Text } = Typography
const MAX_PAGE_SIZE = 5000

// 最上位の親レコードから再起的にエラーIDが含まれている子レコードがあるかを調べる
const isRecordIncludeErrorId = (record: ViewQueryRecordNode, recordId: string): boolean => {
  if (record.id === recordId) {
    return true
  }
  return record.children.some((child) => child.children.some((child) => isRecordIncludeErrorId(child, recordId)))
}

const getErrorRootRecordNodeAndRowIndex = (records: ViewQueryRecordNode[], errorRecordId: string) => {
  const rowIndex = records.findIndex((record) => isRecordIncludeErrorId(record, errorRecordId))
  return {
    rootRecordNode: records[rowIndex],
    rowIndex,
  }
}

const FeedBackSection = ({
  title,
  action,
  viewId,
  sectionKey,
  isDoneFeedBack,
  setIsDoneFeedBack,
}: {
  title: string | undefined
  action: string | undefined
  viewId: string
  sectionKey: string
  isDoneFeedBack: Record<string, boolean>
  setIsDoneFeedBack: Dispatch<SetStateAction<Record<string, boolean>>>
}) => {
  const organizationId = getOrganizationIdFromPath()

  return (
    <Row justify="center">
      <Space direction="vertical" size={2}>
        <span className="text-gray-400" style={{ fontSize: 11 }}>
          {t(`フィードバックを与えます`)}
        </span>
        <span className="font-bold" style={{ fontSize: 16, marginLeft: -45 }}>
          {t(`この情報は役に立ちましたか？`)}
        </span>
        <Space direction="horizontal">
          {isTruthy(isDoneFeedBack[sectionKey]) ? (
            <span className="font-bold text-gray-400" style={{ fontSize: 16, marginLeft: -45 }}>
              {t(`評価して頂きありがとうございます`)}
            </span>
          ) : (
            <>
              <Button
                onClick={() => {
                  setIsDoneFeedBack({ ...isDoneFeedBack, [sectionKey]: true })
                  Posthog.track(POSTHOG_EVENTS.error_feedback_good, { organizationId, viewId, title, action })
                }}
              >
                👍
              </Button>
              <Divider type="vertical" style={{ borderLeft: '1px solid' }} />
              <Button
                onClick={() => {
                  setIsDoneFeedBack({ ...isDoneFeedBack, [sectionKey]: true })
                  Posthog.track(POSTHOG_EVENTS.error_feedback_bad, { organizationId, viewId, title, action })
                }}
              >
                👎
              </Button>
            </>
          )}
        </Space>
      </Space>
    </Row>
  )
}

// eslint-disable-next-line complexity
const DrawerContent = ({
  onClose,
  writeErrors,
  compileErrors,
  compileWarns,
}: {
  onClose: () => void
  writeErrors: EltChangeResult[] | undefined
  compileErrors: string[] | undefined
  compileWarns: string[] | undefined
}) => {
  const selectRowCell = useSetRecoilState(selectRowCellMutation)
  const view = useViewValue()
  const records = useRecordsValue()
  const defaultActiveKey = isSome(writeErrors) ? '1' : isPresent(compileErrors) ? '2' : '3'
  const [isDoneFeedBack, setIsDoneFeedBack] = useState<Record<string, boolean>>({})
  const [pageSize, setPageSize] = usePageSizeState()
  const { openAuthorizationUrl } = useOpenAuthorizationUrl()
  const redirect = useRedirect()

  const fetchUserSourcesQuery = useQuery(FetchUserSourcesDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
    fetchPolicy: 'cache-first',
  })

  // TODO:Viewに紐づくprovider(原則一つのはず)取得のために利用しているが命名が適切でないため汎用的な名前に直す or 新しくクエリを作成する
  const fetchImportButtonParameterQuery = useQuery(FetchImportButtonParamDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
      viewId: view.id.replace(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX, ''),
    },
    fetchPolicy: 'cache-first',
  })
  const viewRelatedSource = fetchImportButtonParameterQuery.data?.viewConnections.first()?.source
  const userSource = fetchUserSourcesQuery.data?.userSources.find((x) => x.provider === viewRelatedSource?.provider)

  const onReconnectionClick = async () => {
    if (isSome(viewRelatedSource)) {
      await openAuthorizationUrl({
        type: 'userSource',
        provider: viewRelatedSource.provider,
        source: viewRelatedSource,
      })
    } else {
      redirect(routes.mePath())
    }
  }

  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      items={[
        {
          label: (
            <Flex justify={'space-around'}>
              <Space>
                {t('書き込みエラー')}
                <Badge count={writeErrors?.length} overflowCount={999} />
              </Space>
            </Flex>
          ),
          key: '1',
          disabled: !isSome(writeErrors),
          children:
            isSome(writeErrors) &&
            // eslint-disable-next-line complexity
            writeErrors.map((parameter, index) => {
              if (!('error' in parameter)) {
                return <></>
              }
              const errorTitle = getErrorTitle(parameter.error)
              const actionMessage = getActionMessage(parameter.error)
              const isVisibleReconnectionButton =
                actionMessage !== undefined &&
                (actionMessage.startsWith(`書き込みを行うために`) || actionMessage.endsWith(`to enable writing.`))
              return (
                <div key={parameter.change.id} className="my-4">
                  <span style={{ fontWeight: `bold` }}>{errorTitle}</span>
                  {isSome(actionMessage) && (
                    <>
                      <Row className="py-2">
                        <Tag color="green" bordered={false}>
                          {t(`解決のヒント`)}
                        </Tag>
                      </Row>
                      <Row>
                        <Text>{actionMessage}</Text>
                        {isVisibleReconnectionButton && (
                          <Col className="pb-2" offset={8}>
                            <Button type="primary" onClick={onReconnectionClick}>
                              {isSome(userSource) ? t(`再連携する`) : t(`連携する`)}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </>
                  )}
                  <Collapse
                    className="py-1"
                    size="small"
                    items={[{ key: '1', label: t(`詳しくみる`), children: <p>{parameter.error}</p> }]}
                    bordered={false}
                  ></Collapse>
                  <Row className="py-2">
                    <Col>
                      <Button
                        type="text"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => {
                          const organizationId = getOrganizationIdFromPath()
                          const { rowIndex, rootRecordNode } = getErrorRootRecordNodeAndRowIndex(
                            records,
                            parameter.change.id,
                          )
                          if (isSome(rootRecordNode)) {
                            selectRowCell({ rowIndex, rootRecordNode })
                            onClose()
                          } else {
                            void message.warning(t(`エラー行を特定できませんでした`))
                          }
                          Posthog.track(POSTHOG_EVENTS.error_move_to_record, { organizationId, viewId: view.id })
                        }}
                      >
                        <Space>
                          <span style={{ fontWeight: `bold` }}>{t(`エラー行へ移動`)} </span>
                          {isSome(parameter.change.modelName) && (
                            <span className="text-gray-500" style={{ fontSize: 12 }}>
                              ({parameter.change.modelName})
                            </span>
                          )}
                        </Space>
                      </Button>
                    </Col>
                  </Row>
                  <FeedBackSection
                    title={errorTitle}
                    action={actionMessage}
                    viewId={view.id}
                    sectionKey={`write-error-${index}`}
                    isDoneFeedBack={isDoneFeedBack}
                    setIsDoneFeedBack={setIsDoneFeedBack}
                  />
                  <Divider />
                </div>
              )
            }),
        },
        {
          label: (
            <Flex justify={'space-around'}>
              <Space>
                {t('表示エラー')}
                <Badge count={compileErrors?.length} overflowCount={999} />
              </Space>
            </Flex>
          ),
          key: '2',
          disabled: !isPresent(compileErrors),
          children:
            isPresent(compileErrors) &&
            compileErrors.map((error, index) => {
              const errorTitle = getErrorTitle(error)
              const actionMessage = getActionMessage(error)
              // 本来このような制御はエラー文ベースで行うべきではない(似たような処理が増える場合はエラーコード等の追加を検討)
              const isVisiblePageSizeUpButton = error.startsWith(`一度に取得できるレコード数上限の`)
              return (
                <div key={index} className="my-4">
                  <span style={{ fontWeight: `bold` }}>{errorTitle}</span>
                  {isSome(actionMessage) && (
                    <>
                      <Row>
                        <Tag color="green" bordered={false}>
                          {t(`解決のヒント`)}
                        </Tag>
                      </Row>
                      <Row>
                        <Text>{actionMessage}</Text>
                      </Row>
                    </>
                  )}
                  <Collapse
                    className="py-1"
                    size="small"
                    items={[{ key: '1', label: t(`詳しくみる`), children: <p>{error}</p> }]}
                    bordered={false}
                  ></Collapse>
                  {isVisiblePageSizeUpButton && (
                    <Row className="py-2" justify="end">
                      <Col>
                        <Button
                          disabled={pageSize >= MAX_PAGE_SIZE}
                          type="primary"
                          onClick={() => {
                            // 「取得レコード数を増やす」ボタン押下時に、既存の値が1000未満であれば1000件に1000以上であれば1段階上げる
                            const updatedPageSize =
                              pageSize < 1000
                                ? 1000
                                : ([2000, MAX_PAGE_SIZE].find((x) => x > pageSize) ?? MAX_PAGE_SIZE)
                            setPageSize(updatedPageSize)
                            void message.info(
                              t(`取得レコード数を{{pageSize}}から{{updatedPageSize}}に増やしました`, {
                                pageSize,
                                updatedPageSize,
                              }),
                            )
                          }}
                        >
                          {t(`取得レコード数を増やす`)}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <FeedBackSection
                    title={errorTitle}
                    action={actionMessage}
                    viewId={view.id}
                    sectionKey={`compile-error-${index}`}
                    isDoneFeedBack={isDoneFeedBack}
                    setIsDoneFeedBack={setIsDoneFeedBack}
                  />
                  <Divider />
                </div>
              )
            }),
        },
        {
          label: (
            <Flex justify={'space-around'}>
              <Space>
                {t('警告')}
                <Badge count={compileWarns?.length} overflowCount={999} />
              </Space>
            </Flex>
          ),
          key: '3',
          disabled: !isPresent(compileWarns),
          children:
            isPresent(compileWarns) &&
            compileWarns.map((warn, index) => {
              const warnTitle = getErrorTitle(warn)
              const actionMessage = getActionMessage(warn)
              return (
                <div key={index} className="my-4">
                  <span style={{ fontWeight: `bold` }}>{warnTitle}</span>
                  {isSome(actionMessage) && (
                    <>
                      <Row className="py-2">
                        <Tag color="green" bordered={false}>
                          {t(`解決のヒント`)}
                        </Tag>
                      </Row>
                      <Row>
                        <Text>{actionMessage}</Text>
                      </Row>
                    </>
                  )}
                  <Collapse
                    className="py-1"
                    size="small"
                    items={[{ key: '1', label: t(`詳しくみる`), children: <p>{warn}</p> }]}
                    bordered={false}
                  ></Collapse>
                  <Divider />
                </div>
              )
            }),
        },
      ]}
    />
  )
}

// eslint-disable-next-line complexity
export const ViewUIErrorDrawer = ({ onClose, open }: { onClose: () => void; open: boolean }) => {
  const writeErrors = useRecoilValue(sheetInSaveErrorsAtom)
  const [queryErrors] = useErrorsState()
  const { logs } = useRecoilValue(compiledConfigSelector)
  const aggregationErrors = useRecoilValue(aggregationErrorsAtom)
  const compileErrors = [...(logs?.showErrors() ?? []), ...queryErrors, ...aggregationErrors]
  const compileWarns = logs?.showWarns() ?? []
  const isDrawerOpen = open && (writeErrors !== undefined || compileErrors !== undefined)

  return (
    <Drawer
      title={
        <span>
          <Flex justify={'space-between'}>
            <Space>{t('エラー一覧')}</Space>
          </Flex>
        </span>
      }
      width={600}
      onClose={onClose}
      open={isDrawerOpen}
    >
      {isDrawerOpen && (
        <DrawerContent
          onClose={onClose}
          writeErrors={writeErrors}
          compileErrors={compileErrors}
          compileWarns={compileWarns}
        />
      )}
    </Drawer>
  )
}
