import { isSome, range } from '@salescore/buff-common'
import {
  CORE_CONSTANT,
  type CoreModel,
  type GoalConfigFragment,
  type ModelProperty,
  type NodePropertyName,
  type ViewConfigField,
  type ViewConfigSheet,
  type ViewConfigSorter,
} from '@salescore/core'
import type { Dayjs } from 'dayjs'
import { t } from 'i18next'

import { GOAL_MODEL_NAME } from './constant'
import { generateGoalViewConfig } from './generateGoalViewConfig'
import type { GoalConfig } from './types'

export const pivotedGoalModelWithoutProperties: CoreModel = {
  name: GOAL_MODEL_NAME,
  label: t(`目標`) ?? `目標`,
  creatable: false,
  properties: [],
}

// eslint-disable-next-line complexity
export function generatePivotedGoalModel({
  goalConfig,
  users,
  userGroups,
  userGroupCategoryNames,
  startAt,
  endAt,
}: {
  goalConfig: GoalConfig | GoalConfigFragment
  users: Array<{ id: string; name: string }>
  userGroups: Array<{ id: string; name: string; depth: number }>
  userGroupCategoryNames: string[]
  startAt: Dayjs
  endAt: Dayjs // periodが渡ってくるのを期待しており、periodは閉区間(endAtを含む)
}) {
  const maxGroupDepth = userGroups.map((x) => x.depth).max() ?? 0
  const userGroupRelated = generateUserGroupSql(maxGroupDepth, goalConfig.userType)
  const userGroupProperties: ModelProperty[] = userGroupRelated.fields
    // .filter((x) => x.propertyName === 'name')
    .map(
      (x, index): ModelProperty => ({
        name: x.fieldName,
        label: userGroupCategoryNames[index] ?? `グループ`,
        type: `string`,
        selectOptions: userGroups
          .filter((x) => x.depth === index + 1)
          .map((x) => ({
            value: x.id,
            label: x.name,
          })),
      }),
    )
  const userOrUserGroupProperty: ModelProperty = {
    name: `user_id`,
    label: goalConfig.userType === 'user' ? (t(`ユーザー`) ?? `ユーザー`) : (t(`対象グループ`) ?? `対象グループ`),
    type: `string`,
    selectOptions: (goalConfig.userType === 'user' ? users : userGroups).map((x) => ({
      value: x.id,
      label: x.name,
    })),
    // referenceTo: [
    //   {
    //     modelName: `salescore_users`,
    //   },
    // ],
  }

  // TODO: GoalSetting, KpiPivotConfigを受け取って返す
  const dimensionProeprties: Array<ModelProperty | undefined> = [
    {
      name: `date`,
      label: t(`日付`) ?? `日付`,
      type: `date`,
    },
    ...(goalConfig.userType === 'none'
      ? []
      : goalConfig.userType === 'user'
        ? [...userGroupProperties, userOrUserGroupProperty]
        : [
            // ...userGroupProperties.slice(0, -1),
            ...userGroupProperties, // TODO: 仕様検討中
            userOrUserGroupProperty,
          ]),
    isSome(goalConfig.goalDimension1)
      ? {
          name: `dimension1`,
          label: goalConfig.goalDimension1.label,
          type: `string`,
          selectOptions: goalConfig.goalDimension1.selectOptionsV3,
        }
      : undefined,
    isSome(goalConfig.goalDimension2)
      ? {
          name: `dimension2`,
          label: goalConfig.goalDimension2.label,
          type: `string`,
          selectOptions: goalConfig.goalDimension2.selectOptionsV3,
        }
      : undefined,
    isSome(goalConfig.goalDimension3)
      ? {
          name: `dimension3`,
          label: goalConfig.goalDimension3.label,
          type: `string`,
          selectOptions: goalConfig.goalDimension3.selectOptionsV3,
        }
      : undefined,
    isSome(goalConfig.goalDimension4)
      ? {
          name: `dimension4`,
          label: goalConfig.goalDimension4.label,
          type: `string`,
          selectOptions: goalConfig.goalDimension4.selectOptionsV3,
        }
      : undefined,
    isSome(goalConfig.goalDimension5)
      ? {
          name: `dimension5`,
          label: goalConfig.goalDimension5.label,
          type: `string`,
          selectOptions: goalConfig.goalDimension5.selectOptionsV3,
        }
      : undefined,
  ]
  const kpisProperties = goalConfig.kpiViews.map((kpi, index) => ({
    name: `kpi-${kpi.id}`,
    label: kpi.name,
    type: `integer` as const,
    write: {
      sourcePropertyName: kpi.id,
    },
  }))
  const pivotedGoalModel: CoreModel = {
    ...pivotedGoalModelWithoutProperties,
    properties: [...dimensionProeprties.compact(), ...kpisProperties],
  }

  const kpisFieldSqls = kpisProperties.map(
    (x) => `SUM(CASE WHEN kpi_id = '${x.write.sourcePropertyName}' then value else null end) as "${x.name}"`,
  )

  const pivotedGoalViewConfig: ViewConfigSheet = {
    type: 'sheet',
    tree: {
      type: `model`,
      name: pivotedGoalModel.name,
      modelName: pivotedGoalModel.name,
      override: {
        tableSql: `SELECT array_to_json(ARRAY[goal_config_id, date::text, user_id, dimension1, dimension2, dimension3, dimension4, dimension5])::text as id,
        goal_config_id,
        date,
        user_id,
        ${userGroupRelated.fields.map((x) => `MIN(${x.nodeName}.${x.propertyName}) AS ${x.fieldName}`).join(`,\n`)}${
          userGroupRelated.fields.isPresent() ? ',' : ''
        }
        dimension1,
        dimension2,
        dimension3,
        dimension4,
        dimension5${kpisFieldSqls.isPresent() ? ',' : ''}
        ${kpisFieldSqls.join(`,\n`)}
    FROM ${CORE_CONSTANT.SALESCORE_GOAL_MODEL.name} ${userGroupRelated.joinSql}
    WHERE goal_config_id = '${goalConfig.id}'
    AND date >= '${startAt.format('YYYY-MM-DD')}'
    AND date <= '${endAt.format('YYYY-MM-DD')}'
    AND kpi_id in ('', ${goalConfig.kpiViews.map((x) => `'${x.id}'`).join(',')})
    GROUP BY goal_config_id, date, user_id, dimension1, dimension2, dimension3, dimension4, dimension5`,
        // ORDER BY ${userGroupRelated.fields.filter((x) => x.propertyName === 'name').map(x => x.fieldName).join(',')}${userGroupRelated.fields.isPresent() ? ', ' : ''}user_id, date, dimension1, dimension2, dimension3, dimension4, dimension5`,
      },
    },
    fields: pivotedGoalModel.properties.map(
      (property): ViewConfigField => ({
        type: `property`,
        property: {
          nodeName: pivotedGoalModel.name,
          modelName: pivotedGoalModel.name,
          propertyName: property.name,
        },
        measures:
          property.type === 'integer'
            ? [
                {
                  type: 'preset',
                  function: 'sum',
                  name: property.name,
                  label: t(`合計`) ?? `合計`,
                  fieldName: [pivotedGoalModel.name, property.name].join('_'),
                },
              ]
            : undefined,
        override: {
          uiColumn: {
            // 目標軸名・KPI名のカラムはデフォルトで日本語8文字分程度の幅に設定する
            width: getFieldWidth(property),
          },
        },
      }),
    ),
    sorters: dimensionProeprties.compact().map(
      (property): ViewConfigSorter => ({
        type: 'property',
        property: {
          nodeName: pivotedGoalModel.name,
          modelName: pivotedGoalModel.name,
          propertyName: property.name,
        },
        order: 'asc',
      }),
    ),
    meta: {
      isGoalView: true,
      // TODO: カラム数が多い場合に固定されるとスクロールの挙動がおかしくなるためコメントアウト
      // fixedLeftColumnIndex: dimensionProeprties.compact().length + 3, // systemColumnsを削除したい
    },
  }

  // goal_configにシート情報が保存されている場合、その値を初期値として上書きする
  const goalViewConfig = generateGoalViewConfig({
    pivotedGoalViewConfig,
    savedGoalConfig: goalConfig,
  })

  return {
    goalViewConfig: {
      ...goalViewConfig,
    },
    pivotedGoalModel,
  }
}

function getFieldWidth(property: ModelProperty) {
  const isGoalDimensionField = property.type !== 'integer'
  const labelLength = property.label.length
  // 目標軸の列ヘッダにはデフォルトで書き込み禁止と並び替えのアイコンが表示されるため、
  // 幅計算時はアイコン幅分広げている。UI依存のロジックではあるが、仕様上仕方ない
  const iconWidth = 44
  const additionalWidth = isGoalDimensionField ? iconWidth : 0

  // 目標軸名・KPI名のカラムはデフォルトで1行あたり日本語8文字分程度の幅に設定する
  // 以降、日本語をベースとして、32文字までは2行で収まる幅を設定する
  if (labelLength <= 16) {
    return 150 + additionalWidth
  }
  if (labelLength <= 24) {
    return 210 + additionalWidth
  }
  return 260 + additionalWidth
}

function generateUserGroupSql(maxDepth: number, userType: 'user' | 'group' | 'none') {
  if (maxDepth === 0 || userType === 'none') {
    return {
      joinSql: ``,
      fields: [],
    }
  }
  const joinSql =
    userType === 'user'
      ? `LEFT JOIN ${CORE_CONSTANT.SALESCORE_USER_WITH_GROUP_MODEL.name} ON ${CORE_CONSTANT.SALESCORE_GOAL_MODEL.name}.user_id = ${CORE_CONSTANT.SALESCORE_USER_WITH_GROUP_MODEL.name}.id`
      : `LEFT JOIN ${CORE_CONSTANT.SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL.name} ON ${CORE_CONSTANT.SALESCORE_GOAL_MODEL.name}.user_id = ${CORE_CONSTANT.SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL.name}.id`
  return {
    joinSql,
    fields: range(1, maxDepth).flatMap(
      (depth): Array<NodePropertyName & { fieldName: string }> => [
        {
          nodeName:
            userType === 'user'
              ? CORE_CONSTANT.SALESCORE_USER_WITH_GROUP_MODEL.name
              : CORE_CONSTANT.SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL.name,
          modelName:
            userType === 'user'
              ? CORE_CONSTANT.SALESCORE_USER_WITH_GROUP_MODEL.name
              : CORE_CONSTANT.SALESCORE_USER_GROUPS_WITH_PARENTS_MODEL.name,
          propertyName: `user_group_d${depth}_id`,
          fieldName: `salescore_user_groups_d${depth}_id`,
        },
        // {
        //   nodeName: `salescore_user_groups_d${depth}`,
        //   modelName: `salescore_user_groups`,
        //   propertyName: `name`,
        //   fieldName: `salescore_user_groups_d${depth}_name`,
        // },
      ],
    ),
  }
}
