import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { ViewUICol } from '@salescore/core'
import { Col } from 'antd'
import { useContext } from 'react'

import { ViewUIComponentC, ViewUIContext } from './ViewUIComponent'

// eslint-disable-next-line complexity
export function ViewUIColC({ component }: { component: ViewUICol }) {
  const { isEditMode } = useContext(ViewUIContext)
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: component.id })

  if (isEditMode) {
    return (
      <Col
        key={component.id}
        span={component.span}
        className={isEditMode ? `border border-solid border-gray-100 p-1` : ``}
        ref={setNodeRef}
        style={{
          transform: CSS.Transform.toString(transform),
          transition: isDragging ? 'boxShadow 100ms ease' : 'all 200ms ease',
          // transition,
          cursor: isDragging ? 'grabbing' : 'grab',
          zIndex: isDragging ? 1000 : 0,
          backgroundColor: 'white',
          boxShadow: isDragging ? '-1px 0 15px 5px rgba(34, 33, 81, 0.1)' : '',
        }}
        {...attributes}
        {...listeners}
      >
        {component.children.map((child) => (
          <ViewUIComponentC component={child} />
        ))}
      </Col>
    )
  }

  return (
    <>
      <Col key={component.id} span={component.span}>
        {component.children.map((child) => (
          <ViewUIComponentC component={child} />
        ))}
      </Col>
    </>
  )
}
