import type { PropertyFieldsFragment } from '@salescore/client-api'
import { type CoreModel, isRequiredProperty, type ModelProperty, type ViewQueryNode } from '@salescore/core'
import { t } from 'i18next'

export interface PropertyWithStream {
  node: ViewQueryNode
  stream: CoreModel
  property: PropertyFieldsFragment
}

export function removeIdLabel(x: string) {
  return x.replace(/\s*ID$/, '')
}

// eslint-disable-next-line complexity
export function getFieldLabel(property: ModelProperty) {
  const isRequired = isRequiredProperty(property)
  if (property.referenceTo?.isPresent() ?? false) {
    const label = removeIdLabel(property.label)
    return isRequired ? `${label}(${t(`必須`)})` : label
    // return isRequired ? `${label}(参照,必須)` : `${label}(参照)`
  }
  return [property.label, isRequired ? `(${t(`必須`)})` : ''].join('')
}
