import type { ViewUISelect } from '@salescore/core'
import { message } from 'antd'
import { t } from 'i18next'
import { useContext, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { useViewQueryResult } from '../../recoil/records/hooks'
import { validationErrorVisibilityAtom } from '../../recoil/view/atoms'
import { getOptions } from '../../rsheet/components/body/cell/input/SelectOptionInputCell'
import { useViewUIRecordNodeContext } from './contexts'
import { TagSelect } from './TagSelect'
import { ViewUIContext } from './ViewUIComponent'
import { viewUiValidation } from './viewUiValidation'

export function ViewUISelectC({ component }: { component: ViewUISelect }) {
  const { isEditMode } = useContext(ViewUIContext)
  const { getValue, onChange, recordNode } = useViewUIRecordNodeContext()
  const viewQueryResult = useViewQueryResult()
  const value = getValue(component.fieldName)
  const validationErrorVisibility = useRecoilValue(validationErrorVisibilityAtom)
  const errors = validationErrorVisibility ? viewUiValidation(value, component) : []
  const options = useMemo(
    () =>
      getOptions({
        // TODO: 現状のフォームUI実装だと、このコンポーネントがどのノードの情報を参照しているかがわからない。
        //       そのため、いったんルートのノード情報を、queryResultから取得している。
        //       コンパイル時に付与するような設計にするのが良いだろうか？
        //       そもそも、ノードの情報をここで参照するような
        nodeName: viewQueryResult?.viewQuery.tree.name ?? '',
        options: component.options ?? [],
        recordNode,
        parent: undefined,
      }),
    [recordNode, viewQueryResult, component],
  )

  return (
    <TagSelect
      value={
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        value as string
      }
      options={options}
      mode={component.multiple === true ? 'multiple' : undefined}
      allowClear
      disabled={isEditMode || component.readonly}
      status={errors.isPresent() ? 'error' : undefined} // 現状multi selectでしか実装されていない
      // style={
      //   errors.isBlank()
      //     ? {}
      //     : {
      //       border: `1px solid red`, // TODO: どうするのが適切か不明。4.19からstatusが追加されているので、これを使いたい。
      //     }
      // }
      onChange={(value) => {
        onChange({ fieldName: component.fieldName, value })
      }}
      onClick={() => {
        if (component.readonly === true) {
          void message.warning(t(`この項目は書き込みが禁止されています。`))
        }
      }}
    />
  )
}
