import { useApolloClient } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import { notifyBugsnag } from '@salescore/client-base'
import type { ViewQueryField, ViewQueryNode, ViewQueryNodeReadJoinOn, ViewQueryRecordNode } from '@salescore/core'
import { logger, mutation } from '@salescore/frontend-common'
import { message } from 'antd'
import { t } from 'i18next'
import { useSetRecoilState } from 'recoil'

import { getInnerRecordNode, getRecordNodeFromRootNode } from '../../../state/nodeUtil'
import type { ChangeHistory } from '../../../state/useViewRecordsState/useChangesState'
import { useQueryValue } from '../../view/hooks'
import { changesAtom, recordsAtom } from '../atoms'
import { fetchChildRecordNode } from './fetchChildNode'
import { getParentReferenceField } from './getParentReferenceField'
import { updateHeight, updateInnerRow } from './upsertViewRecords'

interface Argument {
  rowIndex: number
  innerRowIndex: number
  node: ViewQueryNode
  id: string
}

//
// 参照関係にある子レコードを追加し、親ノードのfieldを更新する
// parentのブロックのレコードを、空セルから入力した時に使う
// TODO: いい感じにリファクタリングしたい
//
const changeRelationsMutation = mutation<
  Argument & {
    fetchedChildNode: ViewQueryRecordNode
    parentReferenceField: ViewQueryField | undefined
    parentNode: ViewQueryNode
    joinOn: ViewQueryNodeReadJoinOn
  }
>({
  key: `rsheet/changeRelationsMutation`,
  // eslint-disable-next-line complexity
  set({ get, set }, { rowIndex, innerRowIndex, node, id, fetchedChildNode, parentReferenceField, parentNode, joinOn }) {
    if (isNull(parentNode.write)) {
      logger.debug(`parentNode.write not found`)
      return
    }

    const records = get(recordsAtom)

    const newRecords = structuredClone(records)

    // ルートのレコード(行)を取得
    const rootRecordNode = newRecords[rowIndex]!
    // 親レコードを取得
    const parentRecordNodes = getRecordNodeFromRootNode(rootRecordNode, node.path.slice(0, -1))
    const parentRecordNode = Array.isArray(parentRecordNodes)
      ? getInnerRecordNode(parentRecordNodes, innerRowIndex)
      : parentRecordNodes
    if (parentRecordNode === undefined) {
      // childrenのnodeについて、emptyなセルの参照項目を直接編集すると起こりうるが、useEditModeする際にこのケースはonAddChildRecordしているはずなので問題ない
      notifyBugsnag({ error: new Error(`parentRecordNode not found in changeRelation. ${node.path.join(',')}`) })
      return
    }

    //
    // 子要素にfetchした値を入れる
    //
    let targetTableNode = parentRecordNode.children.find((x) => x.nodeName === node.name)
    // tableNodeがなければ作成する必要がある
    if (targetTableNode === undefined) {
      targetTableNode = {
        nodeName: node.name,
        children: [],
      }
      parentRecordNode.children.push(targetTableNode)
    }
    if (!targetTableNode.children.some((x) => x.id === id)) {
      targetTableNode.children.splice(0, targetTableNode.children.length, fetchedChildNode)
    }

    //
    // 親要素に参照フィールドがあれば更新
    //
    const oldValue =
      parentReferenceField === undefined ? undefined : parentRecordNode.attributes[parentReferenceField.name]
    if (parentReferenceField !== undefined) {
      // 値が同じならば更新は行わない（更新を行わないことで、再レンダリングを行わないようにする）
      if (oldValue === id) {
        return
      }
      parentRecordNode.attributes[parentReferenceField.name] = id
      parentRecordNode.attributes[`${parentReferenceField.name}_label`] =
        fetchedChildNode.attributes[`${parentReferenceField.name}_label`]
    }

    //
    // 差分の更新
    //

    // heightを参照渡しで更新
    updateHeight(rootRecordNode)
    // innerStartIndexを参照渡しで更新
    updateInnerRow(rootRecordNode)

    set(recordsAtom, newRecords)

    // changesは親要素の参照項目のみ
    const newChange: ChangeHistory | undefined =
      joinOn.type === 'sql'
        ? undefined
        : {
            datetime: new Date().toISOString(),
            rowIndices: [0], // TODO: 結局使ってないので廃止
            snapshot: JSON.stringify(records),
            snapshotAfterChange: JSON.stringify(newRecords),
            changes: [
              {
                rootNodeId: rootRecordNode.id,
                id: parentRecordNode.id,
                streamName: parentNode.write.streamName,
                fieldChanges: [], // callbackは現状の仕様だと特にないはず
                // TODO: ここのchangesのロジックだけpropertySourceNameではなくpropertyNameになっている。後者に統一したい。
                before: {
                  [joinOn.meta.parentNodePropertyName]: oldValue,
                },
                after: {
                  [joinOn.meta.parentNodePropertyName]: id,
                },
              },
            ],
          }
    set(changesAtom, (oldChanges) => [...oldChanges, newChange].compact())
  },
})

export const useChangeRelationsMutation = () => {
  const changeRelations = useSetRecoilState(changeRelationsMutation)
  const query = useQueryValue()
  const client = useApolloClient()

  return async (argument: Argument) => {
    const { node, id } = argument
    const result = getParentReferenceField(query, node)
    const fetchedChildNode = await fetchChildRecordNode(node, id, query, client, result.parentReferenceField)
    if (fetchedChildNode === undefined) {
      void message.warning(t(`レコードが見つかりませんでした`))
      return
    }

    changeRelations({
      ...argument,
      ...result,
      fetchedChildNode,
    })
  }
}
