import { r, range } from '@salescore/buff-common'
import { notifyBugsnag } from '@salescore/client-base'
import { createDimensionFieldName, type NodePath, type ViewQueryRecordNode } from '@salescore/core'
import { mutation, parseJsonIfValid } from '@salescore/frontend-common'
import type { SetRecoilState } from 'recoil'
import { z } from 'zod'

import { drillDownModalAtom, type DrillDownParameterForKpiPivot } from '../../../recoil/navigation/atoms'
import { pickedIdsAtom, recordsAtom } from '../../../recoil/records/atoms'
import { listQueryAtom } from '../../../recoil/view/atoms'
import type { RSheetRecordNode } from '../../types'
import { cursorSelector } from '../selectors/cursorSelector'
import type { RowChange } from './upsertSheetRowMutation'

export const changeSystemFieldMutation = mutation<RowChange>({
  key: `rsheet/changeSystemFieldMutation`,
  // eslint-disable-next-line complexity
  set({ get, set }, { rowIndex, column, innerRowIndex, value, label }: RowChange) {
    const query = get(listQueryAtom)
    const records = get(recordsAtom)
    const record = records[rowIndex]
    const { cursor, expandArea } = get(cursorSelector)
    switch (column.type) {
      case 'open': {
        // 何かあった場合はモーダルを開く
        const streamName = query.tree.meta.dependedStreamNames.first()
        if (streamName === undefined) {
          notifyBugsnag({ error: new Error(`streamが見つかりません`) })
          return
        }

        set(drillDownModalAtom, (x) => ({
          ...x,
          content: {
            type: 'form' as const,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            recordId: value as string,
          },
          visible: true,
        }))
        return
      }
      case 'kpi': {
        const dimensionValues = getDimensionValues(record, column.node.path, innerRowIndex, column.field?.name)
        set(drillDownModalAtom, (x) => ({
          ...x,
          content: {
            type: 'kpi' as const,
            dimensionValues,
          },
          visible: true,
        }))
        return
      }
      case 'rowCheckbox': {
        const records = get(recordsAtom)

        if (expandArea === undefined) {
          pickIdsToggleRecord(set, records[rowIndex], value)
          return
        }

        const areaRecords = range(expandArea.area.upRowIndex, expandArea.area.downRowIndex)
          .map((index) => records[index])
          .compact()

        const pickedRecord = areaRecords.find((areaRecord) => areaRecord.id === record?.id)
        if (pickedRecord === undefined) {
          pickIdsToggleRecord(set, records[rowIndex], value)
          return
        }

        pickIdsOfExpandAreaRecords(set, areaRecords)
        break
      }
      default: {
        break
      }
    }
  },
})

function pickIdsToggleRecord(set: SetRecoilState, record?: ViewQueryRecordNode, value?: unknown) {
  if (record?.id === undefined) {
    return
  }

  const togglePicked = (id: string, forcePick?: boolean) => {
    set(pickedIdsAtom, (oldPickedIds) => {
      const filteredIds = oldPickedIds.filter((oldId) => oldId !== id)
      const picked = [...filteredIds, id]
      const unpicked = filteredIds
      if (forcePick !== undefined) {
        return forcePick ? picked : unpicked
      }

      if (oldPickedIds.includes(id)) {
        return unpicked
      }
      return picked
    })
  }

  if (typeof value === 'boolean') {
    togglePicked(record.id, value)
  } else if (typeof value === 'string') {
    const flag = value === 'true'
    togglePicked(record.id, flag)
  } else {
    togglePicked(record.id)
  }
}

function pickIdsOfExpandAreaRecords(set: SetRecoilState, records: ViewQueryRecordNode[]) {
  const firstRecordId = records.first()?.id
  if (firstRecordId === undefined) {
    return
  }

  set(pickedIdsAtom, (xs) => {
    // 先頭が含まれていれば、全て除去する
    if (xs.includes(firstRecordId)) {
      return xs.difference(records.map((x) => x.id).compact())
    }

    return [...xs, ...records.map((x) => x.id).compact()].compact().unique()
  })
}

function getDimensionValues(
  record: RSheetRecordNode | undefined,
  nodePath: NodePath,
  innerRowIndex: number,
  fieldName: string | undefined,
): DrillDownParameterForKpiPivot['dimensionValues'] {
  if (record === undefined || fieldName === undefined) {
    return {
      rows: [],
      columns: [],
    }
  }

  const rows = getDimensionRecordNodesRec(record, nodePath.slice(1), innerRowIndex, 1)
  const columnDimensionValuesRaw = parseJsonIfValid(fieldName) // TODO: ここのzip,unzipのロジックの統一
  const parsedColumnDimensionValues = z.unknown().array().safeParse(columnDimensionValuesRaw)
  const columnsValues = (parsedColumnDimensionValues.success ? parsedColumnDimensionValues.data : []).slice(0, -1) // TODO: 最後にrole(actual)が入っているという暗黙知が入ってしまっている
  return {
    rows,
    columns: columnsValues.map((value) => ({ value, label: undefined })), // TODO: columnのラベルを取り出す方法がない？
  }
}

function getDimensionRecordNodesRec(
  record: RSheetRecordNode | undefined,
  nodePath: NodePath,
  innerRowIndex: number,
  depth: number,
): DrillDownParameterForKpiPivot['dimensionValues']['rows'] {
  if (record === undefined) {
    return []
  }
  if (nodePath.length === 0) {
    // XXX: ソートを行なった場合、通常のレコード構造ではなく、leaf側に全てのdimensionがあるので、これを処理する
    return r(record.attributes)
      .keys()
      .filter((x) => /^dimension\d+$/.test(x))
      .map((fieldName) => ({
        value: record.attributes[fieldName],
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        label: record.attributes[`${fieldName}_label`] as string | undefined,
      }))
  }

  const childTable = record.children.find((x) => x.nodeName === nodePath[0])
  const childRecordNode = childTable?.children.find(
    (x) => x.meta.innerRowIndexStart <= innerRowIndex && innerRowIndex < x.meta.innerRowIndexEnd,
  )

  // TODO: 命名規則
  // TODO: valuesNodeのrecordを防ぎたいが、綺麗に防げないのでcompact()することで許容している
  const value = record.attributes[createDimensionFieldName(depth)]
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const label = record.attributes[`${createDimensionFieldName(depth)}_label`] as string | undefined
  return [
    {
      value,
      label,
    },
    ...getDimensionRecordNodesRec(childRecordNode, nodePath.slice(1), innerRowIndex, depth + 1),
  ]
}
