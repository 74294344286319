import { isNull, isSome } from '@salescore/buff-common'
import type { OrganizationFieldsFragment, ViewFieldsFragment } from '@salescore/client-api'
import {
  type CompileResources,
  compileViewConfig,
  CORE_CONSTANT,
  type CoreModel,
  defaultCursor,
  flatNodes,
  generateSql,
  isSameNodeProperty,
  type ViewConfigField,
  type ViewConfigKpiTimeSeries,
  type ViewConfigSheet,
  type ViewQueryList,
} from '@salescore/core'
import { t } from 'i18next'
import { selector, useRecoilValue } from 'recoil'

import { meAtom, riFormAtom, viewAtom, viewsContextAtom } from '../atoms'

export const waterfallDrillDownSelector = selector({
  key: 'view/waterfallDrillDownSelector',
  // eslint-disable-next-line complexity
  get: ({ get }) => {
    const { organization } = get(meAtom)
    const view = get(viewAtom)
    const { models, views } = get(viewsContextAtom)
    const config = get(riFormAtom)

    if (isNull(config)) {
      return
    }

    const { kpiFragment } = config
    const sheet = kpiFragment?.sheet
    if (isNull(kpiFragment) || isNull(sheet) || sheet.type !== 'sheet') {
      return
    }

    const rootSnapshotModel = models.find(
      (model) =>
        model.name === sheet.tree?.modelName &&
        model.name.startsWith(CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX) &&
        !model.name.endsWith(CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX),
    )
    // nameプロパティがあれば、デフォルトで追加する
    const rootSnapshotNameProperty = rootSnapshotModel?.properties.find((property) => property.name === 'name')

    const relatedModels = getRelatedModels(sheet, models)
    const allFields = getAllFields(relatedModels)
    const resources = getCompileResources(view, models, views, organization)
    const allFieldsAddedConfig = {
      ...config,
      kpiFragment: {
        ...kpiFragment,
        sheet: {
          ...sheet,
          // TODO:
          // 本来は必要なフィールドのみに絞って取得するべきだが、実装がやや大変なため一旦納期優先で雑な実装になってしまっている
          // 非効率なSQLになってしまうので直したい
          fields: allFields,
        },
      },
    }
    const snapshotCompareSubQuerySql = generateSnapshotCompareSubQuerySql(allFieldsAddedConfig, resources)
    if (isNull(snapshotCompareSubQuerySql)) {
      return
    }

    const previousDrillDownSheet = isSome(config.waterfall?.drillDownSheet)
      ? {
          ...config.waterfall.drillDownSheet,
          fields: config.waterfall.drillDownSheet.fields?.map((field) => {
            if (isSameNodeProperty(field.property, CORE_CONSTANT.WATERFALL_DIMENSION_FIELD().property)) {
              return {
                ...field,
                override: {
                  ...field.override,
                  uiColumn: {
                    // 項目列は削除不可
                    columnDeletable: false,
                  },
                },
              }
            }
            return field
          }),
        }
      : undefined
    const defaultDrillDownSheet: ViewConfigSheet = {
      type: 'sheet',
      fields: [
        ...CORE_CONSTANT.WATERFALL_DRILL_DOWN_DEFAULT_FIELDS(),
        ...(isSome(rootSnapshotNameProperty) && isSome(rootSnapshotModel)
          ? [CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX, CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX].map(
              (suffix): ViewConfigField => ({
                type: 'property',
                property: {
                  modelName: CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME,
                  nodeName: CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME,
                  propertyName: `${rootSnapshotModel.name}_${rootSnapshotNameProperty.name}_${suffix}`,
                },
              }),
            )
          : []),
      ],
      tree: {
        type: 'model',
        modelName: CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME,
        name: CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME,
        override: {
          tableSql: snapshotCompareSubQuerySql,
        },
      },
    }
    const drillDownSheet: ViewConfigSheet = isNull(previousDrillDownSheet?.tree)
      ? defaultDrillDownSheet
      : {
          ...previousDrillDownSheet,
          tree: {
            ...previousDrillDownSheet.tree,
            name: CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME,
            modelName: CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME,
            override: {
              tableSql: snapshotCompareSubQuerySql,
            },
          },
        }

    return {
      model: generateSnapshotCompareModel(config, relatedModels),
      sheet: drillDownSheet,
    }
  },
})

export function useWaterfallDrillDownSelector() {
  return useRecoilValue(waterfallDrillDownSelector)
}

function getRelatedModels(sheet: ViewConfigSheet, models: CoreModel[]): CoreModel[] {
  const nodes = flatNodes(sheet.tree)

  return nodes
    .uniqueBy((node) => node.modelName)
    .map((node) => models.find((model) => model.name === node.modelName))
    .compact()
}

function getCompileResources(
  view: ViewFieldsFragment,
  models: CoreModel[],
  views: ViewFieldsFragment[],
  organization: OrganizationFieldsFragment,
): CompileResources {
  return {
    view,
    models,
    views,
    organizationSetting: {
      ...organization.setting,
      dimensionGroups: organization.dimensionGroups,
    },
  }
}

function getAllFields(models: CoreModel[]): ViewConfigField[] {
  return models.flatMap((model) =>
    model.properties.map((property) => ({
      type: 'property',
      property: {
        modelName: model.name,
        nodeName: model.name,
        propertyName: property.name,
      },
    })),
  )
}

/**
 * 2地点間のスナップショットをJoinして比較するサブクエリを生成する
 */
function generateSnapshotCompareSubQuerySql(
  config: ViewConfigKpiTimeSeries,
  resources: CompileResources,
): string | undefined {
  const result = compileViewConfig(config, resources)
  if (isNull(result)) {
    return
  }

  const query: ViewQueryList = {
    type: 'list',
    tree: result.query.tree,
    // fieldsは後工程で*に置き換えられるので不要
    fields: [],
    // 絞り込みはtree内のサブクエリで処理するので不要
    filterTree: {
      logicalOperator: 'and',
      leafs: [],
      children: [],
    },
    sorters: [],
    extra: {
      asSubQuery: true,
    },
  }

  const sql = generateSql(query, defaultCursor, {}, { shouldGetAllFields: true })
  return sql
}

function generateSnapshotCompareModel(config: ViewConfigKpiTimeSeries, relatedModels: CoreModel[]): CoreModel {
  const dimensions = config.waterfall?.dimensions
  return {
    name: CORE_CONSTANT.SNAPSHOT_COMPARE_MODEL_NAME,
    label: t('スナップショット比較'),
    creatable: false,
    updatable: false,
    deletable: false,
    properties: [
      {
        name: 'id',
        label: 'ID',
        type: 'string',
      },
      {
        name: CORE_CONSTANT.WATERFALL_DIMENSION_FIELD_NAME,
        label: t('項目'),
        type: 'string',
        selectOptions: dimensions?.map((x) => ({
          value: x.name,
          label: x.label,
        })),
      },
      ...relatedModels.flatMap((model) => {
        // スナップショットモデルとシーケンスモデル以外のプロパティは含まない
        if (!model.name.startsWith(CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX)) {
          return []
        }
        return model.properties.map((property) => ({
          type: property.type,
          name: `${model.name}_${property.name}_${CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX}`,
          label: `${model.label}: ${property.label} (${t('期間1')})`,
          // スナップショット比較モデルにはスナップショットモデルを参照させない
          referenceTo: property.referenceTo?.filter(
            (x) => !x.modelName.startsWith(CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX),
          ),
        }))
      }),
      ...relatedModels.flatMap((model) => {
        // スナップショットモデルとシーケンスモデル以外のプロパティは含まない
        if (!model.name.startsWith(CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX)) {
          return []
        }
        return model.properties.map((property) => ({
          type: property.type,
          name: `${model.name}_${property.name}_${CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX}`,
          label: `${model.label}: ${property.label} (${t('期間2')})`,
          // スナップショット比較モデルにはスナップショットモデルを参照させない
          referenceTo: property.referenceTo?.filter(
            (x) => !x.modelName.startsWith(CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX),
          ),
        }))
      }),
    ],
  }
}
