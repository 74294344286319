import { isTruthy } from '@salescore/buff-common'
import { CORE_CONSTANT, presetFunctions, type PresetMeasureFunctions } from '@salescore/core'
import { Form, Select } from 'antd'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'

import { riDisplayFormatAtom } from '../../../../recoil/navigation/atoms'
import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'
import { KpiFormMeasureCustomSqlEditor } from './KpiFormMeasureCustomSqlEditor'
import { PropertySelect } from './PropertySelect'

interface KpiFormMeasureFormItemProperties {
  required?: boolean
  disabled?: boolean
  snapshotMode?: boolean
}

// countUniqueはCOUNT(DISTINCT id)をする関数だが、
// KPIの集計の文脈だと、countと結果が同じになるので、これを表示しない
export const kpiIgnoreMeasureFunctions = new Set<PresetMeasureFunctions>(['countUnique'])
export const waterfallIgnoreMeasureFunctions = new Set<PresetMeasureFunctions>(['count', 'avg', 'min', 'max'])

// eslint-disable-next-line complexity
export const KpiFormMeasureFormItem = (properties_: KpiFormMeasureFormItemProperties) => {
  const { setForm, form, sheet, currentSheet, propertiesForCurrentMeasure, setFormModified } = useKpiFormSelectors()
  const riFormat = useRecoilValue(riDisplayFormatAtom)

  const properties =
    properties_.snapshotMode === true
      ? propertiesForCurrentMeasure.filter(
          (p) =>
            p.model.name.startsWith(CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX) &&
            !p.model.name.endsWith(CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX),
        )
      : propertiesForCurrentMeasure
  const options =
    isTruthy(properties_.snapshotMode) && riFormat === 'waterfall'
      ? presetFunctions()
          .filter((x) => !waterfallIgnoreMeasureFunctions.has(x.value))
          .sort((a, b) => {
            // 滝グラフの時はユニークなレコード数を最初に表示
            if (a.value === 'countUnique') return -1
            if (b.value === 'countUnique') return 1
            return 0
          })
      : presetFunctions().filter((x) => !kpiIgnoreMeasureFunctions.has(x.value))

  return (
    <div>
      <Form.Item label={t(`集計方法`)} rules={[{ required: properties_.required }]} required={properties_.required}>
        <Select
          value={
            properties_.disabled === true
              ? undefined
              : form.measure?.type === 'kpiPreset'
                ? form.measure.function
                : `custom`
          }
          onChange={
            // eslint-disable-next-line complexity
            (value) => {
              const presetFunction = presetFunctions().find((x) => x.value === value)
              const { tree } = currentSheet
              // XXX: 「カウント系を選んだらIDを指定する」というドメインロジックがUI側に実装されていて微妙
              if (
                (presetFunction?.value === `count` || presetFunction?.value === 'countUnique') &&
                tree !== undefined
              ) {
                setForm((oldValue) => ({
                  ...oldValue,
                  measure: {
                    ...oldValue.measure,
                    type: `kpiPreset`,
                    function: presetFunction.value,
                    property: {
                      nodeName: tree.name,
                      modelName: tree.modelName,
                      propertyName: `id`, // XXX: ロジック上ではどうせ使われない想定。であればpropertyNameはoptionalにすべきだったか？
                    },
                  },
                }))
              } else if (presetFunction === undefined) {
                setForm((oldValue) => ({
                  ...oldValue,
                  measure: {
                    type: `kpiCustom`,
                    sql: undefined,
                    dependentNodeNames: [],
                  },
                }))
              } else {
                setForm((oldValue) => ({
                  ...oldValue,
                  measure: {
                    ...oldValue.measure,
                    type: `kpiPreset`,
                    function: presetFunction.value,
                  },
                }))
              }
              setFormModified(true)
            }
          }
          options={[
            ...options,
            {
              value: 'custom',
              label: t(`カスタム`),
            },
          ]}
          disabled={properties_.disabled}
        />
      </Form.Item>
      {form.measure?.type === `kpiPreset` &&
        !(
          (form.measure.function === 'count' || form.measure.function === 'countUnique') &&
          form.measure.property !== undefined
        ) && (
          <Form.Item label={t(`集計項目`)} rules={[{ required: properties_.required }]} required={properties_.required}>
            <PropertySelect
              properties={properties}
              value={form.measure.property}
              onChange={(value) => {
                if (value === undefined) {
                  setForm((oldValue) => ({
                    ...oldValue,
                    measure: {
                      ...oldValue.measure,
                      property: undefined,
                    },
                  }))
                  setFormModified(true)
                  return
                }
                // 選択されたプロパティを詳細項目に自動追加
                const newSheet = sheet.addPropertyField(value.nodePropertyName, { toLast: true })
                setForm((oldValue) => ({
                  ...oldValue,
                  sheet: newSheet.config,
                  measure: {
                    ...oldValue.measure,
                    type: `kpiPreset`,
                    property: value.nodePropertyName,
                  },
                }))
                setFormModified(true)
              }}
            />
          </Form.Item>
        )}

      {form.measure?.type === `kpiCustom` && <KpiFormMeasureCustomSqlEditor />}
    </div>
  )
}
