import { type FileInfo, type Locale, Widget } from '@uploadcare/react-widget'
import i18n from 'i18next'

export const UploadImageButton = ({ onFinish }: { onFinish: (x: string) => Promise<void> }) => {
  const onImageUploaded = (fileInfo: FileInfo) => {
    if (fileInfo.cdnUrl !== null) {
      void onFinish(fileInfo.cdnUrl)
    }
  }

  return (
    <Widget
      publicKey={process.env.UPLOADCARE_API_KEY ?? ''}
      locale={
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        (i18n.language as Locale) ?? 'ja'
      }
      imagesOnly={true}
      crop="150x150" // オリジナル画像が150x150でクロップされるわけではなく、レスポンスのCDN URLに150x150のURLが入った状態になるだけ
      onChange={onImageUploaded}
    />
  )
}
