import { useMutation, useSuspenseQuery } from '@apollo/client'
import type { ViewConfigTypeEnum } from '@salescore/client-api'
import { FetchPoliciesDocument, ReplaceSubjectPoliciesDocument } from '@salescore/client-api'
import { recoil } from '@salescore/client-recoil'
import {
  type Policy,
  type PolicyAction,
  policyActionLabelJaMap,
  type PolicyPrincipal,
  type PolicySubject,
} from '@salescore/features'
import { useBooleanState } from '@salescore/frontend-common'
import { Alert, Button, Collapse, Form, message, Row, Select } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'

import { SuspenseWithLoading } from '../SuspenseWithLoading'

type ActionGroup = `${ViewConfigTypeEnum}` | 'viewGroup'

export function EntityPolicyForm({ subject, actionGroup }: { subject: PolicySubject; actionGroup: ActionGroup }) {
  return (
    <Collapse defaultActiveKey={[]} className="mb-4">
      <Collapse.Panel header={t(`権限設定`)} key="detail">
        <SuspenseWithLoading>
          <Body subject={subject} actionGroup={actionGroup} />
        </SuspenseWithLoading>
      </Collapse.Panel>
    </Collapse>
  )
}

//
// ポリシーを操作するフォームだが、単純なポリシーのCRUDのフォームを提供してもユーザーには理解できないので、
// ユーザーが理解できそうな選択肢を提供した上で、それらをポリシーに変換している
//
type EntityPolicyFormItemValue = 'default' | 'manager' | 'creator' | 'managerAndCreator' | 'everyone'
type EntityPolicyFormValue = Partial<Record<PolicyAction, EntityPolicyFormItemValue>>
const options = (): Array<{ label: string; value: EntityPolicyFormItemValue }> => [
  {
    label: t(`自身の権限に準拠`), // NOTE: CS要望で「組織のデフォルト」から表現(label)を変更、valueと内容が一致しないケース(カスタム権限)があるので要検討
    value: 'default',
  },
  {
    label: t(`組織管理者のみ`),
    value: 'manager',
  },
  // いったんなしという仕様になった
  // {
  //   label: '作成者のみ',
  //   value: 'creator',
  // },
  {
    label: t(`組織管理者と作成者のみ`),
    value: 'managerAndCreator',
  },
  {
    label: t(`全員が可能`),
    value: 'everyone',
  },
]

const actionsByActionGroup: Record<ActionGroup, PolicyAction[]> = {
  sheet: ['sheet-update', 'sheet-delete', 'sheet-create-new-record', 'sheet-save-record', 'sheet-delete-record'],
  kpi: ['kpi-update', 'kpi-delete', 'kpi-create-new-record', 'kpi-save-record', 'kpi-delete-record'],
  kpiPivot: ['kpi-pivot-update', 'kpi-pivot-delete'],
  form: [],
  // TODO: RI
  kpiTimeSeries: [],
  viewGroup: ['view-group-delete'],
}

function Body({ subject, actionGroup }: { subject: PolicySubject; actionGroup: ActionGroup }) {
  const canManage = recoil.global.useCan(`manage-policy`, subject)
  const organizationId = recoil.global.useOrganizationId()
  const [replaceSubjectPolicies] = useMutation(ReplaceSubjectPoliciesDocument)
  const invalidateAbility = recoil.global.useInvalidateAbility(subject)
  const loading = useBooleanState()
  const form = Form.useForm()[0]
  const { data: policies, refetch: invalidate } = useSuspenseQuery(FetchPoliciesDocument, {
    variables: {
      organizationId,
      subjectId: subject.id,
      subjectType: subject.entityType,
    },
  })
  const actions = actionsByActionGroup[actionGroup]
  const initialValues: EntityPolicyFormValue = actions
    .map((action) => convertPoliciesToFormItemValue(policies.policies, action))
    .toObject((x) => x)

  // 明示的にresetしないとinitialVlauesの変更が反映されなかった
  // （keyつきのdivなどでwrapしてもダメ）
  useEffect(() => {
    form.resetFields()
  }, [policies])

  const policyActionLabels = policyActionLabelJaMap()

  return (
    <>
      {!canManage && <Alert className="mb-4" showIcon type="warning" message={t(`権限がないため編集できません`)} />}
      <Form<EntityPolicyFormValue>
        form={form}
        initialValues={initialValues}
        onFinish={async (value) => {
          try {
            loading.setTrue()
            const result = await replaceSubjectPolicies({
              variables: {
                organizationId,
                subjectPolicies: actions
                  .map((action) => createMutationInput(action, subject, value, initialValues))
                  .compact(),
              },
            })
            void invalidate()
            invalidateAbility()
            void message.info(t(`更新しました`))
          } catch {
            void message.error(t(`エラーが発生しました。`))
          } finally {
            loading.setFalse()
          }
        }}
      >
        {actions.map((action) => (
          <Form.Item key={action} name={action} label={policyActionLabels[action]}>
            <Select disabled={!canManage} options={options()} />
          </Form.Item>
        ))}
        <Row justify="end" className="py-4" align="middle">
          {/* htmlType指定でsubmitが動作しなかったので、onClickで指定している */}
          <Button
            type="primary"
            disabled={!canManage}
            loading={loading.isTrue}
            onClick={() => {
              form.submit()
            }}
            // disabled={!isEditable}
          >
            {t(`保存`)}
          </Button>
        </Row>
      </Form>
    </>
  )
}

function createMutationInput(
  action: PolicyAction,
  subject: PolicySubject,
  value: EntityPolicyFormValue,
  initialValues: EntityPolicyFormValue,
) {
  const newValue = value[action]
  const initialValue = initialValues[action]
  if (newValue === initialValue || newValue === undefined) {
    return
  }
  const principalsWithPermission = convertFormItemValueToPrincipals(newValue)
  return {
    principalsWithPermission,
    action,
    subject,
  }
}

function convertFormItemValueToPrincipals(
  value: EntityPolicyFormItemValue,
): Array<Pick<Policy, 'principal' | 'permission'>> {
  switch (value) {
    case 'default': {
      return []
    }
    case 'manager': {
      return [
        {
          principal: {
            type: 'userRole',
            role: 'manager',
          },
          permission: true,
        },
        {
          principal: {
            type: 'everyone',
          },
          permission: false,
        },
      ]
    }
    case 'creator': {
      return [
        {
          principal: {
            type: 'userAttribute',
            attributeType: 'createdBy',
          },
          permission: true,
        },
        {
          principal: {
            type: 'everyone',
          },
          permission: false,
        },
      ]
    }
    case 'managerAndCreator': {
      return [
        {
          principal: {
            type: 'userRole',
            role: 'manager',
          },
          permission: true,
        },
        {
          principal: {
            type: 'userAttribute',
            attributeType: 'createdBy',
          },
          permission: true,
        },
        {
          principal: {
            type: 'everyone',
          },
          permission: false,
        },
      ]
    }
    case 'everyone': {
      return [
        {
          principal: {
            type: 'everyone',
          },
          permission: true,
        },
      ]
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const _: never = value
      return []
    }
  }
}

//
// XXX: 現状の仕様に沿ったロジック。仕様が変わると変な表示になりうる
//
// eslint-disable-next-line complexity
function convertPoliciesToFormItemValue(
  policies: Policy[],
  action: PolicyAction,
): [PolicyAction, EntityPolicyFormItemValue | undefined] {
  const targetPolicies = policies.filter((x) => x.action === action)
  const responseForInvalidPolicy = [action, undefined] as [PolicyAction, undefined] // TODO: 現状のロジック上ありえないはずの値が設定されているとき
  if (targetPolicies.length > 3) {
    // 現状だとありえないので
    return responseForInvalidPolicy
  }

  if (targetPolicies.isBlank()) {
    return [action, 'default'] // 特に設定されていない
  }

  if (targetPolicies.length === 3) {
    return [action, 'managerAndCreator'] // 特に設定されていない
  }

  //
  // ややコードが長くなるが、愚直に判定する
  //
  const [a, b] = targetPolicies
  if (a !== undefined && b !== undefined) {
    if (isManagerPrincipal(a.principal) || isManagerPrincipal(b.principal)) {
      return [action, 'manager']
    }
    if (isCreatedByPrincipal(b.principal) || isCreatedByPrincipal(a.principal)) {
      return [action, 'creator']
    }
  }

  return [action, 'everyone']
}

function isManagerPrincipal(principal: PolicyPrincipal) {
  return principal.type === 'userRole' && principal.role === 'manager'
}

function isCreatedByPrincipal(principal: PolicyPrincipal) {
  return principal.type === 'userAttribute' && principal.attributeType === 'createdBy'
}

function isEveryonePrincipal(principal: PolicyPrincipal) {
  return principal.type === 'everyone'
}
