import { OrganizationStatusEnum } from '@salescore/client-api'
import dayjs from 'dayjs'
import { selector, useRecoilValue } from 'recoil'

import { meAtom, uiAtom } from '../../view/atoms'
import { calculateAverageProgressService } from './calculateAverageProgressService'
import { periodSelector } from './PeriodSelector'

export const progressSelector = selector({
  key: `view/navigation/progressSelector`,
  // eslint-disable-next-line complexity
  get({ get }) {
    const period = get(periodSelector)
    const me = get(meAtom)
    const ui = get(uiAtom)
    const component = ui[0]
    const pivot =
      component?.type === 'KpiPivotSheet' || component?.type === 'KpiSheet'
        ? component.pivot
        : { rows: [], columns: [] }

    return calculateAverageProgressService({
      startAt: period?.startAt,
      endAt: period?.endAt,
      current: dayjs(),
      isDemo: me.organization.status === OrganizationStatusEnum.Demo,
      pivot,
      businessDays: me.organization.businessDays,
    })
  },
})

export const useProgressSelector = () => useRecoilValue(progressSelector)
