import { t } from 'i18next'
import { z } from 'zod'

import type { FieldType } from '../query'
import { nodePropertyNameSchema } from './common'
import { viewConfigFilterNodeSchema } from './filter'

const presetMeasureFunctionsSchema = z.enum([`count`, `sum`, `avg`, `countUnique`, `countUniqueValue`, `min`, `max`])
export type PresetMeasureFunctions = z.infer<typeof presetMeasureFunctionsSchema>
const presetMeasureSchema = z.object({
  type: z.literal(`preset`),
  name: z.string(), // unique
  label: z.string(),
  function: presetMeasureFunctionsSchema,
  fieldName: z.string(),
  filterTree: viewConfigFilterNodeSchema.optional(),
})
export type ViewConfigPresetMeasure = z.infer<typeof presetMeasureSchema>
// eslint-disable-next-line complexity
export const presetFunctions = (): Array<{
  value: PresetMeasureFunctions
  label: string
  availableFieldType?: FieldType[]
}> => [
  {
    value: 'count',
    label: t(`レコード数`) ?? `レコード数`,
  },
  {
    value: 'sum',
    label: t(`合計`) ?? `合計`,
    availableFieldType: ['numeric', 'integer'],
  },
  {
    value: 'avg',
    label: t(`平均`) ?? `平均`,
    availableFieldType: ['numeric', 'integer'],
  },
  {
    value: 'countUnique',
    label: t(`ユニークなレコード数`) ?? `ユニークなレコード数`,
  },
  {
    value: 'countUniqueValue',
    label: t(`ユニークな値の数`) ?? `ユニークな値の数`,
  },
  {
    value: 'min',
    label: t(`最小`) ?? `最小`,
    availableFieldType: ['numeric', 'integer'],
  },
  {
    value: 'max',
    label: t(`最大`) ?? `最大`,
    availableFieldType: ['numeric', 'integer'],
  },
]

const customMeasureSchema = z.object({
  type: z.literal(`custom`),
  name: z.string(), // unique
  label: z.string().optional(),
  sql: z.string(),
  dependentNodeNames: z.string().array(),
})

export const viewConfigMeasureSchema = z.union([presetMeasureSchema, customMeasureSchema])
export type ViewConfigMeasure = z.infer<typeof viewConfigMeasureSchema>

// 以下KPI用
const presetKpiMeasureSchema = z.object({
  type: z.literal(`kpiPreset`),
  function: presetMeasureFunctionsSchema,
  property: nodePropertyNameSchema,
})
export type ViewConfigPresetKpiMeasure = z.infer<typeof presetKpiMeasureSchema>

const customKpiMeasureSchema = z.object({
  type: z.literal(`kpiCustom`),
  sql: z.string(),
  dependentNodeNames: z.string().array(),
})
export type ViewConfigCustomKpiMeasure = z.infer<typeof customKpiMeasureSchema>

export const viewConfigKpiMeasureSchema = z.union([presetKpiMeasureSchema, customKpiMeasureSchema])
export type ViewConfigKpiMeasure = z.infer<typeof viewConfigKpiMeasureSchema>
