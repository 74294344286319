import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import * as holidayJp from '@holiday-jp/holiday_jp'
import { isSome } from '@salescore/buff-common'
import { wrapKeyboardEvent } from '@salescore/frontend-common'
import { Button, Calendar, Input, Row, Space } from 'antd'
import dayjs, { type Dayjs } from 'dayjs'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ja from 'dayjs/locale/ja'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'

import { RSheetsStyle } from '../../../../util/RSheetsStyle'

const DATE_FORMAT = `YYYY-MM-DD`

export const DatePicker = ({
  value,
  onChange,
  onClose,
}: {
  value: Dayjs | string | null | undefined
  onChange: (date: Dayjs | undefined) => void
  onClose: () => void
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputReference = useRef<any>(null)
  const backButtonReference = useRef<HTMLButtonElement>(null)
  const nextButtonReference = useRef<HTMLButtonElement>(null)
  const [date, setDate] = useState(() => {
    if (dayjs.isDayjs(value)) {
      return value
    }
    if (typeof value === 'string') {
      const x = dayjs(value)
      return x.isValid() ? x : dayjs()
    }
    return dayjs()
  })
  const [dateString, setDateString] = useState(date.format(DATE_FORMAT))

  const focusToInput = () => {
    if (isSome(inputReference.current)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      inputReference.current.focus({ cursor: 'end' })
    }
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const focusToCalendar = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    const x = document.querySelectorAll('.ant-picker-panel')[0] as HTMLDivElement | undefined
    x?.focus()
  }

  // 初期のfocusを設定（こんなやりかたしかないだろうか？）
  useEffect(() => {
    setTimeout(() => {
      focusToCalendar() // 初期状態はカレンダーで良いだろうか？[]
    }, 10)
  }, [])

  // dateが変わったら、dateStringも変更する
  useEffect(() => {
    const newDateString = date.format(DATE_FORMAT)
    if (dateString !== newDateString) {
      setDateString(newDateString)
    }
  }, [date])

  // dateStringが変わったら、有効な日付形式のときのみdateを変更する
  useEffect(() => {
    const currentDateString = date.format(DATE_FORMAT)
    if (currentDateString === dateString) {
      return
    }
    const d = dayjs(dateString)
    if (/\d{4}(?:[/-]\d{2}){2}/.test(dateString) && d.isValid()) {
      setDate(d)
    }
  }, [dateString])

  return (
    <div className="my-date-picker">
      <Input
        bordered={false}
        ref={inputReference}
        value={dateString}
        onChange={(e) => {
          setDateString(e.target.value)
        }}
        onKeyDown={(e) => {
          switch (e.key) {
            case 'Enter': {
              const d = dayjs(dateString)
              if (d.isValid()) {
                onChange(d)
              } else {
                // eslint-disable-next-line unicorn/no-useless-undefined
                onChange(undefined)
              }
              e.stopPropagation()
              return
            }
            case 'ArrowDown': {
              focusToCalendar()
              e.preventDefault()
              e.stopPropagation()
              return
            }
            case 'Escape': {
              onClose()
              e.preventDefault()
              e.stopPropagation()
            }
          }
        }}
        style={{
          width: '100%',
          padding: 0,
        }}
      />

      <div
        className="absolute shadow-lg"
        style={{
          top: 50,
          left: -20,
          zIndex: RSheetsStyle.zIndex.cellInputDropdown,
        }}
        onKeyDown={
          // eslint-disable-next-line complexity
          (e) => {
            const wrapedEvent = wrapKeyboardEvent(e)
            switch (wrapedEvent.key) {
              case 'Enter': {
                onChange(date)
                e.stopPropagation()
                return
              }
              case 'Delete': {
                focusToInput()
                e.stopPropagation()
                return
              }
              case 'Backspace': {
                focusToInput()
                e.stopPropagation()
                return
              }
              case 'p': {
                if (wrapedEvent.macCtrlKey) {
                  setDate(date.clone().add(-1, 'week'))
                  focusToCalendar()
                  e.stopPropagation()
                  return
                }
              }
              // eslint-disable-next-line no-fallthrough
              case 'n': {
                if (wrapedEvent.macCtrlKey) {
                  setDate(date.clone().add(1, 'week'))
                  focusToCalendar()
                  e.stopPropagation()
                  return
                }
              }
              // eslint-disable-next-line no-fallthrough
              case 'f': {
                if (wrapedEvent.macCtrlKey) {
                  setDate(date.clone().add(1, 'day'))
                  focusToCalendar()
                  e.stopPropagation()
                  return
                }
              }
              // eslint-disable-next-line no-fallthrough
              case 'b': {
                if (wrapedEvent.macCtrlKey) {
                  setDate(date.clone().add(-1, 'day'))
                  focusToCalendar()
                  e.stopPropagation()
                  return
                }
              }
              // eslint-disable-next-line no-fallthrough
              case 'Escape': {
                onClose()
                e.preventDefault()
                e.stopPropagation()
              }
            }
          }
        }
      >
        <Calendar
          fullscreen={false}
          value={date}
          onSelect={(date) => {
            setDate(date)
          }}
          // XXX: 適切な方法がわからなかった
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          locale={ja}
          style={{
            width: 300,
          }}
          dateFullCellRender={
            // eslint-disable-next-line complexity
            (d) => {
              const isSunday = d.day() === 0
              const isSaturday = d.day() === 6
              const isHoliday = holidayJp.isHoliday(d.toDate())
              const isSelected = d.format(DATE_FORMAT) === date.format(DATE_FORMAT)
              const isCurrentMonth = d.month() === date.month()
              return (
                <div
                  onClick={() => {
                    onChange(d)
                  }}
                  className={`ant-picker-cell-inner ant-picker-calendar-date ${
                    isSelected
                      ? ''
                      : isCurrentMonth
                        ? isSunday || isHoliday
                          ? 'text-red-400'
                          : isSaturday
                            ? 'text-blue-500'
                            : ''
                        : ''
                  }`}
                >
                  <div className="ant-picker-calendar-date-value">{d.format('DD')}</div>
                  <div className="ant-picker-calendar-date-content"></div>
                </div>
              )
            }
          }
          headerRender={({ onChange, onTypeChange, value }) => {
            const onNext = () => {
              onChange(value.clone().add(1, 'month'))
            }
            const onBack = () => {
              onChange(value.clone().subtract(1, 'month'))
            }

            return (
              <Row align="middle" justify="space-between" className="w-full px-2 py-4 font-bold">
                <div className="mr-4">{value.format('YYYY年M月')}</div>
                <Space>
                  <Button
                    type="default"
                    size="small"
                    ref={backButtonReference}
                    onKeyDown={(e) => {
                      switch (e.key) {
                        case 'Enter': {
                          e.preventDefault()
                          e.stopPropagation()
                          onBack()
                          break
                        }
                        case 'ArrowDown': {
                          focusToCalendar()
                          e.preventDefault()
                          e.stopPropagation()
                          break
                        }
                        case 'ArrowLeft': {
                          focusToInput()
                          e.preventDefault()
                          e.stopPropagation()
                          break
                        }
                        case 'ArrowUp': {
                          focusToInput()
                          e.preventDefault()
                          e.stopPropagation()
                          break
                        }
                        case 'ArrowRight': {
                          e.preventDefault()
                          e.stopPropagation()
                          nextButtonReference.current?.focus()
                          break
                        }
                        case 'Escape': {
                          onClose()
                          e.preventDefault()
                          e.stopPropagation()
                          break
                        }
                      }
                    }}
                    onClick={onBack}
                    icon={<LeftOutlined />}
                  >
                    {t(`前月`)}
                  </Button>
                  <Button
                    type="default"
                    size="small"
                    ref={nextButtonReference}
                    onKeyDown={
                      // eslint-disable-next-line complexity
                      (e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.stopPropagation()
                          onNext()
                        }
                        if (e.key === 'ArrowDown') {
                          focusToCalendar()
                          e.preventDefault()
                          e.stopPropagation()
                        }
                        if (e.key === 'ArrowLeft') {
                          backButtonReference.current?.focus()
                          e.preventDefault()
                          e.stopPropagation()
                        }
                        if (e.key === 'ArrowUp') {
                          focusToInput()
                          e.preventDefault()
                          e.stopPropagation()
                        }
                        if (e.key === 'ArrowRight') {
                          e.preventDefault()
                          e.stopPropagation()
                          focusToCalendar()
                        }
                      }
                    }
                    onClick={onNext}
                    icon={<RightOutlined />}
                  >
                    {t(`次月`)}
                  </Button>
                </Space>
              </Row>
            )
          }}
        />
      </div>
    </div>
  )
}
