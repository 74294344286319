import { PlusOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import { UpdateSheetCustomModelPropertyDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import type { ViewQueryNode } from '@salescore/core'
import { useBooleanState } from '@salescore/frontend-common'
import { Button, Form, Input, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { useRecoilState } from 'recoil'

import { sheetCustomModelsAtom } from '../../../../recoil/view/atoms'
import { useViewValue } from '../../../../recoil/view/hooks'
import { getOptionsFromText } from './SheetCustomModelPropertyForm'

interface FormValue {
  label: string
  // type: AvailableType;
  selectOptionsText: string | undefined
}

// eslint-disable-next-line complexity
export function SheetCustomModelPropertyEditForm({
  node,
  propertyName,
}: {
  node: ViewQueryNode
  propertyName: string
}) {
  const [sheetCustomModels, setSheetCustomModels] = useRecoilState(sheetCustomModelsAtom)
  const sheetCustomModel = sheetCustomModels.find((x) => x.name === node.name)
  const property = sheetCustomModel?.properties.find((x) => x.name === propertyName)
  const view = useViewValue()
  const [updateSheetCustomModelProperty] = useMutation(UpdateSheetCustomModelPropertyDocument)
  const [form] = useForm<FormValue>()
  const loading = useBooleanState()

  if (sheetCustomModel === undefined || property === undefined) {
    // ありえないはず
    return <div>{t(`エラー。{{propertyName}}が見つかりません`, { propertyName })}</div>
  }

  return (
    <div
      onKeyDown={(e) => {
        e.stopPropagation()
      }}
    >
      <Form<FormValue>
        layout="vertical"
        form={form}
        className="p-4"
        initialValues={{
          label: property.label,
          selectOptionsText: property.selectOptions?.map((x) => x.value).join('\n'),
        }}
        onFinish={async (value) => {
          // TODO: 同じlabelは追加できないようにする？
          try {
            loading.setTrue()
            const { data: result } = await updateSheetCustomModelProperty({
              variables: {
                organizationId: getOrganizationIdFromPath(),
                input: {
                  id: sheetCustomModel.id,
                  propertyName: property.name,
                  label: value.label,
                  selectOptions: getOptionsFromText(value.selectOptionsText),
                },
              },
            })
            const model = result?.updateSheetCustomModelProperty
            if (isNull(model)) {
              void message.error(t(`追加に失敗しました`)) // TODO
              return
            }

            // modelの内容を更新
            setSheetCustomModels((xs) => [model, ...xs].uniqueBy((x) => x.name)) // 既にあれば、先頭が優先されるので更新される
            void message.success(t(`カスタム項目を更新しました`))
          } catch (error) {
            if (error instanceof Error) {
              void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
            } else {
              void message.error(t(t(`エラーが発生しました。`)))
            }
          } finally {
            loading.setFalse()
          }
        }}
      >
        <Form.Item
          name="label"
          required
          label={t(`ラベル`)}
          rules={[{ required: true, message: t(`入力してください`) }]}
        >
          <Input />
        </Form.Item>
        {/* TODO */}
        {/* <Form.Item name="type" required label="データ型" rules={[{ required: true, message: '入力してください' }]}>
          <Select
            className="disable-on-click-outside"
            options={options}
            onChange={(value) => {
              setType(value as AvailableType)
            }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          />
        </Form.Item> */}
        {(property.selectOptions?.isPresent() ?? false) && (
          <Form.Item
            name="selectOptionsText"
            required
            label={t(`選択肢(改行で入力)`)}
            rules={[
              { required: true, message: t(`入力してください`) },
              {
                validator(rule, value, callback) {
                  if (typeof value !== 'string') {
                    callback()
                    return
                  }
                  const options = getOptionsFromText(value)
                  if (options.uniqueBy((x) => x.value).length === options.length) {
                    callback()
                  } else {
                    callback(t(`重複した選択肢があります`))
                  }
                },
              },
            ]}
          >
            <Input.TextArea rows={8} />
          </Form.Item>
        )}
        <div className="flex flex-row-reverse">
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<PlusOutlined />} loading={loading.isTrue}>
              {t(`更新`)}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}
