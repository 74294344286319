import { Mention, MentionsInput } from 'react-mentions'

const customMentionsOutputStyle = {
  control: {
    border: 'none',
  },
  highlighter: {
    padding: 0,
    border: 'none',
    wordBreak: 'break-all',
  },
  input: {
    padding: 0,
    border: 'none',
    wordBreak: 'break-all',
  },
}

export function ReadonlyMentionTextarea({
  message,
  options,
}: {
  message: string
  options: Array<{ id: string; display: string }>
}) {
  return (
    <MentionsInput value={message} readOnly style={customMentionsOutputStyle}>
      <Mention
        trigger="@"
        data={options}
        className="rounded-sm bg-[#cee4e5]"
        renderSuggestion={
          // eslint-disable-next-line @typescript-eslint/max-params
          (suggestion, search, highlightedDisplay, index, focus) => <span className="ml-2">{highlightedDisplay}</span>
        }
      />
    </MentionsInput>
  )
}
