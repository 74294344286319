// TODO: Monacoに型がつかないため、anyを許している

import { FunctionOutlined, QuestionCircleOutlined, UnorderedListOutlined } from '@ant-design/icons'
import Editor, { useMonaco } from '@monaco-editor/react'
import { abbreviateText, isNull, isSome, type RecursivePartial } from '@salescore/buff-common'
import type { ViewConfigSheet } from '@salescore/core'
import { useModal } from '@salescore/frontend-common'
import { Alert, Col, Dropdown, Form, Menu, Modal, Row } from 'antd'
// @monaco-editor/reactがexportするtypeがなぜかanyになるので、自前で同等の型を付与したかったが、compileでエラーになるのでボツ
// import { editor, Range } from 'monaco-editor'
import { t } from 'i18next'
import { useEffect, useMemo, useRef } from 'react'
import { format } from 'sql-formatter'

import { NodePropertyPickerModalContent } from '../../../components/view_ui/ViewUISheetWithNavigation/SheetNavigation/common/NodePropertyPickerModalContent'
import { useConnectionsSelector } from '../../../recoil/view/selectors/connectionsSelector'
import { flatNodes } from '../../../state/nodeUtil'
import { MonacoEditorNavigationButton, MonacoEditorNavigationHeader } from './ConditionalEffectExpressionEditor'

const POSTGRES_SQL_STATEMENTS = [`SELECT`, `FROM`, `JOIN`, `WHERE`, `AND`, `LIMIT`, `OFFSET`]

const POSTGRES_SQL_FUNCTIONS = [
  { label: t(`カウント`), value: `COUNT(*)` },
  { label: t(`合計`), value: `SUM()` },
  { label: t(`平均`), value: `AVG()` },
  { label: t(`割合`), value: `COUNT(CASE WHEN flag = 1 THEN 1 ELSE NULL END)::NUMERIC / NULLIF(COUNT(*), 0)` },
  { label: t(`最小`), value: `MIN()` },
  { label: t(`最大`), value: `MAX()` },
]

export function MeasureCustomSqlEditor({ sheetConfig }: { sheetConfig: RecursivePartial<ViewConfigSheet> }) {
  const propertyModal = useModal()
  const { getModel } = useConnectionsSelector()
  const { propertiesWithNode, modelsWithNode } = useMemo(() => {
    const { tree } = sheetConfig
    if (tree === undefined) {
      return {
        modelsWithNode: [],
        propertiesWithNode: [],
      }
    }

    const nodes = flatNodes(tree)
    const modelsWithNode = nodes
      .map((node) => {
        const model = getModel(node.modelName)
        if (model === undefined) {
          return
        }
        return {
          node,
          model,
        }
      })
      .compact()
    const propertiesWithNode = modelsWithNode.flatMap((x) =>
      x.model.properties.map((property) => ({
        node: x.node,
        model: x.model,
        property,
      })),
    )
    return {
      propertiesWithNode,
      modelsWithNode,
    }
  }, [sheetConfig])

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const monaco = useMonaco()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorReference = useRef<any>(null)
  // const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null)

  useEffect(() => {
    if (isSome(monaco)) {
      // TODO: suggestion
      const suggestions = [
        ...POSTGRES_SQL_STATEMENTS.map((command) => ({
          label: command,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: `${command} `,
        })),
        ...POSTGRES_SQL_FUNCTIONS.map((command) => ({
          label: command.value,
          detail: command.label,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: `${command.value} `,
        })),
        ...modelsWithNode
          .uniqueBy((x) => x.node.name)
          .map((modelWithNode) => ({
            label: modelWithNode.model.label,
            detail: modelWithNode.model.label,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
            kind: monaco.languages.CompletionItemKind.Field,
            insertText: `"${modelWithNode.node.name}" `,
          })),
        ...propertiesWithNode
          .uniqueBy((propertyWithNode) => `${propertyWithNode.node.name}.${propertyWithNode.property.name}`)
          .map((propertyWithNode) => ({
            // label: propertyWithNode.column_name,
            label: `${propertyWithNode.node.name}.${propertyWithNode.property.name}`,
            detail: `${propertyWithNode.property.label ?? ''}(${propertyWithNode.model.label ?? ''})`,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            kind: monaco.languages.CompletionItemKind.Field,
            insertText: `"${propertyWithNode.node.name}"."${propertyWithNode.property.name}" `,
          })),
      ]
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      monaco.languages.registerCompletionItemProvider('sql', {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        provideCompletionItems(model: any, position: any) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          const word = model.getWordUntilPosition(position)
          return {
            suggestions: suggestions
              .uniqueBy((x) => x.insertText)
              .map((x) => ({
                ...x,
                range: {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                  startLineNumber: position.lineNumber,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                  endLineNumber: position.lineNumber,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                  startColumn: word.startColumn,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                  endColumn: word.endColumn,
                },
              })),
          }
        },
      })
    }
  }, [monaco])

  const insert = (text: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const editor = editorReference.current
    if (editor === null) {
      return
    }
    // // https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IEditor.html#getPosition
    // const position = editor.getPosition()
    // // https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.ITextModel.html#getValue
    // const sql = editor.getModel()?.getValue()
    // if (isNull(sql) || isNull(position)) {
    //   return
    // }
    // form.setFieldsValue({ sql: format(sql, { language: 'postgresql' }) })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const selection = editor.getSelection()
    // https://qiita.com/lumis/items/311b8c39d61312957195#%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%E3%81%A7%E7%B7%A8%E9%9B%86%E3%81%99%E3%82%8B
    // editor.executeEdits(``, [{ range: selection ?? new Range(1, 1, 1, 1), text }])
    if (isSome(selection)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      editor.executeEdits(``, [{ range: selection, text }])
    }
  }

  return (
    <div className="overflow-hidden">
      <Alert
        showIcon
        className="mb-4"
        type="info"
        description={<div>{t(`SQLを使って集計をすることができます。`)}</div>}
      />
      <Row>
        <Col span={24}>
          <MonacoEditorNavigationHeader title={t(`SQLエディタ`)}>
            <>
              <MonacoEditorNavigationButton icon={<UnorderedListOutlined />} onClick={propertyModal.showModal}>
                {t(`項目`)}
              </MonacoEditorNavigationButton>
              <SqlFunctionDropdown
                onFinish={(text) => {
                  insert(text)
                }}
              >
                <MonacoEditorNavigationButton icon={<FunctionOutlined />}>{t(`関数`)}</MonacoEditorNavigationButton>
              </SqlFunctionDropdown>
              <MonacoEditorNavigationButton
                icon={<FunctionOutlined />}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  const editor = editorReference.current
                  if (editor === null) {
                    return
                  }
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-type-assertion
                  const sql = editor.getModel()?.getValue() as string | null
                  if (isNull(sql)) {
                    return
                  }
                  const formatted = format(sql, { language: 'postgresql' })
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                  editor.getModel()?.setValue(formatted)
                }}
              >
                {t(`フォーマット`)}
              </MonacoEditorNavigationButton>
              <MonacoEditorNavigationButton icon={<QuestionCircleOutlined />}>
                {t(`ヘルプ`)}
              </MonacoEditorNavigationButton>
            </>
          </MonacoEditorNavigationHeader>
          <Form.Item name="sql" rules={[{ required: true, message: t(`SQLを入力してください`) }]}>
            <Editor
              height={500}
              theme="vs-dark"
              defaultLanguage="sql"
              defaultValue={`COUNT(*)`}
              onMount={(editor, monaco) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                editorReference.current = editor
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Modal
        open={propertyModal.isModalVisible}
        onCancel={propertyModal.hideModal}
        width={700}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div></div>}
        style={{ top: '3%' }}
      >
        <NodePropertyPickerModalContent
          propertiesWithNode={propertiesWithNode}
          onFinish={(nodePropertyName) => {
            // onFinish(nodePropertyName)
            insert(`"${nodePropertyName.nodeName}"."${nodePropertyName.propertyName}"`)
            propertyModal.hideModal()
          }}
        />
      </Modal>
    </div>
  )
}

function SqlFunctionDropdown({ onFinish, children }: { onFinish: (text: string) => void; children: JSX.Element }) {
  const menu = (
    <Menu
      items={POSTGRES_SQL_FUNCTIONS.map(({ value, label }) => ({
        key: value,
        label: (
          <div>
            {label}
            <span className="ml-2 opacity-60">{abbreviateText(value, 15)}</span>
          </div>
        ),
        onClick: () => {
          onFinish(value)
        },
      }))}
    />
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      {children}
    </Dropdown>
  )
}
