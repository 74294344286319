import type { ModelProperty } from '@salescore/core'

import { getSheetCustomModelColumns } from './createTableForSheetCustomModel'

type TargetProperty = Pick<ModelProperty, 'label' | 'type' | 'selectOptions' | 'meta'>

export function generateSheetCustomModelProperty(
  namesUsedInModel: string[],
  namesUsedInView: string[],
  target: TargetProperty,
) {
  // 使用されていないフィールド名を探す
  const columns = getAvailableColumns(target)
  const unusedColumns = columns.filter((x) => !namesUsedInModel.includes(x.name) && !namesUsedInView.includes(x.name))
  const column = unusedColumns.first()
  if (column === undefined) {
    return {
      success: false as const,
      error: `利用可能なカラムがありません`,
    }
  }

  const property: ModelProperty = {
    name: column.name,
    type: column.type,
    meta: target.meta,
    label: target.label,
    selectOptions: target.selectOptions,
    write: {
      sourcePropertyName: column.name,
    },
  }
  return {
    success: true as const,
    property,
  }
}

// eslint-disable-next-line complexity
function getAvailableColumns(property: TargetProperty) {
  const columns = getSheetCustomModelColumns()
  if (property.selectOptions?.isPresent() ?? false) {
    return columns.options
  }
  if (property.meta === 'text') {
    return columns.text
  }

  const { type } = property
  switch (type) {
    case 'string': {
      return columns.string
    }
    case 'numeric': {
      return columns.numeric
    }
    case 'date': {
      return columns.date
    }
    case 'datetime': {
      return columns.datetime
    }
    case 'boolean': {
      return columns.boolean
    }
    default: {
      throw new Error(`この型のカスタムカラムはサポートしていません。${type}`)
    }
  }
}
