import { isTruthy } from '@salescore/buff-common'
import type { ViewUpdateInputSingle } from '@salescore/client-api'
import { PermissionActionEnum } from '@salescore/client-api'
import { CORE_CONSTANT, type KpiFragment, type ViewConfigKpiTimeSeries } from '@salescore/core'
import { Empty } from 'antd'
import { t } from 'i18next'
import type { ReactNode } from 'react'
import { useSetRecoilState } from 'recoil'

import type { DrillDownParameterForKpiPivot } from '../recoil/navigation/atoms'
import { viewAtom } from '../recoil/view/atoms'
import { useViewsContextValue, useViewValue } from '../recoil/view/hooks'
import { setConfigWithoutChangeMutation } from '../recoil/view/mutations/setConfigMutation'
import { useWaterfallDrillDownSelector } from '../recoil/view/selectors/waterfallDrillDownSelector'
import { ViewDrillDownModalContentForKpi } from './ViewDrillDownModalContentForKpi'

export function ViewDrillDownComponentForRi({
  parameter,
  onFinish,
}: {
  parameter: DrillDownParameterForKpiPivot
  onFinish?: () => Promise<void>
}): ReactNode {
  const previousView = useViewValue()

  const previousConfig = previousView.config
  if (previousConfig.type !== 'kpiTimeSeries') {
    return <Empty description={<div>{t(`KPIビューが見つかりません。`)}</div>} />
  }

  if (isTruthy(previousConfig.option?.asWaterfall)) {
    return (
      <ViewDrillDownComponentForRiWaterfall
        previousViewId={previousView.id}
        previousConfig={previousConfig}
        parameter={parameter}
        onFinish={onFinish}
      />
    )
  }

  return (
    <ViewDrillDownComponentForRiTimeSeries
      previousViewId={previousView.id}
      previousConfig={previousConfig}
      parameter={parameter}
      onFinish={onFinish}
    />
  )
}

function ViewDrillDownComponentForRiTimeSeries({
  previousViewId,
  previousConfig,
  parameter,
  onFinish,
}: {
  previousViewId: string
  previousConfig: ViewConfigKpiTimeSeries
  parameter: DrillDownParameterForKpiPivot
  onFinish?: () => Promise<void>
}): ReactNode {
  const viewsContext = useViewsContextValue()
  const setView = useSetRecoilState(viewAtom)
  const setConfigWithoutChange = useSetRecoilState(setConfigWithoutChangeMutation)

  const previousKpiFragment = previousConfig.kpiFragment
  if (previousKpiFragment === undefined) {
    return <Empty description={<div>{t(`KPIビューが見つかりません。`)}</div>} />
  }

  return (
    <ViewDrillDownModalContentForKpi
      kpiView={{
        id: `${CORE_CONSTANT.KPI_TIME_SERIES_DRILL_DOWN_VIEW_ID_PREFIX}${previousViewId}`,
        config: {
          ...previousKpiFragment,
          type: 'kpi',
        },
        ui: [],
        name: '',
        type: 'kpi',
        queries: [],
        rank: 0,
        defaultPermission: PermissionActionEnum.Read,
        private: false,
        archived: false,
        roleRecordPermissions: [],
      }}
      parameter={parameter}
      onFinish={onFinish}
      viewsContextOverride={{
        updateView: async (kpiFragmentViewUpdateInput) => {
          // KpiTimeSeries は KPI ビューを持っていない。
          // KpiTimeSeries の KpiFragment のアップデートに書き換える。
          if (kpiFragmentViewUpdateInput.config?.type !== 'kpi') {
            return
          }
          const input = {
            id: previousViewId, // KpiTimeSeries の ID
            config: {
              ...previousConfig,
              kpiFragment: {
                ...previousKpiFragment,
                ...kpiFragmentViewUpdateInput.config,
                id: previousKpiFragment.id,
              },
            },
          }
          const updatedView = await viewsContext.updateView(input)
          if (updatedView !== undefined) {
            setView(updatedView)
            setConfigWithoutChange(updatedView.config)
          }
          return updatedView
        },
      }}
    />
  )
}

function ViewDrillDownComponentForRiWaterfall({
  previousViewId,
  previousConfig,
  parameter,
  onFinish,
}: {
  previousViewId: string
  previousConfig: ViewConfigKpiTimeSeries
  parameter: DrillDownParameterForKpiPivot
  onFinish?: () => Promise<void>
}): ReactNode {
  const viewsContext = useViewsContextValue()
  const setView = useSetRecoilState(viewAtom)
  const setConfigWithoutChange = useSetRecoilState(setConfigWithoutChangeMutation)
  const sheetAndModel = useWaterfallDrillDownSelector()
  if (sheetAndModel === undefined) {
    return <Empty description={<div>{t(`KPIビューが見つかりません。`)}</div>} />
  }

  const previousKpiFragment = previousConfig.kpiFragment
  if (previousKpiFragment === undefined) {
    return <Empty description={<div>{t(`KPIビューが見つかりません。`)}</div>} />
  }

  const { sheet, model } = sheetAndModel
  const kpiFragment: KpiFragment = {
    ...previousKpiFragment,
    sheet,
    user: undefined,
    date: undefined,
    dateY: undefined,
  }

  return (
    <ViewDrillDownModalContentForKpi
      kpiView={{
        id: `${CORE_CONSTANT.KPI_TIME_SERIES_DRILL_DOWN_VIEW_ID_PREFIX}${previousViewId}`,
        config: {
          ...kpiFragment,
          type: 'kpi',
        },
        ui: [],
        name: '',
        type: 'kpi',
        queries: [],
        rank: 0,
        defaultPermission: PermissionActionEnum.Read,
        private: false,
        archived: false,
        roleRecordPermissions: [],
      }}
      parameter={{
        ...parameter,
        queryParameter: {},
      }}
      onFinish={onFinish}
      viewsContextOverride={{
        models: [...viewsContext.models, model],
        // eslint-disable-next-line complexity
        updateView: async (kpiFragmentViewUpdateInput) => {
          if (kpiFragmentViewUpdateInput.config?.type !== 'kpi') {
            return
          }
          const sheet = kpiFragmentViewUpdateInput.config.sheet
          const input: ViewUpdateInputSingle = {
            id: previousViewId, // KpiTimeSeries の ID
            config: {
              ...previousConfig,
              waterfall: {
                dimensions: previousConfig.waterfall?.dimensions ?? [],
                drillDownSheet: sheet?.type === 'sheet' ? sheet : undefined,
              },
            },
          }
          const updatedView = await viewsContext.updateView(input)
          if (updatedView !== undefined) {
            setView(updatedView)
            setConfigWithoutChange(updatedView.config)
            // NOTE: ここで最新のviewを取得しないとRecoilの状態が更新されない
            await viewsContext.refetchViews()
          }
          return updatedView
        },
      }}
    />
  )
}
