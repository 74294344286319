import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons'
import { compact, isPresent, isTruthy } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import type { CoreDslFormLeafPartial, CoreDslFormNodeStatePartial, ViewConfigField } from '@salescore/core'
import { Button, Checkbox, Form, Select, Space } from 'antd'
import { t } from 'i18next'

import { CoreDslFormLeafFormItems } from './CoreDslFormLeafFormItems'
import type { TargetProperty } from './targetProperty'

export const generateDefaultFormNode = (targetProperty: TargetProperty): CoreDslFormNodeStatePartial => {
  const defaultFormValue: CoreDslFormNodeStatePartial = {
    logicalOperator: 'and',
    leafs: [
      {
        left: {
          type: 'snapshotVariable',
          property: targetProperty.property,
          nodePropertyName: targetProperty.nodePropertyName,
          before: 0,
          dateSpan: 'day',
        },
      },
    ],
    children: [],
  }
  return defaultFormValue
}

// eslint-disable-next-line complexity
const isDisplayCaseSensitiveForm = (filterState: CoreDslFormLeafPartial) => {
  const equalOperators = ['=', '!=']
  const includeOperators = ['include', 'not_include']
  const startsWithOperators = ['starts_with', 'not_starts_with']
  const property = getLeftProperty(filterState)

  if (property?.type !== 'string') {
    return false
  }
  if (isPresent(property.selectOptions)) {
    return [...includeOperators].includes(filterState.operator ?? '')
  }
  return [...equalOperators, ...includeOperators, ...startsWithOperators].includes(filterState.operator ?? '')
}

export function CoreDslFormNode({
  targetProperty,
  formNode,
  availableFields,
  isAdvancedMode,
  setLeaf,
  setChildren,
  onDestroy,
  onLogicalOperatorChange,
}: {
  targetProperty: TargetProperty
  formNode: CoreDslFormNodeStatePartial
  availableFields: ViewConfigField[]
  isAdvancedMode: boolean
  setLeaf: (index: number, filterFormState: CoreDslFormLeafPartial | undefined) => void
  setChildren: (index: number, filterFormNodeState: CoreDslFormNodeStatePartial | undefined) => void
  onDestroy?: () => void
  onLogicalOperatorChange: (op: CoreDslFormNodeStatePartial['logicalOperator']) => void
}) {
  return (
    <div
      className="mb-4 rounded-lg border border-solid border-gray-300 bg-gray-100 p-6"
      style={{
        backgroundColor: '#f9f9f9',
      }}
    >
      {formNode.leafs.length > 0 && (
        <>
          {/* <div className="border border-solid border-gray-300 rounded-lg px-6 pt-6 mb-4 bg-gray-100"> */}
          {formNode.leafs.map((filterState, index) => (
            <>
              <CoreDslFormLeafFormItems
                targetProperty={targetProperty}
                // key={`filter-${index}-${filter.nodePaths.join(',') ?? ''}`}
                leaf={filterState}
                onChange={(newFilterState: CoreDslFormLeafPartial) => {
                  setLeaf(index, newFilterState)
                }}
                availableFields={availableFields}
              >
                <Button
                  icon={<DeleteOutlined />}
                  type="text"
                  onClick={() => {
                    setLeaf(index, undefined)
                  }}
                />
              </CoreDslFormLeafFormItems>
              {isDisplayCaseSensitiveForm(filterState) && (
                <Form.Item>
                  <Checkbox
                    checked={isTruthy(filterState.extra?.shouldConsiderCase)}
                    onChange={(value) => {
                      setLeaf(index, {
                        ...filterState,
                        extra: {
                          ...filterState.extra,
                          shouldConsiderCase: value.target.checked,
                        },
                      })
                    }}
                  >
                    {t('大文字と小文字を区別する')}
                  </Checkbox>
                </Form.Item>
              )}
              <DividerWithLogicalOperator
                {...{
                  formNode,
                  onLogicalOperatorChange,
                  withLogicalOperator: index !== formNode.leafs.length - 1 && isAdvancedMode,
                }}
              />
            </>
          ))}
          {/* </div> */}
        </>
      )}
      {isAdvancedMode &&
        formNode.children.map((childFilterNodeState, childFilterNodeStateIndex) => (
          <>
            <DividerWithLogicalOperator {...{ withLogicalOperator: true, formNode, onLogicalOperatorChange }} />
            <CoreDslFormNode
              targetProperty={targetProperty}
              isAdvancedMode={true}
              formNode={childFilterNodeState}
              availableFields={availableFields}
              setLeaf={(index: number, newFilter: CoreDslFormLeafPartial | undefined) => {
                const newFilterNodeState = {
                  ...childFilterNodeState,
                  leafs: compact([
                    ...childFilterNodeState.leafs.slice(0, index),
                    newFilter,
                    ...childFilterNodeState.leafs.slice(index + 1),
                  ]),
                }
                setChildren(childFilterNodeStateIndex, newFilterNodeState)
              }}
              setChildren={(index: number, newNode: CoreDslFormNodeStatePartial | undefined) => {
                const newFilterNodeState: CoreDslFormNodeStatePartial = {
                  ...childFilterNodeState,
                  children: [
                    ...childFilterNodeState.children.slice(0, index),
                    newNode,
                    ...childFilterNodeState.children.slice(index + 1),
                  ].compact(),
                }
                setChildren(childFilterNodeStateIndex, newFilterNodeState)
              }}
              onDestroy={() => {
                setChildren(childFilterNodeStateIndex, undefined)
              }}
              onLogicalOperatorChange={(logicalOperator) => {
                setChildren(childFilterNodeStateIndex, {
                  ...childFilterNodeState,
                  logicalOperator,
                })
              }}
            />
          </>
        ))}

      <Space className="">
        <Button
          // type="dashed"
          onClick={() => {
            const leaf: CoreDslFormLeafPartial = {
              left: {
                type: 'snapshotVariable',
                before: 0,
                dateSpan: 'day',
                property: targetProperty.property,
                nodePropertyName: targetProperty.nodePropertyName,
              },
            }
            setLeaf(formNode.leafs.length, leaf)
          }}
          block
          icon={<PlusCircleFilled />}
          type="text"
          style={{
            color: CONSTANT.colors.primaryColor,
            paddingLeft: 0,
          }}
        >
          {t(`条件を追加`)}
        </Button>
        {isAdvancedMode && (
          <>
            <Button
              // type="dashed"
              onClick={() => {
                const newNode = generateDefaultFormNode(targetProperty)
                setChildren(formNode.children.length, newNode)
              }}
              block
              icon={<PlusCircleFilled />}
              type="text"
              style={{
                color: CONSTANT.colors.primaryColor,
              }}
            >
              {t(`グループを追加`)}
            </Button>

            {onDestroy !== undefined && (
              <Button
                icon={<DeleteOutlined />}
                type="text"
                onClick={() => {
                  onDestroy()
                }}
                style={{
                  color: CONSTANT.colors.primaryColor,
                }}
              >
                {t(`削除`)}
              </Button>
            )}
          </>
        )}
      </Space>
    </div>
  )
}

function DividerWithLogicalOperator({
  withLogicalOperator,
  formNode,
  inNestedForm,
  onLogicalOperatorChange,
}: {
  withLogicalOperator: boolean
  formNode: CoreDslFormNodeStatePartial
  inNestedForm?: boolean
  onLogicalOperatorChange: (op: CoreDslFormNodeStatePartial['logicalOperator']) => void
}) {
  return (
    <div className={`relative mb-8 ${inNestedForm === true ? `mt-8` : ``}`}>
      <hr className="opacity-30" />
      {withLogicalOperator && (
        <Select
          style={{
            position: 'absolute',
            top: -16,
          }}
          value={formNode.logicalOperator}
          onChange={(value) => {
            onLogicalOperatorChange(value)
          }}
          options={[
            {
              label: t(`AND条件`),
              value: 'and',
            },
            {
              label: t(`OR条件`),
              value: 'or',
            },
          ]}
        />
      )}
    </div>
  )
}

function getLeftProperty(leaf?: CoreDslFormLeafPartial) {
  if (leaf?.left?.type !== 'snapshotVariable') {
    return
  }
  return leaf.left.property
}
