interface MeUser {
  id: string
  name: string
  role: string
  createdAt: string
  organization: {
    id: string
    name: string
    createdAt: string
  }
}

const initializedKeys: string[] = []

// eslint-disable-next-line complexity
export const openIntercom = ({ name, email, user }: { name?: string; email?: string; user?: MeUser }) => {
  const cacheKey = JSON.stringify({ name, email, user })
  if (initializedKeys.includes(cacheKey)) {
    return
  }

  const APP_ID = 'kj7srkh1'

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-type-assertion,unicorn/prefer-global-this
  const w = window as any
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  const ic = w.Intercom
  if (typeof ic === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    ic('reattach_activator')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    ic('update', w.intercomSettings)
  } else {
    const d = document
    // eslint-disable-next-line no-var,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-type-assertion
    var index = function () {
      // eslint-disable-next-line prefer-rest-params,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      index.c(arguments)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    index.q = []
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-explicit-any
    index.c = function (arguments_: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      index.q.push(arguments_)
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    w.Intercom = index
    const l = function () {
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + APP_ID
      const x = d.querySelectorAll('script')[0]
      if (x !== undefined) {
        x.parentNode?.insertBefore(s, x)
      }
    }
    if (document.readyState === 'complete') {
      l()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    } else if (w.attachEvent) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      w.attachEvent('onload', l)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      w.addEventListener('load', l, false)
    }
  }

  // https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
  // https://www.intercom.com/help/en/articles/179-send-custom-user-attributes-to-intercom
  const organization = user?.organization
  const company =
    organization === undefined
      ? undefined
      : {
          company_id: organization.id,
          created_at: new Date(organization.createdAt).getTime() / 1000,
          name: organization.name,
          // monthly_spend: 49,
          // plan: "Pro",
          // size: 85,
          // website: "http://example.com",
          // industry: "Manufacturing"
        }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-type-assertion,unicorn/prefer-global-this
  ;(window as any).Intercom('boot', {
    app_id: APP_ID,
    name,
    email,
    user_id: user?.id,
    role: user?.role, // roleはデフォルトのプロパティに存在しないが、任意のプロパティを追加することができる
    tags: user === undefined ? [] : [user.role],
    created_at: user === undefined ? undefined : new Date(user.createdAt).getTime() / 1000,
    company,
  })

  initializedKeys.push(cacheKey)
}

export const hideIntercom = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-type-assertion,unicorn/prefer-global-this
  if (!(window as any).Intercom) {
    return
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-type-assertion,unicorn/prefer-global-this
  ;(window as any).Intercom('update', {
    hide_default_launcher: false,
  })
}

export const showIntercom = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-type-assertion,unicorn/prefer-global-this
  if (!(window as any).Intercom) {
    return
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-type-assertion,unicorn/prefer-global-this
  ;(window as any).Intercom('update', {
    hide_default_launcher: true,
  })
}
