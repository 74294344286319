import { isTruthy } from '@salescore/buff-common'
import type { ViewFieldsFragment } from '@salescore/client-api'
import { getOrganizationIdFromPath, Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { CORE_CONSTANT } from '@salescore/core'
import { buildReadonlyHook, TimeWindowExecutor, useMessage } from '@salescore/frontend-common'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { useSheetPickedPresetName } from '../navigation/hooks'
import {
  configAtom,
  configSheetAtom,
  hasChangeToViewSchemaAtom,
  isSavingCurrentViewAtom,
  isSavingWithoutInteractionAtom,
  isUpdatedSheetPresetAtom,
  kpiConfigSelector,
  kpiFormAtom,
  kpiFormModifiedAtom,
  kpiPivotConfigSelector,
  kpiTimeSeriesConfigSelector,
  listQueryAtom,
  meAtom,
  queriesAtom,
  uiAtom,
  viewAbilityAtom,
  viewAtom,
  viewsContextAtom,
} from './atoms'
import { useViewMutation } from './mutations'

// initializer
export const useWatchView = ({ onSaveView }: { onSaveView?: (views: ViewFieldsFragment[]) => void }) => {
  const hasChangeToViewSchema = useRecoilValue(hasChangeToViewSchemaAtom)
  const [isSavingCurrentView, setIsSavingCurrentView] = useRecoilState(isSavingCurrentViewAtom)
  const [isSavingWithoutInteraction, setIsSavingWithoutInteraction] = useRecoilState(isSavingWithoutInteractionAtom)
  const [isUpdatedSheetPreset, setIsUpdatedSheetPreset] = useRecoilState(isUpdatedSheetPresetAtom)
  const [config, setConfig] = useRecoilState(configAtom)
  const [picked] = useSheetPickedPresetName()
  const mutation = useViewMutation()
  const currentView = useViewValue()
  const { isDrillDown } = useViewsContextValue()
  const message = useMessage()

  // シートの場合、現在選択されているプリセットがあれば、シートの設定に合わせて更新する
  const updateCurrentSheetPreset = () => {
    setConfig((previous) => {
      if (
        previous.type !== 'sheet' ||
        previous.presets === undefined ||
        previous.presets.length === 0 ||
        isUpdatedSheetPreset
      ) {
        return previous
      }
      return {
        ...previous,
        presets: previous.presets.map((preset) =>
          preset.name === picked
            ? {
                ...preset,
                tree: previous.tree,
                fields: previous.fields,
                filterTree: previous.filterTree,
                sorters: previous.sorters,
                meta: previous.meta,
              }
            : preset,
        ),
      }
    })
  }

  // ユーザーに対するメッセージやインタラクションなどを表示して保存する
  const saveWithInteraction = async () => {
    const view = await mutation.saveCurrentView()
    if (view !== undefined) {
      message.success(t(`設定を保存しました`))
      Posthog.track(POSTHOG_EVENTS.save_view_setting, {
        organization_id: getOrganizationIdFromPath(),
        viewId: view.id,
        config: view.config,
      })
      mutation.setHasChangeToViewSchema(false)
    }

    if (isTruthy(isDrillDown) || currentView.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)) {
      mutation.setHasChangeToViewSchema(false)
    }

    setIsSavingCurrentView(false)
    setIsUpdatedSheetPreset(false)
    if (onSaveView !== undefined) {
      onSaveView([view].compact())
    }
  }

  // ユーザーに対するメッセージやインタラクションなどを表示しないで保存する
  const saveWithoutInteraction = async () => {
    const view = await mutation.saveCurrentView()
    setIsSavingWithoutInteraction(false)
    setIsUpdatedSheetPreset(false)

    if (
      view !== undefined ||
      isTruthy(isDrillDown) ||
      currentView.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)
    ) {
      mutation.setHasChangeToViewSchema(false)
    }

    if (onSaveView !== undefined) {
      onSaveView([view].compact())
    }
  }

  // view(query)を保存する
  // queryをuseEffectで監視したいところだが、viewが切り替わった際に保存が走るなどのミスが不安なので、一旦別にしている。
  // TODO: この辺のロジックを整理したい
  const save = async () => {
    await (isSavingWithoutInteraction ? saveWithoutInteraction() : saveWithInteraction())
  }

  const saveExecutor = new TimeWindowExecutor(save, 1500)

  // eslint-disable-next-line complexity
  useEffect(() => {
    const isSaving = isSavingCurrentView || isSavingWithoutInteraction
    // 本当はwidthなどのアクションが実行されたトリガーで保存を実行したかったが、
    // 更新後のstateをうまくとってこれなかったので、いったんこのように迂回している
    // シート以外は、 `hasChangeToViewSchema===true` になったらビューの保存を始めている。
    // シートについては、ナビゲーションの「設定を保存」ボタンが押された段階でビューの保存を始めている。
    if ((config.type !== 'sheet' && hasChangeToViewSchema) || (config.type === 'sheet' && isSaving)) {
      if (config.type === 'sheet') {
        updateCurrentSheetPreset()
      }
      saveExecutor.queue([])
    }
  }, [hasChangeToViewSchema, isSavingCurrentView, isSavingWithoutInteraction])
}

// read,write
export const useViewConfigKpiPivot = () => useRecoilState(kpiPivotConfigSelector)
export const useViewConfigKpi = () => useRecoilState(kpiConfigSelector)
export const useViewConfigKpiTimeSeries = () => useRecoilState(kpiTimeSeriesConfigSelector)

export const useQueryValue = buildReadonlyHook(listQueryAtom)
export const useSetQueryState = () => useSetRecoilState(listQueryAtom)
export const useKpiFormAtom = () => useRecoilState(kpiFormAtom)

// readonly
export const useUiValue = buildReadonlyHook(uiAtom)
export const useQueriesValue = buildReadonlyHook(queriesAtom)
export const useViewConfigSheet = buildReadonlyHook(configSheetAtom)
export const useViewConfig = buildReadonlyHook(configAtom)
export const useMeValue = buildReadonlyHook(meAtom)
export const useViewValue = () => useRecoilValue(viewAtom)
export const useViewsContextValue = () => useRecoilValue(viewsContextAtom)
export const useViewAbilityValue = () => useRecoilValue(viewAbilityAtom)
export const useKpiFormModified = () => useRecoilValue(kpiFormModifiedAtom)
