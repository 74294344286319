import { useContextValue } from '../../recoil/models/propModels'
import { useCursorSelector } from '../../recoil/selectors/cursorSelector'
import { ContextMenu } from './ContextMenu'
import { CopyingCursorBox } from './CopyingCursorBox'
import { ExpandCursorBox } from './ExpandCursorBox'
import { InputCursorBox } from './InputCursorBox'
import { MainCursorBox } from './MainCursorBox'

// eslint-disable-next-line complexity
export function RSheetsCursorBox({ asFixed }: { asFixed: boolean }) {
  const options = useContextValue()
  const { cursor } = useCursorSelector()
  if (cursor === undefined) {
    return <></>
  }

  const fixedLeftColumnIndex = options.fixedLeftColumnIndex ?? 0
  const isFixedColumnCursor = cursor.main.columnIndex < fixedLeftColumnIndex
  // 固定カラム上には固定カラムのカーソルのみ、固定カラム外では固定カラム外のカーソルのみを表示したい（表示されるカーソルは常に1つ）
  // 例外的に、ExpandCursorBoxは固定カラム内外で分割して表示する
  if ((asFixed && !isFixedColumnCursor) || (!asFixed && isFixedColumnCursor)) {
    return (
      <div id="cursor-box-1">
        <ExpandCursorBox asFixed={asFixed} />
        <CopyingCursorBox asFixed={asFixed} />
      </div>
    )
  }

  if (cursor.editing?.isEditing === true) {
    return (
      <div id="cursor-box-2">
        <InputCursorBox />
      </div>
    )
  }

  return (
    <div id="cursor-box-3">
      <ExpandCursorBox asFixed={asFixed} />
      <CopyingCursorBox asFixed={asFixed} />
      <MainCursorBox />
      <ContextMenu />
    </div>
  )
}
