import { range } from '@salescore/buff-common'

import { PRESET_MODELS } from '../../../../constant/presetModels'
import type { ViewConfigField, ViewConfigKpi, ViewConfigSheet } from '../../../../schemas/view_config'
import { getNodeByName } from '../../../util/node'
import type { CompileContext } from '../../common'

// eslint-disable-next-line complexity
export function addSalescoreUserField(
  config: ViewConfigSheet,
  user: ViewConfigKpi['user'],
  context: CompileContext,
  options?: ViewConfigKpi['options'],
): ViewConfigSheet {
  if (user === undefined) {
    return config
  }
  if (config.tree === undefined) {
    // ありえないはず
    return config
  }

  // salescore_usersをjoinする
  const newTree = structuredClone(config.tree) // TODO
  const nodeWithUserProperty = getNodeByName(newTree, user.property.nodeName)?.node
  if (nodeWithUserProperty === undefined) {
    context.logs.error(`ユーザーフィールドのノードが見つかりませんでした。${user.property.nodeName}`)
    return config
  }

  const additionalFields: ViewConfigField[] = [
    ...range(1, 5).flatMap((depth): ViewConfigField[] => [
      {
        type: 'property',
        property: {
          nodeName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
          modelName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
          propertyName: `user_group_d${depth}_id`,
        },
      },
      {
        type: 'property',
        property: {
          nodeName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
          modelName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
          propertyName: `user_group_d${depth}_name`,
        },
      },
    ]),
    {
      type: 'property',
      property: {
        nodeName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
        modelName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
        propertyName: `id`,
      },
    },
    {
      type: 'property',
      property: {
        nodeName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
        modelName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
        propertyName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME_PROPERTY_NAME,
      },
    },
    {
      type: 'property',
      property: {
        nodeName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
        modelName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
        propertyName: `user_group_id`,
      },
    },
  ]

  //
  // 「ダッシュボードからセルをクリックし、ドリルダウンしてKPIのシートを表示」のコンテキストでは、
  // ドリルダウン前のピボットのディメンションをそのまま表示する仕様としている
  // 例えば「ユーザー」でピボットしていたら、ユーザーのカラムをシートの項目として表示している。
  // 上記で生成したfieldsは、いったん多めに生成してあるので、不要なものをこの後の工程で削除する
  //
  // いったん、削除のロジックが不要な時は早期returnする。ドリルダウンしてKPIシートを表示するとき以外は不要
  const previousDimmensions = context.additionalConfig?.previousDimmensions
  if (previousDimmensions === undefined) {
    return {
      ...config,
      fields: [...(config.fields ?? []), ...additionalFields],
    }
  }

  const previousPropertyDimensions = (previousDimmensions ?? [])
    .map((d) => (d.type === 'property' ? d : undefined))
    .compact()
  const visibleConfigFields: ViewConfigField[] = additionalFields.filter((field) => {
    if (field.property.propertyName.endsWith('id')) {
      // idはかならずfieldに残す。これは、idは絞り込みで使われることがあるため
      // idはそもそも表示されないロジックにしているので問題ない（TODO: このロジックが分かりづらい）
      // id値を弾くロジックについては、compileSheetViewConfigを参照
      return true
    }

    // id以外の要素について
    if (options?.dimensionFieldsVisibility !== true) {
      // KPI設定で軸の表示をtrueにしていなければ、常に軸は表示しない
      return false
    }
    // previousDimensions側で定義があるときだけ表示する
    // 「階層1のグループ」を軸に指定している時、軸のpropertyNameは user_group_d1_id だが、実際に一覧で表示したいのは user_group_d1_name なので
    // この変換も行う
    // またユーザーについても、「ユーザー」を軸にしているときのpropertyNameはidだが、表示したいのはname
    return previousPropertyDimensions.some(
      (d) =>
        d.property.nodeName === field.property.nodeName &&
        d.property.modelName === field.property.modelName &&
        d.property.propertyName.replace(/id$/, '') === field.property.propertyName.replace(/name$/, ''),
    )
  })

  return {
    ...config,
    fields: [...visibleConfigFields, ...(config.fields ?? [])],
  }
}
