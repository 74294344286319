import { ApolloProvider } from '@apollo/client'
import { type IdToken, useAuth0 } from '@auth0/auth0-react'
import { isPresent } from '@salescore/buff-common'
import { CONSTANT, routes, Tracker, useApolloClient, useSalescoreAuth } from '@salescore/client-base'
import { Loading } from '@salescore/client-common'
import { logger, useRedirect } from '@salescore/frontend-common'
import { useEffect, useState } from 'react'

const Body = ({ token, children }: { token: string; children: JSX.Element }) => {
  const client = useApolloClient(token)

  if (client === undefined) {
    return <></>
  }

  Tracker.shared().setToken(token)

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

const AuthenticatedAuth0ApolloProvider = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0()
  const redirect = useRedirect()
  const [idToken, setIdToken] = useState<IdToken>()

  useEffect(() => {
    if (idToken === undefined) {
      return
    }

    localStorage.setItem(
      CONSTANT.localStorageKey.PARTIAL_AUTH0_RESPONSE,
      JSON.stringify({
        email: idToken.email,
        email_verified: idToken.email_verified,
        exp: idToken.exp,
        updated_at: idToken.updated_at,
        family_name: idToken.family_name,
        given_name: idToken.given_name,
        name: idToken.name,
        nickname: idToken.nickname,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        sub: (idToken as unknown as { sub: string }).sub,
      }),
    )
  }, [idToken])

  useEffect(() => {
    const f = async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently()
        const idToken = await getIdTokenClaims()
        setIdToken(idToken)
      }
    }
    void f()
  }, [isAuthenticated, isLoading])

  if (isLoading) {
    return <Loading message={`認証中...`} />
  }

  if (!isAuthenticated) {
    logger.debug(`Authentication failed on Auth0. Redirect to /sign_in from ${location.href}`)

    redirect(routes.signInPath())
    return <Loading message={`認証できませんでした。リダイレクト中...`} />
  }

  if (idToken?.__raw === undefined) {
    return <Loading message={`アクセストークンの取得中...`} />
  }

  // TODO: 対応方法が分からなかった

  return <Body token={idToken.__raw}>{children}</Body>
}

export const AuthenticatedApolloProvider = ({ children }: { children: JSX.Element }) => {
  const { token } = useSalescoreAuth()
  if (isPresent(token)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-type-assertion
    return <Body token={token}>{children as any}</Body>
  }

  return <AuthenticatedAuth0ApolloProvider>{children}</AuthenticatedAuth0ApolloProvider>
}
