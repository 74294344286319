import type { Operation } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { logger, LoggerEvents } from '@salescore/frontend-common'

import { notifyBugsnag } from '../bugsnag'

type ErrorHandler = Parameters<typeof onError>[0]
type argument = Parameters<ErrorHandler>[0]
type GraphQLErrors = Exclude<argument['graphQLErrors'], undefined>

class ApolloClientNetworkError extends Error {
  public constructor(message: string) {
    super(message)
    this.name = 'ApolloClientNetworkError'
  }
}

class ApolloClientBadUserInputError extends Error {
  public constructor(message: string) {
    super(message)
    this.name = 'ApolloClientBadUserInputError'
  }
}

class ApolloClientUnauthenticatedError extends Error {
  public constructor(message: string) {
    super(message)
    this.name = 'ApolloClientUnauthenticatedError'
  }
}

class ApolloClientForbiddenError extends Error {
  public constructor(message: string) {
    super(message)
    this.name = 'ApolloClientForbiddenError'
  }
}

class ApolloClientInternalServerErrorError extends Error {
  public constructor(message: string) {
    super(message)
    this.name = 'ApolloClientInternalServerErrorError'
  }
}

//
// 前提として、サーバー側でも正しくエラー通知が行われており同等の通知が飛んでいるが、
// より確実にエラーを検知するため、クライアントはクライアントで丁寧にハンドリングして通知を行っておく
//
function notifyGraphQLError(error: GraphQLErrors[0], operation: Operation): void {
  const metadata = getMetadata(operation)

  switch (error.extensions?.code) {
    case 'BAD_USER_INPUT': {
      notifyBugsnag({
        error: new ApolloClientBadUserInputError(error.message),
        metadata,
        severity: 'info',
      })
      return
    }
    case 'UNAUTHENTICATED': {
      notifyBugsnag({
        error: new ApolloClientUnauthenticatedError(error.message),
        metadata,
        severity: 'info',
      })
      return
    }
    case 'FORBIDDEN': {
      notifyBugsnag({
        error: new ApolloClientForbiddenError(error.message),
        metadata,
        severity: 'info',
      })
      return
    }
  }

  notifyBugsnag({
    error: new ApolloClientInternalServerErrorError(error.message),
    metadata,
    severity: 'error',
  })
}

export const errorLink = onError((argument) => {
  const { graphQLErrors, networkError, operation } = argument

  if (graphQLErrors != null) {
    for (const error of graphQLErrors) {
      notifyGraphQLError(error, operation)
    }
  }
  if (networkError != null) {
    notifyBugsnag({
      error: new ApolloClientNetworkError(`${networkError.name}: ${networkError.message}`),
      metadata: getMetadata(operation),
    })
    logger.error(LoggerEvents.APOLLO_CLIENT, `${networkError.name}: ${networkError.message}`)
  }
})

function getMetadata(operation: Operation) {
  return {
    key: 'graphql',
    value: {
      operationName: operation.operationName,
      variables: operation.variables,
      // query: operation.query, // queryを見るとnodeになっており分かりづらい。文字列のクエリが見たいが、相当する情報が見つからなかった。
    },
  }
}
