import { CalendarOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { dateUtil, isNull } from '@salescore/buff-common'
import type { PropertyTypeEnum } from '@salescore/client-api'
import { getOrganizationIdFromPath, HUB_PROPERTY_TYPE_ICONS } from '@salescore/client-common'
import {
  CORE_CONSTANT,
  createDimensionFieldName,
  generateLabelSqlFieldName,
  timeframeSchema,
  type ViewConfigKpiParameter,
  type ViewKpiAppearance,
  type ViewQueryField,
  type ViewQueryRecordNode,
  type ViewQueryResultUiPivotColumn,
  type ViewTimeframe,
  type ViewUiKpi,
  type ViewUiKpiPivot,
  type ViewUiKpiPivotColumn,
} from '@salescore/core'
import type { OrganizationSetting } from '@salescore/features'
import dayjs, { type Dayjs } from 'dayjs'
import { t } from 'i18next'
import { selector } from 'recoil'

import type { RSheetColumn } from '../../rsheet/types'
import { RSheetsStyle } from '../../rsheet/util/RSheetsStyle'
import { flatNodes } from '../../state/nodeUtil'
import { kpiParameterAtom } from '../navigation/atoms'
import { additionalConfigAtom, viewQueryResultAtom } from '../records/atoms'
import { configAtom, meAtom, uiAtom, viewAtom } from '../view/atoms'

const COLUMN_WIDTH = 100

const moreColumnsOrganizationIds = [
  '978f75de-0eac-463f-b42d-db09e637f459', // Irodas 様
]
// 列の仮想化ができておらず、多すぎるとブラウザが落ちるため制限する
const MAX_COLUMN_KEYS = moreColumnsOrganizationIds.includes(getOrganizationIdFromPath()) ? 100 : 60

export const pivotColumnsSelector = selector({
  key: `view/pivotColumnsSelector`,
  // eslint-disable-next-line complexity
  get({ get }) {
    const empty = {
      columnKeys: [],
      systemColumns: [],
      rowColumns: [],
      columnColumns: [],
      columns: [],
      isExceededColumnKeysLimit: false,
    }
    // 本当はcomponentを引数に受け取るhooksにしたかったが、
    // selectorとして定義していくつかの箇所で使いまわしたいユースケースがあったため、諦めた。
    const ui = get(uiAtom)
    const view = get(viewAtom)
    const config = get(configAtom)
    const additionalConfig = get(additionalConfigAtom)
    const me = get(meAtom)
    const component = ui[0]
    if (component?.type !== 'KpiPivotSheet' && component?.type !== 'KpiSheet') {
      return empty
    }
    const viewQueryResult = get(viewQueryResultAtom)
    const kpiParameter = get(kpiParameterAtom)
    const query = viewQueryResult?.viewQuery
    if (viewQueryResult?.ui?.pivotColumns === undefined || query?.type !== 'multiTablePivot') {
      // ありえないはず
      return empty
    }

    // ない方が理想的な気がするが、cursor側のロジックで行番号があることを前提にしてしまっているため、一旦追加しておく
    const systemColumns: Array<RSheetColumn<ViewQueryRecordNode>> = [
      {
        index: 0,
        configIndex: undefined,
        isSystem: true,
        node: query.tree,
        key: `rowIndex`,
        title: '',
        width: 28,
        type: `rowIndex`,
        value(record: ViewQueryRecordNode) {
          // empty
        },
      },
    ]

    const nodes = flatNodes(query.tree)
    // eslint-disable-next-line complexity
    const rowColumns = component.pivot.rows.map((row, index): RSheetColumn<ViewQueryRecordNode> | undefined => {
      const node = nodes[index] ?? nodes.first() // 集計値でソートするとき、nodes[i]が空になる。ここのロジックを整理できていないが、一旦これで動くのでok
      if (node === undefined) {
        return undefined
      }
      const fieldName = createDimensionFieldName(index + 1)
      const field: ViewQueryField = {
        name: fieldName,
        nodePath: node.path,
        read: {
          sql: ``,
        },
        meta: {
          label: ``,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          fieldType: row.fieldType as 'string', // TODO
          dependedPropertyNamesWithStreamName: [],
        },
      }
      const ui = component.appearance?.rows?.find((x) => x.key === row.key)
      const dimensionFilter = (additionalConfig.kpiParameter?.dimensionFilterLeafs ?? []).find(
        (x) => x.type === 'dimension' && x.dimension.key === row.key,
      )
      const configIndex = kpiParameter.pivot.rows.findIndex((x) => x.key === row.key)

      return {
        index: index + systemColumns.length,
        configIndex: configIndex === -1 ? undefined : configIndex, // additionalConfig.kpiParameter.pivot.rows におけるindex
        type: row.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key ? `rowKpi` : row.fieldType,
        field,
        metaType: row.fieldMetaType,
        selectOptions: row.selectOptions,
        node,
        key: `field-${fieldName}`,
        title: row.label,
        // smallTitle: `(行)`,
        // nodeBlock: {
        //   nodePath: [`row`],
        //   labels: [''],
        //   colors: [`blue`],
        // },
        isFiltered: dimensionFilter !== undefined,
        color: `gray`,
        width: ui?.width ?? COLUMN_WIDTH,
        readonly: true,
        icon: getRowIcon(row),
        value(recordNode: ViewQueryRecordNode) {
          // 使ってないので消したい
        },
        label(recordNode: ViewQueryRecordNode) {
          return recordNode.attributes[generateLabelSqlFieldName(fieldName)]
        },
      }
    })

    const isKpiColumn =
      kpiParameter.pivot.columns.length === 1 &&
      kpiParameter.pivot.columns[0]!.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key
    const kpis = component.type === 'KpiPivotSheet' ? component.kpis : []
    const kpiValues = viewQueryResult.ui.pivotColumns.flatMap((col) =>
      col.columnFieldName === CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME ? col.valuesWithLabel : [],
    )
    const grouped = kpis
      .groupBy((x) => x.groupName ?? CORE_CONSTANT.KPI_GROUP_DEFAULT_NAME)
      .transformValues((vs) =>
        vs.map((x) => ({
          value: x.id,
          label: x.label,
          isStreaming: kpiValues.find((v) => v.value === x.id)?.isStreaming,
        })),
      ).data
    const groups = kpis.map((x) => x.groupName ?? CORE_CONSTANT.KPI_GROUP_DEFAULT_NAME).unique() // KPIの順番をグループの順番とする
    const kpiColumnIndex = component.pivot.columns.findIndex((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key)
    const kpiGroupColumnIndex = component.pivot.columns.findIndex(
      (x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_DIMENSION().key,
    )
    const isKpiWithGroup = kpiColumnIndex === kpiGroupColumnIndex + 1 && kpiGroupColumnIndex !== -1
    // NOTE: cartesianProductは、空配列の場合は空配列を返すので、columnがない場合も1つはcolumnColumnsが作成される
    // NOTE: 2022/12 columnKeysは基本的にサーバー側の実行結果(viewQueryResult)より計算するが、KPIの並び替えをUIから行ったときに都度通信が走ると重く、
    //               また不具合により何故かrefetchが走らないこともあり、KPI列単体の表示の時は、component.kpisの情報を使ってcolumnKeysを求めてしまい、表示を高速化する
    const columnKeys = isKpiColumn
      ? kpis.map((kpi) => [{ value: kpi.id, label: kpi.label, isStreaming: false }])
      : cartesianProductForKpi(
          viewQueryResult.ui.pivotColumns,
          isKpiWithGroup ? kpiColumnIndex : -1,
          isKpiWithGroup ? kpiGroupColumnIndex : -1,
          groups,
          grouped,
          me.organization.setting,
        )
    const columnColumns = columnKeys
      .slice(0, MAX_COLUMN_KEYS)
      // eslint-disable-next-line complexity
      .map((valuesWithLabelChunk, index): RSheetColumn<ViewQueryRecordNode> | undefined => {
        // const columnLabels = columnValues.map((x) => forceString(x)) // TODO: referenceの対応
        const columnLabels = valuesWithLabelChunk.map((x) => x.label ?? x.value)
        const columnValues = valuesWithLabelChunk.map((x) => x.value)
        const node = nodes.last()
        if (node === undefined) {
          return undefined
        }
        const fieldName = JSON.stringify([...columnValues, 'actual'])
        const field: ViewQueryField = {
          name: fieldName,
          nodePath: node.path,
          read: {
            sql: ``,
          },
          meta: {
            label: ``,
            fieldType: `string`,
            dependedPropertyNamesWithStreamName: [],
          },
        }
        const kpiView = component.type === 'KpiSheet' ? view : undefined

        const sorter = kpiParameter.sorter?.columnKeys?.find((x) => x.key === field.name)
        const lastColumn = component.pivot.columns.last()
        const lastColumnValue = columnValues.last()
        const ui = component.appearance?.columns
          ?.find((x) => x.key === lastColumn?.key)
          ?.keys.find((x) => x.value === lastColumnValue)
        const isTotalColumn = columnValues.includes(CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING)
        const isStreaming = valuesWithLabelChunk.some((chunk) => chunk.isStreaming)

        // ブロックの判定
        const nextColumnKey = columnKeys[index + 1]
        const isNextSameBlock =
          nextColumnKey !== undefined && valuesWithLabelChunk.slice(0, -1).isEqual(nextColumnKey.slice(0, -1))

        // view.config.type === 'kpi' の時 configIndex=undefined のため、 KPI ピボットでは集計列の移動はできなくなっている
        const configIndex =
          lastColumnValue !== undefined && config.type === 'kpiPivot'
            ? config.kpis.findIndex((x) => x.viewId === lastColumnValue)
            : undefined

        return {
          index: index + systemColumns.length + rowColumns.length,
          configIndex: configIndex === -1 ? undefined : configIndex, // config.kpis におけるindex
          type: 'kpi',
          field,
          node,
          key: fieldName,
          title: columnLabels.last() ?? kpiView?.name ?? '値', // labelsがないとき=列を指定していない時。KPIビューの時はKPI名(=ビュー名)を表示する。
          smallTitle: component.pivot.columns.last()?.label,
          nodeBlock: {
            nodePath: columnValues.slice(0, -1),
            labels: columnLabels.slice(0, -1),
            colors: columnLabels.map((_x) => `gray` as const).slice(0, -1),
            smallLabels: component.pivot.columns.map((x) => x.label),
            helpTexts: columnValues
              .map((_value, index) => getColumnHelpText(columnValues, index, component, kpiParameter))
              .slice(0, -1),
          },
          color: `gray`,
          icon: getColumnIcon(columnValues, columnValues.length - 1, component, kpiParameter),
          helpText: getColumnHelpText(columnValues, columnValues.length - 1, component, kpiParameter),
          width: ui?.width ?? COLUMN_WIDTH,
          readonly: true,
          sortState: sorter?.order,
          cellBackgroundColor: isTotalColumn ? RSheetsStyle.color.kpiTotalCell : undefined,
          kpiBorderRight: !isNextSameBlock,
          isStreaming,
          value(recordNode: ViewQueryRecordNode) {
            // TODO: 消し去りたい
          },
          label(recordNode: ViewQueryRecordNode) {
            return recordNode.attributes[generateLabelSqlFieldName(fieldName)]
          },
        }
      })

    return {
      columnKeys,
      isExceededColumnKeysLimit: columnKeys.length > MAX_COLUMN_KEYS,
      systemColumns,
      rowColumns,
      columnColumns,
      columns: [...systemColumns, ...rowColumns, ...columnColumns].compact(),
    }
  },
})

// eslint-disable-next-line complexity
function getColumnIcon(
  columnValues: string[],
  columnIndex: number,
  component: ViewUiKpi | ViewUiKpiPivot,
  kpiParameter: ViewConfigKpiParameter,
): string | JSX.Element | undefined {
  const column = kpiParameter.pivot.columns[columnIndex]
  // const column = component.pivot.columns[columnIndex] // こっちのfieldTypeはkpiなので注意
  if (column === undefined) {
    return
  }
  if (column.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key && component.type === 'KpiPivotSheet') {
    const columnValue = columnValues[columnIndex]
    if (columnValue === undefined) {
      return
    }
    return component.kpis.find((x) => x.id === columnValue)?.icon
  }
  // kpiじゃない場合は、型情報に合わせてiconを設定
  return HUB_PROPERTY_TYPE_ICONS[column.fieldType as PropertyTypeEnum]
}

const KPI_ACHIEVEMENT_STATUS_TARGET_VALUE_ENUM = {
  averageProgress: t(`目標の標準進捗`),
  goal: t(`目標`),
  zero: t(`ゼロ`),
}

// eslint-disable-next-line complexity
function getColumnHelpText(
  columnValues: string[],
  columnIndex: number,
  component: ViewUiKpi | ViewUiKpiPivot,
  kpiParameter: ViewConfigKpiParameter,
): string | undefined {
  const column = kpiParameter.pivot.columns[columnIndex]
  if (column === undefined) {
    return
  }
  // KPIのとき
  if (column.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key && component.type === 'KpiPivotSheet') {
    const columnValue = columnValues[columnIndex]
    if (columnValue === undefined) {
      return
    }
    const kpi = component.kpis.find((x) => x.id === columnValue)
    if (kpi === undefined) {
      return undefined
    }

    return getKpiHelpText(kpi)
  }
  return undefined
}

export function getKpiHelpText(kpi: ViewKpiAppearance): string | undefined {
  if (kpi.description !== undefined) {
    return kpi.description
  }

  const lessthan = kpi.achievementStatusCalculationType === `greaterThanOrEqual` ? t(`以上`) : t(`以下`)
  const valueType = KPI_ACHIEVEMENT_STATUS_TARGET_VALUE_ENUM[kpi.achievementStatusTargetValueType ?? 'averageProgress']

  return t(`実績が{{valueType}}{{lessthan}}のときに緑色になります。`, { valueType, lessthan })
}

function getRowIcon(row: ViewUiKpiPivotColumn): string | JSX.Element | undefined {
  switch (row.fieldType) {
    case 'user': {
      return <UserOutlined />
    }
    case 'date': {
      return <CalendarOutlined />
    }
    default: {
      break
    }
  }
  if (row.key.startsWith(`p_salescore_user_groups_d`)) {
    // TODO
    return <TeamOutlined />
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  return HUB_PROPERTY_TYPE_ICONS[row.fieldType as PropertyTypeEnum]
}

interface ColumnKey {
  value: string
  label?: string
  isStreaming?: boolean
}

type ColumnKeyWithFieldName = ColumnKey & { columnFieldName: string }

// eslint-disable-next-line @typescript-eslint/max-params
function cartesianProductForKpi(
  array: ViewQueryResultUiPivotColumn[],
  kpiIndex: number,
  kpiGroupIndex: number,
  kpiGroups: string[],
  kpiGroupToKpis: Record<string, ColumnKey[]>,
  settings: OrganizationSetting,
): ColumnKey[][] {
  return array
    .reduce<ColumnKeyWithFieldName[][]>(
      (a, b, index) =>
        // eslint-disable-next-line complexity
        a.flatMap((x) => {
          if (index === kpiGroupIndex) {
            return kpiGroups.map((kpiGroup) =>
              // eslint-disable-next-line unicorn/prefer-spread
              x.concat({ value: kpiGroup, columnFieldName: b.columnFieldName, isStreaming: false }),
            )
          }
          if (index === kpiIndex) {
            const kpiGroup = x.last()?.value ?? ''
            const kpis = kpiGroupToKpis[kpiGroup] ?? []
            return kpis.map((y) =>
              // eslint-disable-next-line unicorn/prefer-spread
              x.concat({ ...y, columnFieldName: b.columnFieldName, isStreaming: y.isStreaming }),
            )
          }
          return b.valuesWithLabel
            .filter((y) => {
              // KPI日付の列の場合、親列の日付範囲に含まれるかどうかを判定する
              if (!isKpiDateColumn(b.columnFieldName)) {
                return true
              }
              // KPI日付の値が空の場合は表示しない
              if (y.value === '') {
                return false
              }
              return isDisplayColumn({
                parentColumns: x,
                childColumn: { ...y, columnFieldName: b.columnFieldName },
                settings,
              })
            })
            .map((y) =>
              // eslint-disable-next-line unicorn/prefer-spread
              x.concat({ ...y, columnFieldName: b.columnFieldName, isStreaming: y.isStreaming }),
            )
        }),
      [[]],
    )
    .map((x) => x.map((y) => ({ value: y.value, label: y.label, isStreaming: y.isStreaming })))
}

function isDisplayColumn({
  childColumn,
  parentColumns,
  settings,
}: {
  childColumn: ColumnKeyWithFieldName
  parentColumns: ColumnKeyWithFieldName[]
  settings: OrganizationSetting
}) {
  const parentKpiDateColumns = getParentKpiDateColumns(parentColumns)
  return parentKpiDateColumns
    .filter((x) => isRequireDateRangeCheck({ parentColumn: x, childColumn }))
    .every((x) => checkDateRange({ parentColumn: x, childColumn, settings }))
}

function isKpiDateColumn(columnFieldName: string) {
  return columnFieldName.endsWith(CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION().key)
}

function getParentKpiDateColumns(parentColumns: ColumnKeyWithFieldName[]) {
  return parentColumns.filter((x) => isKpiDateColumn(x.columnFieldName))
}

function extractTimeframe(columnFieldName: string) {
  const timeframe = columnFieldName.replace(`_${CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION().key}`, '')
  const parsed = timeframeSchema.safeParse(timeframe)
  if (parsed.success) {
    return parsed.data
  }
}

// eslint-disable-next-line complexity
function isRequireDateRangeCheck({
  parentColumn,
  childColumn,
}: {
  parentColumn: ColumnKeyWithFieldName
  childColumn: ColumnKeyWithFieldName
}) {
  const { weekday, hour } = timeframeSchema.Enum
  const parentTimeframe = extractTimeframe(parentColumn.columnFieldName)
  const childTimeframe = extractTimeframe(childColumn.columnFieldName)
  // ありえないはずだが、一応
  if (isNull(parentTimeframe) || isNull(childTimeframe)) {
    return false
  }
  // 子列が小計列の場合は、全ての場合で表示する
  if (childColumn.value === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING) {
    return false
  }
  // 子列が曜日列・時間列の場合は、全ての時間範囲に含まれるので、必ず表示する
  if (childTimeframe === weekday || childTimeframe === hour) {
    return false
  }
  // それ以外は、親列の時間範囲に含まれるかどうかを判定する
  return true
}

// eslint-disable-next-line complexity
function checkDateRange({
  parentColumn,
  childColumn,
  settings,
}: {
  parentColumn: ColumnKeyWithFieldName
  childColumn: ColumnKeyWithFieldName
  settings: OrganizationSetting
}) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { year, fiscal_year, fiscal_half, fiscal_quarter, weekday, day } = timeframeSchema.Enum
  const parentTimeframe = extractTimeframe(parentColumn.columnFieldName)
  const childTimeframe = extractTimeframe(childColumn.columnFieldName)
  if (isNull(parentTimeframe) || isNull(childTimeframe)) {
    return false
  }

  // 親列が曜日かつ子列が日の場合のみ、曜日の比較を行う
  if (parentTimeframe === weekday && childTimeframe === day) {
    return compareWeekday({ parentColumn, childColumn })
  }

  const { accountClosingMonth } = settings
  const parentDateRange = getDateRange({ column: parentColumn, accountClosingMonth })
  const childDateRange = getDateRange({ column: childColumn, accountClosingMonth })
  if (isNull(parentDateRange) || isNull(childDateRange)) {
    return false
  }

  // 親列が年かつ子列が会計年・半期・四半期の場合は、期間が重なっているかどうかを判定する
  const fiscalTimeframes: ViewTimeframe[] = [fiscal_half, fiscal_quarter, fiscal_year]
  if (parentTimeframe === year && fiscalTimeframes.includes(childTimeframe)) {
    return isDateRangeOverlapped(parentDateRange, childDateRange)
  }

  return isDateRangeContained(parentDateRange, childDateRange)
}

function getDateRange({
  column,
  accountClosingMonth,
}: {
  column: ColumnKeyWithFieldName
  accountClosingMonth: number
}) {
  const { columnFieldName, value } = column
  const timeframe = extractTimeframe(columnFieldName)

  switch (timeframe) {
    case timeframeSchema.Enum.year:
    case timeframeSchema.Enum.month:
    case timeframeSchema.Enum.week:
    case timeframeSchema.Enum.day: {
      return dateUtil.parse(value)
    }
    case timeframeSchema.Enum.fiscal_quarter:
    case timeframeSchema.Enum.fiscal_half: {
      return dateUtil.parse(value, { accountClosingMonth })
    }
    case timeframeSchema.Enum.fiscal_year: {
      return dateUtil.parse(value, { accountClosingMonth, timeframe: 'fiscal_year' })
    }
    default: {
      break
    }
  }
}

function isDateRangeOverlapped(
  parentDateRange: { startAt: Dayjs; endAt: Dayjs },
  childDateRange: { startAt: Dayjs; endAt: Dayjs },
) {
  return (
    dateUtil.compare(parentDateRange.startAt, childDateRange.endAt, '<=') &&
    dateUtil.compare(parentDateRange.endAt, childDateRange.startAt, '>=')
  )
}

function isDateRangeContained(
  parentDateRange: { startAt: Dayjs; endAt: Dayjs },
  childDateRange: { startAt: Dayjs; endAt: Dayjs },
) {
  return (
    dateUtil.compare(parentDateRange.startAt, childDateRange.startAt, '<=') &&
    dateUtil.compare(parentDateRange.endAt, childDateRange.endAt, '>=')
  )
}

function compareWeekday({
  parentColumn,
  childColumn,
}: {
  parentColumn: ColumnKeyWithFieldName
  childColumn: ColumnKeyWithFieldName
}) {
  const parentWeekday = parentColumn.value.split('_')[0] // d_dddの形を期待 (ex: 2_Mon)
  const childWeekday = dayjs(childColumn.value).day()
  // parentWeekdayはPostgresのTO_CHAR(x, 'D')の結果と同じなので、1~7の範囲になる
  // dayjsのday()は0~6の範囲なので、+1して比較する
  return parentWeekday === `${childWeekday + 1}`
}
