//
// 引数をDateに変換して返す関数。
// 引数がDateのとき、そのまま返す。
// 引数が文字列のとき、RFCに沿った記法であればDateにする。例外的に、2022/01/01の記法を許す。
// それ以外の時はnullを返す
//

export function normalizeDate(value: unknown): Date | null {
  if (value instanceof Date) {
    return value
  }

  if (typeof value === 'string' && validateDate(value)) {
    // RFCにそろえるため/を-に変更し、桁数が足りない場合は0詰めする
    const replaced = value.replaceAll('/', '-').replace(
      /^(\d{4})-(\d{1,2})-(\d{1,2})$/,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      (_, year, month, day) => `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`,
    )
    return new Date(replaced)
  }

  return null
}

// eslint-disable-next-line complexity
export function validateDate(value: unknown): boolean {
  if (value instanceof Date) {
    return true
  }

  if (typeof value !== 'string') {
    return false
  }

  // 元のvalidatorが、ISO記法をサポートしていなかったので変更する。また0を0?にした。
  // eslint-disable-next-line regexp/no-dupe-disjunctions
  const RFC_3339_REGEX = /^(\d{4}-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01]))/
  // const RFC_3339_REGEX = /^(\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))$/

  // RFCにそろえるため、/を-に変更
  const replaced = value.replaceAll('/', '-')

  if (!RFC_3339_REGEX.test(replaced)) {
    return false
  }

  // Verify the correct number of days for
  // the month contained in the date-string.
  const year = Number(replaced.slice(0, 4))
  const month = Number(replaced.slice(5, 7))
  const day = Number(replaced.slice(8, 10))

  switch (month) {
    case 2: {
      // February
      if (leapYear(year) && day > 29) {
        return false
      }
      if (!leapYear(year) && day > 28) {
        return false
      }
      return true
    }
    case 4: // April
    case 6: // June
    case 9: // September
    case 11: {
      // November
      if (day > 30) {
        return false
      }
      break
    }
  }

  return true
}

function leapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}
