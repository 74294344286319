import { EditOutlined } from '@ant-design/icons'
import { isPresent } from '@salescore/buff-common'
import { flatNodes, type ViewConfigSheet } from '@salescore/core'
import { SqlSyntaxHighlighter, useModal } from '@salescore/frontend-common'
import { Button, Form, Modal } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { useEffect } from 'react'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'
import { MeasureCustomSqlEditor } from '../../../../rsheet/components/footer/MeasureCustomSqlEditor'

// eslint-disable-next-line complexity
export function KpiFormMeasureCustomSqlEditor() {
  const { setForm, form, sheet, currentSheet, propertiesForCurrentMeasure } = useKpiFormSelectors()
  const modal = useModal()
  const { measure } = form
  const [formInstance] = useForm()
  useEffect(() => {
    if (measure?.type === 'kpiCustom' && !isPresent(measure.sql)) {
      modal.showModal()
    }
  }, [measure?.type])
  if (measure === undefined || measure.type !== 'kpiCustom') {
    return <></>
  }

  return (
    <>
      <div>
        <SqlSyntaxHighlighter sql={measure.sql ?? ''} onClick={modal.showModal} />
        <div className="flex w-full flex-row-reverse">
          <Button type="text" icon={<EditOutlined />} className="text-blue-500" onClick={modal.showModal}>
            {t(`SQLを編集`)}
          </Button>
        </div>
      </div>
      <Modal
        width={'60%'}
        open={modal.isModalVisible}
        destroyOnClose
        onOk={() => {
          formInstance.submit()
        }}
        onCancel={modal.hideModal}
      >
        <Form<{ sql: string }>
          form={formInstance}
          initialValues={{ sql: measure.sql ?? `COUNT(*)` }}
          onFinish={(value) => {
            // TODO: SQLが正しいかチェックしたい
            setForm((oldValue) => ({
              ...oldValue,
              measure: {
                type: 'kpiCustom',
                sql: isPresent(value.sql) ? value.sql : undefined,
                dependentNodeNames: getDependentNodeNames(value.sql ?? ``, sheet.config.tree),
              },
            }))
            modal.hideModal()
          }}
        >
          <MeasureCustomSqlEditor sheetConfig={currentSheet} />
        </Form>
      </Modal>
    </>
  )
}

function getDependentNodeNames(sql: string, configTree: ViewConfigSheet['tree']): string[] {
  if (configTree === undefined) {
    // ありえないはず
    return []
  }
  const nodes = flatNodes(configTree)
  const nodeNames = nodes.map((x) => x.name)
  return nodeNames.filter((nodeName) => sql.includes(nodeName)) // 「名前が含まれてれば使っている」という雑な計算を行う
}
