import { isSome, isTruthy } from '@salescore/buff-common'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { getPeriods, usePeriod } from '@salescore/client-common'
import {
  type GoalConfigFragment,
  goalConfigSchema,
  type KpiFragment,
  nodePropertyNameSchema,
  type ViewConfigDimension,
  viewConfigKpiSchema,
  type ViewConfigKpiTimeSeries,
  viewConfigKpiTimeSeriesSchema,
  type ViewConfigSheetExtraPeriod,
  type ViewUIKpiTimeSeries,
  type WaterfallDimension,
  waterfallDimensionSchema,
} from '@salescore/core'
import type { Period } from '@salescore/features'
import { message } from 'antd'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { v4 as uuid } from 'uuid'
import { z } from 'zod'

import { chartDesignSettingsAtom, riDisplayFormatAtom } from '../../../recoil/navigation/atoms'
import { riFormAtom, viewAtom } from '../../../recoil/view/atoms'
import { useMeValue, useUiValue, useViewsContextValue, useViewValue } from '../../../recoil/view/hooks'
import { setConfigWithoutChangeMutation } from '../../../recoil/view/mutations/setConfigMutation'
import { useKpiFormSelectors } from '../../../recoil/view/selectors/kpiFormSelector'
import type { TimeSpan } from './Form/TimeSpanFormInput'

export function useKpiTimeSeriesConfig() {
  const view = useViewValue()
  if (view.config.type !== 'kpiTimeSeries') {
    throw new Error("view.config.type is not 'kpiTimeSeries'")
  }
  return view.config
}

export function useUpdateKpiTimeSeriesView() {
  const [view, setView] = useRecoilState(viewAtom)
  const setConfigWithoutChange = useSetRecoilState(setConfigWithoutChangeMutation)
  const { updateView } = useViewsContextValue()
  const { removeAbsolutePeriod } = usePeriod()
  const setRiForm = useSetRecoilState(riFormAtom)

  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line complexity
  async function updateKpiTimeSeriesView({
    configForm,
    shouldUseExistingGoalConfigFragment = true,
    savedMessage,
  }: {
    configForm: UseKpiTimeSeriesConfigFormReturn['configForm']
    shouldUseExistingGoalConfigFragment?: boolean
    savedMessage?: string
  }): Promise<void> {
    if (loading) {
      return
    }

    try {
      setLoading(true)

      // カスタム以外の期間のstartAt,endAtを削除する
      const configForSave = {
        ...configForm,
        period: removeAbsolutePeriod(configForm.period),
        filters: isSome(configForm.filters)
          ? {
              ...configForm.filters,
              dateY: removeAbsolutePeriod(configForm.filters.dateY),
              startPeriod: removeAbsolutePeriod(configForm.filters.startPeriod),
              endPeriod: removeAbsolutePeriod(configForm.filters.endPeriod),
            }
          : undefined,
      }

      const updatedView = await updateView({
        id: view.id,
        config: configForSave,
        mode: shouldUseExistingGoalConfigFragment ? 'kpiTimeSeriesUseExistingGoalConfigFragment' : undefined,
      })

      if (updatedView !== undefined) {
        setView({
          ...updatedView,
          config: configForm,
        })
        setConfigWithoutChange(configForm)
        setRiForm(configForm)
      }

      message.success(savedMessage ?? t(`設定を保存しました。`))
    } catch (error) {
      message.error(error instanceof Error ? error.message : t(`エラーが発生しました。`))
    } finally {
      setLoading(false)
    }
  }

  return {
    updateKpiTimeSeriesView,
    loading,
  }
}

interface UseKpiTimeSeriesConfigFormOptions {
  period: Period | undefined
  timeSpan: TimeSpan | undefined
  breakdownProperty: ViewConfigDimension | undefined
  waterfallDimensions: WaterfallDimension[] | undefined
}

type UseKpiTimeSeriesConfigFormReturn = ReturnType<typeof useKpiTimeSeriesConfigForm>

// eslint-disable-next-line complexity
export function useKpiTimeSeriesConfigForm(options: UseKpiTimeSeriesConfigFormOptions) {
  const format = useRecoilValue(riDisplayFormatAtom)
  const config = useKpiTimeSeriesConfig()
  const kpiId = useMemo(() => uuid(), [])
  const goalConfigId = useMemo(() => uuid(), [])
  const [riForm, setRiForm] = useRecoilState(riFormAtom)

  const {
    validatedFormValue: { kpiConfig },
    form,
  } = useKpiFormSelectors()

  const kpiName = useKpiName()

  const chartDesignSettings = useRecoilValue(chartDesignSettingsAtom)

  // NOTE: kpiConfig で validate される項目以外のバリデーション
  const formValidator = z.object({
    user: viewConfigKpiSchema.shape.user.optional(),
    ...(format === 'timeSeries'
      ? {
          period: viewConfigKpiTimeSeriesSchema.shape.period,
          timeSpan: goalConfigSchema.shape.timeSpanType,
        }
      : {
          dimensions: waterfallDimensionSchema
            .merge(
              z.object({
                // プロパティの設定を必須にする
                property: nodePropertyNameSchema,
              }),
            )
            .array(),
        }),
  })

  const validatedForm = formValidator.safeParse({
    user: form.user,
    period: {
      ...options.period,
      startAt: options.period?.startAt?.format(`YYYY-MM-DD`),
      endAt: options.period?.endAt?.format(`YYYY-MM-DD`),
    },
    timeSpan: options.timeSpan,
    dimensions: options.waterfallDimensions,
  })

  const disabled = !kpiConfig.success || !validatedForm.success

  const errorMessage = kpiConfig.success
    ? validatedForm.success
      ? ''
      : validatedForm.error.issues.map((index) => index.message).join('\n')
    : kpiConfig.error.issues.map((index) => index.message).join('\n')

  // eslint-disable-next-line complexity
  const configForm = useMemo(() => {
    const kpiFragment: KpiFragment = {
      type: 'kpi',
      id: kpiId,
      ...config.kpiFragment,
      ...(kpiConfig.success ? kpiConfig.data : {}),
      name: config.kpiFragment?.name ?? kpiName ?? '-',
    }

    const goalConfigFragment: GoalConfigFragment = {
      id: goalConfigId,
      name: '-',
      userType: 'user',
      organizationId: getOrganizationIdFromPath(),
      userIdsConfig: [],
      userGroupIdsConfig: ['all'],
      config: {},
      ...config.goalConfigFragment,
      kpiViews: [
        {
          id: kpiFragment.id,
          name: kpiFragment.name,
          aggregationFunction: 'sum',
        },
      ],
      timeSpanType: options.timeSpan ?? 'month',
    }

    const configFormValue: ViewConfigKpiTimeSeries = {
      ...config,
      type: 'kpiTimeSeries',
      kpiFragment,
      goalConfigFragment,
      period:
        options.period === undefined
          ? undefined
          : {
              ...options.period,
              startAt: options.period.startAt?.format(`YYYY-MM-DD HH:mm:ss`),
              endAt: options.period.endAt?.format(`YYYY-MM-DD HH:mm:ss`),
            },
      breakdownProperty: options.breakdownProperty,
      ...(isSome(config.waterfall) || isSome(options.waterfallDimensions) || isSome(riForm?.waterfall)
        ? {
            waterfall: {
              ...config.waterfall,
              ...riForm?.waterfall,
              dimensions: options.waterfallDimensions ?? [],
            },
          }
        : {}),
      designSettings: {
        chartAxisLabelDisplayMode: chartDesignSettings.chartAxisLabelDisplayMode,
        chartValueLabelDisplayMode: chartDesignSettings.chartValueLabelDisplayMode,
        chartValueLabelUnitType: chartDesignSettings.chartValueLabelUnitType,
        chartValueLabelDecimalPlaceType: chartDesignSettings.chartValueLabelDecimalPlaceType,
      },
      filters: {
        ...config.filters,
        // NOTE: 縦軸の期間項目である dateY がクリアされた場合は、フィルタできないので、フィルタ側もクリアする
        dateY: kpiFragment.dateY === undefined ? undefined : config.filters?.dateY,
        // NOTE: 内訳項目が変更された場合は、フィルタ側もクリアする
        breakdownProperties:
          JSON.stringify(options.breakdownProperty) === JSON.stringify(config.breakdownProperty)
            ? config.filters?.breakdownProperties
            : undefined,
      },
      option: {
        asWaterfall: format === 'waterfall',
      },
    }

    // NOTE: 今のところconfigFormAtomは一部でしか使用していないが、RIのフォームの値は徐々にこちらに移行していきたい
    setRiForm(configFormValue)

    return configFormValue
  }, [
    kpiId,
    config,
    kpiConfig.success,
    kpiConfig.data,
    kpiName,
    goalConfigId,
    options.timeSpan,
    options.period,
    options.breakdownProperty,
    options.waterfallDimensions,
    chartDesignSettings.chartAxisLabelDisplayMode,
    chartDesignSettings.chartValueLabelDisplayMode,
    chartDesignSettings.chartValueLabelUnitType,
    chartDesignSettings.chartValueLabelDecimalPlaceType,
    format,
    JSON.stringify({ riForm }),
    setRiForm,
  ])

  return {
    configForm,
    validatedKpiForm: kpiConfig,
    validatedForm,
    disabled,
    errorMessage,
  }
}

export function useKpiName() {
  const { form, rootModels, currentNodeProperties } = useKpiFormSelectors()

  const root = useMemo(() => (form.sheet?.type === 'sheet' ? form.sheet.tree : undefined), [form.sheet])

  const property = useMemo(
    () => (form.measure?.type === 'kpiPreset' ? form.measure.property : undefined),
    [form.measure],
  )

  const rootLabel = rootModels.find((v) => v.name === root?.modelName)?.label

  const propertyLabel = useMemo(() => {
    const propertyNode = currentNodeProperties.find(
      (v) => v.node.model.name === property?.modelName && v.property.name === property.propertyName && v.node.node.name,
    )
    return isSome(propertyNode)
      ? `${propertyNode.node.nodePathAsLabel.join(': ')}: ${propertyNode.property.label}`
      : undefined
  }, [currentNodeProperties])

  const kpiName = propertyLabel ?? rootLabel

  return kpiName
}

export function useDimensions() {
  const ui = useUiValue()

  const dimensions = ui
    .filter((v): v is ViewUIKpiTimeSeries => v.type === 'KpiTimeSeriesGraph')
    .flatMap((v) => v.dimensions)
    .filter((v) => v.type === 'property')

  return dimensions
}

export function useSavedPeriod() {
  const { organization } = useMeValue()
  const { deserializePeriod } = usePeriod()
  const dateXPeriods = getPeriods('kpiTimeSeriesKpiDateX', organization.setting)
  const dateYPeriods = getPeriods('kpiTimeSeriesKpiDateY', organization.setting)

  const getSavedPeriod = (period?: ViewConfigSheetExtraPeriod): Period | undefined => {
    if (isTruthy(period?.name.includes('カスタム'))) {
      return deserializePeriod(period)
    }

    const allPeriods = [...dateXPeriods, ...dateYPeriods].uniqueBy((p) => p.name)
    return allPeriods.find((p) => p.name === period?.name)
  }

  return { getSavedPeriod }
}
