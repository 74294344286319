import { isPresent, r, stringUtil } from '@salescore/buff-common'
import Mustache from 'mustache'

import type {
  NodePath,
  ViewQueryField,
  ViewQueryFilterNode,
  ViewQueryList,
  ViewQueryNode,
  ViewQueryNodeReadJoinOn,
  ViewQueryRecordNode,
  ViewQueryResult,
  ViewQueryResultCursor,
  ViewQueryTableNode,
} from '../../schemas/query'
import { addMetadata } from './executeViewQuery/addMetadata'
import { generateSql } from './executeViewQuery/generateSql'
import { getLabeledName } from './executeViewQuery/util'
import type { SqlClient } from './executeViewQueryForAggregationQuery'

export type SqlResultRecord = Record<string, unknown>

// TODO
const tryAndCatch = async <T>(f: () => T | Promise<T>, handler: (error: Error) => void): Promise<T> => {
  try {
    return await f()
  } catch (error) {
    if (error instanceof Error) {
      handler(error)
    }
    throw error
  }
}

const DEFAULT_PAGE_SIZE = 100
export const POSTGRES_COLUMN_LENGTH_LIMIT = 63

export type ViewResultCursorOrInitialCursor = Omit<ViewQueryResultCursor, 'nextId'> & {
  nextId?: string | null
}

export class ExecuteViewPostgresError extends Error {
  public originalError: Error

  public constructor(e: Error, message: string) {
    super(message)
    this.name = 'ExecuteViewPostgresError'
    this.originalError = e
  }
}

export const defaultCursor: ViewResultCursorOrInitialCursor = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  chunkSize: 0, // chunkSizeは前回取得時の最後の行数で決まるので、デフォルトは0でOK
  nextId: undefined,
}
const PARENT_IDS_FILTER_LIMIT = 1000
const defaultChildrenCursor = {
  ...defaultCursor,
  pageSize: 1000,
}

export const executeViewQueryWithApplicationJoin = async ({
  viewQuery,
  sqlClient,
  cursor,
  mustacheParameter,
  dateForDiffHighlight,
}: {
  viewQuery: ViewQueryList
  sqlClient: SqlClient
  cursor?: ViewResultCursorOrInitialCursor
  mustacheParameter: Record<string, unknown>
  dateForDiffHighlight?: Date
}): Promise<ViewQueryResult> => {
  const { records, sqls, nextCursor, warn } = await dfs({
    node: viewQuery.tree,
    query: viewQuery,
    sqlClient,
    cursor,
    mustacheParameter,
    parentIds: [],
  })

  const result = await addMetadata(sqlClient, records, viewQuery.tree, dateForDiffHighlight)
  // TODO
  // const snapshots = await generateSnapshotsFromHistory({
  //   snapshotsConfigs: viewQuery.extra?.snapshotConfigs ?? [],
  //   records: rows,
  //   sqlClient,
  // })

  return {
    viewQuery,
    result,
    sqls, // deprecated
    sqlDetails: sqls.map((sql) => ({ sql, name: `` })),
    nextCursor,
    warn,
    // queryResultMeta, // TODO
    // snapshots: [], // TODo
  }
}

// eslint-disable-next-line complexity
async function dfs({
  node,
  query,
  sqlClient,
  cursor,
  mustacheParameter,
  parentIds,
  joinRelationColumnName,
}: {
  node: ViewQueryNode
  query: ViewQueryList
  sqlClient: SqlClient
  cursor?: ViewResultCursorOrInitialCursor
  mustacheParameter: Record<string, unknown>
  parentIds: string[]
  joinRelationColumnName?: string
}): Promise<{
  records: ViewQueryRecordNode[]
  sqls: string[]
  nextCursor: ViewQueryResultCursor | undefined
  hasNextRecord: boolean
  warn: string | undefined
}> {
  const isRoot = node.name === query.tree.name

  //
  // 該当ノードに対するクエリを生成・実行
  //
  const nodeWithoutChildren: ViewQueryNode = {
    ...node,
    read: {
      ...node.read,
      join: undefined,
    },
    children: [],
  }
  // ID系のカラム。アプリケーションレイヤーでJOINするために使う
  const additionalColumnNames = [
    'id',
    joinRelationColumnName,
    ...(node.children ?? []).map((child) => {
      if (child.read.join === undefined) {
        return
      }
      return getJoinOnParentNodeColumnName(child.read.join.joinOn)
    }),
  ]
    .compact()
    .unique()
  const fields: ViewQueryField[] = [
    ...additionalColumnNames.map((columnName) => generateViewQueryFieldByName({ columnName, nodePath: node.path })),
    // 元々のフィールドのうち、同じノードのもの
    ...query.fields
      .filter((field) => field.nodePath.last() === node.name)
      // 参照項目の時、labelSqlに他のノードの値が入っているので取り除く。
      // この辺はconfigをcompileするときに本来やれると良いが、一旦依存挙動を極力変えずに、この関数のみで必要な対応を行うため、やや無理やり以下のような形で実装している
      .map((field): ViewQueryField => ({ ...field, read: { ...field.read, labelSql: undefined } })),
    // 元々のフィールドのうち、nodePathは違うノードだけど、selectされる項目はこのノードのもの（＝参照項目）
    ...query.fields
      .filter((field) => field.read.labelSql?.includes(`"${node.name}"`) ?? false) // やや無理やり選択。カラム名にノード名と同じものが含まれていればエラーになる
      .map(
        (field): ViewQueryField => ({
          ...field,
          name: getLabeledName(field.name),
          read: { ...field.read, labelSql: undefined, sql: field.read.labelSql! },
        }),
      ),
  ]
  const pruned = pruneFilterTree(query.filterTree, node.name) // TODO: ルート以外に対する絞り込みは、UI側でそもそもできなくする予定
  const filterTree: ViewQueryFilterNode = {
    logicalOperator: 'and',
    leafs:
      isRoot || parentIds.isBlank()
        ? []
        : [
            {
              nodePaths: [], // 不要なはず
              read: {
                // idsがあまりに多いとSQLが重くなるので、適当に足切りする
                sql: `"${joinRelationColumnName ?? 'id'}" IN (${
                  parentIds
                    .slice(0, PARENT_IDS_FILTER_LIMIT)
                    .map((id) => `'${id}'`)
                    .join(',') ?? ''
                })`,
                dependedParameters: [],
              },
            },
          ],
    children: [pruned, node.read.join?.joinFilterTree].compact(),
  }

  const c: ViewResultCursorOrInitialCursor = isRoot ? (cursor ?? defaultCursor) : defaultChildrenCursor
  const rawSql = generateSql(
    {
      type: 'list',
      tree: nodeWithoutChildren,
      fields,
      filterTree,
      sorters: query.sorters.filter((sorter) => sorter.nodePaths.last()?.last() === node.name),
    },
    c,
    mustacheParameter,
    { shouldSkipRootSort: !isRoot },
  )
  const sql = Mustache.render(rawSql, mustacheParameter)

  const queryResult = await tryAndCatch(
    async () => {
      const result = await sqlClient.query(sql)
      return result
    },
    (e) => {
      throw new ExecuteViewPostgresError(e, `${e.name}: ${e.message}`)
    },
  )
  if (queryResult === undefined) {
    throw new Error(`query execution error. ${sql}`)
  }
  const { rows, ...queryResultMeta } = queryResult

  //
  // 子ノードに対するクエリを生成・実行
  //
  const results: Array<{
    node: ViewQueryNode
    sqls: string[]
    hasNextRecord: boolean
    warn: string | undefined
    grouped: Record<string, ViewQueryRecordNode[]>
  }> = []
  for (const child of node.children ?? []) {
    const currentNodeColumnName = getJoinOnCurrentNodeColumnName(child.read.join!.joinOn)
    const parentNodeColumnName = getJoinOnParentNodeColumnName(child.read.join!.joinOn)
    const ids = rows
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      .map((row) => row[parentNodeColumnName] as string)
      .compact()
      .unique()
      .filter((x) => isPresent(x))
    if (ids.isBlank()) {
      continue
    }
    const childResult = await dfs({
      node: child,
      query,
      sqlClient,
      cursor: undefined,
      mustacheParameter,
      parentIds: ids,
      joinRelationColumnName: getJoinOnCurrentNodeColumnName(child.read.join!.joinOn),
    })
    results.push({
      node: child,
      // records: childRecords,
      sqls: childResult.sqls,
      hasNextRecord: childResult.hasNextRecord,
      warn: childResult.warn,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      grouped: childResult.records.groupBy((x) => x.attributes[currentNodeColumnName] as string).data,
    })
  }

  const records: ViewQueryRecordNode[] = rows.map((row): ViewQueryRecordNode => {
    const children = (node.children ?? [])
      .map((child) => {
        const result = results.find((result) => result.node.name === child.name)
        if (result === undefined) {
          // 結果がなかったとき以外はありえない
          return
        }
        const parentNodeColumnName = getJoinOnParentNodeColumnName(child.read.join!.joinOn)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        const relationIdValue: string = (row[parentNodeColumnName] ?? '') as string
        const tableNode: ViewQueryTableNode = {
          nodeName: child.name,
          children: result.grouped[relationIdValue] ?? [],
        }

        return {
          node: child,
          tableNode,
        }
      })
      .compact()
    // 参照項目の場合、ラベル値がこのノードではなく、子ノード側に値があるが、
    // この値をこのノードのattributesとして扱いたいので、子ノード側の値を抽出する
    // _labelのサフィックスがついているものを全て抽出するだけの、やや雑なロジックなので、
    // 参照項目以外の値も抽出してしまうことがあるが、いったん問題ないはず。必要があれば、やはりconfigをcompileするレイヤーでどうにかしたい
    const childLabelAttributes = children
      .map((x) => {
        if (x.node.read.join?.relation !== 'many_to_one') {
          return
        }
        const record = x.tableNode.children.first()
        if (record === undefined) {
          return
        }
        return r(record.attributes).filter((key) => key.endsWith(`_label`)).data
      })
      .compact()
      .reduce((accumulator, x) => ({ ...accumulator, ...x }), {})

    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      id: row.id as string,
      attributes: {
        ...childLabelAttributes,
        ...row,
      },
      meta: {
        height: 1,
        innerRowIndexStart: 0,
        innerRowIndexEnd: 1,
      },
      children: children.map((x) => x.tableNode),
    }
  })

  const hasNextRecord = records.length >= c.pageSize || parentIds.length > PARENT_IDS_FILTER_LIMIT // 確実ではないが、とりあえずこれで判定する
  const nextCursor: ViewQueryResultCursor | undefined =
    isRoot && hasNextRecord
      ? {
          page: c.page + 1,
          pageSize: c.pageSize,
          chunkSize: c.chunkSize, // 使われない
          nextId: ``, // 使われない
        }
      : undefined

  return {
    records,
    nextCursor,
    sqls: [sql, ...results.flatMap((x) => x.sqls)],
    hasNextRecord,
    warn: results.some((x) => x.hasNextRecord)
      ? `レコード数が多すぎるため、子レコードが全て取得できませんでした`
      : (results
          .map((x) => x.warn)
          .compact()
          .first() ??
        (!isRoot && hasNextRecord ? `レコード数が多すぎるため、子レコードが全て取得できませんでした` : undefined)),
  }
}

function getJoinOnCurrentNodeColumnName(joinOn: ViewQueryNodeReadJoinOn) {
  if (joinOn.type === 'sql') {
    return `id` // TODO
  }

  return joinOn.currentNodeColumnName
}

function getJoinOnParentNodeColumnName(joinOn: ViewQueryNodeReadJoinOn) {
  if (joinOn.type === 'sql') {
    return `id` // TODO
  }

  return joinOn.parentNodeColumnName
}

function generateViewQueryFieldByName({
  columnName,
  nodePath,
}: {
  columnName: string
  nodePath: NodePath
}): ViewQueryField {
  return {
    name: columnName,
    nodePath,
    read: {
      sql: stringUtil.doubleQuoteIfNotQuoted(columnName),
    },
    write: undefined,
    meta: {
      label: ``,
      fieldType: `string`,
      fieldMetaType: undefined,
      required: false,
      defaultValue: undefined,
      searchable: false,
      conditionalEffects: [],
      dependedPropertyNamesWithStreamName: [],
      creatable: false,
      updatable: false,
    },
  }
}

function pruneFilterTree(filterTree: ViewQueryFilterNode | undefined, nodeName: string): ViewQueryFilterNode {
  if (filterTree === undefined) {
    return {
      logicalOperator: 'and',
      leafs: [],
      children: [],
    }
  }
  const res: ViewQueryFilterNode = {
    ...filterTree,
    leafs: filterTree.leafs.filter((leaf) => leaf.nodePaths.last()?.last() === nodeName),
    children: filterTree.children.map((child) => pruneFilterTree(child, nodeName)).compact(),
  }
  if (res.leafs.isBlank() && res.children.isBlank()) {
    return {
      logicalOperator: 'and',
      leafs: [],
      children: [],
    }
  }
  return res
}
