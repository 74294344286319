import { CalendarOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'

import { CONSTANT } from './constant'

const fieldTranslation = {
  [CONSTANT.hub.KPI_DATE]: '日付',
  [CONSTANT.hub.KPI_DATE_MONTH]: '月',
  [CONSTANT.hub.KPI_DATE_WEEK]: '週',
  [`${CONSTANT.hub.KPI_DATE}_day`]: '日',
  [`${CONSTANT.hub.KPI_DATE}_weekday`]: '曜日',
  [`${CONSTANT.hub.KPI_DATE}_hour`]: '時間帯',
  [`${CONSTANT.hub.KPI_DATE}_week`]: '週',
  [`${CONSTANT.hub.KPI_DATE}_month`]: '月',
  [`${CONSTANT.hub.KPI_DATE}_quarter`]: 'Q',
  [`${CONSTANT.hub.KPI_DATE}_fiscal_quarter`]: 'Q',
  [`${CONSTANT.hub.KPI_DATE}_year`]: '年',
  [`${CONSTANT.hub.KPI_DATE}_fiscal_year`]: '年度',
  [CONSTANT.hub.KPI_VALUE]: '指標',
  [CONSTANT.hub.KPI_ID]: 'KPI', // 正確には「KPIのID」だが、ユーザーにはこの方がわかりやすいはず
  [CONSTANT.hub.KPI_KIND]: 'KPI種別',
  [CONSTANT.hub.KPI_OWNER]: 'ユーザー',
  [CONSTANT.hub.SALESCORE_USER_ID]: 'ユーザー',
  [CONSTANT.hub.SALESCORE_USER_GROUP_ID]: 'チーム',
  [CONSTANT.hub.SALESCORE_USER_GROUP1_ID]: 'チーム',
  [CONSTANT.hub.TOTAL_WORD]: '合計',
  [CONSTANT.hub.NULL_WORD]: '入力なし',
  'Deal owner': '取引の所有者',
  'Deal Name': '取引名',
  'Created by user ID': '作成者',
  'Updated by user ID': '更新者',
  'Object create date/time': '作成日時',
  'Object last modified date/time': '更新日時',
  main: 'メインKPI',
  process: 'プロセスKPI',
  action: 'アクションKPI',
  レコードURL: 'URL',
}

// eslint-disable-next-line complexity
export const translateHubField = (
  x: string,
  options?: { kpiValueName?: string; userGroupTranslation?: (x: string) => string | undefined },
) => {
  // 2022/10 nullが渡ってくる可能性があるようなので
  if (x === null) {
    return ``
  }

  if (x.startsWith(CONSTANT.hub.SALESCORE_USER_GROUP_DEPTH_N_PREFIX)) {
    return isSome(options?.userGroupTranslation) ? (options.userGroupTranslation(x) ?? 'チーム') : 'チーム'
  }

  if (x === CONSTANT.hub.KPI_VALUE && options?.kpiValueName !== undefined) {
    return options.kpiValueName
  }

  return fieldTranslation[x] ?? x
}

export const isHubFieldWithIcon = (x: string) => {
  if (x.startsWith(CONSTANT.hub.SALESCORE_USER_GROUP_DEPTH_N_PREFIX)) {
    return true
  }

  return [CONSTANT.hub.SALESCORE_USER_ID, CONSTANT.hub.SALESCORE_USER_GROUP_ID, CONSTANT.hub.KPI_DATE].includes(x)
}

export const translateHubFieldWithIcon = (
  x: string,
  options?: { kpiValueName?: string; userGroupTranslation?: (x: string) => string | undefined },
) => {
  if (x.startsWith(CONSTANT.hub.SALESCORE_USER_GROUP_DEPTH_N_PREFIX)) {
    return (
      <div>
        <TeamOutlined /> {translateHubField(x, options)}
      </div>
    )
  }
  if (x === CONSTANT.hub.SALESCORE_USER_ID) {
    return (
      <div>
        <UserOutlined /> {translateHubField(x, options)}
      </div>
    )
  }
  if (x === CONSTANT.hub.SALESCORE_USER_GROUP1_ID) {
    return (
      <div>
        <TeamOutlined /> {translateHubField(x, options)}
      </div>
    )
  }
  if (x === CONSTANT.hub.KPI_DATE) {
    return (
      <div>
        <CalendarOutlined /> {translateHubField(x, options)}
      </div>
    )
  }
  return translateHubField(x, options)
}
