import {
  type CoreDslFormOperator,
  type CoreDslFormTerm,
  isSameNodeProperty,
  type ModelProperty,
  type ViewConfigField,
  type ViewConfigTreeNode,
} from '@salescore/core'
import { MyDropdownMenu } from '@salescore/frontend-common'
import { Form, Input } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'

import { useConnectionsSelector } from '../../../../../recoil/view/selectors/connectionsSelector'
import { NodePropertyPickerModalContent } from '../common/NodePropertyPickerModalContent'
import { CoreDslFormDropdownButton } from './CoreDslFormDropdownButton'
import { CoreDslFormLiteralFormItem } from './CoreDslFormLiteralFormItem'
import { CoreDslFormLiteralItem } from './CoreDslFormLiteralItem'
import { CoreDslFormSnapshotVariableFormItem, dateSpanMapper } from './CoreDslFormSnapshotVariableFormItem'
import type { TargetProperty } from './targetProperty'

const SKIP_VALUE_DEFINITION_FILTER_TYPES = new Set<CoreDslFormOperator>(['null', 'not_null', 'blank', 'present'])

// eslint-disable-next-line complexity
export const CoreDslFormTermFormItem = ({
  targetProperty,
  availableFields,
  term,
  operator,
  asRight,
  asRightAndLeftIsRecordVariable,
  onChange,
}: {
  targetProperty: Omit<TargetProperty, 'model'>
  availableFields: ViewConfigField[]
  onChange: (x: CoreDslFormTerm) => void
  term: CoreDslFormTerm | undefined
  operator: CoreDslFormOperator | undefined
  asRight?: boolean
  asRightAndLeftIsRecordVariable?: boolean // だいぶ無理やりだが、これが一番シンプルかな…
}) => {
  const { getModelAndProperty } = useConnectionsSelector()
  const fieldsWithProperty = useMemo(
    () =>
      availableFields
        .map((field) => {
          const propertyWithModel = getModelAndProperty(field.property.modelName, field.property.propertyName)
          if (propertyWithModel === undefined) {
            return
          }
          const node: ViewConfigTreeNode = {
            // 手抜き。本来はtreeから取得してくるべき
            type: 'model',
            name: field.property.nodeName,
            modelName: field.property.modelName,
          }
          return {
            field,
            property: propertyWithModel.property,
            model: propertyWithModel.model,
            node,
          }
        })
        .compact(),
    [availableFields],
  )
  const label = asRight === true ? t(`条件の値`) : t(`項目`)

  if (asRight === true) {
    if (operator === undefined) {
      return (
        <Form.Item label={label}>
          <Input disabled={true} />
        </Form.Item>
      )
    }
    if (SKIP_VALUE_DEFINITION_FILTER_TYPES.has(operator)) {
      return <></>
    }
  }

  return (
    <MyDropdownMenu
      items={[
        asRight === true
          ? undefined
          : {
              key: `current`,
              type: 'label',
              label: t(`現在の値`),
              onClick: () => {
                onChange({
                  type: 'snapshotVariable',
                  dateSpan: 'day',
                  before: 0,
                  property: targetProperty.property,
                  nodePropertyName: targetProperty.nodePropertyName,
                })
              },
            },
        asRightAndLeftIsRecordVariable === true
          ? undefined
          : {
              key: `prev`,
              type: 'label',
              label: t(`過去の値`),
              children: [
                {
                  key: `prev-child`,
                  type: 'render',
                  render: ({ hide }) => (
                    <div
                      style={{
                        minWidth: 200,
                      }}
                      className="px-2 py-1"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    >
                      <CoreDslFormSnapshotVariableFormItem
                        targetProperty={targetProperty}
                        onFinish={(term) => {
                          onChange(term)
                          hide()
                        }}
                      />
                    </div>
                  ),
                },
              ],
            },
        asRight === true
          ? {
              type: 'divider',
            }
          : undefined,
        asRight === true
          ? {
              key: `literal`,
              type: 'label',
              label: t(`値を直接入力`),
              children: [
                {
                  key: `literal-child`,
                  type: 'render',
                  render: ({ hide }) => (
                    <div
                      className="px-2 py-1"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    >
                      <CoreDslFormLiteralFormItem
                        term={term}
                        property={targetProperty.property}
                        operator={operator}
                        onFinish={(term) => {
                          onChange(term)
                          hide()
                        }}
                      />
                    </div>
                  ),
                },
              ],
            }
          : undefined,
        {
          type: 'divider',
        },
        {
          key: 'otherFields',
          type: 'label',
          label: t(`他の列の現在の値`),
          children: [
            {
              key: `otherFields-drilldown`,
              type: 'render',
              render: ({ hide }) => (
                <div
                  style={{ width: 500 }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <NodePropertyPickerModalContent
                    propertiesWithNode={fieldsWithProperty}
                    onFinish={(value) => {
                      const fieldWithProperty = fieldsWithProperty.find((field) =>
                        isSameNodeProperty(value, field.field.property),
                      )
                      if (fieldWithProperty !== undefined) {
                        onChange({
                          type: 'recordNodeVariable',
                          property: fieldWithProperty.property,
                          nodePropertyName: fieldWithProperty.field.property,
                        })
                      }
                      hide()
                    }}
                  />
                </div>
              ),
            },
          ],
        },
      ]}
    >
      <span>
        <DataDispaly term={term} property={targetProperty.property} label={label} />
      </span>
    </MyDropdownMenu>
  )
}

function DataDispaly({
  term,
  property,
  label,
}: {
  term: CoreDslFormTerm | undefined
  property: ModelProperty
  label: string
}) {
  const { getModel } = useConnectionsSelector()

  if (term === undefined) {
    return <CoreDslFormDropdownButton label={label} />
  }
  const { type } = term
  switch (type) {
    case undefined: {
      // ありえないはずだが、不具合があるとこの分岐になる
      return <CoreDslFormDropdownButton label={label} />
    }
    case 'literal': {
      return <CoreDslFormLiteralItem term={term} property={property} label={label} />
    }
    case 'snapshotVariable': {
      const value = term.before === 0 ? t(`現在の値`) : `${term.before}${dateSpanMapper[term.dateSpan]}${t(`前の値`)}`
      return <CoreDslFormDropdownButton value={value} label={label} />
    }
    case 'recordNodeVariable': {
      const value = `${getModel(term.nodePropertyName.modelName)?.label}: ${term.property.label}` // TODO: 最新の値を使うべき
      return <CoreDslFormDropdownButton value={value} label={label} />
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = type
      return <></>
    }
  }
}
