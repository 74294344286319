import { isSome } from '@salescore/buff-common'
import { type CSSProperties, useEffect, useRef, useState } from 'react'

import { useResize } from '../../hooks/useResizeObserver'

export interface MyDropdownProperties {
  overlay: (argument: { hide: () => void }) => JSX.Element
  children: JSX.Element
  className?: string
  style?: CSSProperties
  visibility?: boolean
  position?: 'right' | 'bottom'
  setVisibility?: (visibility: boolean) => void
  onHide?: () => void
}

// eslint-disable-next-line complexity
export const MyDropdown = ({
  overlay,
  children,
  className,
  style,
  visibility,
  setVisibility,
  onHide,
  position,
}: MyDropdownProperties) => {
  const [visibilityInner, setVisibilityInner] = useState(false)
  const triggerReference = useRef<HTMLDivElement>()
  const contentReference = useRef<HTMLDivElement>()
  const { ref, rect } = useResize()

  useEffect(() => {
    document.addEventListener(
      'click',
      // eslint-disable-next-line complexity
      (e) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-type-assertion
        if (isSome(triggerReference.current) && (triggerReference.current.contains(e.target as any) ?? false)) {
          // trigger要素の内部をクリックした場合は何もしない
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-type-assertion
        } else if (isSome(contentReference.current) && (contentReference.current.contains(e.target as any) ?? false)) {
          // contentRef要素の内部をクリックした場合も何もしない
        } else if (setVisibility === undefined) {
          setVisibilityInner(false)
          onHide?.()
        } else {
          // XXX: これ、必要な認識だが、これをやると開けなくなる
          // setVisibility(false)
          // onHide?.()
        }
      },
    )
  }, [])

  return (
    <div className={`relative ${className ?? ''}`} ref={ref}>
      <span
        onClick={(e) => {
          if (setVisibility === undefined) {
            setVisibilityInner((x) => !x)
          } else {
            setVisibility(true)
          }
          // closeWithClickOutSideMethod(e)
        }}
        ref={
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-type-assertion
          triggerReference as any
        }
      >
        {children}
      </span>
      {(visibility ?? visibilityInner) && (
        <div
          className="absolute z-50 bg-white shadow-lg"
          ref={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-type-assertion
            contentReference as any
          }
          style={{
            // width: 300,
            ...(position === 'bottom' || position === undefined ? { top: (rect?.height ?? 0) + 2, left: 0 } : {}),
            ...(position === 'right' ? { top: 0, left: (rect?.width ?? 0) + 2 } : {}),
            ...style,
          }}
        >
          {overlay({
            hide: () => {
              if (setVisibility !== undefined) {
                setVisibility(false)
              }
              setVisibilityInner(false)
              // onHide?.()
            },
          })}
        </div>
      )}
    </div>
  )
}
