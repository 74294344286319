import { sortSpecificElementOnly } from '@salescore/buff-common'
import { CORE_CONSTANT, type ViewConfigDimension } from '@salescore/core'
import { message } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'

import { useKpiPivotParameter } from '../../../recoil/navigation/hooks'
import { DimensionsPickerSelect } from './DimensionsPicker/DimensionsPickerSelect'
import { DimensionsPickerSortableList } from './DimensionsPicker/DimensionsPickerSortableList'

// dimensionを複数選ぶ
export function DimensionsPicker({
  dimensions,
  type,
}: {
  dimensions: ViewConfigDimension[]
  type: 'rows' | 'columns'
}) {
  const [kpiPivotParameter, setKpiPivotParameter] = useKpiPivotParameter()
  const pivot = kpiPivotParameter.pivot

  // PieChartDimensionsPicker などに合わせて、パネル切り替え時に DimensionsPicker の中で pivot を更新するようにしている
  useEffect(() => {
    setKpiPivotParameter((previous) => ({
      ...previous,
    }))
  }, [])

  return (
    <div className="dimensions-picker w-full">
      <Body
        dimensions={dimensions}
        pickedDimensions={pivot[type]}
        onChange={
          // eslint-disable-next-line complexity
          (dimensions) => {
            if (type === 'rows') {
              // kpiは削除できない
              if (
                pivot.rows.some((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key) &&
                !dimensions.some((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key)
              ) {
                void message.warning(t(`KPIダッシュボードでは、集計軸にKPIを必ず含める必要があります。`))
                return
              }
              setKpiPivotParameter({
                ...kpiPivotParameter,
                pivot: {
                  // dateSpan周りの考慮はpicker側でやる
                  rows: dimensions,
                  columns: pivot.columns.filter((x) => !dimensions.map((d) => d.key).includes(x.key)),
                },
              })
            } else {
              if (
                pivot.columns.some((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key) &&
                !dimensions.some((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key)
              ) {
                void message.warning(t(`KPIダッシュボードでは、集計軸にKPIを必ず含める必要があります。`))
                return
              }
              setKpiPivotParameter({
                ...kpiPivotParameter,
                pivot: {
                  // dateSpan周りの考慮はpicker側でやる
                  columns: dimensions,
                  rows: pivot.rows.filter((x) => !dimensions.map((d) => d.key).includes(x.key)),
                },
              })
            }
          }
        }
      />
    </div>
  )
}

function Body({
  dimensions,
  pickedDimensions,
  placeholder,
  onChange,
}: {
  dimensions: ViewConfigDimension[]
  pickedDimensions: ViewConfigDimension[]
  placeholder?: string
  onChange: (dimensions: ViewConfigDimension[]) => void
}) {
  return (
    <>
      <DimensionsPickerSelect
        dimensions={dimensions}
        placeholder={placeholder}
        pickedDimensions={pickedDimensions}
        onChange={(keys: string[]) => {
          const currentDimensions = pickedDimensions.filter((x) => keys.includes(x.key))
          const newDimensions = keys
            .filter((key) => !pickedDimensions.map((x) => x.key).includes(key))
            .map((key) => dimensions.find((x) => x.key === key))
            .compact()
          onChange(sortDimensions([...currentDimensions, ...newDimensions]))
        }}
      />
      <DimensionsPickerSortableList
        dimensions={pickedDimensions}
        onChange={(dimensions) => {
          onChange(sortDimensions(dimensions))
        }}
      />
    </>
  )
}

function sortDimensions(ds: ViewConfigDimension[]): ViewConfigDimension[] {
  // ユーザー、ユーザーグループは常に並び順を一定にする
  const sortedByUserRelated = sortSpecificElementOnly(ds, (d: ViewConfigDimension) => {
    if (d.type === 'property' && d.property.nodeName.startsWith(`salescore_users_with_group`)) {
      if (d.property.propertyName === 'id') {
        return 99
      }
      const depth = Number.parseInt(d.property.propertyName.replace('user_group_d', '').replace('_id', ''))
      return depth
    }
    if (d.key === CORE_CONSTANT.KPI_PIVOT_USER_DIMENSION().key) {
      return 1000 // 最後列にするため、適当な値にする
    }
  })

  // KPIグループは常にKPIの1つ前にする
  const kpiGroupIndex = ds.findIndex((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_DIMENSION().key)
  const kpiIndex = ds.findIndex((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key)
  if (kpiIndex === -1 || kpiGroupIndex === -1) {
    return sortedByUserRelated
  }
  const dsWithoutKpiGrouop = sortedByUserRelated.filter(
    (x) => x.key !== CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_DIMENSION().key,
  )
  const newKpiIndex = dsWithoutKpiGrouop.findIndex((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key)
  return [
    ...dsWithoutKpiGrouop.slice(0, newKpiIndex),
    CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_DIMENSION(),
    ...dsWithoutKpiGrouop.slice(newKpiIndex),
  ]
}
