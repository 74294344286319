import {
  ArrowsAltOutlined,
  CheckSquareFilled,
  EditOutlined,
  LinkOutlined,
  LoadingOutlined,
  MinusSquareOutlined,
  PlayCircleFilled,
} from '@ant-design/icons'
import { isNull, isPresent, isSome } from '@salescore/buff-common'
import { CONSTANT, getMiitelWidget } from '@salescore/client-base'
import { getOrganizationIdFromPath, SanitizedHtml, UserAvatar } from '@salescore/client-common'
import { CORE_CONSTANT } from '@salescore/core'
import { logger, numberWithDelimiterFilter, useHover, useResizeObserver } from '@salescore/frontend-common'
import { Button, Popover, Spin, Tag } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import {
  createRef,
  type CSSProperties,
  type MutableRefObject,
  type RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { z } from 'zod'

import { usePickedIds } from '../../../../recoil/records/hooks'
import { useMeValue } from '../../../../recoil/view/hooks'
import { useUsersSelector } from '../../../../recoil/view/selectors/usersSelector'
import { useContextValue } from '../../../recoil/models/propModels'
import type { RSheetColumn, RSheetRecordNode, RSheetsSelectOption } from '../../../types'
import { MINIMUM_WIDTH } from '../../header/DraggableBar'
import type { CellArgument } from './cellArgument'
import { getOptions } from './input/SelectOptionInputCell'
import { KpiCell } from './kpi/KpiCell'
import { RowKpiCell } from './kpi/RowKpiCell'

export function CellBody(argument: CellArgument): JSX.Element {
  if (argument.column.isStreaming && isNull(argument.value)) {
    return <StreamingCell />
  }

  return (
    <>
      <CellDataBody {...argument} />
      {argument.column.isStreaming && <StreamingOverlay />}
    </>
  )
}

// eslint-disable-next-line complexity
export function CellDataBody(argument: CellArgument): JSX.Element {
  const { column, value } = argument
  if (column.width === MINIMUM_WIDTH) {
    return <></>
  }
  // TODO: 手抜き
  if (value === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING) {
    return <StringCell {...{ ...argument, value: `小計` }} />
  }
  if (value === CORE_CONSTANT.KPI_PIVOT_NULL_STRING) {
    return <StringCell {...{ ...argument, value: `-` }} />
  }

  switch (column.metaType) {
    case 'record_url': {
      return <RecordUrlCell {...argument} />
    }
    case 'relation': {
      return <ReferenceCell {...argument} />
    }
    case 'id': {
      if (column.type === 'user') {
        return <UserCell {...argument} />
      }
      return <ReferenceCell {...argument} />
    }
    case 'url': {
      return <UrlCell {...argument} />
    }
    case 'phone_number': {
      return <UrlCell {...argument} />
    }
    case 'email': {
      return <UrlCell {...argument} />
    }
    case 'html': {
      return <HtmlCell {...argument} />
    }
    default: {
      break
    }
  }

  if ((column.selectOptions ?? []).length > 0) {
    if (column.metaType === 'multi_select') {
      return <MultiSelectOptionCell {...argument} />
    }
    return <SelectOptionCell {...argument} />
  }

  const { type } = column
  switch (type) {
    case 'kpi' as const: {
      return <KpiCell {...argument} />
    }
    case 'rowKpi' as const: {
      return <RowKpiCell {...argument} />
    }
    case 'user' as const: {
      return <UserCell {...argument} />
    }
    case 'integer' as const: {
      return <NumberCell {...argument} />
    }
    case 'numeric' as const: {
      return <NumberCell {...argument} />
    }
    case 'date' as const: {
      return <DateCell {...argument} />
    }
    case 'datetime' as const: {
      return <DatetimeCell {...argument} />
    }
    case 'time' as const: {
      return <TimeCell {...argument} />
    }
    case 'boolean' as const: {
      return <BooleanCell {...argument} />
    }
    case 'checkbox' as const: {
      return <BooleanCell {...argument} />
    }
    case 'open' as const: {
      return <OpenCell {...argument} />
    }
    case 'rowCheckbox' as const: {
      return <RowCheckboxCell {...argument} />
    }
    // cell側で出しわけしたので、ここには来ない
    case 'rowIndex' as const: {
      return <span className="rsheet-cell-body-imvalid-row-index" />
    }
    case 'string' as const: {
      return <StringCell {...argument} />
    }
    case 'object' as const: {
      // TODO
      return <StringCell {...argument} />
    }
    case 'array' as const: {
      // TODO: どう表示させるか？一旦JSONにして表示している
      return (
        <MultiSelectOptionCell
          {...argument}
          column={{
            ...argument.column,
            selectOptions: Array.isArray(argument.value)
              ? // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                argument.value.map((value) => ({ value: value as string, label: value as string, color: 'default' }))
              : [],
          }}
        />
      )
    }
    case 'reference' as const: {
      return <ReferenceCell {...argument} />
    }
    case 'notFound' as const: {
      return <></>
    }
    case undefined: {
      return <StringCell {...argument} />
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = type
      return <StringCell {...argument} />
    }
  }
}

function UserCell({ value }: CellArgument) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const id = value as string
  const { usersMapper } = useUsersSelector()
  const user = usersMapper[id]?.resourceUser
  return (
    <div className="pt-2 text-center" style={{ marginTop: -6 }}>
      {isSome(user) ? (
        <>
          <UserAvatar user={user} size="large" />
          <br />
          {user.name}
        </>
      ) : (
        id
      )}
    </div>
  )
}

function RecordUrlCell({ value }: CellArgument) {
  return (
    // リンクを開くのはsetMainCursorByRecordIdMutation側で行うため、ここではaタグは不要
    // <a href={value as string} target="_blank" style={{ color: '#aaa' }}>
    <div style={{ color: '#aaa' }} className="w-full cursor-pointer">
      <LinkOutlined />
    </div>
    // </a>
  )
}

function getUrlProtocol(column: RSheetColumn<RSheetRecordNode>) {
  const oid = getOrganizationIdFromPath()
  // const bbClientOrganizationIds: string[] = [`275afebc-0fef-4f0c-9101-e36aa7816678`]
  const bbClientOrganizationIds: string[] = []
  const callToOrganizationIds: string[] = [`275afebc-0fef-4f0c-9101-e36aa7816678`]
  switch (column.metaType) {
    case 'phone_number': {
      return bbClientOrganizationIds.includes(oid)
        ? 'bbclient:call:'
        : callToOrganizationIds.includes(oid)
          ? 'callto:'
          : 'tel:'
    }
    case 'email': {
      return 'mailto:'
    }
    default: {
      return ''
    } // http
  }
}

// url, tel, emailなどのリンク付きセル
function UrlCell({ value, column, style, setCursorAndUseEditMode }: CellArgument) {
  // eslint-disable-next-line complexity
  const element = useHover((hovered) => {
    const strings = z.string().array().safeParse(value)
    const string = z.string().safeParse(value)
    const emails = strings.success ? strings.data : string.success ? [string.data] : undefined

    if (emails === undefined || emails.isBlank()) {
      return <></>
    }

    return (
      <div style={{ width: '100%', height: '100%', padding: '0 2px' }}>
        {emails.map((email) => (
          <a
            href={`${getUrlProtocol(column)}${email}`}
            target={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
              ['url', 'email'].includes(column.metaType as string) ? '_blank' : ''
            }
            style={{ textDecoration: 'underline', color: style.color }}
            onClick={(e) => {
              e.stopPropagation()

              // phoneのとき、miitelの判定を行う
              if (column.metaType === 'phone_number') {
                const miitel = getMiitelWidget()
                if (miitel !== undefined) {
                  miitel.call(email)
                  e.preventDefault()
                }
              }
            }}
            rel="noreferrer"
          >
            {email}
          </a>
        ))}
        <Button
          icon={<EditOutlined />}
          style={{
            position: 'absolute',
            top: 4,
            right: 2,
            opacity: hovered ? 100 : 0,
          }}
          onClick={(e) => {
            e.stopPropagation()
            setCursorAndUseEditMode()
          }}
        />
      </div>
    )
  })

  return <>{element}</>
}

// eslint-disable-next-line complexity
function ReferenceCell({ value, column, recordNode, style }: CellArgument) {
  const context = useContextValue()
  const label = column.label === undefined ? undefined : column.label(recordNode)
  const colorStyle = {
    color: style.color,
    borderColor: style.color,
    backgroundColor: style.backgroundColor,
  }

  if (!isPresent(value)) {
    return <span style={{ ...colorStyle, color: colorStyle.color ?? '#ccc' }}></span>
  }
  if (isNull(label)) {
    return (
      <span style={{ ...colorStyle, color: colorStyle.color ?? '#ccc' }}>
        <>
          {t('該当なし')} id: {value}
        </>
      </span>
    )
  }
  if (context.asKpiTable === true) {
    // タグで表示するとpaddingの分でむしろ見づらいため
    return (
      <div>
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          label as string
        }
      </div>
    )
  }
  return (
    <Tag
      className="bg-gray-50 text-gray-900"
      style={{
        fontSize: 14,
        padding: '3px 10px',
        marginTop: -4,
        ...colorStyle,
      }}
    >
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        label as string
      }
    </Tag>
  )
}

function NumberCell({ value, column }: CellArgument) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const text = numberWithDelimiterFilter(value as number)
  return (
    <div className={`w-full truncate text-right`}>
      <span>{text}</span>
    </div>
  )
}

const formatToDatetime = (x: string) => {
  try {
    // memo: 以前ここでタイムゾーンの考慮をしてしまっていたが、pg側をtimestamptzで統一したので、不要となった
    return t(`{{datetime, datetimestr}}`, { datetime: dayjs(x).toDate() })
  } catch {
    return x
  }
}

function DatetimeCell({ value }: CellArgument) {
  if (typeof value !== 'string') {
    return <></>
  }

  // 時刻まで表示したいことがなさそうなので、いったん日付のみを表示する
  // dayjsを使うとレンダリングの負荷があがるだろうか？要検討
  return <span>{formatToDatetime(value)}</span>
}

// デフォルトのチェックボックスを使うと、クリックイベントが扱いづらいため、純粋に表示のみを行うコンポーネントとして実装している
export function MyCheckbox({ value }: { value: boolean }) {
  const style: CSSProperties = {
    fontSize: 16,
    color: value ? '#0D6FDA' : '#ddd',
  }
  if (value) {
    return <CheckSquareFilled style={style} />
  }
  return <MinusSquareOutlined style={style} />
}

function BooleanCell({ value }: CellArgument) {
  if (typeof value === 'boolean') {
    return <MyCheckbox value={value} />
  }
  if (typeof value === 'string') {
    return <MyCheckbox value={value === 'true'} />
  }
  return <MyCheckbox value={false} />
}

function RowCheckboxCell({ recordNode }: CellArgument) {
  const [pickedIds] = usePickedIds()
  const value = recordNode.id === undefined ? false : pickedIds.includes(recordNode.id)
  if (typeof value === 'boolean') {
    return <MyCheckbox value={value} />
  }
  if (typeof value === 'string') {
    return <MyCheckbox value={value === 'true'} />
  }
  return <MyCheckbox value={false} />
}

function OpenCell({ value, column }: CellArgument) {
  // 2023/11 一時的な実装。IS-GPTのオブジェクトであれば、再生ボタンを表示
  if (column.node.name === 'custom_d_extracted_calling_metadata_refined') {
    return (
      <PlayCircleFilled style={{ color: CONSTANT.colors.primaryColor }} className="cursor-pointer hover:opacity-70" />
    )
  }
  return <ArrowsAltOutlined style={{ color: '#ddd' }} className="cursor-pointer" />
}

// eslint-disable-next-line complexity
export const SelectOptionTag = ({
  value,
  selectOptions,
  overrideColor,
}: {
  value: unknown
  selectOptions: RSheetsSelectOption[]
  overrideColor?: string
}) => {
  const context = useContextValue()
  if (value === undefined || value === '' || value === null || !['string', 'number'].includes(typeof value)) {
    return <></>
  }

  const option = selectOptions.find((option) => option.value === value)

  if (context.asKpiTable === true) {
    // タグで表示するとpaddingの分でむしろ見づらいため
    return (
      <div>
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          option?.label ?? (value as string)
        }
      </div>
    )
  }

  return (
    <Tag
      color={option?.color ?? 'default'}
      style={{
        // color: overrideColor, // ハイライト条件が設定されているとき、背景色は元のまま、文字色と枠線を変える -> 2023/06 いったん元の色を維持する仕様に変更
        // borderColor: overrideColor,
        fontSize: 14,
        padding: '3px 10px',
        marginTop: -4,
        opacity: option === undefined ? 0.7 : 1,
      }}
    >
      {/* 選択値以外の表示はしない仕様も検討したが、salesforceで「選択肢だが自由入力可」という入力項目があるため表示することにした */}
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
        option?.label ?? (value as string)
      }
    </Tag>
  )
}

function SelectOptionCell({ value, column, recordNode, sheetCell, style }: CellArgument) {
  const { organization } = useMeValue()
  // 要望があった特定の組織のみ、表示時にもオプションを絞り込みする（通常はパフォーマンスの問題があるため一旦行わない）
  const options = [`55c356f0-a8f8-42a0-9e14-bc7184388ae1`].includes(organization.id)
    ? getOptions({
        nodeName: column.node.name,
        options: column.selectOptions ?? [],
        recordNode,
        parent: sheetCell.parentRecordNode,
      })
    : (column.selectOptions ?? [])

  return <SelectOptionTag value={value} selectOptions={options} overrideColor={style.color} />
}

// eslint-disable-next-line complexity
function MultiSelectOptionCell({ value, column, style }: CellArgument) {
  const cellReference = useRef<HTMLDivElement>(null)
  const tagReferences = useRef<Array<RefObject<HTMLElement>>>([])
  const [shownCount, setShouwnCount] = useState<number>(0)
  const [reloadCount, setReloadCount] = useState<number>(0)

  const values = Array.isArray(value) ? value : [value]
  const validated = z.union([z.string(), z.number()]).array().safeParse(values)

  const options = validated.success
    ? validated.data
        // 2023/05 ELT側の不具合で、Salesforceの複数選択項目が空のとき、['']という1要素の配列になってしまう
        // ELT側で対応すべき処理だが、即時の対応が難しかったため、一旦こちらで対応
        // 同様の処理をMultiSelectOptionInputCellでもやっている
        // https://github.com/salescore-inc/issues/issues/198
        .filter((v) => isPresent(v))
        .map(
          (v) =>
            column.selectOptions?.find((option) => option.value === v) ?? {
              label: isPresent(v) ? v : `(空文字列)`, // 上記でフィルタしているので不要なはずだが一応
              color: 'gray',
            },
        )
    : []

  // return <div>{ options.map(x => x.label ).join(',')}</div>

  // cell幅を変更した際に再計算するために、タグをすべて表示状態にする
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  useResizeObserver([cellReference as MutableRefObject<null>], () => {
    for (const reference of tagReferences.current) {
      if (isSome(reference.current)) {
        // サイズ計算のため一旦 display のみ有効にする
        reference.current.style.display = 'inline-block'
        reference.current.style.visibility = 'hidden'
      }
    }

    // すべて表示状態にする際にラグが生じ、タグがちらつくためresizeCountを利用する
    setReloadCount((current) => current + 1)
  })

  // useResizeObserverが上手く動作しないことがあるのでWorkaround実装
  useEffect(() => {
    for (const reference of tagReferences.current) {
      if (isSome(reference.current)) {
        // サイズ計算のため一旦 display のみ有効にする
        reference.current.style.display = 'inline-block'
        reference.current.style.visibility = 'hidden'
      }
    }

    setReloadCount((current) => current + 1)
  }, [cellReference.current?.offsetWidth])

  useEffect(() => {
    setReloadCount((current) => current + 1)
  }, [])

  // eslint-disable-next-line complexity
  useEffect(() => {
    const width = cellReference.current?.offsetWidth ?? 0

    // セル幅に収まるタグを計算する
    // eslint-disable-next-line complexity
    const showLabels = tagReferences.current.reduce<Array<string | number>>((previous, reference, index) => {
      const { current } = reference
      const option = options[index]

      if (!isSome(current) || !isSome(option)) {
        return previous
      }

      if (index === 0) {
        return [option.label]
      }

      const offset = current.offsetLeft + current.offsetWidth

      // 表示幅 + タグ最小幅を超えている場合は省略する
      if (offset <= 0 || offset + 10 > width) {
        return previous
      }

      // 以前に非表示のラベルがある場合は非表示とする
      if (previous.length < index) {
        return previous
      }

      return [...previous, option.label]
    }, [])

    // タグの表示・非表示を設定
    for (const { current, option } of tagReferences.current
      .map((reference, index) => {
        const { current } = reference
        const option = options[index]

        if (!isSome(current) || !isSome(option)) {
          return
        }

        return { current, option }
      })
      .compact()) {
      current.style.display = showLabels.includes(option.label) ? 'inline-block' : 'none'
      current.style.visibility = showLabels.includes(option.label) ? 'visible' : 'hidden'
    }

    setShouwnCount(showLabels.length)
  }, [value, reloadCount])

  if (value === undefined || value === '') {
    return <></>
  }

  if (!validated.success) {
    return <></>
  }

  return (
    <div ref={cellReference} className="flex">
      {options.map((option, index) => {
        tagReferences.current[index] = createRef()

        return (
          <Tag
            className={index === shownCount - 1 ? 'min-w-[10px] shrink truncate' : ''}
            color={option.color ?? 'gray'}
            style={{
              display: 'inline-block',
              visibility: 'hidden',
              // visibility: 'visible',
              fontSize: 14,
              padding: '3px 10px',
              marginTop: -4,
              // color: style.color, // いったん、元の色を維持する仕様
              // borderColor: style.color,
            }}
            ref={tagReferences.current[index]}
          >
            {option.label}
          </Tag>
        )
      })}
      <Tag className={options.length > shownCount ? 'flex-none' : 'hidden'}>{`+${options.length - shownCount}`}</Tag>
    </div>
  )
}

function TimeCell({ value }: CellArgument) {
  if (isNull(value)) {
    return <></>
  }

  if (typeof value !== 'string') {
    logger.debug(`TimeCell expected string type. value:`, value)
    return <></>
  }
  if (/^\d{2}:\d{2}/.test(value)) {
    return <pre style={{ overflow: 'hidden' }}>{value.slice(0, 5)}</pre>
  }

  return <pre style={{ overflow: 'hidden' }}>{value}</pre>
}

function DateCell({ value }: CellArgument) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  return <pre style={{ overflow: 'hidden' }}>{value as string}</pre>
}

// eslint-disable-next-line complexity
function StringCell({ value, height, column, recordNode }: CellArgument) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  const label = column.label === undefined ? undefined : (column.label(recordNode) as string) // 2023/08 StringCellだがラベル値があるのは、現状はsalescore_users_with_groupのみ
  const textLineSize = Math.floor(height * 1.82)
  const lineClampStyle: CSSProperties = {
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: textLineSize,
    display: '-webkit-box',
  }
  const stringValue =
    typeof value === 'string'
      ? value
      : typeof value === 'number'
        ? `${value}`
        : isNull(value)
          ? ``
          : JSON.stringify(value)
  const text = label ?? stringValue
  // TODO: ここでpopoverしてしまうとpadding分を考慮できずhoverの判定が微妙になる。構造を変える必要がある。
  return (
    <Popover content={<pre style={{ width: 400 }}>{text}</pre>} placement="bottomLeft">
      {
        // 変則的だが、1行のときはspanで親要素divのtextOverflowによって三点リーダを表示し、1行より大きい場合はwebkit-line-clampを使って表示
        height === 1 ? <span>{text}</span> : <p style={lineClampStyle}>{text}</p>
      }
    </Popover>
  )
}

function HtmlCell({ value, height }: CellArgument) {
  if (typeof value !== 'string') {
    return <></>
  }
  const htmlContent = <SanitizedHtml rawHtmlText={value} />

  return (
    <Popover
      content={
        <div className="html-cell-popover" style={{ width: 600, maxHeight: '60vh', overflow: 'scroll' }}>
          {htmlContent}
        </div>
      }
      placement="rightTop"
    >
      <div>{htmlContent}</div>
    </Popover>
  )
}

function StreamingCell() {
  return (
    <div className="flex size-full items-center justify-center">
      <span className="size-4">
        <Spin indicator={<LoadingOutlined spin />} />
      </span>
    </div>
  )
}

function StreamingOverlay() {
  return (
    <div className="absolute left-0 top-0 z-[1] size-full bg-gray-200 bg-opacity-30">
      <StreamingCell />
    </div>
  )
}
