import { selector, useRecoilValue } from 'recoil'

import { kpiParameterAtom } from '../../navigation/atoms'
import { periodSelector } from '../../navigation/selector/PeriodSelector'
import { mustacheParameterAtom } from '../atoms'

const mustacheParameterSelector = selector({
  key: 'view/mustacheParameterSelector',
  // eslint-disable-next-line complexity
  get({ get }) {
    const raw = get(mustacheParameterAtom)
    const kpiPivotParameter = get(kpiParameterAtom) // この2つのatomはマージするべきものか？
    const period = get(periodSelector)

    return {
      ...raw,
      ...kpiPivotParameter.queryParameter,
      // date_trunc('month', current_date) のような値をparameterに保存しようかとも思ったが、SQLを書いてしまうとDestinationの差異の対応が大変
      // また、toISOString()を初期は考えていたが、startAtをそのまま文字列で埋め込むので、タイムゾーンでおかしくなるためやめる
      startAt: period?.startAt?.format(`YYYY-MM-DD HH:mm:ss`) ?? kpiPivotParameter.queryParameter?.startAt,
      endAt: period?.endAt?.format(`YYYY-MM-DD HH:mm:ss`) ?? kpiPivotParameter.queryParameter?.endAt,
    }
  },
})

export const useMustacheParameterSelector = () => useRecoilValue(mustacheParameterSelector)
