import { PlusOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import { AddSheetCustomModelPropertyDocument, FetchSheetCustomModelsDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath, Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import type { ModelProperty, NodePropertyName, ViewConfigTreeNode } from '@salescore/core'
import { useBooleanState } from '@salescore/frontend-common'
import { Button, Form, Input, message, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { useSheetColumns } from '../../../../recoil/selectors/useSheetColumns'
import { usePosthogTrackView } from '../../../../recoil/usePosthogTrack'
import { sheetCustomModelsAtom } from '../../../../recoil/view/atoms'
import { useMeValue, useViewValue } from '../../../../recoil/view/hooks'
import type { ReferenceToPropertyForAddChildNodeMutation } from '../../../../recoil/view/mutations/tree/addChildNode'

type AvailableType = 'string' | 'text' | 'numeric' | 'date' | 'datetime' | 'boolean' | 'options'
const options: Array<{
  label: string
  value: AvailableType
}> = [
  {
    label: t(`テキスト`),
    value: 'string',
  },
  {
    label: t(`テキスト(複数行)`),
    value: 'text',
  },
  { value: 'numeric', label: t(`数値`) },
  { value: 'date', label: t(`日付`) },
  { value: 'datetime', label: t(`日時`) },
  { value: 'boolean', label: t(`真偽値`) },
  { value: 'options', label: t(`選択肢`) },
]

interface FormValue {
  label: string
  type: AvailableType
  selectOptionsText: string | undefined
}

// eslint-disable-next-line complexity
export function SheetCustomModelPropertyForm({
  node,
  parentNode,
  addField,
  addChildNode,
}: {
  node: ViewConfigTreeNode
  parentNode: ViewConfigTreeNode | undefined
  addField: (nodeProperty: NodePropertyName) => void
  addChildNode: (
    referenceToProperty: ReferenceToPropertyForAddChildNodeMutation,
    option?: { skipSetCurrentNodePath?: boolean },
  ) => void
}) {
  const [sheetCustomModels, setSheetCustomModels] = useRecoilState(sheetCustomModelsAtom)
  const sheetColumns = useSheetColumns()
  const targetNode = sheetCustomModels.map((x) => x.name).includes(node.name) ? parentNode : node
  const view = useViewValue()
  const [add] = useMutation(AddSheetCustomModelPropertyDocument)
  const [form] = useForm<FormValue>()
  const [type, setType] = useState<undefined | AvailableType>(options.first()?.value)
  const loading = useBooleanState()
  const posthogTrackView = usePosthogTrackView()
  const me = useMeValue()
  const fetchSheetCustomModelsQuery = useQuery(FetchSheetCustomModelsDocument, {
    // refetchのみで使う
    variables: {
      organizationId: me.organization.id,
      viewId: view.id,
    },
  })

  useEffect(() => {
    posthogTrackView(POSTHOG_EVENTS.view_sheet_custom_model_property_form, {
      nodeName: targetNode?.name,
    })
  }, [])

  if (targetNode === undefined) {
    // ありえないはず
    return <></>
  }

  return (
    <div
      onKeyDown={(e) => {
        e.stopPropagation()
      }}
    >
      <Form<FormValue>
        layout="vertical"
        form={form}
        className="p-4"
        initialValues={{
          type: options.first()?.value,
        }}
        onFinish={
          // eslint-disable-next-line complexity
          async (value) => {
            const converted = convertType(value.type, value.selectOptionsText)
            try {
              loading.setTrue()
              const { data: result } = await add({
                variables: {
                  organizationId: getOrganizationIdFromPath(),
                  input: {
                    viewId: view.id,
                    nodeName: targetNode.name,
                    label: value.label,
                    type: converted.type,
                    meta: converted.meta,
                    selectOptions: converted.selectOptions,
                    // XXX: カスタムカラムの追加を行うタイミングで、ビューの情報を参照して不要となったテーブル・カラムの削除処理を行なっている。
                    //      これは、列削除のタイミングでsheetCustomModelの更新処理を行うのがやや怖かったため、この設計になっている。
                    //      2023/11以前はビューのconfigの変更はバックエンド側でも即時保存されていたが、これ以降より変更は即時保存されなくなり、
                    //      現在のclient側でのビューの情報と、server側でのビューの情報が一致しなくなる形になった。
                    //      上記の削除処理を行う際に、最新のビューのカラム情報が必要なので、これを引数で渡している
                    currentViewConfigFieldProperties: sheetColumns.map((x) => x.configField?.property).compact(),
                  },
                },
              })
              const r = result?.addSheetCustomModelPropertyV2
              if (isNull(r)) {
                void message.error(t(`追加に失敗しました`)) // TODO
                return
              }
              const { sheetCustomModel, addedProperty } = r
              // modelの内容を更新
              setSheetCustomModels((xs) => [sheetCustomModel, ...xs].uniqueBy((x) => x.name)) // 既にあれば、先頭が優先されるので更新される
              if (node.modelName !== sheetCustomModel.name) {
                addChildNode(
                  {
                    // 既にノードがあればそのまま追加、なければ新規作成して追加
                    parentNodeName: node.name,
                    nodeName: sheetCustomModel.name,
                    modelName: sheetCustomModel.name,
                    propertyName: 'parent_id',
                    referenceTo: {
                      modelName: node.modelName,
                    },
                  },
                  { skipSetCurrentNodePath: true },
                )
              }
              addField({
                nodeName: sheetCustomModel.name,
                modelName: sheetCustomModel.name,
                propertyName: addedProperty.name,
              })
              // ここまででモデルの内容は更新されているが、GraphQLのレイヤーに残っているキャッシュをクリアするためにrefetchする
              // （本来であれば追加のmutationのレスポンスで更新されるはずだが、現状の実装ではそうなっていない？）
              await fetchSheetCustomModelsQuery.refetch()

              void message.success(t(`カスタム項目を作成しました`))
              Posthog.track(POSTHOG_EVENTS.create_sheet_custom_model_property, {
                viewId: view.id,
                nodeName: targetNode.name,
                label: value.label,
                type: converted.type,
                meta: converted.meta,
                selectOptions: converted.selectOptions,
              })
              form.resetFields()
            } catch (error) {
              if (error instanceof Error) {
                void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
              } else {
                void message.error(t(`エラーが発生しました。`))
              }
            } finally {
              loading.setFalse()
            }
          }
        }
      >
        <Form.Item
          name="label"
          required
          label={t(`ラベル`)}
          rules={[{ required: true, message: t(`入力してください`) }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          required
          label={t(`データ型`)}
          rules={[{ required: true, message: t(`入力してください`) }]}
        >
          <Select
            className="disable-on-click-outside"
            options={options}
            onChange={(value) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
              setType(value as AvailableType)
            }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          />
        </Form.Item>
        {type === 'options' && (
          <Form.Item
            name="selectOptionsText"
            required
            label={t(`選択肢(改行で入力)`)}
            rules={[
              { required: true, message: t(`入力してください`) },
              {
                validator(rule, value, callback) {
                  if (typeof value !== 'string') {
                    callback()
                    return
                  }
                  const options = getOptionsFromText(value)
                  if (options.uniqueBy((x) => x.value).length === options.length) {
                    callback()
                  } else {
                    callback(t(`重複した選択肢があります`))
                  }
                },
              },
            ]}
          >
            <Input.TextArea rows={8} />
          </Form.Item>
        )}
        <div className="flex flex-row-reverse">
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<PlusOutlined />} loading={loading.isTrue}>
              {t(`追加`)}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

function convertType(
  type: AvailableType,
  selectOptionsText: string | undefined,
): Pick<ModelProperty, 'type' | 'selectOptions' | 'meta'> {
  switch (type) {
    case 'string': {
      return {
        type: 'string',
      }
    }
    case 'text': {
      return {
        type: 'string',
        meta: 'text',
      }
    }
    case 'numeric': {
      return {
        type: 'numeric',
      }
    }
    case 'date': {
      return {
        type: 'date',
      }
    }
    case 'datetime': {
      return {
        type: 'datetime',
      }
    }
    case 'boolean': {
      return {
        type: 'boolean',
      }
    }
    case 'options': {
      // TODO: selecttOptionsの判定
      return {
        type: 'string',
        selectOptions: getOptionsFromText(selectOptionsText),
      }
    }
  }
}

export function getOptionsFromText(selectOptionsText: string | undefined) {
  return (
    selectOptionsText
      ?.split('\n')
      .map((x) => x.trim())
      .filter((x) => x !== '')
      .map((value) => ({
        value,
        label: value,
      })) ?? []
  )
}
