import { isNull } from '@salescore/buff-common'
import { z } from 'zod'

import { CORE_CONSTANT } from '../../constant'
import type { ViewConfigTreeNode } from '../../schemas/view_config'
import { flatNodes } from '../query/executeViewQuery/util'

export interface ViewRecordFieldChangeItem {
  id: string
  fieldName: string
  value: unknown
  rowIndex: number
  innerRowIndex: number
}

export interface ViewRecordFieldChange {
  id?: string
  streamName: string
  fieldChanges: ViewRecordFieldChangeItem[] // 2022/06 コールバックを呼ぶために実装。全体的にやや実装が雑
  recordNameLabel?: string | undefined
  before?: Record<string, unknown>
  after?: Record<string, unknown>
  deleted?: boolean
}

export const eltChangeToCreateSchema = z.object({
  type: z.literal(`create`),
  modelName: z.string(),
  id: z.string(), // このIDはUI側で発行した一時IDなので注意
  data: z.record(z.unknown()),
})
export type EltChangeToCreate = z.infer<typeof eltChangeToCreateSchema>

export const eltChangeToUpdateSchema = z.object({
  type: z.literal(`update`),
  modelName: z.string(),
  id: z.string(),
  before: z.record(z.unknown()),
  data: z.record(z.unknown()),
  recordNameLabel: z.string().optional(),
})
export type EltChangeToUpdate = z.infer<typeof eltChangeToUpdateSchema>

export const eltChangeToDeleteSchema = z.object({
  type: z.literal(`delete`),
  modelName: z.string(),
  id: z.string(),
  before: z.record(z.unknown()),
  recordNameLabel: z.string().optional(),
})
export type EltChangeToDelete = z.infer<typeof eltChangeToDeleteSchema>

export const eltChangeSchema = z.union([eltChangeToCreateSchema, eltChangeToUpdateSchema, eltChangeToDeleteSchema])
export type EltChange = z.infer<typeof eltChangeSchema>
export const eltChangesChunkSchema = z.object({
  modelName: z.string(),
  changes: eltChangeSchema.array(),
})
export type EltChangesChunk = z.infer<typeof eltChangesChunkSchema>

export function generateEltChangesChunk(
  viewRecordChanges: ViewRecordFieldChange[],
  tree: ViewConfigTreeNode | undefined,
): EltChangesChunk[] {
  const eltChanges = mergeChanges(viewRecordChanges)
  const chunks = eltChanges
    .groupBy((x) => x.modelName)
    .map(
      (modelName, groupedEltChanges): EltChangesChunk => ({
        modelName,
        changes: groupedEltChanges,
      }),
    )
  // 親子を同時に新規作成するような際に、連携先の親子関係に応じてchunkを並び替える必要がある。
  // 現状のロジックだと、単純にtreeの構造を親から順番に並べれば十分なのでそれで実行する
  const nodes = tree === undefined ? [] : flatNodes(tree) // flatNodesすれば親から順番にdfsで並ぶ
  return chunks.sortBy((chunk) => nodes.findIndex((node) => node.modelName === chunk.modelName))
}

function mergeChanges(changes: ViewRecordFieldChange[]): EltChange[] {
  const groupedChanges = changes.groupBy((change) => JSON.stringify([change.streamName, change.id]))
  const mergedChanges = groupedChanges
    // eslint-disable-next-line complexity
    .map((_key, groupedChange): EltChange | undefined => {
      const { id } = groupedChange[0]!
      const modelName = groupedChange[0]?.streamName // change側もmodelNameにしたい
      const recordNameLabel = groupedChange[0]?.recordNameLabel
      if (modelName === undefined) {
        return undefined
      }
      if (isNull(id)) {
        throw new Error(`IDがnullです`) // IDがnullになることはないはず
      }

      const after = groupedChange.reduce((accumulator, c) => ({ ...accumulator, ...c.after }), {})
      const before = groupedChange.reduce((accumulator, c) => ({ ...c.before, ...accumulator }), {})

      // 削除差分が1件でもあれば削除
      if (groupedChange.some((x) => x.deleted === true)) {
        // 削除差分であり、かつ新規レコードであるときはサーバー側に送信する必要がない
        if (id.startsWith(CORE_CONSTANT.VIEW_NEW_RECORD_PREFIX)) {
          return undefined
        }

        return {
          type: `delete`,
          id,
          modelName,
          before,
          recordNameLabel,
        }
      }

      if (id.startsWith(CORE_CONSTANT.VIEW_NEW_RECORD_PREFIX)) {
        return {
          type: `create`,
          id,
          modelName,
          data: after,
        }
      }

      return {
        type: `update`,
        id,
        modelName,
        recordNameLabel,
        before,
        data: after,
      }
    })
    .compact()

  return mergedChanges.compact()
}
