import { ApolloClient, ApolloLink, from, InMemoryCache, type NormalizedCacheObject, split } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { VERSION } from '@salescore/buff-common'
import { Kind, OperationTypeNode } from 'graphql'
import { createClient } from 'graphql-ws'
import { useEffect, useState } from 'react'

import { cleanTypeNameLink } from './apollo/clearTypeNameLink'
import { errorLink } from './apollo/errorLink'
import { httpLink } from './apollo/httpLink'
import { retryLink } from './apollo/retryLink'

const createAuthLink = (token: string | undefined): ApolloLink =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: token === undefined ? '' : `Bearer ${token}`,
      },
    }))

    return forward(operation)
  })

const createWsLint = (token: string | undefined): GraphQLWsLink =>
  new GraphQLWsLink(
    createClient({
      // TODO: 本当はこのfallbackは止めたい
      url: process.env.GRAPHQL_WS_URL ?? 'ws://localhost:4000/graphql',
      connectionParams: {
        authToken: token,
      },
    }),
  )

export const createApolloClient = (token: string | undefined): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION
      },
      createWsLint(token),
      from([cleanTypeNameLink, errorLink, createAuthLink(token), retryLink, httpLink]),
    ),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only', // cache-and-networkが選べなかった
      },
    },
    name: 'salescore-client',
    version: VERSION,
  })

export const useApolloClient = (token?: string): ApolloClient<NormalizedCacheObject> | undefined => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>()

  const awaitSetClient = (): void => {
    const client = createApolloClient(token)
    setClient(client)
  }

  useEffect(() => {
    awaitSetClient()
  }, [])

  return client
}
