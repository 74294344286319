import type {
  FavoriteFieldsFragment,
  SheetThreadCommentNotification,
  ViewForSiderFieldsFragment,
  ViewGroupFieldsFragment,
  ViewGroupTypeEnum,
} from '@salescore/client-api'
import type { ViewConfig } from '@salescore/core'
import {
  localStorageEffect,
  localStorageWithQueryParameterEffect,
  type ModalAtomType,
  removeQueryParameterToUrl,
} from '@salescore/frontend-common'
import { atom, atomFamily, DefaultValue, selector } from 'recoil'
import { z } from 'zod'

import type { ExpandedAntdNode } from './functions/convertFlatNodesToAntdTree'

const prefix = `view/pages`

export const viewGroupIdsAtom = atom<string[]>({
  key: `${prefix}/viewGroupIds`,
  default: [],
})

// NOTE: idが見つからなかった場合のレスポンスの型を明示的に指定する必要があるため、undefinedをunionしている。
export const viewGroupAtomFamily = atomFamily<ViewGroupFieldsFragment | undefined, { id: string }>({
  key: `${prefix}/viewGroup`,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  default: undefined as never,
})

export const viewGroupsAtom = selector<ViewGroupFieldsFragment[]>({
  key: `${prefix}/viewGroups`,
  get: ({ get }) => {
    const viewGroupIds = get(viewGroupIdsAtom)
    return viewGroupIds
      .map((id) => {
        const viewGroup = get(viewGroupAtomFamily({ id }))
        return viewGroup
      })
      .compact()
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(viewGroupIdsAtom, [])
    } else {
      set(viewGroupIdsAtom, newValue.map((viewGroup) => viewGroup.id).compact())
      for (const viewGroup of newValue) {
        if (viewGroup.id !== undefined) {
          // TODO: idがundefinedになるのはいつ？
          set(viewGroupAtomFamily({ id: viewGroup.id }), viewGroup)
        }
      }
    }
  },
})

export const viewIdsAtom = atom<string[]>({
  key: `${prefix}/viewIds`,
  default: [],
})

// NOTE: idが見つからなかった場合のレスポンスの型を明示的に指定する必要があるため、undefinedをunionしている。
export const viewAtomFamily = atomFamily<Omit<ViewForSiderFieldsFragment, '__typename'> | undefined, { id: string }>({
  key: `${prefix}/view`,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  default: undefined as never,
})

export const viewsAtom = selector<Array<Omit<ViewForSiderFieldsFragment, '__typename'>>>({
  key: `${prefix}/views`,
  get: ({ get }) => {
    const viewIds = get(viewIdsAtom)
    return viewIds
      .map((id) => {
        const view = get(viewAtomFamily({ id }))
        return view
      })
      .compact()
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(viewIdsAtom, [])
    } else {
      set(viewIdsAtom, newValue.map((view) => view.id).compact())
      for (const view of newValue) {
        if (view.id !== undefined) {
          // TODO: idがundefinedになるのはいつ？
          set(viewAtomFamily({ id: view.id }), view)
        }
      }
    }
  },
})

// ツリーはselectorにせずatomで持つことで、必要なときに必要な箇所のみを更新する形にする
// views,viewGroupsに変化があった場合は、都度リセットする
export const viewGroupTreeAtom = atom<ExpandedAntdNode[]>({
  key: `${prefix}/viewGroupTree`,
  default: [],
  dangerouslyAllowMutability: true,
})

export const viewGroupTreeExpandedKeysAtom = atom<string[]>({
  key: `${prefix}/viewGroupTreeExpandedKeysAtom`,
  default: [],
  effects: [localStorageEffect(`${prefix}/viewGroupTreeExpandedKeysAtom`, z.string().array())],
})

export const viewGroupTreeFavoritedExpandedKeysAtom = atom<string[]>({
  key: `${prefix}/viewGroupTreeFavoritedExpandedKeysAtom`,
  default: [],
  effects: [localStorageEffect(`${prefix}/viewGroupTreeFavoritedExpandedKeysAtom`, z.string().array())],
})

export const privateViewGroupTreeAtom = atom<ExpandedAntdNode[]>({
  key: `${prefix}/privateViewGroupTree`,
  default: [],
  dangerouslyAllowMutability: true,
})

export const archivedViewGroupTreeAtom = atom<ExpandedAntdNode[]>({
  key: `${prefix}/archivedViewGroupTreeAtom`,
  default: [],
  dangerouslyAllowMutability: true,
})

export const privateViewGroupTreeExpandedKeysAtom = atom<string[]>({
  key: `${prefix}/privateViewGroupTreeExpandedKeysAtom`,
  default: [],
  effects: [localStorageEffect(`${prefix}/viewGroupTreeExpandedKeysAtom`, z.string().array())],
})

export const pickedViewIdsAtom = atom<string[]>({
  key: `${prefix}/pickedViewIds`,
  default: [],
  effects: [localStorageEffect(`${prefix}/pickedViewIds`, z.string().array())],
})

export const pickedViewIdAtom = atom<string | null | undefined>({
  key: `${prefix}/pickedViewId`,
  default: undefined,
  effects: [
    localStorageWithQueryParameterEffect(`${prefix}/pickedViewId`, `viewId`),
    ({ onSet }) => {
      onSet((newValue, _, isReset) => {
        // どこでこの処理をやるべきか悩ましいが、いったんここで
        removeQueryParameterToUrl(`searchQuery`)
      })
    },
  ],
})

export type UrlQueryParameterMap = Map<string, { value: string; pickedAt: number }>

export const urlQueryParametersAtom = atom<UrlQueryParameterMap>({
  key: `${prefix}/urlQueryParameters`,
  default: new Map(),
})

export const viewGroupModalAtom = atom<
  ModalAtomType<{ parentViewGroupId?: string; viewGroupId?: string; type: ViewGroupTypeEnum; private: boolean }>
>({
  key: `${prefix}/viewGroupModal`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const viewFormModalAtom = atom<
  ModalAtomType<{
    id?: string
    viewGroupId?: string
    type?: ViewConfig['type']
    private?: boolean
  }>
>({
  key: `${prefix}/viewFormModal`,
  default: {
    visible: false,
    content: undefined,
  },
})

export type MoveViewOrViewGroupModalAtomArg =
  | {
      type: 'view'
      item: ViewForSiderFieldsFragment
      action: 'publish' | 'unarchive'
    }
  | {
      type: 'viewGroup'
      item: Pick<
        ViewGroupFieldsFragment,
        'id' | 'type' | 'private' | 'createdById' | 'archived' | 'name' | 'viewGroupIdBeforeArchived'
      >
      action: 'publish' | 'unarchive'
    }
export const moveViewOrViewGroupModalAtom = atom<ModalAtomType<MoveViewOrViewGroupModalAtomArg>>({
  key: `${prefix}/moveViewOrViewGroupModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export type ViewOrViewGroupModalArg =
  | {
      type: 'view'
      item: ViewForSiderFieldsFragment
    }
  | {
      type: 'viewGroup'
      item: Pick<
        ViewGroupFieldsFragment,
        'id' | 'type' | 'private' | 'createdById' | 'archived' | 'name' | 'viewGroupIdBeforeArchived'
      >
    }
export const deleteViewOrViewGroupModalAtom = atom<ModalAtomType<ViewOrViewGroupModalArg>>({
  key: `${prefix}/deleteViewOrViewGroupModalAtom`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const hasChangesAtom = atom<boolean>({
  key: `${prefix}/hasChanges`,
  default: false,
})

// view-features パッケージの中の hasChangeToViewSchema || isSavingCurrentView と同じもの
// タブを移動する時の警告メッセージを表示するため、本パッケージにもその情報を持たせている
export const isSaveConfigButtonActiveAtom = atom<boolean>({
  key: `${prefix}/isSaveConfigButtonActive`,
  default: false,
})

export const favoritesAtom = atom<FavoriteFieldsFragment[]>({
  key: `${prefix}/favorites`,
  default: [],
})

export const searchKeyAtom = atom<string>({
  key: `${prefix}/searchKey`,
  default: '',
})

export const sheetThreadCommentNotificationsDrawerOpenAtom = atom<boolean>({
  key: `${prefix}/sheetThreadCommentNotificationsDrawerOpen`,
  default: false,
})

export const sheetThreadCommentNotificationsAtom = atom<SheetThreadCommentNotification[]>({
  key: `${prefix}/sheetThreadCommentNotifications`,
  default: [],
})

export const hasVersionUpdateAtom = atom<boolean>({
  key: `${prefix}/hasVersionUpdate`,
  default: false,
})
