import { useSuspenseQuery } from '@apollo/client'
import { compact, isNull } from '@salescore/buff-common'
import { FetchConnectionsWithStreamsDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath, HandleQuery } from '@salescore/client-common'
import type { useModal } from '@salescore/frontend-common'
import { Button, Col, Form, Modal, Row, Select } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useState } from 'react'

// eslint-disable-next-line complexity
export const Body = ({ onSave }: { onSave: (items: Array<{ value: string; label: string }>) => void }): ReactNode => {
  const {
    data: { connections },
  } = useSuspenseQuery(FetchConnectionsWithStreamsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
    fetchPolicy: 'cache-and-network',
  })
  const [streamName, setStreamName] = useState<string>()
  const [propertyName, setPropertyName] = useState<string>()

  const streams = connections.filter((x) => x.source.provider !== 'salescore').flatMap((x) => x.streams)
  const pickedStream = streams.find((x) => x.name === streamName)
  const properties = pickedStream?.properties ?? []
  const pickedProperty = properties.find((x) => x.name === propertyName)
  // TODO: SQLを実行して選択肢以外も取得
  const propertyOptionGroups = properties
    .filter((x) => (x.meta.selectOptions ?? []).length > 0)
    .map((property) => ({
      label: property.meta.label,
      value: property.name,
      type: property.destination.type,
      key: property.meta.label,
    }))
    .groupBy((x) => x.type)
    .toArray()
  const pickedSelectOptions = pickedProperty?.meta.selectOptions ?? []
  const dimensions = compact(pickedSelectOptions.map((x) => ({ value: x.value, label: x.label })))

  return (
    <div>
      <Row justify="space-between">
        <Col span={11}>
          <Form>
            {/* PropertyCascaderを使うとUIを統一できて便利だが、全てのstreamの全てのpropertyを計算するとそれなりに重くなりそうなので一応避ける */}
            <Row justify="space-between">
              <Col span={20}>
                <Form.Item label={t(`オブジェクト名`)}>
                  <Select
                    onChange={(event) => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                      setStreamName(event as string)
                      setPropertyName(undefined)
                    }}
                    options={streams.map((stream) => ({
                      label: stream.meta.label,
                      key: stream.meta.label,
                      value: stream.name,
                    }))}
                    showSearch
                    filterOption={(input, option) => (option?.key ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item label={t(`項目名`)}>
                  <Select
                    onChange={(event) => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                      setPropertyName(event as string)
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                      ((option?.key as string) ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    disabled={isNull(pickedStream)}
                  >
                    {propertyOptionGroups.map(([groupName, options], index) => (
                      // 手抜き
                      <Select.OptGroup key={index} label={<>{t(`文字列`)}</>}>
                        {options
                          .mySortBy((x) => x.label.length)
                          .map((option) => (
                            <Select.Option value={option.value} key={[option.value, option.label].join('-')}>
                              {option.label}
                            </Select.Option>
                          ))}
                      </Select.OptGroup>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Row justify="end" className="w-full">
                <Col>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      onSave(dimensions)
                    }}
                  >
                    {t(`インポート`)}
                  </Button>
                </Col>
              </Row>
            </Row>
          </Form>
        </Col>
        <Col span={12}>
          <div>
            {pickedSelectOptions.length > 0 ? (
              <div>
                <div>{t(`インポートされる項目`)}</div>
                <pre className="bg-gray-100 p-4 text-xs">
                  {dimensions.map((option) => `${option.label}, ${option.value}`).join('\n')}
                </pre>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export const ImportDimensionModal = ({
  onSave,
  modal,
}: {
  onSave: (items: Array<{ value: string; label: string }>) => void
  modal: ReturnType<typeof useModal>
}): ReactNode => (
  <Modal
    open={modal.isModalVisible}
    onCancel={modal.hideModal}
    width={'60%'}
    cancelText={t(`閉じる`)}
    okButtonProps={{ style: { display: 'none' } }}
    title={<div>{t(`目標軸のインポート`)}</div>}
    style={{ top: '10%' }}
  >
    <HandleQuery>
      <Body onSave={onSave} />
    </HandleQuery>
  </Modal>
)
