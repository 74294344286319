import { FilterOutlined } from '@ant-design/icons'
import type { ViewConfigFilterNode } from '@salescore/core'
import { stableStringify } from '@salescore/features'
import { addModelIdProperty, useBooleanState } from '@salescore/frontend-common'
import { Col, Row, Space, Switch } from 'antd'
import { t } from 'i18next'

import { convertToFilterNodeForForm } from '../../../../recoil/navigation/hooks'
import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'
import {
  applyAdvancedSettingsFlag,
  ViewFilterTreeForm,
} from '../../ViewUISheetWithNavigation/SheetNavigation/filter/ViewFilterTreeForm'

interface KpiFormFilterTreeFormItemProperties {
  disabled?: boolean
}

// eslint-disable-next-line complexity
export function KpiFormFilterTreeFormItem(properties: KpiFormFilterTreeFormItemProperties) {
  const { currentSheet, currentNodeProperties, form, setSheetConfig, setFormModified, modelSearcher } =
    useKpiFormSelectors()
  const isAdvancedModeState = useBooleanState(
    (currentSheet.filterTree?.children ?? []).isPresent() || currentSheet.filterTree?.logicalOperator === 'or',
  )
  const onChange = (filterTree: ViewConfigFilterNode | undefined, option?: { asInitialization?: boolean }) => {
    setSheetConfig({
      ...currentSheet,
      filterTree,
    })
    // 変更を行ったら、変更フラグを立てる
    // フォームの初期レンダリング時に、一部ロジックでonChangeを呼ぶことがあるが、この際は変更フラグを立てない
    if (option?.asInitialization !== true) {
      setFormModified(true)
    }
  }
  const propertiesWithNode = currentNodeProperties.map((x) => ({
    node: x.node.node,
    model: x.model,
    property: x.property,
    nodePathAsLabel: x.node.nodePathAsLabel,
  }))
  const rootModel = modelSearcher.searchModel(currentSheet.tree?.modelName)
  const properitesAndModelIdWithNode = addModelIdProperty(
    { node: currentSheet.tree, model: rootModel },
    propertiesWithNode,
  )

  return (
    <div>
      <Row justify="space-between" className="mb-4">
        <Col>
          <Space className="text-md font-normal">
            <FilterOutlined />
            <span>{t(`集計条件`)}</span>
          </Space>
        </Col>
        <Col>
          <Space>
            {t(`高度なフィルタ条件`)}
            <Switch
              checked={isAdvancedModeState.isTrue}
              onChange={(value) => {
                if (value) {
                  isAdvancedModeState.setTrue()
                } else {
                  isAdvancedModeState.setFalse()
                }
              }}
              disabled={properties.disabled}
            />
          </Space>
        </Col>
      </Row>
      <ViewFilterTreeForm
        // ViewFilterTreeFormは内部で状態をもっている
        // 初期化時以外で、内部の状態を変更するべきときは、以下のキャッシュキーに指定している要素を変更する。このケースは以下が該当する
        // ・ツリーを変更した時
        // ・KPIプレビューから絞り込み条件を変更した時（このとき、filterFormItemCacheKeyを更新している）
        cacheKey={stableStringify([currentSheet.tree ?? '', form.filterFormItemCacheKey ?? ''])}
        filterTree={convertToFilterNodeForForm(
          currentSheet.filterTree ?? { logicalOperator: `and`, leafs: [], children: [] },
        )}
        propertiesWithNode={properitesAndModelIdWithNode}
        isAdvancedMode={isAdvancedModeState.isTrue}
        inNestedForm
        onChange={(filterTree, option) => {
          const newFilterTree = applyAdvancedSettingsFlag(isAdvancedModeState.isTrue, filterTree)
          onChange(newFilterTree, option)
        }}
        disabled={properties.disabled}
      />
    </div>
  )
}
