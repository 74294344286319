import { useApolloClient } from '@apollo/client'
import { isNull, isSome, isTruthy } from '@salescore/buff-common'
import { FetchViewQueryResultDocument, type ViewQueryResultCursorInput } from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { CORE_CONSTANT, type ViewQueryField, type ViewQueryRecordNode } from '@salescore/core'
import { message } from 'antd'
import merge from 'lodash.merge'
import { type SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import type { ChangeHistory } from '../../../state/useViewRecordsState/useChangesState'
import { emptyQuery } from '../../view/atoms'
import { useMeValue, useViewValue } from '../../view/hooks'
import { useCachedViewsSelector } from '../../view/selectors/cachedViewSelector'
import { useConfigSheetSelector } from '../../view/selectors/configSheetSelector'
import {
  changesAtom,
  cursorAtom,
  errorsAtom,
  organizationIdAtom,
  pageSizeAtom,
  recordsAtom,
  snapshotsAtom,
  viewQueryResultAtom,
} from '../atoms'
import { useLoadingState } from '../hooks'
import { highlightConfigSelector } from '../selectors/highlightConfigSelector'
import { useMustacheParameterSelector } from '../selectors/mustacheParameterSelector'
import { listQuerySelector } from '../selectors/querySelector'
import { useSheetThreads } from './useSheetThreads'

interface Save {
  kind: 'save'
}

interface Reset {
  kind: 'reset'
}

interface ChangeView {
  kind: 'changeView'
}

interface LoadNext {
  kind: 'loadNext'
}

interface AddColumn {
  kind: 'addColumn'
  fields: ViewQueryField[]
}

interface DeleteColumn {
  kind: 'deleteColumn'
  fields: ViewQueryField[]
}

// TODO: 適当に定義した以下のインターフェースのプロパティの型をきちんと定義する
interface AddFilter {
  kind: 'addFilter'
  name: string
}

interface DeleteFilter {
  kind: 'deleteFilter'
  name: string
}

interface AddSort {
  kind: 'addSort'
  name: string
}

interface DeleteSort {
  kind: 'deleteSort'
  name: string
}

export type RefetchReason =
  | Save
  | Reset
  | ChangeView
  | LoadNext
  | AddColumn
  | DeleteColumn
  | AddFilter
  | DeleteFilter
  | AddSort
  | DeleteSort

// TODO: writable selectorに移動
export const useRefetchMutation = () => {
  const view = useViewValue()
  const me = useMeValue()
  const { config, shouldJoinRecordsInApplication } = useConfigSheetSelector()
  const organizationId = useRecoilValue(organizationIdAtom)
  const mustacheParameter = useMustacheParameterSelector()
  const { query, queryWithAdditionalOption } = useRecoilValue(listQuerySelector)
  const client = useApolloClient()
  const loading = useLoadingState()
  const pageSize = useRecoilValue(pageSizeAtom)
  const cursor = useRecoilValue(cursorAtom)
  const { dateForDiffHighlight } = useRecoilValue(highlightConfigSelector)
  const [changes, setChanges] = useRecoilState(changesAtom)
  const { updateCachedView } = useCachedViewsSelector()
  const { fetchAndUpdateSheetThreads } = useSheetThreads()

  const setErrors = useSetRecoilState(errorsAtom)
  const setRecords = useSetRecoilState(recordsAtom)
  const setSnapshots = useSetRecoilState(snapshotsAtom)
  const setCursor = useSetRecoilState(cursorAtom)
  const setViewQueryResult = useSetRecoilState(viewQueryResultAtom)

  const posthogProperties = {
    organizationId,
    organizationName: me.organization.name,
    viewId: view.id,
    viewType: view.type,
    viewName: view.name,
    userName: me.myUser.name,
    email: me.myUser.identity.email,
  }

  // eslint-disable-next-line complexity
  const fetchViewQueryResult = async ({
    cursor,
    useCache,
  }: {
    cursor: ViewQueryResultCursorInput
    useCache?: boolean
  }) => {
    const result = await client.query({
      query: FetchViewQueryResultDocument,
      variables: {
        organizationId,
        viewQuery: queryWithAdditionalOption,
        mustacheParameter,
        dateForDiffHighlight,
        cursor,
        // アプリケーションジョインをするかどうかは組織設定側で決めるが、
        // KPIセルをドリルダウンしたときは強制的にアプリケーションジョインを解除
        shouldJoinRecordsInApplication: view.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)
          ? false
          : config.type === 'sheet'
            ? shouldJoinRecordsInApplication
            : undefined,
        viewId: view.id,
      },
      fetchPolicy: isTruthy(useCache) ? 'cache-first' : 'no-cache', // cache-and-networkが選べない？
    })
    if (isSome(result.error)) {
      setErrors([JSON.stringify(result.error)])
      sendRefetchErrorToPosthog({
        ...posthogProperties,
        error: result.error,
      })
      return
    }
    setErrors([])

    if (isNull(result.data)) {
      return
    }

    const { viewQueryResult } = result.data
    const { error, warn } = viewQueryResult

    if (isSome(error)) {
      setErrors([error])
      sendRefetchErrorToPosthog({
        ...posthogProperties,
        error,
      })
      void message.error(error)
      return
    }
    if (isSome(warn)) {
      setErrors([warn])
      void message.warning(warn)
    } else {
      setErrors([])
    }

    return viewQueryResult
  }

  // eslint-disable-next-line complexity
  const fetch = async ({
    withCursor,
    refetchReason,
    useCache,
  }: {
    withCursor: boolean
    refetchReason: RefetchReason
    useCache?: boolean
  }) => {
    // TODO: 残念な実装になってしまった……
    if (query.tree.name === emptyQuery.tree.name) {
      return
    }
    const c = withCursor ? cursor : undefined
    const defaultCursor: ViewQueryResultCursorInput = {
      page: 1,
      pageSize,
      chunkSize: 0,
    }
    try {
      loading.setTrue()
      const viewQueryResult = await fetchViewQueryResult({ cursor: c ?? defaultCursor, useCache })
      loading.setFalse()
      if (!viewQueryResult) {
        return
      }
      setViewQueryResult(viewQueryResult)
      const viewQueryNodeResult = viewQueryResult.result
      const isInitialized = isNull(c) || c.page === 1
      const mergeChangeReasons: Array<RefetchReason['kind']> = ['addColumn', 'deleteColumn', 'changeView']
      if (isInitialized) {
        if (mergeChangeReasons.includes(refetchReason.kind) && changes.length > 0) {
          const latestSnapshotAfterChange = changes.last()!.snapshotAfterChange // changes.length > 0 なので、snapshotAfterChangeは必ず存在する
          try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
            const latestSnapshotAfterChangeRecords = JSON.parse(latestSnapshotAfterChange) as ViewQueryRecordNode[]
            const newRecords = mergeRecords(
              viewQueryResult.viewQuery.tree.name,
              viewQueryNodeResult,
              latestSnapshotAfterChangeRecords,
            )
            setRecords(newRecords)
            if (refetchReason.kind === 'deleteColumn') {
              // changes の中からの削除されたカラムの変更を削除する
              deleteChangesOnDeletedColumn(refetchReason, changes, setChanges)
            }
          } catch {
            setRecords(viewQueryNodeResult)
            setChanges([])
          }
        } else {
          setRecords(viewQueryNodeResult)
          setChanges([])
          // refetchが完了するよりも前にタブを切り替えた時のためにcachedViewも更新しておく
          updateCachedView?.({ viewId: view.id, changes: [] })
        }
        setSnapshots(viewQueryResult.snapshots ?? {})
      } else {
        setRecords((oldDataState) => [...(oldDataState ?? []), ...viewQueryNodeResult])
        setSnapshots((oldValue) => merge(oldValue, viewQueryResult.snapshots ?? {}))
      }
      setCursor(viewQueryResult.nextCursor)

      const recordIds = viewQueryNodeResult.map((x) => x.id).compact()
      await fetchAndUpdateSheetThreads(recordIds)
    } catch (error) {
      if (error instanceof Error) {
        setErrors([error.message])
        sendRefetchErrorToPosthog({
          ...posthogProperties,
          error: error.message,
        })
      }
    } finally {
      loading.setFalse()
    }
  }

  return {
    async refetch(refetchReason: RefetchReason, useCache?: boolean) {
      // TODO: 複数ページになっているとき、全ページ分をrefetchする必要がある
      await fetch({
        withCursor: false,
        refetchReason,
        useCache,
      })
    },
    async loadNext() {
      if (isNull(cursor)) {
        return
      }
      await fetch({
        withCursor: true,
        refetchReason: { kind: 'loadNext' },
      })
    },
  }
}

// eslint-disable-next-line complexity
function deleteChangesOnDeletedColumn(
  refetchReason: DeleteColumn,
  changes: ChangeHistory[],
  setChanges: SetterOrUpdater<ChangeHistory[]>,
) {
  const deletedFieldNames = new Set(refetchReason.fields.map((x) => x.name))
  const validChangeHistories = []
  for (const changeHistory of changes) {
    const validChanges = []
    for (const change of changeHistory.changes) {
      if (change.deleted === true) {
        validChanges.push(change)
        continue
      }
      const validFieldChanges = []
      for (const fieldChange of change.fieldChanges) {
        if (!deletedFieldNames.has(fieldChange.fieldName)) {
          validFieldChanges.push(fieldChange)
        }
      }
      if (validFieldChanges.length > 0) {
        validChanges.push({ ...change, fieldChanges: validFieldChanges })
      }
    }
    if (validChanges.length > 0) {
      validChangeHistories.push({ ...changeHistory, changes: validChanges })
    }
  }
  setChanges(validChangeHistories)
}

// records2 (行の増減があるかも) に存在しないプロパティを records1 (列の増減があるかも) で上書きする
// eslint-disable-next-line complexity
function mergeRecords(rootModelName: string, records1: ViewQueryRecordNode[], records2: ViewQueryRecordNode[]) {
  // getIdToNodesDict は records を破壊的に変更するため、ディープコピーを渡す
  const records1Copy = structuredClone(records1)
  const dict1 = getIdToNodesDict(rootModelName, records1Copy)
  const records2Copy = structuredClone(records2)
  const dict2 = getIdToNodesDict(rootModelName, records2Copy)

  // dict1 から dict2 に存在しないものを削除する
  for (const key of Object.keys(dict1)) {
    if (dict2[key] === undefined) {
      // records1 が、records2 が削除した子供のデータを含んでいる場合、
      // 当該子供を親のレコードからも削除しなくてはいけない
      for (const recordAndParent of dict1[key]!) {
        // 絶対に一個以上の要素が存在する
        const { parent } = recordAndParent
        if (parent !== undefined) {
          for (const tableNode of parent.children) {
            tableNode.children = tableNode.children.filter(
              (childRecordNode) => childRecordNode.id !== recordAndParent.record.id,
            )
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete dict1[key]
    }
  }
  const dict3 = merge(dict1, dict2)
  merge(dict2, dict3)
  return records2Copy
}

interface RecordAndParent {
  record: ViewQueryRecordNode
  parent: ViewQueryRecordNode | undefined
}

// ここで id は モデルID と レコードID の組み合わせである。
function getIdToNodesDict(rootModelName: string, records: ViewQueryRecordNode[]) {
  const dict: Record<string, RecordAndParent[]> = {}
  getIdToNodesDictInner(records, 0, dict, rootModelName)
  return dict
}

// records から id をキーにした辞書を作る破壊的な関数。
// 深すぎる階層は循環参照の可能性があるため探索しない
// eslint-disable-next-line complexity,@typescript-eslint/max-params
function getIdToNodesDictInner(
  records: ViewQueryRecordNode[],
  depth: number,
  dict: Record<string, RecordAndParent[]>,
  modelName: string,
  parent?: ViewQueryRecordNode,
) {
  if (depth > 10) {
    return
  }
  for (const record of records) {
    // 自分
    if (record.id === undefined) {
      continue
    }
    if (dict[`${modelName}-${record.id}`] === undefined) {
      dict[`${modelName}-${record.id}`] = [{ record, parent }]
    } else {
      dict[`${modelName}-${record.id}`]?.push({ record, parent })
    }
    // 子について再帰
    for (const tableNode of record.children) {
      for (const childRecordNode of tableNode.children) {
        getIdToNodesDictInner([childRecordNode], depth + 1, dict, tableNode.nodeName, record)
      }
    }
  }
}

export function sendRefetchErrorToPosthog({
  viewId,
  viewType,
  viewName,
  organizationId,
  organizationName,
  userName,
  email,
  error,
}: {
  viewId: string
  viewType: string
  viewName: string
  organizationId: string
  organizationName: string
  userName: string
  email: string
  error: unknown
}): void {
  Posthog.track(POSTHOG_EVENTS.failed_fetch_view_query_result, {
    viewId,
    viewType,
    viewName,
    organizationId,
    organizationName,
    userName,
    email,
    error,
  })
}
