import type { SubscriptionStatusFieldsFragment } from '@salescore/client-api'
import { Table } from 'antd'
import { t } from 'i18next'

export function OrganizationSubscriptionStatusTable({
  subscriptionStatus,
}: {
  subscriptionStatus: SubscriptionStatusFieldsFragment[]
}) {
  return (
    <>
      <Table
        title={() => <div className="font-bold">{t(`契約情報`)}</div>}
        pagination={false}
        className="mb-4"
        dataSource={subscriptionStatus}
        columns={[
          {
            dataIndex: `plan`,
            title: t(`ライセンス`),
            render: (_, record) => <span>{record.subscription.plan.toLocaleUpperCase()}</span>,
          },
          {
            dataIndex: `numberOfSeats`,
            title: t(`契約中ライセンス数`),
          },
          {
            dataIndex: `numberOfUsers`,
            title: t(`ユーザー数(+招待中ユーザー数)`),
            render: (_, record) => (
              <span>
                {record.numberOfUsers} {record.subscription.plan === 'ri' ? `` : `(+${record.numberOfInvitations})`}
              </span>
            ),
          },
          {
            dataIndex: `numberOfRestSeats`,
            title: t(`招待可能ユーザー数`),
            render: (_, record) => <span>{record.subscription.plan === 'ri' ? `-` : record.numberOfRestSeats}</span>,
          },
        ]}
      />
    </>
  )
}
