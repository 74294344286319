import type { ViewQueryResultCursor } from '../../schemas/query'

export type ViewResultCursorOrInitialCursor = Omit<ViewQueryResultCursor, 'nextId'> & {
  nextId?: string | null
}
export type SqlResultRecord = Record<string, unknown>

export interface KeyValueStoreClient {
  set: (key: string, value: string, option?: { expireSec: number }, timeout?: number) => Promise<void>
  // eslint-disable-next-line @typescript-eslint/max-params
  setHash: (
    key: string,
    field: string,
    value: string,
    option?: { expireSec: number },
    timeout?: number,
  ) => Promise<void>
  get: (key: string, option?: { timeout?: number }) => Promise<string | null>
  getHashAll: (key: string, option?: { timeout?: number }) => Promise<Record<string, unknown>>
}

export const POSTGRES_COLUMN_LENGTH_LIMIT = 63
