import { isNull } from '@salescore/buff-common'
import type { ViewConfigKpiTimeSeries, WeeklyAggregationDay } from '@salescore/core'
import { Form, Input, InputNumber, Select } from 'antd'
import { t } from 'i18next'
import type { ReactNode } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { viewAtom } from '../../../../recoil/view/atoms'
import { useViewConfigKpiTimeSeries } from '../../../../recoil/view/hooks'
import { setConfigWithoutChangeMutation } from '../../../../recoil/view/mutations/setConfigMutation'

interface DayOfWeekOption {
  label: string
  value: WeeklyAggregationDay['filterValue']
}

export const dayOfWeekOptions: DayOfWeekOption[] = [
  {
    label: '日曜日',
    value: 'sunday',
  },
  {
    label: '月曜日',
    value: 'monday',
  },
  {
    label: '火曜日',
    value: 'tuesday',
  },
  {
    label: '水曜日',
    value: 'wednesday',
  },
  {
    label: '木曜日',
    value: 'thursday',
  },
  {
    label: '金曜日',
    value: 'friday',
  },
  {
    label: '土曜日',
    value: 'saturday',
  },
]

export function AggregationDayFormInput(): ReactNode {
  const [config] = useViewConfigKpiTimeSeries()
  const [, setView] = useRecoilState(viewAtom)
  const setConfigWithoutChange = useSetRecoilState(setConfigWithoutChangeMutation)
  const kpiFragment = config?.kpiFragment
  const aggregationDay = kpiFragment?.aggregationDay

  const Wrapper = ({ children }: { children: JSX.Element | JSX.Element[] }): ReactNode => (
    <Form.Item label={t(`集計対象日`)}>
      <div className="flex items-center gap-1">{children}</div>
    </Form.Item>
  )

  const EmptyForm = (): ReactNode => (
    <Wrapper>
      <Input disabled />
    </Wrapper>
  )

  if (isNull(aggregationDay)) {
    return <EmptyForm />
  }

  switch (aggregationDay.timeSpan) {
    case 'month': {
      return (
        <Wrapper>
          <span>{t('毎月')}</span>
          <InputNumber
            style={{
              width: '56px',
            }}
            min={1}
            max={31}
            defaultValue={Number(aggregationDay.filterValue)}
            onBlur={(e) => {
              const { value } = e.target
              if (isNull(config) || isNull(kpiFragment) || Number.isNaN(Number(value))) {
                return
              }
              const updatedConfig: ViewConfigKpiTimeSeries = {
                ...config,
                kpiFragment: {
                  ...kpiFragment,
                  aggregationDay: {
                    timeSpan: 'month',
                    filterType: 'day',
                    filterValue: value,
                  },
                },
              }

              setView((previous) => ({ ...previous, config: updatedConfig }))
              setConfigWithoutChange(updatedConfig)
            }}
          />
          <span>{t('日')}</span>
        </Wrapper>
      )
    }
    case 'week': {
      return (
        <Wrapper>
          <span>{t('毎週')}</span>
          <Select
            style={{
              width: '100px',
            }}
            options={dayOfWeekOptions}
            value={aggregationDay.filterValue}
            onChange={(value) => {
              if (isNull(config) || isNull(kpiFragment)) {
                return
              }

              const updatedConfig: ViewConfigKpiTimeSeries = {
                ...config,
                kpiFragment: {
                  ...kpiFragment,
                  aggregationDay: {
                    timeSpan: 'week',
                    filterType: 'dayOfWeek',
                    filterValue: value,
                  },
                },
              }

              setView((previous) => ({ ...previous, config: updatedConfig }))
              setConfigWithoutChange(updatedConfig)
            }}
          />
        </Wrapper>
      )
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      return <EmptyForm />
    }
  }
}
