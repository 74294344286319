import { CONSTANT } from '@salescore/client-base'
import { recoil } from '@salescore/client-recoil'
import { Tabs } from 'antd'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'

import { LauncherCommandList } from './LauncherCommandList'

export function LauncherBodyV2() {
  const { filterType, hide, setFilterType } = recoil.global.launcher.useLauncher()
  const launcherState = recoil.global.launcher.useLauncherState()
  const reference = useRef<HTMLInputElement>(null)
  const [composing, setComposing] = useState(false)
  const ability = recoil.global.policy.useCanForFeatures()

  useEffect(() => {
    if (reference.current !== null) {
      reference.current.focus()
    }
  }, [])

  return (
    <>
      <div
        className="fixed left-0 top-0 size-full"
        // zIndex, antdのmodalが1000なのでそれ以上にする
        style={{ backdropFilter: 'blur(.15rem)', backgroundColor: 'rgba(255, 255, 255, 0.3)', zIndex: 1001 }}
      />
      <div
        style={{
          zIndex: 1001,
        }}
        className="fixed left-0 top-0 z-30 flex size-full items-center justify-center align-middle"
        onClick={() => {
          hide()
        }}
      >
        <div
          onKeyDown={(e) => {
            if (!composing) {
              launcherState.handleKeyDown(e)
            }
          }}
          onClick={(e) => {
            e.stopPropagation()
          }}
          style={{
            top: 'calc(50% - 200px)',
            alignSelf: 'center',
            position: 'absolute',
            backgroundColor: 'white',
            color: '#333',
            maxWidth: '80vw',
            width: 800,
            borderRadius: '0.5em',
            overflow: 'hidden',
            margin: 'auto',
            zIndex: 100,
            boxShadow: '0px 0px 32px 0px #aaa',
            fontFamily: 'AvenirBlack',
          }}
        >
          {/* title */}
          <div
            className="font-bold uppercase"
            style={{ paddingLeft: 30, paddingTop: 20, paddingBottom: 20, fontSize: 12, letterSpacing: '.3em' }}
          >
            {t(`グローバル検索`)}
          </div>
          {/* input */}
          <div
            style={{
              // backgroundColor: '#f0f0f0',
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 30,
              borderTop: `1px solid ${CONSTANT.colors.border}`,
              borderBottom: `1px solid ${CONSTANT.colors.border}`,
            }}
          >
            <input
              className="placeholder:text-md placeholder:text-gray-300"
              placeholder={t(`検索ワードを入力...`)}
              ref={reference}
              value={launcherState.searchKey}
              onCompositionStart={(e) => {
                setComposing(true)
              }}
              onCompositionEnd={(e) => {
                setComposing(false)
              }}
              onChange={(e) => {
                launcherState.setSearchKey(e.target.value)
              }}
              style={{
                fontSize: 24,
                backgroundColor: 'transparent',
                outline: 'none',
                border: 'none',
                width: '100%',
              }}
            />
          </div>
          {/* result */}
          {/* uiを使いたいのでTabsを使うが、Paneの中身は全部同じ（フィルタは別ロジックで済み） */}
          <Tabs
            activeKey={filterType}
            className=""
            tabBarStyle={{ paddingLeft: 16, marginBottom: 4 }}
            onChange={(filterType) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-type-assertion
              setFilterType(filterType as any) // TODO
            }}
          >
            <Tabs.TabPane tab={t(`全て`)} key="all" className="">
              <LauncherCommandList />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t(`シート`)} key="sheet">
              <LauncherCommandList />
            </Tabs.TabPane>
            {ability.canOpenKpi && (
              <Tabs.TabPane tab="KPI" key="kpi">
                <LauncherCommandList />
              </Tabs.TabPane>
            )}
            <Tabs.TabPane tab={t(`ダッシュボード`)} key="kpiPivot">
              <LauncherCommandList />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t(`設定ページ`)} key="page">
              <LauncherCommandList />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t(`ゴミ箱`)} key="archived">
              <LauncherCommandList />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t(`Tabで移動`)} key="none" disabled />
          </Tabs>
          {launcherState.maxVisibleCommandExceededWarningVisibility && (
            <div className="p-4 text-xs text-yellow-300">
              {t(`表示上限を超えているため、すべてのコマンドが表示されていません。`)}
              <br />
              {t(`検索ワードを入力するか、タブを切り替えてコマンドを絞り込んでください。`)}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
