import { CORE_CONSTANT } from '../../../../constant'
import type { ViewQueryAggregation, ViewQueryList, ViewQueryNode, ViewQueryPivot } from '../../../../schemas/query'
import type { ViewUiKpi, ViewUiKpiPivotColumn } from '../../../../schemas/ui/ui'
import type {
  ViewConfigCustomFilter,
  ViewConfigDimensionFilter,
  ViewConfigField,
  ViewConfigFilterNode,
  ViewConfigKpi,
  ViewConfigKpiMeasure,
  ViewConfigMeasure,
  ViewConfigPivot,
  ViewConfigPropertyFilter,
  ViewConfigSheet,
  ViewConfigTreeNode,
} from '../../../../schemas/view_config'
import { defaultCursor } from '../../../query/executeViewQuery'
import { generateSql } from '../../../query/executeViewQuery/generateSql'
import { flatNodes } from '../../../query/executeViewQuery/util'
import { getNode, getNodeByName } from '../../../util/node'
import type { CompileContext } from '../../common'
import { generateSelectOptionsWithColor } from './compileSheetViewConfigFields'
import { compileSheetViewConfigFilterTree } from './compileSheetViewConfigFilterTree'
import { compileSheetViewConfigTree } from './compileSheetViewConfigTree'
import type { DimensionWithProperty } from './dimensionWithProperty'
import { expandChildrenByReferenceField } from './expandChildrenByReferenceField'
import { compileMeasure } from './measure/compileMeasure'
import { generateDimensionField } from './measure/generateDimensionField'
import { pruneSheetConfigTree } from './pruneSheetConfigTree'
import { findConfigField, searchFieldAndPropertyForDimension } from './searchFieldAndPropertyForDimension'

//
// sheetに対してmeasureとpivotを渡して、集計用のqueryとuiを得る
//
export interface CompileSheetAndMeasureResultExtra {
  // インデックス用に欲しい値
  dimensionProperties: Array<{ modelName: string; propertyName: string }>
  filterLeafProperties: Array<{ modelName: string; propertyName: string }>
}

interface CompileSheetAndMeasureResult {
  query: ViewQueryAggregation
  ui: Pick<ViewUiKpi, 'pivot'>
  extra: CompileSheetAndMeasureResultExtra
}

// eslint-disable-next-line complexity,@typescript-eslint/max-params
export function compileSheetAndMeasure(
  measure: ViewConfigMeasure | ViewConfigKpiMeasure,
  config: ViewConfigSheet,
  configPivot: ViewConfigPivot | undefined,
  dimensionAliases: ViewConfigKpi['dimensionAliases'],
  context: CompileContext,
): CompileSheetAndMeasureResult | undefined {
  const measureNodeNames = extractMeasureNodeNames(measure, config)
  const rowDimensionFields = (configPivot?.rows ?? []).map((d) =>
    searchFieldAndPropertyForDimension(d, config, dimensionAliases, context),
  )
  const columnDimensionFields = (configPivot?.columns ?? []).map((d) =>
    searchFieldAndPropertyForDimension(d, config, dimensionAliases, context),
  )
  const dimensionsWithoutRollupDimensionFields = (configPivot?.dimensionsWithoutRollup ?? []).map((d) =>
    searchFieldAndPropertyForDimension(d, config, dimensionAliases, context),
  )
  const filterTreeWithoutFieldTypeLeaf = convertFieldLeaf(config.filterTree, config.fields ?? [], context)

  const uiPivot = {
    rows: rowDimensionFields.map((x): ViewUiKpiPivotColumn => generatePivotColumnUi(x, 'row')),
    columns: columnDimensionFields.map((x): ViewUiKpiPivotColumn => generatePivotColumnUi(x, 'column')),
    // ...dimensionsWithoutRollupDimensionFields.map((x): ViewUiKpiPivotColumn => generatePivotColumnUi(x, 'column')), // いったん、目標のみを想定すると不要なはず
  }
  const ui = {
    pivot: uiPivot,
  }

  //
  // 序盤はsheetとほぼ同じ
  //
  // pruneを行うために、fieldsを求める。fieldsはmeasuresとdimensionsで使うものだけとする。
  const fields = [
    ...rowDimensionFields.map((x) => x.field),
    ...columnDimensionFields.map((x) => x.field),
    ...dimensionsWithoutRollupDimensionFields.map((x) => x.field),
  ].compact()
  const prunedTree = pruneSheetConfigTree({
    tree: config.tree,
    fields,
    filterTree: {
      logicalOperator: `and`,
      leafs: [],
      children: [
        filterTreeWithoutFieldTypeLeaf,
        // TODO: 2023/01/14 集計側の絞り込みを考慮するためには、このようにtreeを書き換えてから計算する必要がある。
        //       この対応のためには他の箇所も修正が必要で、現状の開発ステータスでやるべきではないと判断し、一旦後回し。（現状は絞り込みは集計項目に対してしかかけられないので問題ない）
        // ...measuresWithField.flatMap(({ measure }) =>
        //   measure.type === 'preset' ? measure.filterTree : undefined,
        // ),
      ].compact(),
    },
    nodeNames: measureNodeNames,
    dimensionNodeSearcher: (d) => {
      const dimensionWithField = searchFieldAndPropertyForDimension(d, config, dimensionAliases, context)
      return dimensionWithField.field?.property.nodeName
    },
  })

  const expandedTree = expandChildrenByReferenceField(
    prunedTree,
    {
      fields,
      filterTree: filterTreeWithoutFieldTypeLeaf,
    },
    context,
  )
  const tree = compileSheetViewConfigTree(expandedTree, context)
  if (tree === undefined || expandedTree === undefined) {
    if (config.tree === undefined) {
      context.logs.error(`${context.resources.view.name}: 初期設定が終わっていません`)
    } else {
      context.logs.error(`${context.resources.view.name}: 指定したオブジェクトが見つかりません。${config.tree.name}`)
    }
    return undefined
  }
  const queryNodes = flatNodes(tree)
  const configNodes = flatNodes(expandedTree)

  //
  // fields,filter,dimensions,measuresのコンパイル
  //
  // TODO: 事前にfilterTreeのfield typeを、propertyに変更
  const filterTree = compileSheetViewConfigFilterTree(
    filterTreeWithoutFieldTypeLeaf,
    queryNodes,
    configNodes,
    [],
    context,
    config,
    dimensionAliases,
  )
  const pivot: ViewQueryPivot | undefined =
    configPivot === undefined
      ? undefined
      : {
          rows: rowDimensionFields
            .map((x, index) =>
              generateDimensionField(x, { queryNodes, configNodes }, context, true, rowDimensionFields.slice(0, index)),
            )
            .compact(),
          columns: columnDimensionFields
            .map((x, index) =>
              generateDimensionField(
                x,
                { queryNodes, configNodes },
                context,
                true,
                columnDimensionFields.slice(0, index),
              ),
            )
            .compact(),
          dimensionsWithoutRollup: dimensionsWithoutRollupDimensionFields
            .map((x, index) => generateDimensionField(x, { queryNodes, configNodes }, context, false, []))
            .compact(),
        }
  const compiledMeasure = compileMeasure(
    measure,
    config,
    {
      queryNodes,
      configNodes,
      fields: [],
    },
    context,
  )
  // 多重度を考慮しないコンパイル結果
  const result: CompileSheetAndMeasureResult = {
    query: {
      type: 'aggregation',
      tree,
      measures: [compiledMeasure].compact(),
      filterTree,
      pivot,
      sorters: [],
    },
    ui,
    extra: {
      filterLeafProperties: flatNodes(filterTreeWithoutFieldTypeLeaf)
        .flatMap((x) => x.leafs)
        .map((x) => (x.type === `property` ? x.property : undefined))
        .compact(),
      dimensionProperties: [...rowDimensionFields, ...columnDimensionFields, ...dimensionsWithoutRollupDimensionFields]
        .map((x) => {
          if (x.model === undefined || x.property === undefined) {
            return
          }
          return {
            modelName: x.model.name,
            propertyName: x.property.name,
          }
        })
        .compact(),
    },
  }

  //
  // TODO: 複数のノードにまたがるとき（＝カスタムSQL）のケースでの多重度の考慮は一旦諦める
  // このケースでは、普通にコンパイル結果を返すだけ
  //
  const measureNodePaths = compiledMeasure?.nodePaths ?? []
  const measureNodePath = measureNodePaths.first()
  if (measureNodePath === undefined || measureNodePaths === undefined || measureNodePaths.length > 1) {
    return result
  }

  //
  // 多重度の考慮が必要ない場合は、普通にコンパイル結果を返す
  //
  const measureNode = getNode(tree, measureNodePath)
  if (measureNode === undefined) {
    // ありえないはず
    context.logs.error(`ノードが見つかりません。 ${measureNodePath.join('.')}`)
    return undefined
  }
  const validMultiplicityNode = pruneInvalidMultiplicityNode(tree, measureNode) ?? tree
  if (validMultiplicityNode === undefined) {
    throw new Error(`invalid validMultiplicityNode`) // ありえないはず
  }
  const isValidMultiplicity = flatNodes(validMultiplicityNode).length === flatNodes(tree).length
  if (isValidMultiplicity || context.additionalConfig?.skipMultiplicityCheck === true) {
    return result
  }

  //
  // 重複が発生してしまうケースでも、重複を避けられないケースはそのまま返す
  // 現状、以下のみが該当
  // ・分析軸に1:NのN側の軸を指定したとき
  // ・カスタムSQLで1:NのN側の軸を指定したとき（TODO: これは丁寧に対処すれば、この後の処理と同じ話になるので対応可能）
  //
  const validNodes = validMultiplicityNode === undefined ? [] : flatNodes(validMultiplicityNode)
  const validNodeNames = new Set(validNodes.map((x) => x.name))
  const ds = [...rowDimensionFields, ...columnDimensionFields, ...dimensionsWithoutRollupDimensionFields]
  const hasInvalidMultiplicityDimension = ds.some(
    (d) => d.field !== undefined && !validNodeNames.has(d.field.property.nodeName),
  )
  if (hasInvalidMultiplicityDimension) {
    return result
  }

  //
  // 多重度が異なるとき、そのまま集計すると重複が発生してしまうため、
  // 「サブクエリでdistinctしたidをjoinする」という手段を使う。すなわち、以下の操作を行う
  // ・treeの変更。サブクエリを実行した結果をINNER JOINする
  // ・フィルタはサブクエリ側で実行するので不要
  // ・dimensionsは、多重度が再発しないようなtreeの組み立てをおこなった上で、残ったノードに対してのみのdimensionを残す
  //
  const listQuery: ViewQueryList = {
    type: 'list',
    tree,
    fields: [],
    filterTree,
    sorters: [],
  }
  return {
    query: {
      type: 'aggregation',
      tree: {
        ...validMultiplicityNode,
        children: [
          ...(validMultiplicityNode.children ?? []),
          generateSubQuery(listQuery, measureNode, context.additionalConfig?.kpiParameter?.queryParameter),
        ],
      },
      measures: [compiledMeasure].compact(),
      filterTree: {
        // サブクエリでフィルタ済みなので、フィルタする必要はない
        logicalOperator: `and`,
        leafs: [],
        children: [],
      },
      pivot,
      sorters: [], // TODO
    },
    ui, // TODO: 残ったdimensionsのみにすべきか？
    extra: result.extra,
  }
}

// eslint-disable-next-line complexity
function extractMeasureNodeNames(measure: ViewConfigMeasure | ViewConfigKpiMeasure, sheet: ViewConfigSheet) {
  switch (measure.type) {
    case 'custom': {
      return measure.dependentNodeNames ?? []
    } // 2023/02/27 invalidなconfigがいくつかあるらしく、dependentNodeNamesがundefinedになっているので一旦応急処置
    case 'kpiCustom': {
      return measure.dependentNodeNames ?? []
    }
    case 'kpiPreset': {
      return [measure.property.nodeName]
    }
    case 'preset': {
      const field = (sheet.fields ?? []).find((field) => field.measures?.some((x) => x.name === measure.name) ?? false)
      return [field?.property.nodeName].compact()
    }
  }
}

//
// typeがfieldのleafを、propertyに変換する。
// 本来はこの処理を行わず、そのままfilterのロジックで処理したかったが、多重度の計算のためにフィルタがどのノードに紐づくかを考慮する必要があり、
// この辺を適切にやるには結局フィールドからプロパティに変換したほうが楽だったので、通常のpropertyのfilterに変換する処理を行う
//
function convertFieldLeaf(
  filterNode: ViewConfigFilterNode | undefined,
  fields: ViewConfigField[],
  context: CompileContext,
): ViewConfigFilterNode | undefined {
  if (filterNode === undefined) {
    return undefined
  }

  return {
    ...filterNode,
    leafs: filterNode.leafs
      .map((leaf): ViewConfigPropertyFilter | ViewConfigDimensionFilter | ViewConfigCustomFilter | undefined => {
        if (leaf.type !== 'field') {
          return leaf
        }

        const field = findConfigField(leaf.fieldName, fields)
        if (field === undefined) {
          context.logs.error(
            `絞り込み用のフィールドが見つかりません。fieldName: ${leaf.fieldName} fields: ${fields
              .map((x) => x.property.propertyName)
              .join(',')}`,
          )
          return undefined
        }
        return {
          ...leaf,
          filterDateTimeType: 'datetime', // TODO
          type: 'property',
          property: field.property,
        }
      })
      .compact(),
    children: filterNode.children.map((childNode) => convertFieldLeaf(childNode, fields, context)).compact(),
  }
}

// 「多重度」が正しくなるように、不適切なノードを削除する。
// 「正しい多重度になっている」とは、対象のノードについて以下を満たすことである
// ・子孫ノード、または親戚ノードに、1:Nの関係となるノードが存在しない
// すなわち、nodePathに含まれるノード以外に、1:Nの関係となっているノードが存在しないことを確認すれば良い
export function pruneInvalidMultiplicityNode(
  node: ViewQueryNode,
  targetNode: ViewQueryNode,
): ViewQueryNode | undefined {
  // 多重度を増やしてしまうノードであれば削除
  if (node.read.join?.relation === 'one_to_many' && !targetNode.path.includes(node.name)) {
    return undefined
  }

  return {
    ...node,
    children: (node.children ?? []).map((childNode) => pruneInvalidMultiplicityNode(childNode, targetNode)).compact(),
  }
}

// dimensionのために、configNodeのレイヤーで、多重度がおかしいものを弾く
// 判定は集計項目があるノード以下でやればOK
function pruneInvalidMultiplicityConfigNodeForTargetNode(
  tree: ViewConfigTreeNode | undefined,
  targetNodeName: string,
): ViewConfigTreeNode | undefined {
  if (tree === undefined) {
    return undefined
  }

  // pathの取得
  const targetNode = getNodeByName(tree, targetNodeName)
  if (targetNode === undefined) {
    return undefined
  }
  // このノード以下で、one_to_manyなものを弾く
  return pruneInvalidMultiplicityConfigNode(tree, targetNode.path)
}

function pruneInvalidMultiplicityConfigNode(
  node: ViewConfigTreeNode,
  measureNodePath: string[],
): ViewConfigTreeNode | undefined {
  // one_to_manyの判定を、一旦nodeNameのみで判定する（ほぼこれで間違いないはず。one_to_oneが存在すると微妙になる）
  // nodeNameが一致すれば、すなわちone_to_manyであり、多重度が不適切になるため、ここでは除外する
  // 多重度判定のロジックはpruneInvalidMultiplicityNodeのコメントを参照
  if (node.referenceToProperty?.nodeName === node.name && !measureNodePath.includes(node.name)) {
    return undefined
  }
  return {
    ...node,
    children: (node.children ?? [])
      .map((childNode) => pruneInvalidMultiplicityConfigNode(childNode, measureNodePath))
      .compact(),
  }
}

function generateSubQuery(
  viewQuery: ViewQueryList,
  node: ViewQueryNode,
  queryParameter: Record<string, string | undefined> | undefined,
): ViewQueryNode {
  // queryParameterはpresetsのためだけにもっている値であり、本来はqueryの解決のためだけに使われるが、
  // ここでgenerateSql()する都合で、このタイミングでmustache parameterを解決する必要があり、仕方なく以下の実装をしている
  const parameter =
    queryParameter === undefined
      ? {}
      : queryParameter.periodName !== undefined && queryParameter.periodName !== '全期間'
        ? {
            startAt: ``,
            endAt: ``,
          }
        : queryParameter
  const subQueryNodeName = `sub`
  const sql = generateSql(
    {
      ...viewQuery,
      fields: [
        {
          name: `id`,
          nodePath: node.path,
          read: {
            sql: `"${node.name}".id`,
            distinct: true,
          },
          meta: {
            label: ``,
            fieldType: `string`,
            dependedPropertyNamesWithStreamName: [],
          },
        },
      ],
      extra: {
        asSubQuery: true,
      },
    },
    defaultCursor,
    parameter,
  )
  return {
    name: subQueryNodeName,
    path: [node.name, subQueryNodeName],
    read: {
      tableSql: sql, // TODO
      tableType: `subquery`,
      idColumn: `id`,
      join: {
        relation: `many_to_one`,
        joinType: `INNER JOIN`,
        joinOn: {
          type: `id`,
          parentNodeName: node.name,
          parentNodeColumnName: `id`,
          currentNodeName: subQueryNodeName,
          currentNodeColumnName: `id`,
          // TODO: 不毛なので削除したい
          meta: {
            parentNodeTableSql: ``,
            parentNodeLabel: ``,
            parentNodeColumnLabel: ``,
            parentNodeStreamName: ``,
            parentNodePropertyName: ``,
            currentNodeTableSql: ``,
            currentNodeLabel: ``,
            currentNodeColumnLabel: ``,
            currentNodeStreamName: ``,
            currentNodePropertyName: ``,
          },
        },
        search: {
          sql: ``,
          dependedPropertyNames: [],
        },
      },
    },
    meta: {
      label: ``,
      dependedStreamNames: [],
    },
  }
}

function generatePivotColumnUi(x: DimensionWithProperty, type: 'row' | 'column'): ViewUiKpiPivotColumn {
  const { dimension } = x
  return {
    key: [dimension.dateSpan, dimension.key].compact().join('_'), // TODO
    label: dimension.label,
    fieldType: getPivotColunnFieldType(x, type),
    fieldMetaType: x.property?.meta ?? undefined,
    selectOptions: generateSelectOptionsWithColor(x.property?.selectOptions ?? undefined),
  }
}

// eslint-disable-next-line complexity
function getPivotColunnFieldType({ dimension, property }: DimensionWithProperty, type: 'row' | 'column') {
  if (type === 'column') {
    return 'kpi' as const
  }
  if (dimension.key === CORE_CONSTANT.KPI_PIVOT_USER_DIMENSION().key) {
    return 'user' as const
  }
  if (property?.referenceTo !== undefined) {
    return 'reference' as const
  }
  if (property?.type === 'date' || property?.type === 'datetime') {
    return 'date' as const
  }
  return 'string' as const
}
