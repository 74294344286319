import { CheckCircleFilled } from '@ant-design/icons'
import { isNull, isSome } from '@salescore/buff-common'
import type { ViewFieldsFragment } from '@salescore/client-api'
import { Loading } from '@salescore/client-common'
import { Flex, Space, Spin } from 'antd'
import Layout from 'antd/es/layout/layout'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'

import { type LoadingProgress, loadingProgressAtom } from '../recoil/view/atoms'
import { useDurationMetricsSelector } from '../recoil/view/selectors/durationMetricsSelector'

export interface ViewLoadingContext {
  view?: ViewFieldsFragment
}

// eslint-disable-next-line complexity
export const ViewLoading = ({ context }: { context: ViewLoadingContext }) => {
  const { view } = context
  // 読み込み予測時間が1秒以上の場合にメッセージを表示する
  // TODO: そもそも読み込みが一秒以内のViewがほとんどないので不要かも。。。
  const durationThreshold = 1000

  const loadingProgress = useRecoilValue(loadingProgressAtom)
  const { durationMetricsList, getPredictedDuration } = useDurationMetricsSelector()
  const predictedDuration = view
    ? getPredictedDuration({
        viewId: view.id,
        durationMetricsList: durationMetricsList ?? [],
      })
    : undefined
  const { percent, loadingMessage } = getStatus({ view, loadingProgress })

  if (view?.config.type === 'form') {
    return <Loading />
  }

  return (
    <Layout className="h-full">
      <Flex className="size-full" align="center" justify="center">
        <Flex className="w-3/5" vertical align="center" gap={16}>
          <span className="text-xl font-bold">{t('表示の準備をしています')}</span>
          <Space className="w-full gap-4" direction="vertical" align="center">
            <Spin size="large" />
            <Flex vertical className="w-full " justify="space-between" align="center" gap={8}>
              <span className="text-xl text-gray-400">{t('{{percent}}%', { percent })}</span>
              <span className="text-gray-400">{loadingMessage}</span>
            </Flex>
          </Space>
          <div className="relative w-full">
            <Flex className="absolute w-full" vertical align="center" gap={16}>
              {isSome(predictedDuration) && predictedDuration > durationThreshold && (
                <span className="text-center leading-6">
                  {/* 秒数の強調表示のためにt()関数の文章を分割せざるを得なかったため
                  この部分は日本語と英語で分割した文章全体で同じ意味になるように翻訳する必要があり、少し特殊な翻訳をしている。
                  詳細はtranslation.jsonを参照。*/}
                  {t('過去の傾向から読み込み完了までおそらく')}
                  <strong className="font-bold text-blue-500">
                    {t('{{duration}}秒', { duration: Math.floor(predictedDuration / 1000) })}
                  </strong>
                  {t('かかります。')}
                  <br />
                  {t('ローディング中にタブを切り替えると、ローディングがリセットされます。')}
                </span>
              )}
              <Space direction="vertical">
                <Space>
                  <CheckCircleFilled
                    className={percent >= 10 ? 'text-blue-500' : 'text-gray-300'}
                    style={{ fontSize: 24 }}
                  />
                  <span>{t('ビューの切り替え')}</span>
                </Space>
                <Space>
                  <CheckCircleFilled
                    className={percent >= 50 ? 'text-blue-500' : 'text-gray-300'}
                    style={{ fontSize: 24 }}
                  />
                  <span>{t('設定の読み込み')}</span>
                </Space>
                <Space>
                  <CheckCircleFilled
                    className={percent >= 100 ? 'text-blue-500' : 'text-gray-300'}
                    style={{ fontSize: 24 }}
                  />
                  <span>{t('データの読み込み')}</span>
                </Space>
              </Space>
            </Flex>
          </div>
        </Flex>
      </Flex>
    </Layout>
  )
}

function getStatus({ view, loadingProgress }: { view?: ViewFieldsFragment; loadingProgress: LoadingProgress }) {
  if (isNull(view)) {
    return {
      status: 'loadingSetting',
      percent: 10,
      loadingMessage: t('設定の読み込み中'),
    }
  }

  if (loadingProgress.completed) {
    return {
      status: 'completed',
      percent: 100,
      loadingMessage: t('表示の準備が完了しました'),
    }
  }

  return {
    status: 'loadingData',
    percent: 50,
    loadingMessage: t('データの読み込み中'),
  }
}
