import { ArrowLeftOutlined, FlagOutlined } from '@ant-design/icons'
import { isNull, isSome } from '@salescore/buff-common'
import { POSTHOG_EVENTS } from '@salescore/client-base'
import { calculateUnit, numberWithDelimiterFilter, numberWithFixedDecimal, unitLabel } from '@salescore/frontend-common'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'

import { type KpiUserAppearance, kpiUserAppearanceAtom } from '../../../../../recoil/navigation/atoms'
import { useProgressSelector } from '../../../../../recoil/navigation/selector/ProgressSelector'
import { usePosthogTrackView } from '../../../../../recoil/usePosthogTrack'
import { useViewValue } from '../../../../../recoil/view/hooks'
import { useColumnsValue, useContextValue } from '../../../../recoil/models/propModels'
import type { CellArgument } from '../cellArgument'
import { type CellStyle, extractCellStyle } from './extractCellStyle'
import { extractKpiInfo, type KpiInfo } from './extractKpiInfo'
import { extractTransitionInfo, type TransitionInfo } from './extractTransitionInfo'

const DEFAULT_KPI_FONT_SIZE = 18

// eslint-disable-next-line complexity
export function KpiCell({ value, recordNode, column }: CellArgument) {
  const context = useContextValue()
  const columns = useColumnsValue()
  const view = useViewValue()
  const kpiUserAppearance = useRecoilValue(kpiUserAppearanceAtom)
  const progress = useProgressSelector()
  const kpiInfo = extractKpiInfo(column, recordNode, progress, kpiUserAppearance, context)
  const transitionInfo = extractTransitionInfo(kpiInfo, column, columns, recordNode)
  const cellStyle = extractCellStyle(kpiInfo, transitionInfo, kpiUserAppearance)
  const posthogTrackView = usePosthogTrackView()

  // kpi, rowKpiなどはcellBody側でpaddingを無視している
  const actualText = numberFilter(
    kpiUserAppearance.perElapsedBusinessDays === true ? kpiInfo.actualPerElapsed : kpiInfo.actual,
    kpiInfo,
  )
  const secondaryFontSize = getSecondaryFontSize(kpiUserAppearance, 10)
  return (
    <div
      onClick={() => {
        if (view.type === 'kpiPivot') {
          posthogTrackView(POSTHOG_EVENTS.click_kpi_from_dashboard, {
            viewId: view.id,
          })
        }
      }}
      className="flex size-full items-center justify-center overflow-hidden align-middle"
    >
      <div
        className={`text-center text-xs ${cellStyle.color} cursor-pointer hover:opacity-75`}
        style={{ marginTop: -4 }}
      >
        <div
          className="font-bold leading-7"
          style={{
            marginTop: kpiInfo.goal === undefined ? 2 : 0, // 不要なはずだが、微妙に必要…？
            fontSize: kpiUserAppearance.fontSize ?? getAutoFontSize(actualText.length, column.width),
          }}
        >
          {isSome(kpiInfo.actual) && kpiInfo.kpi?.displayFormat === 'yen' && (
            <span style={{ fontSize: secondaryFontSize, marginLeft: 1 }}>¥</span>
          )}
          {actualText}
          <span style={{ fontSize: secondaryFontSize, opacity: 0.6, marginLeft: 2 }}>
            {unitLabel(kpiInfo.actual, kpiInfo.kpi?.unitType, kpiInfo.kpi?.abbreviationType)}
          </span>
          {/* %を追加で表示する */}
          {kpiInfo.kpi?.displayFormat === 'percent' && (
            <span style={{ fontSize: secondaryFontSize, marginLeft: 1 }}>%</span>
          )}
          <ElapsedDays kpiInfo={kpiInfo} />
        </div>
        <GoalRelated kpiInfo={kpiInfo} />
      </div>
      <TransitionValue transitionInfo={transitionInfo} cellStyle={cellStyle} />
    </div>
  )
}

// eslint-disable-next-line complexity
function GoalRelated({ kpiInfo }: { kpiInfo: KpiInfo }) {
  const kpiUserAppearance = useRecoilValue(kpiUserAppearanceAtom)
  if (
    kpiInfo.goal === undefined ||
    kpiUserAppearance.goalVisible === false ||
    kpiUserAppearance.perElapsedBusinessDays === true
  ) {
    return <></>
  }

  const secondaryFontSize = getSecondaryFontSize(kpiUserAppearance, 12)
  return (
    <div style={{ fontSize: secondaryFontSize, marginTop: -6 }}>
      / {numberFilter(kpiInfo.goal, kpiInfo)}
      <br />
      {kpiInfo.ratio ?? '-'}%
      {kpiUserAppearance.diffVisible !== false && (
        <div
          className="ml-1 flex justify-center leading-4"
          style={{ fontSize: secondaryFontSize, transform: 'scale(0.9)' }}
        >
          <FlagOutlined className="" style={{ marginRight: 0 }} />
          <div>
            {(kpiInfo.diffActualIdeal ?? 0) > 0 ? '+' : ''}
            {numberFilter(kpiInfo.diffActualIdeal, kpiInfo)}
          </div>
        </div>
      )}
    </div>
  )
}

// eslint-disable-next-line complexity
function TransitionValue({ transitionInfo, cellStyle }: { transitionInfo?: TransitionInfo; cellStyle: CellStyle }) {
  if (transitionInfo?.transitionRatio === undefined) {
    return <></>
  }

  return (
    <div
      className="absolute inset-y-0 left-0 m-auto flex h-full items-center justify-center text-center align-middle text-xs"
      style={{
        left: 0,
        transform: 'scale(0.85)',
      }}
    >
      {/* <div style={{ width: 14, height: '70%', backgroundColor: 'white', left: -7 }} className="absolute"></div> */}
      <div className={`absolute inset-x-0 py-2 ${cellStyle.transitionColor} `} style={{ width: '4em', left: '-2em' }}>
        {showRatio(transitionInfo.transitionRatio)}
        %
        <br />
        <ArrowLeftOutlined />
        {transitionInfo.diffTransitionRatio !== undefined && Number.isFinite(transitionInfo.diffTransitionRatio) && (
          <div style={{ transform: 'scale(0.7)' }}>
            <FlagOutlined className="" style={{ marginRight: 0 }} />
            {transitionInfo.diffTransitionRatio > 0 && '+'}
            {isSome(transitionInfo.diffTransitionRatio)
              ? numberWithDelimiterFilter(
                  Math.abs(transitionInfo.diffTransitionRatio) > 10 ** 7
                    ? `${Math.ceil(transitionInfo.diffTransitionRatio / 10 ** 6)}M`
                    : Math.abs(transitionInfo.diffTransitionRatio) > 10 ** 4
                      ? `${Math.ceil(transitionInfo.diffTransitionRatio / 10 ** 3)}K`
                      : `${transitionInfo.diffTransitionRatio}`,
                )
              : '-'}
          </div>
        )}
      </div>
    </div>
  )
}

function ElapsedDays({ kpiInfo }: { kpiInfo: KpiInfo }) {
  const kpiUserAppearance = useRecoilValue(kpiUserAppearanceAtom)
  const { elapsedDays, businessDays, progressRate, actual } = kpiInfo
  if (kpiUserAppearance.perElapsedBusinessDays !== true || elapsedDays === undefined) {
    return <></>
  }

  return (
    <>
      <div
        className="ml-1 mt-1 flex justify-center leading-4 text-gray-300"
        style={{ fontSize: getSecondaryFontSize(kpiUserAppearance, 12), transform: 'scale(0.9)' }}
      >
        <div>
          {elapsedDays} {t(`営業日`)}
          {/* <br />
          {businessDays}営業日(全)
          <br />
          {progressRate}終了 */}
          <br />
          {t(`合計`)}:{numberFilter(actual, kpiInfo)}
        </div>
      </div>
    </>
  )
}

// eslint-disable-next-line complexity
function getAutoFontSize(textLength: number, width: number | undefined) {
  // いったん、widthによらず一定にする
  if (textLength >= 12) {
    return 10
  }
  if (textLength >= 11) {
    return 11
  }
  if (textLength >= 10) {
    return 12
  }
  if (textLength >= 9) {
    return 13
  }
  if (textLength >= 8) {
    return 14
  }
  if (textLength >= 7) {
    return 15
  }
  return DEFAULT_KPI_FONT_SIZE
}

function getSecondaryFontSize(kpiUserAppearance: KpiUserAppearance, defaultFontSize: number) {
  if (isNull(kpiUserAppearance.fontSize)) {
    return defaultFontSize
  }
  const fontSizeRatio = defaultFontSize / DEFAULT_KPI_FONT_SIZE
  return kpiUserAppearance.fontSize * fontSizeRatio
}

function showRatio(x: number | undefined): string {
  if (x === undefined || !Number.isFinite(x)) {
    return `-`
  }
  if (Math.abs(x) >= 1000) {
    return '999+'
  }
  if (Math.abs(x) < 10) {
    return x.toPrecision(2) // extractTransitionInfoで2桁で丸めているが、4.0が4になってしまっているため、ここで改めて2桁に合わせる
  }

  return `${x}`
}

// eslint-disable-next-line complexity
function numberFilter(x: number | undefined, kpi: KpiInfo): string {
  if (x === undefined || x === null) {
    return '-'
  }

  const calculatedNumber = calculateUnit(x, kpi.kpi?.unitType, kpi.kpi?.abbreviationType)
  const fixedNumber = numberWithFixedDecimal(calculatedNumber, kpi.kpi?.decimalPlaceType, kpi.kpi?.abbreviationType)

  return numberWithDelimiterFilter(fixedNumber)
}
