import dayjs, { type Dayjs } from 'dayjs'

//
// 2021-01のような文字列を解析して、timeframe,format,dateを返す関数
// 具体的に期待している文字列は、実装参照
//
// eslint-disable-next-line complexity
export function detect(
  x: string,
):
  | { timeframe: 'year' | 'month' | 'week' | 'day'; date: Dayjs; format: string }
  | { timeframe: 'fiscal_quarter' | 'fiscal_half' }
  | undefined {
  if (/^\d{4}$/.test(x)) {
    // (例) 2022
    return { timeframe: 'year', format: `YYYY`, date: dayjs(`${x}-01-01`) }
  }
  if (/^\d{4}-(上半期|下半期)$/.test(x)) {
    // (例) 2022-上半期
    return { timeframe: 'fiscal_half' }
  }
  if (/^\d{4}-\dQ$/.test(x)) {
    // (例) 2022-1Q
    return { timeframe: 'fiscal_quarter' }
  }
  if (/^\d{4}-\d{2}$/.test(x)) {
    // (例) 2022-02
    return { timeframe: 'month', format: `YYYY-MM`, date: dayjs(`${x}-01`) }
  }
  if (/^\d{4}-\d{2}-\d{2}W$/.test(x)) {
    // (例) 2022-02-01W
    return { timeframe: 'week', format: `YYYY-MM-DD[W]`, date: dayjs(x.replace('W', '')) }
  }
  if (/^\d{4}-\d{2}-\d{2}$/.test(x)) {
    // (例) 2022-02-01
    return { timeframe: 'day', format: `YYYY-MM-DD`, date: dayjs(x) }
  }
  return undefined
}
