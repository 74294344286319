import { compact, isNull, isSome } from '@salescore/buff-common'
import type { NodePath, ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'
import { selector, useRecoilValue } from 'recoil'

import type { RSheetColumn } from '../../rsheet/types'
import { getNode, getRecordNodeFromRootNode } from '../../state/nodeUtil'
import { uiAtom } from '../view/atoms'
import { isSameNodeProperty } from '../view/mutations/field/util'
import { configSheetSelector } from '../view/selectors/configSheetSelector'
import { querySelector } from '../view/selectors/querySelector'

// TODO: sheetUi.columns = RSheetColumnにして、変換が不要になるようにしたい
const sheetColumnsSelector = selector({
  key: `view/sheetColumnsSelector`,
  get({ get }) {
    const { query, nodeNamesWithColors } = get(querySelector)
    const { config, flattenFilterNodeLeafs, getConfigField } = get(configSheetSelector)
    const ui = get(uiAtom)
    const sheetUi = ui.first()
    if (sheetUi === undefined || sheetUi.type !== 'Sheet') {
      return []
    }

    return (
      sheetUi.columns
        // eslint-disable-next-line complexity
        .map((column, index): RSheetColumn<ViewQueryRecordNode> | undefined => {
          const field = query.fields.find((x) => x.name === column.fieldName)
          if (field === undefined) {
            return undefined
          }
          const configField = getConfigField(field)

          const { nodePath } = field
          const nodePaths = nodePath
            .reduce<NodePath[]>((paths, nodeName) => {
              const lastPath = paths.at(-1) ?? []
              const currentPath = [...lastPath, nodeName]
              return [...paths, currentPath]
            }, [])
            .uniqueBy((x) => x.join(','))
          const nodes = compact(nodePaths.map((path) => getNode(query.tree, path)))
          const node = nodes.last() ?? query.tree // TODO: なかった場合適当にルートにしている
          const configIndex =
            config.fields !== undefined && configField !== undefined
              ? config.fields.findIndex((x) => isSameNodeProperty(x.property, configField.property))
              : undefined // config.field 内でのindex
          return {
            index, // 後で上書きしている
            configIndex: configIndex === -1 ? undefined : configIndex,
            field,
            node,
            nodeBlock: {
              nodePath: node.path,
              labels: nodes.map((x) => x.meta.label),
              colors: nodes.map((x) => nodeNamesWithColors[x.name]!),
              // onClick // menuにするはずだから、menuを実装すればいい？検討
            },
            key: `field-${field.name}`,
            title: column.label,
            helpText: column.description,
            // TODO: この辺のtree系の要素をuiにいれないと、query/uiを完全に分離できなそう
            nodeType: convertRelationToNodeType(node),
            color: column.fieldType === 'notFound' ? 'gray' : nodeNamesWithColors[node.name],
            width: column.width ?? (column.fieldType === 'numeric' ? 100 : 300),
            type: column.fieldType,
            metaType: column.fieldMetaType,
            selectOptions: column.selectOptions,
            // TODO: ここでのvalue,labelの実装と、convertRecordNodeToSheetRowでのvalue実装が重複してしまっている。。統一したい
            value(recordNode: ViewQueryRecordNode) {
              return recordNode.attributes[field.name]
            },
            ...(column.fieldMetaType === 'relation'
              ? {
                  label(recordNode: ViewQueryRecordNode) {
                    return recordNode.attributes[`${field.name}_label`]
                  },
                }
              : {}),
            highlight(recordNode: ViewQueryRecordNode) {
              return getHighlight(recordNode, nodePath, field.write?.propertySourceName) // TODO
            },
            isFiltered:
              isSome(configField) &&
              flattenFilterNodeLeafs.some(
                (leaf) => leaf.type === 'property' && isSameNodeProperty(leaf.property, configField.property),
              ),
            isHighlighted:
              configField?.conditionalEffects?.flatMap((x) => (x.effect === 'highlight' ? [x] : [])).isPresent() ??
              false,
            sortState: query.sorters.find((sorter) => sorter.read.sql === field.read.sql)?.read.order,
            // creatableでもupdatableでもないとき、readonlyにする。こうすると、RSheets側の機構で書き込み禁止が実現される
            readonly: isNull(field.write) || (column.creatable === false && column.updatable === false),
            deletable: column.deletable,
            columnDeletable: column.columnDeletable,
            // validationをどういう形にするか要検討
            required: column.required === true,
            visible: column.visible,
            // ほぼデバッグ用なので消したい
            // headerPopoverContent: viewState.debugMode ? <JsonSyntaxHighlighter jsonString={JSON.stringify(field)} /> : <></>,
            conditionalEffects: field.meta.conditionalEffects, // TODO
            measures: column.measures,
            dynamicSelectOptionConfig: column.dynamicSelectOptionsConfig,
            // 元々の設計構想だと、config系の値をUIから参照するのは避けていく予定だったが、編集系のUIで結局必要になることが多く、結局いれることにした…
            configField,
          }
        })
        .compact()
    )
  },
})

const sheetVisibleColumnsSelector = selector({
  key: `view/sheetVisibleColumnsSelector`,
  get({ get }) {
    const columns = get(sheetColumnsSelector)
    return columns.filter((x) => x.visible === undefined || x.visible)
  },
})

export const useSheetColumns = () => useRecoilValue(sheetColumnsSelector)

export const useSheetVisibleColumns = () => useRecoilValue(sheetVisibleColumnsSelector)

export function getValue(rootViewNodeRecord: ViewQueryRecordNode, nodePath: NodePath, fieldName: string) {
  const viewNodeRecord = getRecordNodeFromRootNode(rootViewNodeRecord, nodePath)
  if (viewNodeRecord === undefined) {
    // TODO: エラー
    return
  }

  if (Array.isArray(viewNodeRecord)) {
    return viewNodeRecord.map((x) => x.attributes[fieldName])
  }
  return viewNodeRecord.attributes[fieldName]
}

function getHighlight(
  rootViewNodeRecord: ViewQueryRecordNode,
  nodePath: NodePath,
  propertyName: string | undefined,
): boolean | boolean[] {
  if (propertyName === undefined) {
    return false
  }

  const viewNodeRecord = getRecordNodeFromRootNode(rootViewNodeRecord, nodePath)
  if (viewNodeRecord === undefined) {
    // TODO: エラー
    return false
  }

  if (Array.isArray(viewNodeRecord)) {
    return viewNodeRecord.map((x) => (x.meta.highlightAttributeNames ?? []).includes(propertyName))
  }
  return (viewNodeRecord.meta.highlightAttributeNames ?? []).includes(propertyName)
}

export function convertRelationToNodeType(node: ViewQueryNode): RSheetColumn<ViewQueryRecordNode>['nodeType'] {
  const relation = node.read.join?.relation

  switch (relation) {
    case 'one_to_many': {
      return 'children'
    }
    case 'many_to_one': {
      return 'parent'
    }
    case 'one_to_one': {
      return 'child'
    }
    case undefined: {
      return 'root'
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = relation
      return 'root'
    }
  }
}
