import { TeamOutlined, UserOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { isNull, isPresent, isTruthy } from '@salescore/buff-common'
import { FetchUserGroupsDocument, type UserGroup } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { PeriodPicker, usePeriod } from '@salescore/client-common'
import {
  CORE_CONSTANT,
  createDimensionFieldName,
  flatRecordNodes,
  generateLabelSqlFieldName,
  type ViewUIKpiTimeSeries,
} from '@salescore/core'
import type { Period } from '@salescore/features'
import { Select, Tooltip } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { z } from 'zod'

import { recordsAtom } from '../../../../recoil/records/atoms'
import { useUiValue, useViewConfigKpiTimeSeries } from '../../../../recoil/view/hooks'
import { useSavedPeriod, useUpdateKpiTimeSeriesView } from '../hooks'
import type { ViewUIRiPreviewProperties } from '../viewUIRiPreviewProperties'
import { filterSavedMessage } from './ViewUiRiPreviewWrapper'

const TOTAL_VALUE = CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING

const optionSchema = z.object({
  title: z.string(),
})
type Option = z.infer<typeof optionSchema>

// eslint-disable-next-line complexity
export function ViewUiRiTimeSeriesPreviewHeader(properties: ViewUIRiPreviewProperties): ReactNode {
  const [config] = useViewConfigKpiTimeSeries()
  const { serializePeriod } = usePeriod()
  const { getSavedPeriod } = useSavedPeriod()
  const { updateKpiTimeSeriesView } = useUpdateKpiTimeSeriesView()
  const [userOrUserGroupIdsFilter, setUserOrUserGroupIdsFilter] = useState(config?.filters?.userOrUserGroupIds ?? [])
  const breakdownProperties = useRiTimeSeriesBreakdownPropertyRecords()
  const { data: userGroups } = useQuery(FetchUserGroupsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })
  const [breakdownPropertiesFilter, setBreakdownPropertiesFilter] = useState(
    // NOTE: フィルター表示をしない場合は、内訳フィルターの絞り込みもしない
    isTruthy(properties.hideFilters) ? [] : (config?.filters?.breakdownProperties ?? []),
  )
  const [dateYFilter, setDateYFilter] = useState<Period | undefined>(
    config?.kpiFragment?.dateY !== undefined && config.filters?.dateY !== undefined
      ? getSavedPeriod(config.filters.dateY)
      : undefined,
  )

  if (isNull(config)) {
    return <></>
  }

  const onChangeUserOrUserGroupIdsFilter = async (v: string[]) => {
    setUserOrUserGroupIdsFilter(v)

    await updateKpiTimeSeriesView({
      configForm: {
        ...config,
        filters: {
          ...config.filters,
          userOrUserGroupIds: v,
          userIds: mapUserOrUserGroupIdsToUserIds({
            userOrUserGroupIds: v,
            userGroups: userGroups?.userGroups ?? [],
          }),
        },
      },
      savedMessage: filterSavedMessage,
    })
  }

  const onChangeBreakdownPropertiesFilter = async (v: Array<string | number>) => {
    setBreakdownPropertiesFilter(v)

    await updateKpiTimeSeriesView({
      configForm: {
        ...config,
        filters: {
          ...config.filters,
          breakdownProperties: v,
        },
      },
      savedMessage: filterSavedMessage,
    })
  }

  const onChangeDateYFilter = async (v: Period) => {
    setDateYFilter(v)

    // NOTE: デフォルト値が適用されただけなので、更新はしない
    if (dateYFilter === undefined && v.name === '全期間') {
      return
    }

    await updateKpiTimeSeriesView({
      configForm: {
        ...config,
        filters: {
          ...config.filters,
          dateY: serializePeriod(v),
        },
      },
      savedMessage: filterSavedMessage,
    })
  }

  return (
    !isTruthy(properties.hideFilters) && (
      <>
        <ViewUiRiPeriodFilter
          period={dateYFilter}
          onChange={(period) => {
            void onChangeDateYFilter(period)
          }}
          disabled={config.kpiFragment?.dateY === undefined}
        />
        <ViewUiRiUserOrGroupIdsFilter
          userOrUserGroupIds={userOrUserGroupIdsFilter}
          onChange={(ids) => {
            void onChangeUserOrUserGroupIdsFilter(ids)
          }}
          userGroups={userGroups}
          disabled={config.kpiFragment?.user === undefined}
        />

        <Tooltip
          title={
            config.breakdownProperty === undefined
              ? t('内訳フィルターを有効にするためには、案件管理の設定で内訳項目を指定してください。')
              : undefined
          }
        >
          <div>
            <Select
              mode="multiple"
              style={{ minWidth: '200px' }}
              placeholder="内訳をフィルター"
              disabled={config.breakdownProperty === undefined}
              value={breakdownPropertiesFilter}
              onChange={onChangeBreakdownPropertiesFilter}
              filterOption={(input, option?: Option) => {
                const parsedOption = optionSchema.safeParse(option)
                return (parsedOption.data?.title ?? '').toLowerCase().includes(input.toLowerCase())
              }}
            >
              {breakdownProperties.map((bp) => (
                <Select.Option key={bp.value} title={`${bp.label ?? bp.value}`} value={bp.value}>
                  <div>{bp.label ?? bp.value}</div>
                </Select.Option>
              ))}
            </Select>
          </div>
        </Tooltip>
      </>
    )
  )
}

export function ViewUiRiPeriodFilter({
  period,
  onChange,
  disabled,
}: {
  period: Period | undefined
  onChange: (v: Period) => void
  disabled: boolean
}): ReactNode {
  return (
    <Tooltip
      title={
        disabled ? t('期間フィルターを有効にするためには、案件管理の設定で期間項目を指定してください。') : undefined
      }
    >
      <div>
        <PeriodPicker
          pickerType="kpiTimeSeriesKpiDateY"
          selectedPeriod={period}
          setPeriod={(v) => {
            onChange(v)
          }}
          width={200}
          disabled={disabled}
          withInitialization
          initialPeriodName="全期間"
        />
      </div>
    </Tooltip>
  )
}

export function ViewUiRiUserOrGroupIdsFilter({
  userOrUserGroupIds,
  onChange,
  userGroups,
  disabled,
}: {
  userOrUserGroupIds: string[]
  onChange: (ids: string[]) => void
  userGroups: { userGroups: UserGroup[] } | undefined
  disabled: boolean
}): ReactNode {
  return (
    <Tooltip
      title={
        disabled
          ? t('ユーザーフィルターを有効にするためには、案件管理の設定でユーザー項目を指定してください。')
          : undefined
      }
    >
      <div>
        <Select
          mode="multiple"
          style={{ minWidth: '200px' }}
          placeholder="ユーザーをフィルター"
          value={userOrUserGroupIds}
          onChange={onChange}
          disabled={disabled}
          filterOption={(input, option: { key: string; value: string } | undefined) =>
            (option?.key ?? '')
              .replace(option?.value ?? '', '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {userGroups?.userGroups
            .sortBy((ug) => ug.rank)
            .map((ug) => (
              <Select.Option key={`${ug.id}-${ug.name}`} value={ug.id}>
                <div className="flex items-center gap-2">
                  <TeamOutlined />
                  <div>{ug.name}</div>
                </div>
              </Select.Option>
            ))}
          {userGroups?.userGroups
            .flatMap((ug) => ug.users.map((u) => ({ userGroup: ug, user: u })))
            .map(({ userGroup, user }) => (
              <Select.Option key={`${user.id}-${user.name}-${userGroup.name}`} value={user.id}>
                <div className="flex items-center gap-2">
                  <UserOutlined />
                  <div>{user.name}</div>
                  <div>
                    - {t('現在')}: {userGroup.name}
                  </div>
                </div>
              </Select.Option>
            ))}
        </Select>
      </div>
    </Tooltip>
  )
}

function useRiTimeSeriesBreakdownPropertyRecords() {
  const [ui] = useViewUiRi()

  const recordNodes = useRecoilValue(recordsAtom)
  const records = recordNodes.flatMap((v) => flatRecordNodes(v, []))

  const breakdownPropertyIndex = ui?.queriedDimensions.indexOf('breakdownProperty')
  const breakdownPropertyKey = mapIndexToDimensionFieldName(breakdownPropertyIndex)

  return breakdownPropertyKey === undefined
    ? []
    : records
        .filter((r) => isPresent(r[breakdownPropertyKey]) && r[breakdownPropertyKey] !== TOTAL_VALUE)
        .uniqueBy((r) => r[breakdownPropertyKey])
        .map((r) => ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          value: r[breakdownPropertyKey] as string | number,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
          label: r[generateLabelSqlFieldName(breakdownPropertyKey)] as string | undefined,
        }))
}

export function useViewUiRi() {
  const ui = useUiValue()
  const viewUiKpiTimeSeries = ui.filter((v): v is ViewUIKpiTimeSeries => v.type === 'KpiTimeSeriesGraph')

  return viewUiKpiTimeSeries
}

function mapUserOrUserGroupIdsToUserIds({
  userOrUserGroupIds,
  userGroups,
}: {
  userOrUserGroupIds: string[]
  userGroups: UserGroup[]
}) {
  return userOrUserGroupIds
    .flatMap((id) => {
      const userGroupUserIds = userGroups.filter((ug) => ug.id === id).flatMap((ug) => ug.users.map((u) => u.id)) ?? []
      const userIds = userGroups.flatMap((ug) => ug.users.filter((u) => u.id === id).map((u) => u.id)) ?? []
      return [...userGroupUserIds, ...userIds]
    })
    .unique()
}

export function mapIndexToDimensionFieldName(index: number | undefined) {
  return typeof index === 'number' && index > -1 ? createDimensionFieldName(index + 1) : undefined
}
