import { PageHeader } from '@ant-design/pro-layout'
import { isPresent } from '@salescore/buff-common'
import { Button, Form, Modal } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { configAtom, hasChangeToViewSchemaAtom } from '../../../../recoil/view/atoms'
import { DynamicSelectOptionSqlEditor } from '../../../../rsheet/components/header/DynamicSelectOptionSqlEditor'

export function DynamicSelectOptionModal() {
  const { dynamicSelectOptionModal } = useNavigationModal()
  const [loading, setLoading] = useState(false)
  const column = dynamicSelectOptionModal.content?.column
  const [config, setConfig] = useRecoilState(configAtom)
  const setHasChangeToViewSchema = useSetRecoilState(hasChangeToViewSchemaAtom)

  const title = t(`「{{columnName}}」の連動項目`, { columnName: column?.title })

  return (
    <Modal
      open={dynamicSelectOptionModal.isModalVisible}
      cancelText={t(`閉じる`)}
      onCancel={dynamicSelectOptionModal.hideModal}
      destroyOnClose
      okButtonProps={{ style: { display: 'none' } }}
      width="60%"
    >
      <PageHeader title={title}>
        <Form
          layout="vertical"
          initialValues={{ sql: column?.dynamicSelectOptionConfig?.sql }}
          onFinish={
            // eslint-disable-next-line complexity
            (value: { sql: string }) => {
              setLoading(true)
              // ありえないはず
              if (config.type !== 'sheet' || config.fields === undefined || column?.configIndex === undefined) {
                return
              }
              const targetField = config.fields[column?.configIndex]!
              const newTargetField = {
                ...targetField,
                override: {
                  ...targetField.override,
                  uiColumn: {
                    ...targetField.override?.uiColumn,
                    ...(isPresent(value.sql)
                      ? {
                          dynamicSelectOptionsConfig: {
                            sql: value.sql,
                            type: 'sqlArray' as const,
                            valuesColumn: 'labels',
                          },
                        }
                      : { dynamicSelectOptionsConfig: undefined }),
                  },
                },
              }
              const newFields = config.fields.map((x, index) => (index === column?.configIndex ? newTargetField : x))
              // 設定=即保存としない(sheetに戻って意図したselectOptionが表示されているか確認するactionが想定されるため)
              setConfig({ ...config, fields: newFields })
              setHasChangeToViewSchema(true)
              setLoading(false)
              dynamicSelectOptionModal.hideModal()
            }
          }
        >
          {t(`SQLで連動項目の選択肢を設定することができます。`)}
          <DynamicSelectOptionSqlEditor />
          <Form.Item className="flex justify-end">
            <Button type="primary" htmlType="submit" loading={loading}>
              {t(`設定`)}
            </Button>
          </Form.Item>
        </Form>
      </PageHeader>
    </Modal>
  )
}
