import { BlockOutlined, ToolOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { useSuspenseQuery } from '@apollo/client'
import { FetchEltModelDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { SuspenseWithLoading } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { CORE_CONSTANT, type NodePropertyName, type ViewConfigTreeNode } from '@salescore/core'
import { organizationHasFeature } from '@salescore/frontend-common'
import { Spin, Tabs, type TabsProps } from 'antd'
import { Array, Option } from 'effect'
import { isNotNull } from 'effect/Predicate'
import { t } from 'i18next'
import { type CSSProperties, type ReactNode, Suspense } from 'react'
import { useRecoilValue } from 'recoil'

import { sheetCustomModelsAtom } from '../../../../recoil/view/atoms'
import { useViewValue } from '../../../../recoil/view/hooks'
import type { ReferenceToPropertyForAddChildNodeMutation } from '../../../../recoil/view/mutations/tree/addChildNode'
import { TABLE_HEIGHT } from './const'
import { PropertySelectorTableBody } from './PropertySelectorTableBody'
import { PropertySelectorTableBodyForParentChildrenNode } from './PropertySelectorTableBodyForParentChildrenNode'
import { SalescoreCustomModelPropertyForm } from './SalescoreCustomModelPropertyForm'
import { SheetCustomModelPropertyForm } from './SheetCustomModelPropertyForm'

export const NUMERIC_TYPE_IGNORE_CHARACTER_REGEXP = '[¥,]'

// eslint-disable-next-line complexity
export function PropertySelectorTable({
  node,
  parentNode,
  addField,
  addChildNode,
  style,
}: {
  node: ViewConfigTreeNode
  parentNode: ViewConfigTreeNode | undefined
  addField: (nodeProperty: NodePropertyName) => void
  addChildNode: (
    referenceToProperty: ReferenceToPropertyForAddChildNodeMutation,
    option?: { skipSetCurrentNodePath?: boolean },
  ) => void
  style?: CSSProperties
}): ReactNode {
  const me = recoil.global.useMe()
  const { data } = useSuspenseQuery(FetchEltModelDocument, {
    variables: { organizationId: getOrganizationIdFromPath(), name: node.name },
  })
  // nodeの情報だけで決定したいのだが方法は無い？
  const enableSalescoreCustomObject =
    organizationHasFeature(me.organization, 'enable_salescore_custom_object') && isNotNull(data.eltModel)
  const haveDataExtensionLicense =
    me.isAdmin || me.organization.organizationPlans.some((x) => x.license === 'dataExtension')
  const sheetCustomModels = useRecoilValue(sheetCustomModelsAtom)
  const isSheetCustomModelNode = sheetCustomModels.some((x) => x.name === node.name) // これ以外の方法で判定できるようにしたいが、仕方ない？
  const view = useViewValue()
  const isKpiSheet = view.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX) // TODO: これで判定するのやめたい

  const customItems: TabsProps['items'] = Array.getSomes([
    Option.some({
      key: 'sheet',
      label: t('シート'),
      children: (
        <SheetCustomModelPropertyForm
          node={node}
          parentNode={parentNode}
          addChildNode={addChildNode}
          addField={addField}
        />
      ),
    }),
    !isKpiSheet && enableSalescoreCustomObject && !node.name.startsWith('custom_s')
      ? Option.some({
          key: 'object',
          label: t(`オブジェクト`),
          children: (
            <Suspense fallback={<Spin />}>
              <SalescoreCustomModelPropertyForm
                node={node}
                parentNode={parentNode}
                addChildNode={addChildNode}
                addField={addField}
              />
            </Suspense>
          ),
        })
      : Option.none(),
  ])

  const items: TabsProps['items'] = Array.getSomes([
    isSheetCustomModelNode
      ? Option.none()
      : Option.some({
          key: '1',
          label: (
            <>
              <UnorderedListOutlined />
              {t(`項目`)}
            </>
          ),
          children: <PropertySelectorTableBody node={node} addField={addField} addChildNode={addChildNode} />,
        }),
    isSheetCustomModelNode
      ? Option.none()
      : Option.some({
          key: '2',
          label: (
            <>
              <BlockOutlined />
              {t(`関連オブジェクト`)}
            </>
          ),
          children: <PropertySelectorTableBodyForParentChildrenNode node={node} addChildNode={addChildNode} />,
        }),
    // KPIのシートでカスタム列を扱うとややこしくなるので、一旦作らせない
    isKpiSheet || !haveDataExtensionLicense
      ? Option.none()
      : Option.some({
          key: '3',
          label: (
            <>
              <ToolOutlined />
              {t(`カスタム`)}
            </>
          ),
          children: (
            <SuspenseWithLoading>
              {enableSalescoreCustomObject ? (
                <Tabs defaultActiveKey={'sheet'} type="card" items={customItems} />
              ) : (
                <SheetCustomModelPropertyForm
                  node={node}
                  parentNode={parentNode}
                  addChildNode={addChildNode}
                  addField={addField}
                />
              )}
            </SuspenseWithLoading>
          ),
        }),
  ])

  return (
    <div className={`${node.name} absolute w-full bg-white`} style={style ?? { height: TABLE_HEIGHT }}>
      <Tabs defaultActiveKey="1" type="card" items={items} />
    </div>
  )
}
