import { DeleteOutlined, DownOutlined, PlusCircleFilled } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { treeUtil } from '@salescore/buff-common'
import { CONSTANT, Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import {
  type ConditionalEffectConfig,
  coreDslFormNodeSchema,
  type CoreDslFormNodeStatePartial,
  generateSalesforceHistoryModelName,
  type ViewConfigField,
} from '@salescore/core'
import { generateRandomString, useMessage, useModalAtom } from '@salescore/frontend-common'
import { Avatar, Button, Col, Divider, Dropdown, Form, Modal, Row, Select, Space } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'

import { CoreDslForm } from '../../../components/view_ui/ViewUISheetWithNavigation/SheetNavigation/expression/CoreDslForm'
import { conditionalEffectsFormModalAtom2 } from '../../../recoil/navigation/atoms'
import { useChangesValue } from '../../../recoil/records/hooks'
import { useViewConfigSheet, useViewValue } from '../../../recoil/view/hooks'
import { useFieldMutations } from '../../../recoil/view/mutations/fieldMutations'
import { useConnectionsSelector } from '../../../recoil/view/selectors/connectionsSelector'

//
// ハイライト条件用のモーダル
// salesforceの項目履歴管理が有効になっていないと使えない
//
export const ConditionalEffectsFormModalV2 = () => {
  const conditionalEffectsFormModal = useModalAtom(conditionalEffectsFormModalAtom2)
  const changes = useChangesValue()
  const message = useMessage()

  useEffect(() => {
    if (conditionalEffectsFormModal.isModalVisible && changes.length > 0) {
      message.warning(t('設定を変更すると変更情報が失われます。'))
    }
  }, [conditionalEffectsFormModal.isModalVisible])

  return (
    <Modal
      open={conditionalEffectsFormModal.isModalVisible}
      onCancel={() => {
        conditionalEffectsFormModal.hideModal()
      }}
      width={'80%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1000,
        padding: 0,
      }}
      bodyStyle={
        {
          // backgroundColor: "#eee"
        }
      }
      // title={'集計方法の編集'}
      destroyOnClose
      footer={<></>}
    >
      {conditionalEffectsFormModal.content !== undefined && (
        <PageHeader
          title={
            <span>
              {t(`{{title}}のハイライト条件設定`, { title: conditionalEffectsFormModal.content.column.title })}
            </span>
          }
        >
          <ConditionalEffectsForm
            configField={conditionalEffectsFormModal.content.configField}
            onFinish={() => {
              conditionalEffectsFormModal.hideModal()
            }}
          />
        </PageHeader>
      )}
    </Modal>
  )
}

interface HighlightColor {
  color: string
  label?: string
}

interface ConditionalEffectFormItemState {
  highlightColor?: HighlightColor
  ast?: CoreDslFormNodeStatePartial
  key: string // jsxのためのuniqueなkey（適当に生成）
}

export const highlightColorMapper: Record<string, string> = {
  negative: '#F65948' as const,
  positive: '#3953F9' as const,
}
const presetColors = [
  `#0081C2`,
  `#3953F9`,
  `#6836FF`,
  `#8201E7`,
  `#B0007F`,
  `#DB0024`,
  `#F07801`,
  `#F5C001`,
  `#0FBD01`,
  `#00A4B6`,
]
export const highlightRibonMapper = new Map<string, string>([
  ['#F65948', '#C32615'],
  ['#3953F9', '#0620C6'],
  ['#0081C2', '#004E8F'],
  ['#6836FF', '#3503CC'],
  ['#8201E7', '#4F00B4'],
  ['#B0007F', '#7D004C'],
  ['#DB0024', '#A80000'],
  ['#F07801', '#BD4500'],
  ['#F5C001', '#C28D00'],
  ['#0FBD01', '#008A00'],
  ['#00A4B6', '#007183'],
])
const negativeOption: HighlightColor = {
  color: highlightColorMapper.negative!,
  label: t(`ネガティブ`),
}

function ConditionalEffectsForm({ configField, onFinish }: { configField: ViewConfigField; onFinish: () => void }) {
  const config = useViewConfigSheet()
  const fields = config.fields ?? []
  const sameNodeFields = fields.filter((field) => field.property.nodeName === configField.property.nodeName)
  const mutation = useFieldMutations()
  const view = useViewValue()
  const message = useMessage()
  const [effects, setEffects] = useState<ConditionalEffectFormItemState[]>(() => {
    const highlights = (configField.conditionalEffects ?? []).flatMap((x) => (x.type === 'form' ? x : []))
    if (highlights.isBlank()) {
      return [
        {
          highlightColor: negativeOption,
          ast: undefined,
          key: generateRandomString(),
        },
      ]
    }
    return highlights.map(
      (x): ConditionalEffectFormItemState => ({
        highlightColor: x.highlightColor,
        ast: x.ast,
        key: generateRandomString(),
      }),
    )
  })
  const { getModelAndProperty, getModel } = useConnectionsSelector()
  const { model, property, historyModel } = useMemo(() => {
    const propertyWithModel = getModelAndProperty(configField.property.modelName, configField.property.propertyName)
    const historyModel = getModel(generateSalesforceHistoryModelName(configField.property.modelName))
    if (propertyWithModel === undefined) {
      return { model: undefined, property: undefined, historyModel }
    }
    return {
      ...propertyWithModel,
      historyModel,
    }
  }, [configField])

  if (model === undefined || property === undefined) {
    return <></> // ありえないはず
  }

  return (
    <Form
      layout="vertical"
      onFinish={() => {
        const conditionalEffects = effects
          .map((effect): ConditionalEffectConfig | undefined => {
            if (effect.ast === undefined) {
              return undefined
            }
            const parsed = coreDslFormNodeSchema.safeParse(effect.ast)
            // TODO: 中途半端に入力されてないときの対応
            if (!parsed.success) {
              return undefined
            }
            const ast = parsed.data
            const allLeafs = treeUtil.flatNodes(ast).flatMap((x) => x.leafs)
            if (allLeafs.isBlank()) {
              // 条件が何も設定されていなければ設定しない
              return undefined
            }
            return {
              type: 'form',
              effect: `highlight` as const,
              highlightColor: effect.highlightColor ?? negativeOption,
              ast: parsed.data,
            }
          })
          .compact()

        mutation.setField({
          field: {
            ...configField,
            conditionalEffects,
          },
        })
        message.success(t(`条件付き書式を設定しました`))
        onFinish()
        Posthog.track(POSTHOG_EVENTS.save_highlight_conditional_effect, { viewId: view.id })
      }}
    >
      {effects.map((effect, index) => (
        <>
          <Row justify="space-between" key={effect.key}>
            <Col span={12}>
              <Form.Item label="" rules={[{ required: true, message: t(`効果を入力してください`) }]}>
                <HighlightColorPicker
                  value={effect.highlightColor}
                  onChange={(highlightColor) => {
                    setEffects((xs) => [
                      ...xs.slice(0, index),
                      {
                        ...effect,
                        highlightColor,
                      },
                      ...xs.slice(index + 1),
                    ])
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} className="text-right">
              <Form.Item label="">
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setEffects((xs) => [...xs.slice(0, index), ...xs.slice(index + 1)])
                  }}
                >
                  {t(`削除`)}
                </Button>
              </Form.Item>
            </Col>
            <Col span={24}>
              <CoreDslForm
                isAdvancedMode
                targetProperty={{
                  property,
                  model,
                  nodePropertyName: configField.property,
                  historyModel,
                }}
                initialValue={effect.ast}
                availableFields={sameNodeFields}
                onChange={(ast) => {
                  setEffects((xs) => [
                    ...xs.slice(0, index),
                    {
                      ...effect,
                      ast,
                    },
                    ...xs.slice(index + 1),
                  ])
                }}
              />
            </Col>
          </Row>
          {effects.length - 1 !== index && <Divider />}
        </>
      ))}
      <Row justify="center">
        <Space>
          <Button
            type="text"
            icon={<PlusCircleFilled />}
            color={CONSTANT.colors.primaryColor}
            style={{
              color: CONSTANT.colors.primaryColor,
            }}
            onClick={() => {
              setEffects((xs) => [
                ...xs,
                {
                  highlightColor: negativeOption,
                  ast: undefined,
                  key: generateRandomString(),
                },
              ])
            }}
          >
            {t(`効果を追加`)}
          </Button>
        </Space>
      </Row>
      <Divider />
      <Row justify="end" className="mt-4">
        <Space>
          <Button htmlType="submit">{t(`適用`)}</Button>
        </Space>
      </Row>
    </Form>
  )
}

// eslint-disable-next-line complexity
function HighlightColorPicker({
  value,
  onChange,
}: {
  value: HighlightColor | undefined
  onChange: (value: HighlightColor) => void
}) {
  const [open, setOpen] = useState(false)
  return (
    <Dropdown
      open={open}
      onOpenChange={(open) => {
        setOpen(open)
      }}
      menu={{
        items: [
          {
            key: ``,
            label: (
              <div
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <Form layout="vertical">
                  <Form.Item label={t(`種類`)} style={{ width: 200 }}>
                    <Select
                      onChange={(value) => {
                        if (value === 'negative') {
                          onChange({
                            color: highlightColorMapper.negative!,
                            label: t(`ネガティブ`),
                          })
                          setOpen(false)
                          return
                        }
                        onChange({
                          color: highlightColorMapper.positive!,
                          label: t(`ポジティブ`),
                        })
                        setOpen(false)
                      }}
                      options={[
                        {
                          value: `negative` as const,
                          label: <span style={{ color: highlightColorMapper.negative }}>{t(`ネガティブ`)}</span>,
                        },
                        {
                          value: `positive` as const,
                          label: <span style={{ color: highlightColorMapper.positive }}>{t(`ポジティブ`)}</span>,
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item label={t(`カラー`)}>
                    {presetColors.eachSlice(5).map((colors) => (
                      <div className="mb-4 flex w-full justify-between">
                        {colors.map((color) => (
                          <Avatar
                            size={22}
                            style={{ backgroundColor: color }}
                            onClick={() => {
                              onChange({
                                color,
                              })
                              setOpen(false)
                            }}
                          />
                        ))}
                      </div>
                    ))}
                  </Form.Item>
                </Form>
              </div>
            ),
          },
        ],
      }}
    >
      <Space>
        <span>{t(`ハイライト色を選択`)}</span>
        <Button
          style={{ color: highlightColorMapper[value?.color ?? ''] ?? value?.color }}
          icon={
            <Avatar
              style={{ backgroundColor: highlightColorMapper[value?.color ?? ''] ?? value?.color, marginBottom: 2 }}
              size={16}
            />
          }
        >
          {value?.label}

          <DownOutlined
            style={{
              marginLeft: 8,
              color: '#CFCFCF',
            }}
          />
        </Button>
      </Space>
    </Dropdown>
  )
}
