import { useSuspenseQuery } from '@apollo/client'
import { isPresent } from '@salescore/buff-common'
import { FetchGoalConfigsDocument, FetchOrganizationSettingDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import {
  CORE_CONSTANT,
  type GoalConfig,
  type ViewConfig,
  type ViewConfigKpiParameter,
  type ViewConfigKpiPreset,
} from '@salescore/core'
import { isSharedLinkPresetName, NotionLikeSelector, useMessage } from '@salescore/frontend-common'
import { Button } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import {
  useKpiPivotNavigationModal,
  useKpiPivotParameter,
  useKpiPivotPickedPresetName,
} from '../../../recoil/navigation/hooks'
import {
  type CachedView,
  configAtom,
  hasChangeToKpiPivotParameterAtom,
  hasChangeToViewSchemaAtom,
} from '../../../recoil/view/atoms'
import { useViewAbilityValue, useViewValue } from '../../../recoil/view/hooks'
import { useCachedViewsSelector } from '../../../recoil/view/selectors/cachedViewSelector'

// eslint-disable-next-line complexity
export function KpiPivotPresetsPicker({ withNoDateFieldKpis = false }: { withNoDateFieldKpis?: boolean }) {
  const view = useViewValue()
  const { kpiPresetFormModal } = useKpiPivotNavigationModal()
  const [config, setConfig] = useRecoilState(configAtom)
  const [picked, setPicked] = useKpiPivotPickedPresetName()
  const setHasChangeToViewSchema = useSetRecoilState(hasChangeToViewSchemaAtom)
  const hasChangeToKpiPivotParameter = useRecoilValue(hasChangeToKpiPivotParameterAtom)
  const [parameter, setParameter] = useKpiPivotParameter()
  const message = useMessage()
  const { currentCachedView } = useCachedViewsSelector()
  // goalConfigs周りは本来はgoalConfigsPickerでやりたいところだが、
  // effectの発動順や各種のケースによる不具合が続いており、ここでまとめて設定する形にした。
  const { data: allGoalConfigs } = useSuspenseQuery(FetchGoalConfigsDocument, {
    variables: { organizationId: getOrganizationIdFromPath(), viewId: view.id },
  })
  const ability = useViewAbilityValue()
  const { data: organizationSetting } = useSuspenseQuery(FetchOrganizationSettingDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })

  if (config.type !== 'kpi' && config.type !== 'kpiPivot') {
    // ありえないはず
    return <></>
  }

  // presetにある組織の階層構造名や目標種別(goalConfig)が古い場合があるため、最新の設定内容で上書きする
  const { presets, goalConfigs } = useMemo(() => {
    const goalConfigs = allGoalConfigs.goalConfigs.sortBy((x) => x.rank)
    const userGroupNames = organizationSetting.organization.setting.userGroupCategoryNames.map((value, index) => {
      const groupDimensionKey = `p_salescore_user_groups_d${index + 1}_id`
      return { groupDimensionKey, name: value }
    })
    const updatedPresets = config.presets?.map((preset): ViewConfigKpiPreset => {
      // userGroupに関連するラベル名の更新
      const newRows = preset.parameter.pivot.rows
        .map((row) => {
          const latestLabel = row.key.startsWith(`p_salescore_user_groups`)
            ? userGroupNames.find((x) => x.groupDimensionKey === row.key)?.name
            : row.label
          // presetにある階層が存在しない場合は表示しない
          if (latestLabel === undefined) {
            return
          }
          return {
            ...row,
            label: latestLabel,
          }
        })
        .compact()
      return {
        ...preset,
        parameter: {
          ...preset.parameter,
          pivot: {
            ...preset.parameter.pivot,
            rows: newRows,
          },
          // XXX: プリセットの中に保存されているgoalConfigが古い可能性があるので、ここで最新にする
          //      そもそもプリセットにはgoalConfigのIDのみを保持すべきで、ロジックがやや破綻している
          goalConfig: goalConfigs.find((x) => x.id === preset.parameter.goalConfig?.id) ?? goalConfigs.first(),
        },
      }
    })

    const presets = updatedPresets?.isPresent() ? updatedPresets : []
    return {
      goalConfigs,
      presets,
    }
  }, [config.presets, organizationSetting, allGoalConfigs])

  const select = (value: string) => {
    const newPreset = presets.find((x) => x.name === value)
    if (newPreset !== undefined) {
      setPicked(newPreset.name)
      setParameter(newPreset.parameter)
    }
  }

  const pickedPreset = presets.find((x) => x.name === picked)
  useEffect(() => {
    if (pickedPreset === undefined) {
      setInitPreset({
        config,
        currentCachedView,
        presets,
        goalConfigs,
        setPicked,
        setParameter,
      })
    } else {
      changePreset({
        pickedPreset,
        currentCachedView,
        withNoDateFieldKpis,
        setParameter,
      })
    }
  }, [picked]) // TODO: setInitPreset の後に changePreset が呼ばれないとうまくいっていなかった。ここは空にしたい。

  return (
    <>
      <NotionLikeSelector
        disableAddition={!ability.canUpdate}
        showAddition={true}
        className="mr-2"
        buttonProps={{ type: 'text' }}
        value={pickedPreset?.name}
        uneditable={isSharedLinkPresetName}
        options={presets.map((x) => ({ value: x.name, label: x.name }))}
        setValue={(value) => {
          if (hasChangeToKpiPivotParameter) {
            const messageKey = 'select-preset-warning'
            message.warning({
              key: messageKey,
              content: (
                <span>
                  {t(`変更差分がある場合はプリセットの変更により差分が削除される可能性があります。`)}
                  <br />
                  {t(`プリセットを変更しますか？`)}
                  <Button
                    className="ml-2"
                    onClick={() => {
                      select(value)
                      message.destroy(messageKey)
                    }}
                  >
                    {t(`はい`)}
                  </Button>
                </span>
              ),
              duration: 3,
            })
          } else {
            select(value)
          }
        }}
        placeholder={t(`プリセット`)}
        additionText={t(`プリセットの新規作成`)}
        onAddButtonClick={() => {
          kpiPresetFormModal.showModal({ preset: undefined })
        }}
        onEdit={
          ability.canUpdate
            ? (value) => {
                const preset = presets.find((x) => x.name === value)
                kpiPresetFormModal.showModal({ preset })
              }
            : undefined
        }
        onSort={
          ability.canUpdate
            ? (values: string[]) => {
                const sortedPresets = values.map((value) => presets.find((x) => x.name === value)).compact()
                setConfig({
                  ...config,
                  presets: sortedPresets,
                })
                setHasChangeToViewSchema(true)
              }
            : undefined
        }
        onDestroy={
          ability.canUpdate
            ? (value: string) => {
                const newPresets = presets.filter((x) => x.name !== value)
                setConfig({
                  ...config,
                  presets: newPresets,
                })
                setHasChangeToViewSchema(true)
                // 選択中プリセットが削除された場合は先頭のプリセットをデフォルトとして設定する
                if (value === picked && newPresets[0]?.name !== undefined) {
                  select(newPresets[0].name)
                }
              }
            : undefined
        }
      />
    </>
  )
}

// eslint-disable-next-line complexity
function changePreset({
  currentCachedView,
  pickedPreset,
  withNoDateFieldKpis,
  setParameter,
}: {
  currentCachedView?: CachedView
  pickedPreset: ViewConfigKpiPreset
  withNoDateFieldKpis: boolean
  setParameter: (kpiParameter: ViewConfigKpiParameter) => void
}) {
  // キャッシュが存在する場合はキャッシュを利用する
  // pickedPresetName, kpiParameterのRecoilへのセットはinitializeStateで行なっている
  if (
    currentCachedView?.kpiPivotPickedPresetName === pickedPreset.name &&
    isPresent(currentCachedView.additionalConfig)
  ) {
    return
  }

  setParameter({
    ...pickedPreset.parameter,
    // KPIに期間項目が含まれない場合は期間を考慮しないため、Periodを明示的に全期間にする
    queryParameter: {
      ...pickedPreset.parameter.queryParameter,
      periodName: withNoDateFieldKpis ? '全期間' : pickedPreset.parameter.queryParameter?.periodName,
      startAt: withNoDateFieldKpis ? undefined : pickedPreset.parameter.queryParameter?.startAt,
      endAt: withNoDateFieldKpis ? undefined : pickedPreset.parameter.queryParameter?.endAt,
    },
  })
}

function setInitPreset({
  config,
  currentCachedView,
  presets,
  goalConfigs,
  setPicked,
  setParameter,
}: {
  config: ViewConfig
  currentCachedView?: CachedView
  presets: ViewConfigKpiPreset[]
  goalConfigs: GoalConfig[]
  setPicked: (pickedPresetName: string) => void
  setParameter: (kpiParameter: ViewConfigKpiParameter) => void
}) {
  // キャッシュが存在する場合は、キャッシュを利用する
  // pickedPresetName, kpiParameterのRecoilへのセットはinitializeStateで行なっている
  // TODO: 初期化処理を1つにまとめたい。。。
  if (isPresent(currentCachedView?.additionalConfig)) {
    return
  }

  // プリセットが未選択なとき、先頭のものを強制的に選択する
  // goalConfigについて、同上
  const firstPreset = presets.first()
  if (firstPreset !== undefined) {
    setPicked(firstPreset.name)
    setParameter(firstPreset.parameter)
    return
  }

  if (config.type === 'kpiPivot') {
    setParameter({
      ...CORE_CONSTANT.KPI_PIVOT_DEFAULT_PRESET().parameter,
      goalConfig: goalConfigs.first(),
    })
  } else {
    setParameter({
      ...CORE_CONSTANT.PIVOT_EMPTY_PRESET(config).parameter,
      goalConfig: goalConfigs.first(),
    })
  }
}
