import type { ApolloClient } from '@apollo/client'
import type {
  SharedUrl,
  SheetCustomModelFieldsFragment,
  SheetThreadCommentNotification,
  ViewFieldsFragment,
} from '@salescore/client-api'
import { type Me, recoil } from '@salescore/client-recoil'
import {
  CORE_CONSTANT,
  type ViewConfigAdditionalConfig,
  viewConfigFilterNodeSchema,
  viewConfigFilterSchema,
  viewConfigKpiParameterSchema,
  type ViewConfigKpiPreset,
} from '@salescore/core'
import { isSharedLinkPresetName, makeSharedLinkPresetName } from '@salescore/frontend-common'
import { useEffect } from 'react'
import { type MutableSnapshot, useSetRecoilState } from 'recoil'
import { z } from 'zod'

import type { ViewsContext } from '../components/ViewComponent'
import { kpiParameterAtom, kpiPivotPickedPresetNameAtom, sheetPickedPresetNameAtom } from './navigation/atoms'
import { sheetThreadCommentNotificationsAtom } from './notification/atoms'
import { mustacheParameterAtom, organizationIdAtom, recordsAtom } from './records/atoms'
import {
  autoDrillDownParameterAtom,
  cachedViewsAtom,
  durationMetricsListAtom,
  meAtom,
  oldestSyncedAtAtom,
  sharedUrlAtom,
  sheetCustomModelsAtom,
  type ViewAbility,
  viewAbilityAtom,
  viewsContextAtom,
} from './view/atoms'
import { initializeViewMutation } from './view/mutations/initializeViewMutation'

type InitializeState = ((mutableSnapshot: MutableSnapshot) => void) | undefined

export interface InitializeStateProperties {
  organizationId: string
  mustacheParameter: Record<string, unknown>
  view: ViewFieldsFragment
  client: ApolloClient<unknown>
  me: Me
  additionalConfig: ViewConfigAdditionalConfig | undefined
  oldestSyncedAt?: string
  viewsContext: ViewsContext
}

function extractConfigKpiParameter(sharedUrl: SharedUrl):
  | {
      data: z.infer<typeof viewConfigKpiParameterSchema>
      kind: 'kpiPivotSharedUrl' | 'sheetThreadCommentInKpiPivotDrillDown'
    }
  | undefined {
  const parsedKpiPivotSharedUrl = viewConfigKpiParameterSchema.safeParse(sharedUrl.parameter)
  if (parsedKpiPivotSharedUrl.success) {
    return {
      data: parsedKpiPivotSharedUrl.data,
      kind: 'kpiPivotSharedUrl',
    }
  }
  const parsedSheetThreadCommentInKpiPivotDrillDown = z
    .object({
      kpiPivotParameter: viewConfigKpiParameterSchema,
    })
    .safeParse(sharedUrl.parameter)
  if (parsedSheetThreadCommentInKpiPivotDrillDown.success) {
    return {
      data: parsedSheetThreadCommentInKpiPivotDrillDown.data.kpiPivotParameter,
      kind: 'sheetThreadCommentInKpiPivotDrillDown',
    }
  }
  return undefined
}

function extractAdditionalConfigFilterLeafs(sharedUrl?: SharedUrl) {
  const parsedSheetThreadCommentInKpiPivotDrillDown = z
    .object({
      additionalConfigFilterLeafs: z
        .object({
          type: z.enum([`drillDown`]),
          leaf: viewConfigFilterSchema,
        })
        .array(),
    })
    .safeParse(sharedUrl?.parameter)
  if (parsedSheetThreadCommentInKpiPivotDrillDown.success) {
    return parsedSheetThreadCommentInKpiPivotDrillDown.data.additionalConfigFilterLeafs
  }
}

// eslint-disable-next-line complexity
function applySharedUrlToView(
  view: ViewFieldsFragment,
  sharedUrl: SharedUrl | undefined,
): {
  view: ViewFieldsFragment
  kind?: string
} {
  if (sharedUrl === undefined) {
    return { view, kind: undefined }
  }
  switch (view.config.type) {
    case 'sheet': {
      {
        if (sharedUrl.sheetThreadComment === undefined) {
          return { view, kind: undefined }
        }
        const parameter = z
          .object({
            filterTree: viewConfigFilterNodeSchema,
            sheetPresetName: z.string().optional(),
          })
          .safeParse(sharedUrl.parameter)
        if (parameter.success) {
          const sharedLinkPreset = {
            name: makeSharedLinkPresetName(parameter.data.sheetPresetName),
            tree: view.config.tree,
            filterTree: parameter.data.filterTree,
          }
          const newPresets =
            view.config.presets === undefined
              ? [sharedLinkPreset]
              : [sharedLinkPreset, ...view.config.presets]
                  // 同名のプリセットがあれば上書き
                  .uniqueBy((p) => p.name)

          const viewSharedUrlApplied = {
            ...view,
            config: {
              ...view.config,
              presets: newPresets,
              filterTree: parameter.data.filterTree,
            },
          }
          return {
            view: viewSharedUrlApplied,
            kind: 'sheetThreadComment',
          }
        }
      }
      break
    }
    case 'kpiPivot': {
      if (view.id !== sharedUrl.viewId) {
        return { view, kind: undefined }
      }
      const viewConfigKpiParameter = extractConfigKpiParameter(sharedUrl)
      if (viewConfigKpiParameter === undefined) {
        return { view, kind: undefined }
      }
      const parameter = z
        .object({
          kpiPivotPresetName: z.string().optional(),
        })
        .safeParse(sharedUrl.parameter)
      const sharedLinkPreset: ViewConfigKpiPreset = {
        name: makeSharedLinkPresetName(parameter.data?.kpiPivotPresetName),
        parameter: {
          ...viewConfigKpiParameter.data,
          pivot: {
            rows: viewConfigKpiParameter.data.pivot.rows.uniqueBy((x) => [x.key, x.dateSpan].compact().join(',')), // XXX: 不要なはずだが、どこかのロジックでKPIが重複することがあったので一応追加
            columns: viewConfigKpiParameter.data.pivot.columns.uniqueBy((x) => [x.key, x.dateSpan].compact().join(',')),
          },
        },
      }
      const { presets } = view.config
      // Remove the preset with the same name as the new preset
      const presetsWithoutSharedLink = presets?.filter((preset) => !isSharedLinkPresetName(preset.name)) ?? []
      const newPresets = [sharedLinkPreset, ...presetsWithoutSharedLink]
      const newView = {
        ...view,
        config: {
          ...view.config,
          presets: newPresets,
        },
      }
      return {
        view: newView,
        kind: viewConfigKpiParameter.kind,
      }
    }
    case 'kpiTimeSeries': {
      if (view.id !== sharedUrl.viewId) {
        return { view, kind: undefined }
      }
      return {
        view,
        kind: 'sheetThreadCommentInKpiTimeSeriesDrillDown',
      }
    }
    default: {
      break
    }
  }
  return { view, kind: undefined }
}

export const initializeState =
  ({
    organizationId,
    mustacheParameter,
    view,
    client,
    me,
    additionalConfig,
    oldestSyncedAt,
    viewsContext,
    ability,
    sheetCustomModels,
    sharedUrl,
    sheetThreadCommentNotifications,
  }: InitializeStateProperties & {
    ability: ViewAbility
    sheetCustomModels: SheetCustomModelFieldsFragment[]
    sharedUrl: SharedUrl | undefined
    sheetThreadCommentNotifications: SheetThreadCommentNotification[]
  }): InitializeState =>
  // eslint-disable-next-line complexity
  (snapshot) => {
    const { set } = snapshot

    // feature-view の RecoilRoot 内で client-recoil を利用することがある。
    // そのとき外側の RecoilRoot の状態は引き継がれないため、
    // ここで明示的に再度 initialize をする
    recoil.global.initializeState({ organizationId, client, me })?.(snapshot)

    set(meAtom, me)
    set(organizationIdAtom, organizationId)
    set(viewAbilityAtom, ability)
    set(mustacheParameterAtom, mustacheParameter)
    set(viewsContextAtom, viewsContext)
    set(cachedViewsAtom, viewsContext.cachedViews ?? [])
    set(durationMetricsListAtom, viewsContext.durationMetricsList ?? [])
    // applySharedUrlがここで呼び出されている理由
    // ビューを初期化した直後に sharedUrl の変更をビューに適用すると、
    // sharedUrl 適用前後の二つの `fetchViewQueryResult` がレースコンディションになってしまった。
    // sharedUrl の内容をここで適用することで、ビューの初期化時に`fetchViewQueryResult`が一度だけ呼ばれるようにする。
    const sharedUrlApplied = applySharedUrlToView(view, sharedUrl)
    set(initializeViewMutation, {
      view: sharedUrlApplied.view,
      additionalConfig,
      isSharedUrlApplied: sharedUrlApplied.kind !== undefined,
    })
    set(oldestSyncedAtAtom, oldestSyncedAt)
    set(sheetCustomModelsAtom, sheetCustomModels)
    set(sharedUrlAtom, sharedUrl)
    set(sheetThreadCommentNotificationsAtom, sheetThreadCommentNotifications)

    if (sharedUrlApplied.kind === 'sheetThreadComment' && sharedUrlApplied.view.config.type === 'sheet') {
      const sharedLinkPresetName = sharedUrlApplied.view.config.presets?.find((preset) =>
        isSharedLinkPresetName(preset.name),
      )?.name
      set(sheetPickedPresetNameAtom, sharedLinkPresetName)
    }
    if (
      (sharedUrlApplied.kind === 'kpiPivotSharedUrl' ||
        sharedUrlApplied.kind === 'sheetThreadCommentInKpiPivotDrillDown') &&
      sharedUrlApplied.view.config.type === 'kpiPivot'
    ) {
      const sharedLinkPresetName = sharedUrlApplied.view.config.presets?.find((preset) =>
        isSharedLinkPresetName(preset.name),
      )?.name
      set(kpiPivotPickedPresetNameAtom, sharedLinkPresetName)
      set(kpiParameterAtom, (oldValue) => {
        if (sharedUrlApplied.view.config.type !== 'kpiPivot') {
          return oldValue
        }
        const sharedLinkPreset = sharedUrlApplied.view.config.presets?.find((preset) =>
          isSharedLinkPresetName(preset.name),
        )
        return {
          ...oldValue,
          queryParameter: {
            ...oldValue.queryParameter,
            ...sharedLinkPreset?.parameter.queryParameter,
          },
          goalConfig: sharedLinkPreset?.parameter.goalConfig,
        }
      })
    }
    if (sharedUrlApplied.kind === 'sheetThreadCommentInKpiPivotDrillDown') {
      const additionalFilterLeafsWithPrevious = extractAdditionalConfigFilterLeafs(sharedUrl)
      const sheetViewId = z
        .object({
          sheetViewId: z.string().refine((x) => x.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)),
        })
        .safeParse(sharedUrl?.parameter)
      if (additionalFilterLeafsWithPrevious !== undefined && sheetViewId.success) {
        const kpiViewId = sheetViewId.data.sheetViewId.replace(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX, '')
        set(autoDrillDownParameterAtom, {
          type: 'kpi',
          dimensionValues: {
            rows: [],
            columns: [],
          },
          additionalFilterLeafsWithPrev: additionalFilterLeafsWithPrevious,
          viewId: kpiViewId,
          queryParameter: {
            periodName: '全期間',
          },
        })
      }
    }
    if (sharedUrlApplied.kind === 'sheetThreadCommentInKpiTimeSeriesDrillDown') {
      const additionalFilterLeafsWithPrevious = extractAdditionalConfigFilterLeafs(sharedUrl)

      if (additionalFilterLeafsWithPrevious !== undefined) {
        set(autoDrillDownParameterAtom, {
          type: 'kpi',
          dimensionValues: {
            rows: [],
            columns: [],
          },
          additionalFilterLeafsWithPrev: additionalFilterLeafsWithPrevious,
          viewId: `${CORE_CONSTANT.KPI_TIME_SERIES_DRILL_DOWN_VIEW_ID_PREFIX}${view.id}`,
          queryParameter: {
            periodName: '全期間',
          },
        })
      }
    }

    if (viewsContext.shouldInitializeWithNewRecord === true) {
      set(recordsAtom, [
        {
          id: CORE_CONSTANT.VIEW_NEW_RECORD_PREFIX, // prefixのみで十分なはず
          attributes: {},
          children: [],
          meta: {
            height: 1,
            innerRowIndexStart: 0,
            innerRowIndexEnd: 1,
          },
        },
      ])
    }
  }

export const useReInitializeState = ({ viewsContext }: InitializeStateProperties) => {
  const setViewsContext = useSetRecoilState(viewsContextAtom)
  // 一部の値は書き変わることがあるので、書きかわった場合にatomsも初期化する
  useEffect(() => {
    setViewsContext(viewsContext)
  }, [viewsContext])
}
