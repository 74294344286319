import { isNull, isSome } from '@salescore/buff-common'
import { PermissionActionEnum, type ViewFieldsFragment } from '@salescore/client-api'
import { DelayWrapper } from '@salescore/client-common'
import { CORE_CONSTANT, type ViewQueryRecordNode, type ViewUiKpi } from '@salescore/core'
import { Empty, Table } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { CLIENT_VIEW_CONSTANT } from '../../../constants/constants'
import { kpiDisplayFormatAtom } from '../../../recoil/navigation/atoms'
import { additionalConfigAtom } from '../../../recoil/records/atoms'
import { useViewQueryResult } from '../../../recoil/records/hooks'
import { pivotColumnsSelector } from '../../../recoil/selectors/pivotColumnsSelector'
import { hasChangeToViewSchemaAtom, loadingProgressAtom, sharedUrlAtom } from '../../../recoil/view/atoms'
import { useViewConfigKpi, useViewsContextValue, useViewValue } from '../../../recoil/view/hooks'
import { useViewsRelated } from '../../../recoil/view/selectors/viewsRelatedSelector'
import { RSheet } from '../../../rsheet/components/RSheet'
import { ViewComponent } from '../../ViewComponent'
import { ViewLoading } from '../../ViewLoading'
import { KpiPieChart } from './KpiPieChart'
import { KpiStackedChart } from './KpiStackedChart'

const dynamicViewFields: ViewFieldsFragment = {
  id: '',
  viewGroupId: ``,
  type: `sheet`,
  name: ``,
  queries: [],
  ui: [],
  config: { type: `sheet` },
  rank: 0,
  defaultPermission: PermissionActionEnum.None,
  importedSourceId: ``,
  emoji: ``,
  roleRecordPermissions: [],
  private: false,
  archived: false,
  archivedAt: null,
}

export const ViewUIKpiBody = ({ component }: { component: ViewUiKpi }) => {
  const format = useRecoilValue(kpiDisplayFormatAtom)
  switch (format) {
    case 'sheet': {
      return <KpiSheet />
    }
    case 'table': {
      return <KpiTable component={component} />
    }
    case 'bar': {
      return <KpiStackedChart component={component} />
    }
    case 'pie': {
      return <KpiPieChart component={component} />
    }
    case 'bar-time-series': {
      return <KpiStackedChart component={component} options={{ ignoreNull: true, fillKeysAsDate: true }} />
    }
    default: {
      return <KpiTable component={component} />
    }
  }
}

export function flatRecordNodes(
  recordNode: ViewQueryRecordNode,
  parents: ViewQueryRecordNode[],
): Array<Record<string, unknown>> {
  if (recordNode.children.isBlank()) {
    // attrbiutesに重複がない前提で、マージする
    const xs = [...parents, recordNode].map((x) => x.attributes)
    return [xs.reduce<Record<string, unknown>>((accumulator, x) => ({ ...accumulator, ...x }), {})]
  }

  return recordNode.children.flatMap((childTableNode) =>
    childTableNode.children.flatMap((childRecordNode) => flatRecordNodes(childRecordNode, [...parents, recordNode])),
  )
}

function KpiSheet() {
  const view = useViewValue()
  const viewsContext = useViewsContextValue()
  const additionalConfig = useRecoilValue(additionalConfigAtom)
  const sharedUrl = useRecoilValue(sharedUrlAtom)
  const [kpiViewConfig, setKpiViewConfig] = useViewConfigKpi()
  const { searchConfigIfReference } = useViewsRelated()
  const sheetViewConfig = searchConfigIfReference(kpiViewConfig?.sheet)
  const [hasChangeToViewSchema, setHasChangeToViewSchema] = useRecoilState(hasChangeToViewSchemaAtom)
  const dynamicViewId = CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX + view.id

  if (sheetViewConfig === undefined || sheetViewConfig.type !== 'sheet') {
    return <Empty description={<div>{t(`シートが見つかりません。`)}</div>} />
  }

  // useEffect(() => {
  //   const existRenderDuration = findLastDurationMetrics(view.id)
  //   if (isNull(existRenderDuration)) {
  //     return
  //   }
  //   // すでに記録を開始しているdurationのviewIdも変化するので、更新しておく
  //   updateDurationMetrics(
  //     {
  //       ...existRenderDuration,
  //       viewId: dynamicViewId,
  //     },
  //     view.id,
  //   )
  // }, [dynamicViewId])

  return (
    <>
      <ViewComponent
        view={{
          ...dynamicViewFields,
          // TODO: ここで指定するidをもとのKPIのIDと同じにすると、不具合の際に誤ってシートの情報でKPIを上書きしてしまいそうで、一応別のIDを割り振っている
          //       しかし、ここで「新しいIDをprefix+idとする」というロジックが暗黙的でわかりづらそう
          id: dynamicViewId,
          name: `${view.name}の一覧`,
          config: sheetViewConfig,
          queries: [],
          ui: [],
          defaultPermission: view.defaultPermission,
        }}
        parameter={{}}
        additionalConfig={{
          // ドリルダウンのフィルタ、期間フィルタを引き継がせる
          // 期間フィルタは「棒グラフ側で期間設定するとシートに引き継がれる」「シートで設定してもKPIに引き継がれない」という微妙な挙動になるが、許容する
          ...additionalConfig,
          // previousConfigにkpiViewConfigを指定することで、各種のKPIの設定を使ったり、KPIビューとしてのシートであることを指定する
          // 2023/04 例えば「KPIのときは集計表示をしない」というロジックもpreviousConfigで指定している
          previousConfig: kpiViewConfig,
        }}
        // onFinish={onFinish}
        viewsContext={{
          ...viewsContext,
          updateView: async (argument): Promise<undefined> => {
            const sheetViewConfig = argument.config
            if (sheetViewConfig?.type !== 'sheet') {
              return // ありえないはず
            }
            if (view.id.startsWith(CORE_CONSTANT.KPI_PREVIEW_DRILL_DOWN_VIEW_ID_PREFIX)) {
              // プレビューのドリルダウンの場合は、元の KPI のフォームに変更を加えるため、プレビュー側の updateView に処理をお任せする
              await viewsContext.updateView(argument)
              return
            }
            // KPI タブの一覧パネルから呼ばれた場合、以下で処理する
            // eslint-disable-next-line complexity
            setKpiViewConfig((oldConfig) => {
              if (oldConfig === undefined) {
                // i18n:エラー系は保留
                throw new Error(`oldConfigがundefinedです`) // ありえないはず
              }
              if (oldConfig.sheet?.type !== 'sheet') {
                return oldConfig
              }
              return {
                ...oldConfig,
                sheet: {
                  ...oldConfig.sheet,
                  tree: sheetViewConfig.tree,
                  fields: sheetViewConfig.fields,
                  // ダッシュボードのモーダルからこの KPI ビューが開かれている場合、絞り込みの設定は反映しないのが仕様である
                  filterTree:
                    additionalConfig.previousDimmensions === undefined
                      ? sheetViewConfig.filterTree
                      : oldConfig.sheet.filterTree,
                  sorters: sheetViewConfig.sorters,
                  meta: {
                    fixedLeftColumnIndex: sheetViewConfig.meta?.fixedLeftColumnIndex,
                    visibleRowNum: sheetViewConfig.meta?.visibleRowNum,
                  },
                },
              }
            })
            if (!hasChangeToViewSchema) {
              setHasChangeToViewSchema(true)
            }
          },
        }}
        sharedUrl={sharedUrl}
      />
    </>
  )
}

// eslint-disable-next-line complexity
export function KpiTable({ component }: { component: ViewUiKpi }) {
  const view = useViewValue()
  const [config] = useViewConfigKpi()
  const { columns } = useRecoilValue(pivotColumnsSelector)
  const queryResult = useViewQueryResult()

  const isSettingUncompleted = isNull(config?.sheet) || isNull(config.measure)
  const isEmptyRecords = isSome(queryResult) && columns.isBlank()

  const isEmptyTable = isSettingUncompleted || isEmptyRecords
  const setLoadingProgress = useSetRecoilState(loadingProgressAtom)

  useEffect(() => {
    if (isEmptyTable) {
      setLoadingProgress((progress) => ({
        ...progress,
        completed: true,
      }))
    }
  }, [isEmptyTable])

  return (
    <div className="view-ui-sheet h-full">
      {isEmptyTable ? (
        <DelayWrapper
          delay={CLIENT_VIEW_CONSTANT.RENDER_DELAY_AFTER_LOAD}
          fallback={<ViewLoading context={{ view }} />}
        >
          {/* ローディング画面で読み込みが完了したことを通知してからViewを表示するために、描画タイミングを読み込み完了からごく短時間遅らせている */}
          <div className="p-8">
            <Table />
          </div>
        </DelayWrapper>
      ) : (
        <RSheet
          height="100%" // TODO
          columns={columns}
          context={{
            kpi: component.kpi,
            pivot: component.pivot,
            asKpiTable: true,
            fixedLeftColumnIndex: component.pivot.rows.length + 1,
          }}
        />
      )}
    </div>
  )
}
