import { mutation } from '@salescore/frontend-common'

import { configAtom } from '../../atoms'
import { setConfigMutation } from '../setConfigMutation'

export const moveFieldMutation = mutation({
  key: `view/moveFieldMutation`,
  // eslint-disable-next-line complexity
  set({ get, set }, { oldFieldIndex, newFieldIndex }: { oldFieldIndex: number; newFieldIndex: number }) {
    const config = get(configAtom)
    if (oldFieldIndex === newFieldIndex || config.type !== 'sheet') {
      return
    }
    const fields = config.fields ?? []
    const oldField = fields[oldFieldIndex]
    if (oldField === undefined || newFieldIndex < 0 || newFieldIndex >= fields.length) {
      return
    }
    const removedFields = [...fields.slice(0, oldFieldIndex), ...fields.slice(oldFieldIndex + 1)]
    const insertedFields = [...removedFields.slice(0, newFieldIndex), oldField, ...removedFields.slice(newFieldIndex)]

    set(setConfigMutation, {
      ...config,
      fields: insertedFields,
    })
  },
})
