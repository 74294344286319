import { CORE_CONSTANT } from '../../../../constant'
import { SALESCORE_USER_WITH_GROUP_MODEL } from '../../../../constant/models/SALESCORE_USER_WITH_GROUP_MODEL'
import { PRESET_MODELS } from '../../../../constant/presetModels'
import type { CoreModel } from '../../../../schemas/model/model'
import type { ModelProperty } from '../../../../schemas/model/modelProperty'
import type { ViewUiDimensionCandidate } from '../../../../schemas/ui/ui'
import type { ViewConfigKpi, ViewConfigSheet } from '../../../../schemas/view_config'
import { flatNodes } from '../../../query/executeViewQuery/util'
import { makeIdentifiable } from '../../../util/makeIdentifiable'
import type { CompileContext } from '../../common'
import { pruneInvalidMultiplicityNode } from '../sheet/compileSheetAndMeasure'
import { compileSheetViewConfigTree } from '../sheet/compileSheetViewConfigTree'

// dimensionにできるのは、measureNodeに対して多重度が適切になるフィールドのみ
// eslint-disable-next-line complexity
export function generateUiDimensionCandidates(
  sheet: ViewConfigSheet,
  kpi: ViewConfigKpi,
  context: CompileContext,
): ViewUiDimensionCandidate[] {
  const tree = sheet.tree
  const measure = kpi.measure
  if (tree === undefined || measure === undefined) {
    return []
  }
  const queryTree = compileSheetViewConfigTree(tree, context)
  if (queryTree === undefined) {
    return []
  }

  // カスタムSQLの時の集計ノードは、一旦先頭のノード扱いとする
  const measureNodeName =
    measure.type === 'kpiPreset' ? measure.property.nodeName : (measure.dependentNodeNames.first() ?? tree.name)
  const nodes = flatNodes(queryTree)
  const measureNode = nodes.find((x) => x.name === measureNodeName)
  // measureNodeが見つからないことはないはずだが、万が一見つからなかったら一旦ルートとする
  const prunedTree = pruneInvalidMultiplicityNode(queryTree, measureNode ?? queryTree) ?? queryTree
  const prunedFlatNodeNames = new Set(flatNodes(prunedTree).map((x) => x.name))
  // pruneした後のnodeに含まれるfieldのみとする
  const dimensions = (sheet.fields ?? [])
    .filter((configField) => prunedFlatNodeNames.has(configField.property.nodeName))
    .flatMap((configField): ViewUiDimensionCandidate[] => {
      const model = context.modelSearcher.searchModel(configField.property.modelName)
      const property = model?.properties.find((y) => y.name === configField.property.propertyName)

      //
      if (model === undefined || property === undefined) {
        return [
          // モデル、プロパティが見つからなかったら一旦非表示とする
          // {
          //   type: 'fieldName',
          //   label: [x.property.nodeName, x.property.propertyName].join('_'),
          //   fieldName,
          // },
        ]
      }
      // 一旦雑に実装したが、fieldNameをkey扱いしている箇所があり、動かない
      // if (property.type === 'date' || property.type === 'datetime') {
      //   const timeframes: ViewTimeframe[] = ['day', 'week', 'month']
      //   return timeframes.map(
      //     (timeframe): ViewUiDimensionCandidate => ({
      //       type: 'fieldName',
      //       label: [[model.label, property.label].join(': '), timeframe].join('_'),
      //       fieldName,
      //       dateSpan: timeframe,
      //     }),
      //   )
      // }

      return [getPropertyDimension({ nodeName: configField.property.nodeName, model, property })]
    })
  const userGroupCategoryNames = context.resources.organizationSetting.userGroupCategoryNames.isPresent()
    ? context.resources.organizationSetting.userGroupCategoryNames
    : CORE_CONSTANT.USER_GROUP_CATEGORY_NAMES
  return [
    ...(kpi.user === undefined
      ? []
      : [
          CORE_CONSTANT.KPI_PIVOT_USER_DIMENSION(),
          ...userGroupCategoryNames.map(
            (userGroupCategoryName, index): ViewUiDimensionCandidate =>
              getSalescoreUserGroupDimension(index + 1, userGroupCategoryName),
          ),
        ]),
    // KPIとしてはDATE_DIMENSIONをいれる必要性はない。KPI_PIVOT側は、compileKpiPivotViewConfig側でdimensionsの指定をしているので、ここで定義しなくて良い。
    // （ユーザーに関しても同じだが、ユーザーグループがあるのでユーザーは紐付けしている）
    // -> 2022/11/09 この仕様やめるか？dimensionsにdateがないと、KPIビュー側でやりづらい
    kpi.date === undefined ? undefined : CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION(),
    ...dimensions,
  ].compact()
}

function generateSalescoreUserGroupNodeName(depth: number) {
  return `${PRESET_MODELS.USER_GROUP.NAME}_d${depth}`
}

export function getSalescoreUserGroupDimension(depth: number, userGroupCategoryName: string): ViewUiDimensionCandidate {
  return {
    type: 'property',
    key: [`p`, generateSalescoreUserGroupNodeName(depth), `id`].join('_'),
    property: {
      nodeName: SALESCORE_USER_WITH_GROUP_MODEL.name,
      modelName: SALESCORE_USER_WITH_GROUP_MODEL.name,
      propertyName: `user_group_d${depth}_id`,
    },
    label: userGroupCategoryName,
    fieldType: 'string',
  }
}

export function getPropertyDimension({
  nodeName,
  model,
  property,
}: {
  nodeName: string
  model: CoreModel
  property: ModelProperty
}): ViewUiDimensionCandidate {
  return {
    type: 'property',
    key: makeIdentifiable([`p`, nodeName, property.name].join('_')),
    property: {
      nodeName,
      modelName: model.name,
      propertyName: property.name,
    },
    label: [model.label, property.label].join(': '),
    fieldType: property.type,
  }
}
