import { isNull, isSome } from '@salescore/buff-common'

import { CORE_CONSTANT } from '../../../../../constant'
import type { SelectOption } from '../../../../../schemas/query'
import type {
  KpiTimeSeriesFilters,
  ViewConfigKpiTimeSeries,
  WaterfallDimension,
} from '../../../../../schemas/view_config'
import { quote } from '../../../../query/executeViewQuery/generateSql'
import type { CompileContext } from '../../../common'
import { generateFieldName } from '../../sheet/generateFieldName'
import { generateSheetWithAdditionalFields } from './generateSheetWithAdditionalFields'
import { dimensionColumnName, snapshotP1Name, snapshotP2Name } from './utils'

// eslint-disable-next-line complexity
export function generatePrioritizedSql({
  config,
  context,
}: {
  config: ViewConfigKpiTimeSeries
  context: CompileContext
}): string | undefined {
  const sheet = config.kpiFragment?.sheet
  if (sheet?.type !== 'sheet' || isNull(sheet.tree)) {
    return
  }

  if (isNull(config.waterfall)) {
    return
  }

  const fields =
    generateSheetWithAdditionalFields({
      sheet,
      config,
    }).fields ?? []

  const idFieldAsName = generateFieldName({ nodeName: sheet.tree.name, propertyName: 'id' })
  const idField = `COALESCE(${quote(snapshotP1Name)}.${quote(idFieldAsName)},${quote(snapshotP2Name)}.${quote(idFieldAsName)}) AS ${quote('id')}`

  const p1Fields = fields.map((x) => {
    const asName = `${generateFieldName(x.property)}_${CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX}`
    return `${quote(snapshotP1Name)}.${quote(generateFieldName(x.property))} AS ${quote(asName)}`
  })
  const p2Fields = fields.map((x) => {
    const asName = `${generateFieldName(x.property)}_${CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX}`
    return `${quote(snapshotP2Name)}.${quote(generateFieldName(x.property))} AS ${quote(asName)}`
  })

  const dimensionField = config.waterfall.dimensions.isBlank()
    ? `NULL AS ${quote(dimensionColumnName)}`
    : `CASE
${config.waterfall.dimensions
  .map(
    (dimension) =>
      `WHEN ${generateDimensionCaseFilter({
        dimension,
        filters: config.filters,
        context,
      })} THEN '${dimension.name}'`,
  )
  .join('\n')}
END AS ${quote(dimensionColumnName)}`

  const sql = `SELECT
${[idField, ...p1Fields, ...p2Fields, dimensionField].join(',\n')}
FROM ${quote(snapshotP1Name)}
FULL OUTER JOIN ${quote(snapshotP2Name)} ON ${quote(snapshotP1Name)}.${quote(idFieldAsName)} = ${quote(snapshotP2Name)}.${quote(idFieldAsName)}`

  return sql
}

/**
 * 滝グラフの軸項目の種類に応じて、優先度フィールドのCASE式内の条件部分を生成する
 *
 * TODO: 一旦軸項目の種類は固定で実装するが、将来的に条件をユーザー側で設定できるようにしたい
 */
// eslint-disable-next-line complexity
function generateDimensionCaseFilter({
  dimension,
  filters,
  context,
}: {
  dimension: WaterfallDimension
  filters?: KpiTimeSeriesFilters
  context: CompileContext
}): string {
  if (isNull(dimension.property)) {
    return 'FALSE'
  }

  const { modelName, nodeName, propertyName } = dimension.property
  const p1NodeAndProperty = `${quote(snapshotP1Name)}.${quote(generateFieldName({ nodeName, propertyName }))}`
  const p2NodeAndProperty = `${quote(snapshotP2Name)}.${quote(generateFieldName({ nodeName, propertyName }))}`

  switch (dimension.name) {
    case 'newOpportunity': {
      return `${p1NodeAndProperty} IS NULL AND ${p2NodeAndProperty} IS NOT NULL`
    }
    case 'lostOpportunity': {
      return `${p1NodeAndProperty} IS NOT NULL AND ${p2NodeAndProperty} IS NULL`
    }
    case 'amountIncreased': {
      return `${coalesce(p1NodeAndProperty, '0')} < ${coalesce(p2NodeAndProperty, '0')}`
    }
    case 'amountDecreased': {
      return `${coalesce(p1NodeAndProperty, '0')} > ${coalesce(p2NodeAndProperty, '0')}`
    }
    case 'timelineAdvanced': {
      return isSome(filters?.startPeriod?.startAt) && isSome(filters.endPeriod?.endAt)
        ? `(${p1NodeAndProperty} NOT BETWEEN '${filters.startPeriod.startAt}' AND '${filters.endPeriod.endAt}') AND (${p2NodeAndProperty} BETWEEN '${filters.startPeriod.startAt}' AND '${filters.endPeriod.endAt}')`
        : 'FALSE'
    }
    case 'timelineDelayed': {
      return isSome(filters?.startPeriod?.startAt) && isSome(filters.endPeriod?.endAt)
        ? `(${p1NodeAndProperty} BETWEEN '${filters.startPeriod.startAt}' AND '${filters.endPeriod.endAt}') AND (${p2NodeAndProperty} NOT BETWEEN '${filters.startPeriod.startAt}' AND '${filters.endPeriod.endAt}')`
        : 'FALSE'
    }
    case 'probabilityIncreased': {
      const property = context.modelSearcher.searchProperty(modelName, propertyName)
      // 確度項目が数値型の場合は数値比較を行う
      if (property?.type === 'numeric' || property?.type === 'integer') {
        return `${coalesce(p1NodeAndProperty, '0')} < ${coalesce(p2NodeAndProperty, '0')}`
      }

      // それ以外の場合は選択肢項目のはずなので順序に従って比較を行う
      return isSome(dimension.extra?.optionsOrder)
        ? `(${generateOptionsOrderRank(dimension.extra.optionsOrder, p1NodeAndProperty)}) < (${generateOptionsOrderRank(dimension.extra.optionsOrder, p2NodeAndProperty)})`
        : 'FALSE'
    }
    case 'probabilityDecreased': {
      const property = context.modelSearcher.searchProperty(modelName, propertyName)
      // 確度項目が数値型の場合は数値比較を行う
      if (property?.type === 'numeric' || property?.type === 'integer') {
        return `${coalesce(p1NodeAndProperty, '0')} > ${coalesce(p2NodeAndProperty, '0')}`
      }

      // それ以外の場合は選択肢項目のはずなので順序に従って比較を行う
      return isSome(dimension.extra?.optionsOrder)
        ? `(${generateOptionsOrderRank(dimension.extra.optionsOrder, p1NodeAndProperty)}) > (${generateOptionsOrderRank(dimension.extra.optionsOrder, p2NodeAndProperty)})`
        : 'FALSE'
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = dimension.name
      throw new Error(`Unknown dimension`)
    }
  }
}

function generateOptionsOrderRank(optionsOrder: SelectOption[], field: string): string {
  return `CASE ${field}
${[...optionsOrder]
  .reverse()
  .map((option, index) => `WHEN '${option.value}' THEN ${index + 1}`)
  .join('\n')} ELSE 0 END`
}

export function coalesce(field: string, fallback: string): string {
  return `COALESCE(${field}, ${fallback})`
}
