import { SaveOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useApolloClient, useMutation, useSuspenseQuery } from '@apollo/client'
import type { ReverseEltFieldsFragment } from '@salescore/client-api'
import {
  CreateReverseEltDocument,
  FetchReverseEltsDocument,
  FetchSqlResultDocument,
  UpdateReverseEltDocument,
} from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { SqlEditorWithExecuteModal } from '@salescore/frontend-common'
import { Button, Form, Input, message, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { startTransition, useState } from 'react'

interface FormValue {
  name: string
  destinationId: string
  eltModelId: string
}

export function UpsertReverseEltForm({
  reverseElt,
  onAfterFinish,
}: {
  reverseElt?: ReverseEltFieldsFragment
  onAfterFinish: () => void
}) {
  const { data, refetch } = useSuspenseQuery(FetchReverseEltsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const { eltModels, destinations } = data
  const invalidate = () => {
    startTransition(async () => {
      await refetch()
    })
  }
  const [form] = useForm<FormValue>()
  const [updateReverseEltMutation] = useMutation(UpdateReverseEltDocument)
  const [createReverseEltMutation] = useMutation(CreateReverseEltDocument)
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()
  const [sql, setSql] = useState(reverseElt?.sql ?? ``)

  const onFinish = async (values: FormValue) => {
    try {
      setLoading(true)
      await (reverseElt === undefined
        ? createReverseEltMutation({
            variables: {
              organizationId: getOrganizationIdFromPath(),
              reverseElt: {
                ...values,
                sql,
              },
            },
          })
        : updateReverseEltMutation({
            variables: {
              organizationId: getOrganizationIdFromPath(),
              reverseElt: {
                id: reverseElt.id,
                name: values.name,
                sql,
              },
            },
          }))
      void message.info(t(`Reverse ETL設定を更新しました！`))
      invalidate()
      onAfterFinish() // redirect
    } catch (error) {
      if (error instanceof Error) {
        void message.error(error.message)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <PageHeader title={t(`Reverse ETL設定を作成`)}>
      <Form<FormValue> form={form} onFinish={onFinish} layout="vertical" initialValues={reverseElt}>
        <Form.Item
          name="name"
          label={t(`Reverse ETL設定の名前`)}
          rules={[{ required: true, message: t(`入力してください`) }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="destinationId"
          label={t(`SQLを実行するデータベース`)}
          rules={[{ required: true, message: t(`入力してください`) }]}
        >
          <Select options={destinations.map((x) => ({ value: x.id, label: x.provider }))} />
        </Form.Item>
        <Form.Item
          name="eltModelId"
          label={t(`書き込み先`)}
          rules={[{ required: true, message: t(`入力してください`) }]}
        >
          <Select
            options={eltModels.map((x) => ({ value: x.id, label: `${x.model.label} - ${x.name}` }))}
            showSearch
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>
        <SqlEditorWithExecuteModal
          sql={sql}
          onFinish={(sql) => {
            setSql(sql)
          }}
          onExecute={async (sql) => {
            const { data: result } = await client.query({
              query: FetchSqlResultDocument,
              variables: {
                sql,
                organizationId: getOrganizationIdFromPath(),
                destinationId: form.getFieldsValue().destinationId,
              },
            })
            return {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
              rows: (result.adminSqlResult.rows ?? []) as Array<Record<string, string>>,
              fields: result.adminSqlResult.fields?.map((x) => x.name) ?? [],
            }
          }}
        />
        <Form.Item>
          <Space className="mt-16 w-full flex-row-reverse">
            <Button icon={<SaveOutlined />} type="primary" loading={loading} htmlType="submit">
              {t(`保存`)}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </PageHeader>
  )
}
