import { useMutation, useSuspenseQuery } from '@apollo/client'
import type {
  EltConfigFieldsFragment,
  EltConfigInput,
  SourceEntitySchemaFieldsFragment,
  SourceFieldsFragment,
} from '@salescore/client-api'
import { FetchSourceEntitySchemasDocument, UpdateEltConfigDocument } from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS, routes } from '@salescore/client-base'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { useRedirect } from '@salescore/frontend-common'
import { Alert, App, Button, Space } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { SourceEntitySchemasTable } from './SourceEntitySchemasTable'

// salesforceの場合、項目数が多すぎるとURI Too Longでエラーになるため、多い場合はAPIコールを複数回するような挙動にしている
// 通常よりAPIコールが増えることになるので（またサーバー側の負担も増えるので）警告を出す
const PROPERTY_LENGTH_LIMIT = 300

export const EditConnectionStep2 = ({
  source,
  pickedEntityNames,
  eltConfig,
  onAfterFinish,
}: {
  source: SourceFieldsFragment
  pickedEntityNames: string[]
  eltConfig: EltConfigFieldsFragment | undefined // 未設定だったらundefined
  onAfterFinish: () => void
}) => {
  const { message } = App.useApp()
  const { data } = useSuspenseQuery(FetchSourceEntitySchemasDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
      id: source.id,
      targetEntityNames: pickedEntityNames,
    },
  })
  const entitySchemas = data.sourceEntitySchemas
  const [loading, setLoading] = useState(false)
  const [updateEltConfig] = useMutation(UpdateEltConfigDocument)
  const redirect = useRedirect()
  const [pickedEntitySchemas, setPickedEntitySchemas] = useState<SourceEntitySchemaFieldsFragment[]>(
    entitySchemas
      // eslint-disable-next-line complexity
      .map((entitySchema) => {
        // ありえないはずだが、プロパティが1つもないentityがあればスキップ
        if (entitySchema.properties.isBlank()) {
          return
        }

        const sourceConfig = eltConfig?.sourceConfigs?.find(
          (sourceConfig) => entitySchema.name === sourceConfig.sourceName,
        )
        // 元のELT設定にないとき、すなわち連携オブジェクトを新規追加したときは、entitySchemaをそのまま使う
        if (sourceConfig === undefined) {
          return entitySchema
        }
        // 元のELT設定があるとき、元の設定と最新のentitySchemaをマージする
        const propertyMapper = entitySchema.properties.groupByUniqueKey((x) => x.name)
        const properties =
          sourceConfig.propertyNamesWhitelist
            ?.map((y) => {
              const property = propertyMapper[y]
              if (property === undefined) {
                // 連携先から削除されたオブジェクトは設定から削除する
                return
              }
              return {
                name: property.name,
                label: property.label, // 使われない
              }
            })
            .compact() ?? []
        return {
          name: entitySchema.name,
          label: entitySchema.label,
          properties: properties.isPresent() ? properties : entitySchema.properties, // 何も選択されていなければ、全選択状態にする
        }
      })
      .compact() ?? [],
  )
  const tooManyPropertyEntitySchemas = pickedEntitySchemas.filter((x) => x.properties.length > PROPERTY_LENGTH_LIMIT)

  const onFinish = async () => {
    try {
      setLoading(true)
      // TODO: forcePickが指定されているか一応チェック？
      // TODO: 本来であればwhitelist以外の設定を引き継いでから更新すべきだが、
      //       現状では他の設定をユーザーが変更するUIがなく、そのため他の設定のマージをバックエンド側でやるようにしている
      const newEltConfig: EltConfigInput = {
        sourceNamesWhitelist: pickedEntitySchemas.map((x) => x.name),
        sourceConfigs: pickedEntitySchemas.map((x) => ({
          sourceName: x.name,
          propertyNamesWhitelist: x.properties.map((y) => y.name),
        })),
      }
      const { data: result } = await updateEltConfig({
        variables: {
          organizationId: getOrganizationIdFromPath(),
          sourceId: source.id,
          eltConfig: newEltConfig,
        },
      })
      onAfterFinish()
      void message.info(t(`同期設定を保存しました`))
      redirect(routes.sourceSettingsPathV2())
      Posthog.track(POSTHOG_EVENTS.save_sync_fields, {
        organizationId: getOrganizationIdFromPath(),
        config: result?.updateEltConfig,
      }) //転送量が大きいためコストが嵩むようなら情報を削る
    } catch (error: unknown) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)} ${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      {tooManyPropertyEntitySchemas.length > 0 && source.provider === 'salesforce' && (
        <Alert
          className="mb-1 mt-3"
          message={t(`項目数が多すぎます`)}
          description={`${t(
            `項目数が{{limit}}を超えているオブジェクトがあります。同期項目が多い場合、連携先へのAPIリクエストが通常より増加したり、同期に時間がかかる可能性があります。対象のオブジェクト：`,
            { limit: PROPERTY_LENGTH_LIMIT },
          )}${tooManyPropertyEntitySchemas.map((x) => x.label).join(',')}
          `}
          type="warning"
          closable
          showIcon
        />
      )}
      <SourceEntitySchemasTable
        pickedEntitySchemas={pickedEntitySchemas}
        entitySchemas={entitySchemas}
        setPickedEntitySchemas={setPickedEntitySchemas}
      />

      <div className="mb-8 mt-2 flex flex-row-reverse">
        <Space>
          <Button>{t(`戻る`)}</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              void onFinish()
            }}
          >
            {t(`完了`)}
          </Button>
        </Space>
      </div>
    </div>
  )
}
