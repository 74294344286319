import { useSuspenseQuery } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import type { ViewGroupFieldsFragment } from '@salescore/client-api'
import { FetchViewGroupsDocument, FetchViewsForSiderDocument } from '@salescore/client-api'
import { useRecoilValue } from 'recoil'

import { organizationIdAtom } from '../atoms'

const useViewGroupsRelatedSelector = () => {
  const organizationId = useRecoilValue(organizationIdAtom)
  const { data } = useSuspenseQuery(FetchViewGroupsDocument, { variables: { organizationId } })
  const { viewGroups } = data
  const viewGroupsMap = viewGroups.groupByUniqueKey((x) => x.id)
  const viewGroupsWithParents = viewGroups.map((viewGroup) => ({
    viewGroup,
    parents: searchParentViewGroups(viewGroup.viewGroupId, viewGroupsMap),
  }))
  const viewGroupsWithParentsMap = viewGroupsWithParents.groupByUniqueKey((x) => x.viewGroup.id)

  return {
    viewGroups,
    viewGroupsMap,
    viewGroupsWithParents,
    searchViewGroupWithParents: (viewGroupId: string) => viewGroupsWithParentsMap[viewGroupId],
  }
}

const useViewsRelatedSelector = () => {
  const organizationId = useRecoilValue(organizationIdAtom)
  const { data } = useSuspenseQuery(FetchViewsForSiderDocument, { variables: { organizationId } })
  const views = data.viewsForSider
  const viewsMap = views.groupByUniqueKey((x) => x.id)

  return {
    views,
    viewsMap,
    searchView: (viewId: string) => viewsMap[viewId],
  }
}

export const view = {
  useViewGroupsRelatedSelector,
  useViewsRelatedSelector,
}

// eslint-disable-next-line complexity
function searchParentViewGroups(
  viewGroupId: string | undefined | null,
  viewGroupsMap: Record<string, ViewGroupFieldsFragment>,
  depth?: number,
): ViewGroupFieldsFragment[] {
  if ((depth ?? 0) > 20) {
    // ありえないはずだが、循環参照があった場合に無限ループになるので、念のため
    return []
  }
  if (isNull(viewGroupId)) {
    return []
  }
  const viewGroup = viewGroupsMap[viewGroupId]
  if (viewGroup === undefined) {
    return []
  }
  return [viewGroup, ...searchParentViewGroups(viewGroup.viewGroupId ?? undefined, viewGroupsMap, (depth ?? 0) + 1)]
}
