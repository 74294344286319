import { ApolloConsumer } from '@apollo/client'
import { recoil } from '@salescore/client-recoil'
import { wrapKeyboardEvent } from '@salescore/frontend-common'
import { type KeyboardEvent, useEffect } from 'react'

import { LauncherBodyV2 } from '../GlobalLauncherV2/LauncherBodyV2'
import { SuspenseWithLoading } from '../SuspenseWithLoading'

export function GlobalLauncher() {
  const { visibility, open, hide } = recoil.global.launcher.useLauncher()

  useEffect(() => {
    const handler = (event: unknown) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
      const e = event as KeyboardEvent<HTMLDivElement> // TODO
      const wrapped = wrapKeyboardEvent(e)
      if (wrapped.key === 'k' && wrapped.commandKey && !visibility) {
        open()
        e.preventDefault()
      }
      if (wrapped.code === 'Escape') {
        hide()
        e.preventDefault()
      }
    }
    window.addEventListener('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
  })

  // エラー表示などを出したくないのでhandleQueryは使わない
  if (!visibility) {
    return <></>
  }

  return (
    <ApolloConsumer>
      {(client) => (
        <SuspenseWithLoading>
          <LauncherBodyV2 />
        </SuspenseWithLoading>
      )}
    </ApolloConsumer>
  )
}
