import { t } from 'i18next'
import { Mention, MentionsInput } from 'react-mentions'

const customMentionsInputStyle = {
  control: {
    minHeight: 60,
    border: 'none',
  },
  highlighter: {
    padding: 4,
    border: 'none',
    wordBreak: 'break-all',
  },
  input: {
    padding: 4,
    border: '1px solid silver',
    borderRadius: '8px',
    wordBreak: 'break-all',
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16,
      maxHeight: 200,
      overflow: 'auto',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
}

export function MentionTextarea(properties: { isReply: boolean; options: Array<{ id: string; display: string }> }) {
  const placeholder = properties.isReply ? t(`@name をメンションして返信`) : t(`@name をメンションしてコメント`)
  return (
    <MentionsInput
      placeholder={placeholder}
      rows={3}
      autoFocus
      allowSuggestionsAboveCursor
      style={customMentionsInputStyle}
      {...properties}
    >
      <Mention
        trigger="@"
        data={properties.options}
        className="rounded-sm bg-[#cee4e5]"
        renderSuggestion={
          // eslint-disable-next-line @typescript-eslint/max-params
          (suggestion, search, highlightedDisplay, index, focus) => <span className="ml-2">{highlightedDisplay}</span>
        }
      />
    </MentionsInput>
  )
}
