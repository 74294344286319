import type {
  ArrayFiltersType,
  BooleanFiltersType,
  DateFiltersType,
  NumericFiltersType,
  StringFiltersType,
} from '../../../../../../schemas/query/filterTypes'
import { ValidationError } from '../../../../../errors'

export const validateFilterType = <
  T extends StringFiltersType | NumericFiltersType | DateFiltersType | ArrayFiltersType | BooleanFiltersType,
>(
  type: string,
  validTypes: T,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
  if ((validTypes as unknown as string[]).includes(type)) {
    // TOOD: ts4.5にした影響でうまくいかなくなった。zodに移行したい。
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    return type as T[number]
  }
  throw new ValidationError()
}
