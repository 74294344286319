import { isSome } from '@salescore/buff-common'
import Mustache from 'mustache'

import type { ViewQueryList, ViewQueryResult, ViewQueryResultCursor } from '../../schemas/query'
import { makeIdentifiable } from '../util/makeIdentifiable'
import { addMetadata } from './executeViewQuery/addMetadata'
import { generateSql } from './executeViewQuery/generateSql'
import { groupByCurrentNode } from './executeViewQuery/groupByCurrentNode'
import { pruneRows } from './executeViewQuery/pruneRows'
import { generateAbsoluteFieldAsName } from './executeViewQuery/util'
import {
  ExecuteViewPostgresError,
  type SqlClient,
  type SqlResultRecord,
  type ViewResultCursorOrInitialCursor,
} from './executeViewQueryForAggregationQuery'
import { defaultCursor, executeViewQueryWithApplicationJoin } from './executeViewQueryWithApplicationJoin'
import { generateSnapshotsFromHistory } from './snapshots/generateSnapshotsFromHistory'

// TODO
const tryAndCatch = async <T>(f: () => T | Promise<T>, handler: (error: Error) => void): Promise<T> => {
  try {
    return await f()
  } catch (error) {
    if (error instanceof Error) {
      handler(error)
    }
    throw error
  }
}

// eslint-disable-next-line complexity
export async function executeViewQueryForListQuery({
  viewQuery,
  sqlClient,
  cursor,
  mustacheParameter,
  dateForDiffHighlight,
  shouldJoinRecordsInApplication,
}: {
  viewQuery: ViewQueryList
  sqlClient: SqlClient
  cursor?: ViewResultCursorOrInitialCursor
  mustacheParameter: Record<string, unknown>
  dateForDiffHighlight?: Date
  shouldJoinRecordsInApplication?: boolean
}): Promise<ViewQueryResult> {
  if (shouldJoinRecordsInApplication === true) {
    return await executeViewQueryWithApplicationJoin({
      viewQuery,
      sqlClient,
      cursor,
      mustacheParameter,
      dateForDiffHighlight,
    })
  }

  const currentCursor: ViewResultCursorOrInitialCursor = cursor ?? defaultCursor
  const rawSql = generateSql(viewQuery, currentCursor, mustacheParameter)
  const sql = Mustache.render(rawSql, mustacheParameter)

  // 2023/03/11 緊急対応
  if (sql.includes(`"salesforce_activity_1"`) || sql.includes(`"salesforce_activity_2"`)) {
    throw new Error(`行動オブジェクトを複数回関連付けすることはできません`)
  }

  const queryResult = await tryAndCatch(
    async () => {
      const result = await sqlClient.query(sql)
      return result
    },
    (e) => {
      throw new ExecuteViewPostgresError(e, `${e.name}: ${e.message}`)
    },
  )
  if (queryResult === undefined) {
    throw new Error(`query execution error. ${sql}`)
  }

  const { rows, ...queryResultMeta } = queryResult
  const { currentRows, nextRowSize, nextId, pruneError } = pruneRows(
    rows as SqlResultRecord[], // TODO
    currentCursor,
    makeIdentifiable(generateAbsoluteFieldAsName(viewQuery.tree.name, viewQuery.tree.read.idColumn ?? 'id')),
  ) // TODO
  const nodeRecords = groupByCurrentNode(currentRows, viewQuery.tree, viewQuery)
  const nextCursor: ViewQueryResultCursor | undefined = isSome(nextId)
    ? {
        page: currentCursor.page + 1,
        pageSize: currentCursor.pageSize,
        chunkSize: nextRowSize,
        nextId,
      }
    : undefined

  // debug
  // const x = {
  //   view,
  //   rows,
  //   result,
  //   nextCursor,
  // }
  // const y = inspect(x, { colors: true, depth: 8 })

  const result = await addMetadata(sqlClient, nodeRecords, viewQuery.tree, dateForDiffHighlight)
  const snapshots = await generateSnapshotsFromHistory({
    snapshotsConfigs: viewQuery.extra?.snapshotConfigs ?? [],
    records: rows,
    sqlClient,
  })

  return {
    viewQuery,
    result,
    sqls: [sql],
    sqlDetails: [
      {
        sql,
        name: ``, // TODO
      },
    ],
    nextCursor,
    queryResultMeta,
    snapshots,
    warn: pruneError,
  }
}
