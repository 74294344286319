import _clone from 'just-clone'
import intersect from 'just-intersect'
import _union from 'just-union'

import { mapConsIncremental as _mapConsIncremental, sliceWhen as _sliceWhen } from '../util/array'
import { multiCompareFunction } from '../util/array/compareFunction'
import { MRecord, toRecord as _toRecord } from '../util/record'
import { compareFunction, eachSlice as _eachSlice, range } from '../util/util'
import { defineProperty } from './util'

// Array

const _toObject = function <T, V>(as: T[], f: (x: T, index: number) => [key: string | undefined, value: V]) {
  return as.reduce((accumulator, a, index) => {
    const x = f(a, index)
    if (x[0] === undefined) {
      return accumulator
    }
    return {
      ...accumulator,
      [x[0]]: x[1],
    }
  }, {})
}

const toObject = function <T, V>(this: T[], f: (x: T, index: number) => [key: string | undefined, value: V]) {
  return _toObject(this, f)
}

const toRecord = function <T, V>(this: T[], f: (x: T, index: number) => [key: string, value: V]) {
  const data = _toObject(this, f)
  return _toRecord(data)
}

const _toMap = function <K, V>(xs: Array<[K, V]>) {
  return xs.reduce((accumulator, property) => {
    accumulator.set(property[0], property[1])
    return accumulator
  }, new Map<K, V>())
}

const toMap = function <K, V>(this: Array<[K, V]>) {
  return _toMap(this)
}

const last = function (this: unknown[]) {
  return this.at(-1)
}

const first = function (this: unknown[]) {
  return this[0]
}

const sum = function (this: number[]) {
  return this.reduce((accumulator, x) => accumulator + x, 0)
}

const eachCons = function (this: unknown[], n: number) {
  const result: unknown[][] = []
  for (let index = 0; index < this.length - n + 1; index++) {
    result.push(this.slice(index, index + n))
  }
  return result
}

const eachSlice = function (this: unknown[], n: number) {
  return _eachSlice(n)(this)
}

const unique = function <T>(this: T[]): T[] {
  // Setは挿入順を保持するため、以下で配列の順序は保証される
  // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Set
  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(new Set(this))
}

const _groupBy = function <V extends string, T>(xs: T[], f: (x: T) => V) {
  const result = xs.reduce<Record<V, T[]>>(
    (accumulator, x) => {
      const key = f(x)
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const currentGroup = accumulator[key] ?? []
      accumulator[key] = [...currentGroup, x]
      return accumulator
    },
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter,@typescript-eslint/consistent-type-assertions,@typescript-eslint/no-unsafe-type-assertion
    {} as Record<V, T[]>,
  )
  return result
}
const groupBy = function <V extends string, T>(this: T[], f: (x: T) => V) {
  const data = _groupBy(this, f)
  return new MRecord(data) // groupByしてtransformPropertiesすることが圧倒的に多いので、MRecordでラップして返す
}
const groupByUniqueKey = function <V extends string, T>(this: T[], f: (x: T) => V) {
  const data = _groupBy(this, f)
  return new MRecord(data).transformValues((vs) => vs.first()!).data
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const _sortBy = function <T>(xs: T[], f: (x: T) => unknown | unknown[]) {
  return [...xs].sort((a, b) => {
    const aKey = f(a)
    const bKey = f(b)
    if (Array.isArray(aKey) && Array.isArray(bKey)) {
      return multiCompareFunction(aKey, bKey)
    }
    return compareFunction(aKey, bKey)
  })
}
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const sortBy = function <T>(this: T[], f: (x: T) => unknown | unknown[]) {
  return _sortBy(this, f)
}

// const mapMap = function <K, V, T>(xs: Map<K, V>, f: (xs: [K, V]) => T) {
//   return Array.from(xs.entries()).map(([k, v]) => f([k, v]))
// }

const countBy = function <T, Key extends string>(this: T[], f: (x: T) => Key) {
  const mapped = _groupBy(this, f)
  const record = _toRecord(mapped)
  return record.transformValues((v) => v.length).data
}

// 順序を保証してuniqueByする。重複があった場合、先頭が優先される
// もともとObject.values()を使って実装していたが、これは挿入順を保証しないので注意
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
const uniqueBy = function <T, K>(this: T[], f: (x: T) => K) {
  const result = this.reduce(
    (accumulator, x) => {
      const key = f(x)
      if (accumulator.keys.has(key)) {
        // 既にあれば、何もしない
        return accumulator
      }
      // まだなければ追加する
      return {
        keys: accumulator.keys.add(key),
        values: [...accumulator.values, x], // Array.prototype.concatを使うと、xが配列だった際に死ぬので注意
      }
    },
    {
      keys: new Set<K>(),
      values: [] as T[],
    },
  )
  return result.values
}

const zip = function <T, S>(this: T[], ys: S[]) {
  const zs = [] as Array<[T, S]>
  for (let index = 0; index < Math.min(this.length, ys.length); index++) {
    const x = this[index]!
    const y = ys[index]!
    zs.push([x, y])
  }
  return zs
}

const sample = function <T>(this: T[]) {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
  }

  return this[getRandomInt(0, this.length)]
}

const compact = function (this: unknown[]) {
  return this.filter((x) => x !== undefined && x !== null)
}

// const myMap = () => {
//   throw new Error(`myMap called`)
// }

const minBy = function <T>(this: T[], f: (x: T) => number) {
  const xs = _sortBy(this, f)
  return xs[0]
}

const maxBy = function <T>(this: T[], f: (x: T) => number) {
  const xs = _sortBy(this, f)
  return xs.at(-1)
}

const min = function (this: number[]) {
  if (this.length === 0) {
    return
  }
  return Math.min(...this)
}

const max = function (this: number[]) {
  if (this.length === 0) {
    return
  }
  return Math.max(...this)
}

const sliceWhen = function <T>(this: T[], function_: (a: T, b: T) => boolean): T[][] {
  return _sliceWhen(function_)(this)
}

const mapConsIncremental = function <A>(this: A[]): A[][] {
  return _mapConsIncremental(this)
}

const partition = function <T>(this: T[], function_: (x: T) => boolean): [trues: T[], falses: T[]] {
  const trues = this.filter((x) => function_(x))
  const falses = this.filter((x) => !function_(x))
  return [trues, falses]
}

const isPresent = function (this: unknown[]) {
  return this.length > 0
}

const isBlank = function (this: unknown[]) {
  return this.length === 0
}

const isEmpty = function (this: unknown[]) {
  return this.length === 0
}

const isEqual = function <T>(this: T[], ys: T[]) {
  return JSON.stringify(this) === JSON.stringify(ys)
}

const intersection = function <T>(this: T[], ys: T[]) {
  return intersect(this, ys)
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
const intersectionBy = function <T, S>(this: T[], ys: T[], f: (a: T) => S) {
  const changedYs = new Set(ys.map((y) => f(y)))
  return this.filter((x) => changedYs.has(f(x)))
}

const union = function <T>(this: T[], ys: T[]) {
  return _union(this, ys)
}

const difference = function <T>(this: T[], ys: T[]) {
  return this.filter((x) => !ys.includes(x))
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
const differenceBy = function <T, S>(this: T[], ys: T[], f: (a: T) => S) {
  const changedYs = new Set(ys.map((y) => f(y)))
  return this.filter((x) => !changedYs.has(f(x)))
}

const promiseAll = async function <T>(this: Array<Promise<T>>) {
  return await Promise.all(this)
}

const clone = function <T>(this: T[]) {
  return _clone(this)
}

const upsertBy = function <T extends Record<string, unknown>>(this: T[], x: T, f: (x: T) => string): T[] {
  const index = this.findIndex((y) => f(x) === f(y))
  if (index === -1) {
    return [...this, x]
  }
  return [...this.slice(0, index), x, ...this.slice(index + 1)]
}

// oldの位置にある要素を、newの位置に入れ替える
const move = function <T>(this: T[], oldIndex: number, newIndex: number) {
  const xs = [...this]
  const x = xs[oldIndex]
  if (x === undefined) {
    return xs
  }
  xs.splice(oldIndex, 1)
  xs.splice(newIndex, 0, x)

  return xs
}

function _combination<T>(xs: T[], n: number): T[][] {
  if (n === 0) {
    return [[]]
  }
  if (xs.length === 0) {
    // TODO: この辺の定義が自信なし
    return []
  }

  const y = xs[0]!
  const ys = xs.slice(1)
  return [..._combination(ys, n - 1).map((subset): T[] => [y, ...subset]), ..._combination(ys, n)]
}

const combination = function <T>(this: T[], n: number) {
  return _combination(this, n)
}

const subsets = function <T>(this: T[]): T[][] {
  const subsets = range(0, this.length).flatMap((index): T[][] => _combination(this, index))
  return subsets
}

export function defineArrayProperties() {
  defineProperty(Array.prototype, 'toObject', toObject, 'Array')
  defineProperty(Array.prototype, 'toRecord', toRecord, 'Array')
  defineProperty(Array.prototype, 'toMap', toMap, 'Array')
  defineProperty(Array.prototype, 'last', last, 'Array')
  defineProperty(Array.prototype, 'first', first, 'Array')
  defineProperty(Array.prototype, 'sum', sum, 'Array')
  defineProperty(Array.prototype, 'eachCons', eachCons, 'Array')
  defineProperty(Array.prototype, 'unique', unique, 'Array')
  defineProperty(Array.prototype, 'groupBy', groupBy, 'Array')
  defineProperty(Array.prototype, 'groupByUniqueKey', groupByUniqueKey, 'Array')
  defineProperty(Array.prototype, 'mySortBy', sortBy, 'Array') // TODO: sortByだとエラーが出ていたことがあった？現在でていないので不要
  defineProperty(Array.prototype, 'sortBy', sortBy, 'Array')
  defineProperty(Array.prototype, 'countBy', countBy, 'Array')
  defineProperty(Array.prototype, 'uniqueBy', uniqueBy, 'Array')
  defineProperty(Array.prototype, 'zip', zip, 'Array')
  defineProperty(Array.prototype, 'sample', sample, 'Array')
  defineProperty(Array.prototype, 'compact', compact, 'Array')
  defineProperty(Array.prototype, 'minBy', minBy, 'Array')
  defineProperty(Array.prototype, 'maxBy', maxBy, 'Array')
  defineProperty(Array.prototype, 'sliceWhen', sliceWhen, 'Array')
  defineProperty(Array.prototype, 'mapConsIncremental', mapConsIncremental, 'Array')
  defineProperty(Array.prototype, 'eachSlice', eachSlice, 'Array')
  defineProperty(Array.prototype, 'partition', partition, 'Array')
  defineProperty(Array.prototype, 'isPresent', isPresent, 'Array')
  defineProperty(Array.prototype, 'isBlank', isBlank, 'Array')
  defineProperty(Array.prototype, 'isEmpty', isEmpty, 'Array')
  defineProperty(Array.prototype, 'union', union, 'Array')
  defineProperty(Array.prototype, 'intersection', intersection, 'Array')
  defineProperty(Array.prototype, 'difference', difference, 'Array')
  defineProperty(Array.prototype, 'intersectionBy', intersectionBy, 'Array')
  defineProperty(Array.prototype, 'differenceBy', differenceBy, 'Array')
  defineProperty(Array.prototype, 'isEqual', isEqual, 'Array')
  defineProperty(Array.prototype, 'max', max, 'Array')
  defineProperty(Array.prototype, 'min', min, 'Array')
  defineProperty(Array.prototype, 'promiseAll', promiseAll, 'Array')
  defineProperty(Array.prototype, 'clone', clone, 'Array')
  defineProperty(Array.prototype, 'upsertBy', upsertBy, 'Array')
  defineProperty(Array.prototype, 'move', move, 'Array')
  defineProperty(Array.prototype, 'combination', combination, 'Array')
  defineProperty(Array.prototype, 'subsets', subsets, 'Array')

  // defineProperty(Array.prototype, 'map', myMap, 'Array') // 既にある定義をオーバーライドしようとするとエラーになる
}
