import { t } from 'i18next'

import { CORE_CONSTANT } from '../../../../../constant'
import { SALESCORE_GOAL_MODEL } from '../../../../../constant/models/SALESCORE_GOAL_MODEL'
import type { ViewTimeframe } from '../../../../../schemas/misc/timeframe'
import type { ViewQueryDimensionField, ViewQueryNode } from '../../../../../schemas/query'
import type { ViewConfigDimension, ViewConfigTreeNode } from '../../../../../schemas/view_config'
import { NotImplementedError } from '../../../../errors'
import type { CompileContext } from '../../../common'
import { generatePropertySql } from '../compileSheetViewConfigFields'
import type { DimensionWithProperty } from '../dimensionWithProperty'

interface Compiled {
  configNodes: ViewConfigTreeNode[]
  queryNodes: ViewQueryNode[]
}

// eslint-disable-next-line complexity,@typescript-eslint/max-params
export function generateDimensionField(
  dimensionWithProperty: DimensionWithProperty,
  compiled: Compiled,
  context: CompileContext,
  withRollup: boolean,
  previousDimensions: DimensionWithProperty[],
): ViewQueryDimensionField | undefined {
  const d = dimensionWithProperty.dimension
  const { field } = dimensionWithProperty
  if (
    d.key === CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME &&
    compiled.configNodes.every((x) => x.modelName !== SALESCORE_GOAL_MODEL.name)
  ) {
    // XXX: 目標のコンパイルの際のみkpi_idでグルーピングしている。この辺わかりづらい。。
    // return {
    //   type: 'field' as const,
    //   name: d.key,
    //   // TODO: alias
    //   sql: `'${kpi.id}'`,
    //   isScalar: true,
    // }
    return undefined
  }

  if (
    field === undefined ||
    previousDimensions
      .filter(
        (x) =>
          x.dimension.key !== CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key &&
          x.dimension.key !== CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_DIMENSION().key,
      )
      .some((x) => x.field === undefined)
  ) {
    // ROLLUPする前のdimensionが空だったら常にNULL扱い
    // （KPIは実際はROLLUPされないので除いておく）
    // context.logs.warn(`[generateDimensionField] フィールドが見つかりません. フィールド名: ${d.key} ${d.fieldType}`)
    // 特にKPIピボットを使う時、存在しないフィールドをdimensionにすることもありうる
    const nullDimension: ViewQueryDimensionField = {
      type: 'field' as const,
      name: d.key,
      sql: `'${CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING}'`,
      fieldSql: `NULL`,
      isScalar: true,
    }
    return nullDimension
  }

  const sql = generatePropertySql({ ...field.property })
  // if (sql === undefined) {
  //   return nullDimension
  // }
  const stringSql = convertToString(sql, dimensionWithProperty, context)
  const labelSql = generateLabelSql(dimensionWithProperty, compiled, context)

  return {
    type: 'field' as const,
    name: generateDimensionFieldName(d),
    sql: withRollup ? generateRollupField(stringSql) : stringSql,
    fieldSql: stringSql,
    labelSql,
  }
}

// eslint-disable-next-line complexity
function generateLabelSql(
  d: DimensionWithProperty,
  { queryNodes, configNodes }: Compiled,
  context: CompileContext,
): string | undefined {
  if (d.model === undefined || d.property === undefined || d.field === undefined) {
    return
  }
  const { model, property, field } = d

  // TODO: だいぶ無理やりやっている
  if (
    d.field.property.nodeName === `salescore_users_with_group` &&
    d.field.property.propertyName === `user_group_d1_id`
  ) {
    return `salescore_users_with_group.user_group_d1_name`
  }
  if (
    d.field.property.nodeName === `salescore_users_with_group` &&
    d.field.property.propertyName === `user_group_d2_id`
  ) {
    return `salescore_users_with_group.user_group_d2_name`
  }
  if (
    d.field.property.nodeName === `salescore_users_with_group` &&
    d.field.property.propertyName === `user_group_d3_id`
  ) {
    return `salescore_users_with_group.user_group_d3_name`
  }
  if (
    d.field.property.nodeName === `salescore_users_with_group` &&
    d.field.property.propertyName === `user_group_d4_id`
  ) {
    return `salescore_users_with_group.user_group_d4_name`
  }
  if (
    d.field.property.nodeName === `salescore_users_with_group` &&
    d.field.property.propertyName === `user_group_d5_id`
  ) {
    return `salescore_users_with_group.user_group_d5_name`
  }
  if (d.field.property.nodeName === `salescore_users_with_group` && d.field.property.propertyName === `user_group_id`) {
    // return `salescore_users_with_group.user_group_name`
    return undefined
  }

  // プロパティがID値のとき（現状、salescore_usersしかないはず）
  if (property.meta === 'id') {
    const nameProperty = model.properties.find((x) => x.meta === 'name')
    if (nameProperty === undefined) {
      return undefined
    }

    // TODO: だいぶ無理やりやっている
    if (d.field.property.nodeName === `salescore_users`) {
      return `salescore_users.name`
    }

    return `"${field.property.nodeName}"."${nameProperty.name}"`
  }

  // 参照項目の時、参照先を表示する
  // getReferenceRelatedSqlと似たようなロジック。マージするか？
  const reference =
    d.property.referenceTo?.find((x) => x.modelName === 'salesforce_user') ?? d.property.referenceTo?.first()
  if (reference === undefined) {
    return undefined
  }
  const queryNode = queryNodes.find((x) => x.name === field.property.nodeName)
  if (queryNode === undefined) {
    return
  }
  const configNode = configNodes.find((x) => x.name === queryNode.name)
  if (configNode === undefined) {
    return
  }

  const childConfigNode = (configNode.children ?? []).find(
    (childNode) =>
      childNode.referenceToProperty?.propertyName === property.name &&
      childNode.referenceToProperty.modelName === model.name &&
      childNode.referenceToProperty.referenceTo.modelName === reference.modelName,
  )
  const childQueryNode = (queryNode.children ?? []).find((x) => x.name === childConfigNode?.name)
  if (childQueryNode === undefined || childConfigNode === undefined) {
    context.logs.warn(`[generateLabelSql] childQueryNode or childConfigNode not found`)
    return undefined
  }

  const childModel = context.modelSearcher.searchModel(childConfigNode.modelName)
  const nameProperty = childModel?.properties.find((x) => x.meta === 'name')
  if (nameProperty === undefined) {
    return undefined
  }

  return `"${childQueryNode.name}"."${nameProperty.name}"`
}

// eslint-disable-next-line complexity
function convertToString(sql: string, dimensionWithProperty: DimensionWithProperty, context: CompileContext): string {
  const d = dimensionWithProperty.dimension
  const { property } = dimensionWithProperty
  if (property === undefined) {
    return sql
  }
  if (property.type === 'date' || property.type === 'datetime') {
    return generateTimeTypeDimensionGroupSql(sql, d.dateSpan, context)
  }

  // if (property.type === 'date') {
  //   return `${sql}::text`
  // }
  // if (property.type === 'datetime') {
  //   return `${sql}::date::text`
  // }

  if (property.type === 'numeric' || property.type === 'integer') {
    return `${sql}::text`
  }

  if (property.type === 'string') {
    return sql
  }

  return `${sql}::text`
}

function generateRollupField(sql: string) {
  return `CASE
  WHEN GROUPING(${sql}) = 0
  AND ${sql} IS NULL THEN '${CORE_CONSTANT.KPI_PIVOT_NULL_STRING}'
  WHEN ${sql} IS NULL THEN '${CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING}'
  ELSE ${sql}
END`
}

function generateTimeTypeDimensionGroupSql(
  sql: string,
  timeframe: ViewTimeframe | undefined,
  context: CompileContext,
  // dimensionGroup: DimensionGroupProperty
  // fiscalMonthOffset?: number
  // timezoneInterval?: TimezoneInterval
) {
  const fiscalMonthOffset = -(context.resources.organizationSetting.accountClosingMonth % 12) // 3月終わりのとき、-3。9月終わりのとき-9。12月終わりのとき0
  switch (timeframe) {
    case undefined: {
      return `TO_CHAR(${sql}::date, 'YYYY-MM')`
    } // デフォルトはmonthにする（dayだとレコード数が爆発する可能性があるので）
    case 'hour': {
      return `TO_CHAR(${sql}::timestamp, 'HH24')`
    }
    case 'weekday': {
      return `TO_CHAR(${sql}::date, 'D_') || (array['${t(`日曜日`)}','${t(`月曜日`)}', '${t(`火曜日`)}','${t(`水曜日`)}', '${t(`木曜日`)}','${t(`金曜日`)}','${t(`土曜日`)}'])[EXTRACT(DOW FROM ${sql}::date) + 1]`
    }
    case 'day': {
      return `TO_CHAR(${sql}::date, 'YYYY-MM-DD')`
    }
    case 'week': {
      return `TO_CHAR(TO_DATE(TO_CHAR(${sql}::date, 'IYYY-IW'), 'IYYY-IW'), 'YYYY-MM-DD"W"')`
    }
    case 'month': {
      return `TO_CHAR(${sql}::date, 'YYYY-MM')`
    }
    // case 'quarter':
    // return `TO_CHAR(${sql}::date, 'YYYY-Q"Q"')`
    case 'year': {
      return `TO_CHAR(${sql}::date, 'YYYY')`
    }
    //    offsetを渡す必要がある
    case 'fiscal_quarter': {
      return `TO_CHAR((${sql}::date + interval '${fiscalMonthOffset} month'), 'YYYY-Q"Q"')`
    }
    // NOTE: 上半期/下半期でグルーピングする(e.g. 決算月が3月の場合 4~9月が上半期 10~3月が下半期)
    case 'fiscal_half': {
      return `CASE
        WHEN 1 <= ((EXTRACT(MONTH FROM ${sql}::date)::int + ${fiscalMonthOffset}) + 12) % 12 AND ((EXTRACT(MONTH FROM ${sql}::date)::int + ${fiscalMonthOffset}) + 12) % 12 <= 6 THEN
          TO_CHAR((${sql}::date + interval '${fiscalMonthOffset} month'), 'YYYY-"${t(`上半期`)}"')
        ELSE
          TO_CHAR((${sql}::date + interval '${fiscalMonthOffset} month'), 'YYYY-"${t(`下半期`)}"')
      END`
    }
    case 'fiscal_year': {
      return `TO_CHAR((${sql}::date + interval '${fiscalMonthOffset} month'), 'YYYY')`
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = timeframe
      throw new NotImplementedError(`unexepected timeframe`)
    }
  }
}

export function generateDimensionFieldName(d: ViewConfigDimension) {
  return [d.dateSpan, d.key].compact().join('_')
}
