import { isPresent, r } from '@salescore/buff-common'
import { z } from 'zod'

// eslint-disable-next-line complexity
export function normalizeUserGroupIdCsvRecord(
  record: Record<string, unknown>,
  userIds: string[],
  userGroupIds: string[],
  index: number,
) {
  // すべて空行だったらスキップ
  if (
    r(record)
      .values()
      .every((x) => x === '')
  ) {
    return {
      success: false as const,
      isEmptyLine: true,
      message: `空行です。`,
      record,
      index,
    }
  }

  const parsed = z
    .object({
      user_id: z.string(),
      user_group_id: z.string(),
    })
    .safeParse(record)
  if (!parsed.success) {
    return {
      success: false as const,
      message: `user_id, user_gorup_idという名の列を含めてください。`,
      record,
      index,
    }
  }

  const userGroupIdTrimed = parsed.data.user_group_id.trim()
  const change = {
    user_id: parsed.data.user_id,
    // 空文字列だったらnull扱いにする
    user_group_id: isPresent(userGroupIdTrimed) ? userGroupIdTrimed : null,
  }
  if (!userIds.includes(change.user_id)) {
    return {
      success: false as const,
      message: `${change.user_id}のユーザーIDは存在しません`,
      record,
      index,
    }
  }

  if (change.user_group_id !== null && !userGroupIds.includes(change.user_group_id)) {
    return {
      success: false as const,
      message: `${change.user_group_id}のユーザーグループIDは存在しません`,
      record,
      index,
    }
  }

  return {
    success: true as const,
    record,
    index,
    data: change,
  }
}

// eslint-disable-next-line complexity
export function normalizeUserGroupCsvRecord(record: Record<string, unknown>, userGroupIds: string[], index: number) {
  // すべて空行だったらスキップ
  if (
    r(record)
      .values()
      .every((x) => x === '')
  ) {
    return {
      success: false as const,
      isEmptyLine: true,
      message: `空行です。`,
      record,
      index,
    }
  }

  // インポートするCSVファイルのカラム名がそのままObjectのプロパティ名になるため、扱いやすいように命名し直す
  delete Object.assign(record, { user_group_id: record['グループID(user_group_id)'] })['グループID(user_group_id)']
  delete Object.assign(record, { user_group_name: record['グループ名'] })['グループ名']

  const parsed = z
    .object({
      user_group_id: z.string(),
      user_group_name: z.string(),
    })
    .safeParse({
      ...record,
      user_group_name: record.user_group_name ?? record['グループID(user_group_id)'],
      user_group_id: record.user_group_id ?? record['グループID'],
    })
  if (!parsed.success) {
    return {
      success: false as const,
      message: `グループID(user_group_id), グループ名という名の列を含めてください。`,
      record,
      index,
    }
  }

  const userGroupIdTrimed = parsed.data.user_group_id.trim()
  const change = {
    user_group_id: userGroupIdTrimed,
    user_group_name: parsed.data.user_group_name,
  }
  if (
    (!isPresent(change.user_group_id) || !isPresent(change.user_group_name)) &&
    change.user_group_name !== '所属なし'
  ) {
    return {
      success: false as const,
      message: `グループID(user_group_id), グループ名の項目は入力必須です`,
      record,
      index,
    }
  }

  if (isPresent(change.user_group_id) && !userGroupIds.includes(change.user_group_id)) {
    return {
      success: false as const,
      message: `${change.user_group_id}のユーザーグループIDは存在しません`,
      record,
      index,
    }
  }

  return {
    success: true as const,
    record,
    index,
    data: change,
  }
}
