// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Diff from 'text-diff'

export function calculateTextDiff(a: string, b: string) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
  const diff = new Diff() // options may be passed to constructor; see below
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  const textDiff = diff.main(a, b) // produces diff array
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  const html = diff.prettyHtml(textDiff)
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
    html: html as string,
  }
}
