import type {
  NodePropertyName,
  ViewConfigDimension,
  ViewConfigField,
  ViewConfigFilterNode,
  ViewConfigSorter,
  ViewConfigTreeNode,
  ViewConfigUiComponent,
} from '../../../../schemas/view_config'
import { flatNodes } from '../../../query/executeViewQuery/util'

// eslint-disable-next-line complexity
export function pruneSheetConfigTree({
  tree,
  fields,
  filterTree,
  sorters,
  components,
  nodeNames,
  dimensionNodeSearcher,
}: {
  tree?: ViewConfigTreeNode
  fields?: ViewConfigField[]
  filterTree?: ViewConfigFilterNode
  sorters?: ViewConfigSorter[]
  components?: ViewConfigUiComponent[]
  nodeNames?: string[]
  dimensionNodeSearcher?: (dimension: ViewConfigDimension) => NodePropertyName['nodeName'] | undefined
}): ViewConfigTreeNode | undefined {
  if (tree === undefined) {
    return undefined
  }

  // TODO: overrideしているとき、特にadditionalFieldsを指定しているときに、動かなくなる可能性
  const usingNodeNames = [
    ...(fields ?? []).map((x) => x.property.nodeName),
    ...(sorters ?? []).map((x) => x.property.nodeName),
    ...flatNodes(filterTree)
      .flatMap((x) => x.leafs)
      .map((x) => {
        const { type } = x
        switch (type) {
          case 'property': {
            return x.property.nodeName
          }
          case 'dimension': {
            if (x.dimension === undefined || dimensionNodeSearcher === undefined) {
              return
            }
            return dimensionNodeSearcher(x.dimension)
          }
          // あまりないはず
          default:
        }
      }),
    ...(components ?? []).flatMap((x) => flatNodes(x)).map((x) => x.property?.nodeName),
    ...(nodeNames ?? []),
  ]
    .unique()
    .compact()

  return rec(tree, usingNodeNames) ?? tree // rootが削除されることはなく、recがundefinedを返すこともないはずだが、undefinedになった際には一応元のtreeを返す
}

function rec(node: ViewConfigTreeNode, validNodeNames: string[]): ViewConfigTreeNode | undefined {
  // 直接ノードを使用していなくても、childrenを使用していれば残す必要がある
  const children = (node.children ?? []).map((child) => rec(child, validNodeNames)).compact()
  if (!validNodeNames.includes(node.name) && children.isBlank()) {
    return undefined
  }

  return {
    ...node,
    children,
  }
}
