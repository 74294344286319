import { isNull } from '@salescore/buff-common'

import {
  type GenerateSearchSqlForStreamArgument,
  orderByLength,
  SEARCH_RESULT_LABEL_COLUMN_NAME,
  SEARCH_RESULT_VALUE_COLUMN_NAME,
} from './common'

// eslint-disable-next-line complexity
export function generateSearchSqlForSalesforceRecordType({ model, parentModel }: GenerateSearchSqlForStreamArgument) {
  const nameProperty = model.properties.find((x) => x.meta === 'name')
  const labelField = nameProperty?.name ?? 'id'
  const sobjectTypeProperty = model.properties.find((x) => x.write?.sourcePropertyName === 'SobjectType') // TODO: sourceでの判定が必要
  const objectName = parentModel.write?.sourceEntityName // TODO sourceで判定
  if (isNull(nameProperty) || isNull(sobjectTypeProperty) || isNull(objectName)) {
    return
  }

  const searchSql = `SELECT
record_type.id as ${SEARCH_RESULT_VALUE_COLUMN_NAME},
record_type."${labelField}" as ${SEARCH_RESULT_LABEL_COLUMN_NAME}
FROM "${model.name}" as record_type
WHERE record_type."${sobjectTypeProperty.name}" = '${objectName}'
`
  return {
    sql: orderByLength(searchSql, `record_type."${labelField}"`),
    dependedPropertyNames: [
      {
        streamName: model.name,
        propertyName: nameProperty.name,
      },
      {
        streamName: model.name,
        propertyName: sobjectTypeProperty.name,
      },
    ],
  }
}
