import { r } from '@salescore/buff-common'
import type { ModelSearcher, ViewQueryList, ViewQueryRecordNode } from '@salescore/core'
import { logger } from '@salescore/frontend-common'
import tree from 'antd/es/tree'
import type { SetRecoilState } from 'recoil'

import { getNode } from '../../../../state/nodeUtil'
import { recordAtomFamily, recordIdsAtom } from '../../atoms'
import { getRecordNodeFromRecordNodePath, type RecordNodePath } from '../upsertViewRecordByPath'
import { updateHeight, updateInnerRow } from '../upsertViewRecords'
import { generateDeleteChanges } from '../upsertViewRecords/generateDeleteChanges'

export interface DeleteRecordChangeByPath {
  recordNodePath: RecordNodePath
  isDeleteAllChildren?: boolean
}

// eslint-disable-next-line complexity,@typescript-eslint/max-params
export function deleteRecordByPath(
  query: ViewQueryList,
  rootRecordNode: ViewQueryRecordNode,
  newRecordChange: DeleteRecordChangeByPath,
  modelSearcher: ModelSearcher,
  set: SetRecoilState,
  // onAfterInsert?: OnAfterInsert,
) {
  const { recordNodePath, isDeleteAllChildren } = newRecordChange

  if (recordNodePath.length === 0) {
    set(recordIdsAtom, (oldIds) => {
      const newIds = oldIds.filter((id) => id !== rootRecordNode.id)
      return newIds
    })
    return generateDeleteChanges(query, query.tree, rootRecordNode, modelSearcher, isDeleteAllChildren)
  }

  const parentPath = recordNodePath.slice(0, -1)
  const { tableNodeName, recordNodeId } = recordNodePath.last()!
  const targetRecordNode = getRecordNodeFromRecordNodePath(rootRecordNode, parentPath)
  const targetTableNode = targetRecordNode?.children.find((x) => x.nodeName === tableNodeName)
  const nodePath = [query.tree.name, ...recordNodePath.map((x) => x.tableNodeName)]
  const targetQueryNode = getNode(query.tree, nodePath)
  if (targetTableNode === undefined || targetRecordNode === undefined || targetQueryNode === undefined) {
    logger.debug(`[addNewChildRecordByPath] not found.`, {
      targetTableNode,
      targetRecordNode,
      targetQueryNode,
      nodePath,
      tree,
    })
    return
  }

  const deleteIndex = targetTableNode.children.findIndex((x) => x.id === recordNodeId)
  const childRecordNode = targetTableNode.children[deleteIndex]
  if (childRecordNode === undefined) {
    return
  }
  targetTableNode.children.splice(deleteIndex, 1)

  // メタ情報を更新
  updateHeight(rootRecordNode)
  updateInnerRow(rootRecordNode)

  // familyを更新
  set(recordAtomFamily({ id: rootRecordNode.id! }), r(rootRecordNode).clone().data) // 複製して渡さないとfreezeされてしまい、後続の関数で編集できなくなる

  // 変更差分を返す
  return generateDeleteChanges(query, targetQueryNode, childRecordNode, modelSearcher, isDeleteAllChildren)
}
