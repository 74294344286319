import type { MyUserFieldsFragment } from '@salescore/client-api'

export const checkSyncEnabled = (myUser: Pick<MyUserFieldsFragment, 'planForSync' | 'role'> | undefined) =>
  myUser !== undefined && (myUser.planForSync !== 'none' || myUser.role === 'admin')

export const checkVisualizeEnabled = (myUser: Pick<MyUserFieldsFragment, 'planForVis' | 'role'> | undefined) =>
  myUser !== undefined && (myUser.planForVis !== 'none' || myUser.role === 'admin')

export const checkRiEnabled = (myUser: Pick<MyUserFieldsFragment, 'role' | 'userLicenses'> | undefined) =>
  myUser !== undefined && (myUser.userLicenses.some((x) => x.license === 'ri') || myUser.role === 'admin')
