import { isNull } from '@salescore/buff-common'
import type { UserFieldsFragment, UserPlanEnum } from '@salescore/client-api'
import { Space, Tag } from 'antd'
import { t } from 'i18next'

export const UserPlanTag = ({
  userOrInvitation,
}: {
  // Partialはsync,visライセンスを新tableに移行 + invitation対応をするまでの一時的な処置
  userOrInvitation: Partial<Pick<UserFieldsFragment, 'planForSync' | 'planForVis' | 'userLicenses'>> | undefined
}) => {
  if (isNull(userOrInvitation)) {
    return <></>
  }
  const riLicense = userOrInvitation.userLicenses?.find((x) => x.license === 'ri')
  return (
    <Space>
      <PlanTag color={`blue`} plan={userOrInvitation.planForSync}>
        SYNC
      </PlanTag>
      <PlanTag color={`green`} plan={userOrInvitation.planForVis}>
        VISUALIZE
      </PlanTag>
      <PlanTag color={`volcano`} plan={riLicense?.plan}>
        RI
      </PlanTag>
    </Space>
  )
}

function PlanTag({ plan, children, color }: { plan: UserPlanEnum | undefined; children: string; color: string }) {
  if (plan === 'none' || plan === undefined) {
    return <></>
  }

  return (
    <Tag style={{ opacity: plan === 'standard' ? 1 : 0.7 }} color={color}>
      {children}
      {plan === 'free' ? t(`(無料)`) : ''}
    </Tag>
  )
}
