import { useEffect } from 'react'

// https://usehooks.com/useOnClickOutside/
export function useOnClickOutside(
  reference: React.RefObject<HTMLElement>,
  handler: () => void,
  ignoreClassNames?: string[],
) {
  useEffect(
    () => {
      // eslint-disable-next-line complexity
      const callback = (pathRaw: EventTarget[], target: EventTarget | null) => {
        const path = pathRaw.map((x) => (x instanceof HTMLElement ? x : undefined)).compact()

        if (!reference.current || (target instanceof HTMLElement && reference.current.contains(target))) {
          return
        }

        // 指定されたクラスを持つ要素であれば、何もしない
        if (
          ignoreClassNames?.some((className) =>
            path.some((element) => typeof element.className === 'string' && element.className.includes(className)),
          ) ??
          false
        ) {
          return
        }

        handler()
      }
      const handleMouseDown = (event: MouseEvent): void => {
        callback(event.composedPath(), event.target)
      }
      const handleTouchStart = (event: TouchEvent): void => {
        callback(event.composedPath(), event.target)
      }

      document.addEventListener('mousedown', handleMouseDown)
      document.addEventListener('touchstart', handleTouchStart)

      return () => {
        document.removeEventListener('mousedown', handleMouseDown)

        document.removeEventListener('touchstart', handleTouchStart)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [reference, handler],
  )
}
