import { CopyOutlined, EllipsisOutlined } from '@ant-design/icons'
import { useApolloClient, useMutation } from '@apollo/client'
import { faColumns3 } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isPresent, isSome, isTruthy } from '@salescore/buff-common'
import { CopyViewDocument, FetchViewQueryResultAsCsvDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath, POSTHOG_EVENTS } from '@salescore/client-base'
import { recoil } from '@salescore/client-recoil'
import { CORE_CONSTANT } from '@salescore/core'
import { ButtonWithTooltip, CsvDownloadWithTextButton, useModalAtom } from '@salescore/frontend-common'
import { Button, Dropdown, message, Space } from 'antd'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'

import { columnsDrawerAtom } from '../../../recoil/navigation/atoms'
import { useMustacheParameterSelector } from '../../../recoil/records/selectors/mustacheParameterSelector'
import { listQuerySelector } from '../../../recoil/records/selectors/querySelector'
import { usePosthogTrackClick } from '../../../recoil/usePosthogTrack'
import { useUiValue, useViewAbilityValue, useViewsContextValue, useViewValue } from '../../../recoil/view/hooks'

// eslint-disable-next-line complexity
export function SheetMoreActionsButton() {
  const client = useApolloClient()
  const me = recoil.global.useMe()
  const organizationId = me.organization.id
  const { queryWithAdditionalOption } = useRecoilValue(listQuerySelector)
  const mustacheParameter = useMustacheParameterSelector()
  const viewUi = useUiValue()
  const ability = useViewAbilityValue()
  const columnsDrawer = useModalAtom(columnsDrawerAtom)
  const posthogTrackClick = usePosthogTrackClick()

  const view = useViewValue()
  const [copy] = useMutation(CopyViewDocument)
  const { refetchViews, pickView, isSheetMoreActionsCopyVisible, prevView } = useViewsContextValue()
  const isWaterfallDrillDown =
    prevView?.config.type === 'kpiTimeSeries' && isTruthy(prevView.config.option?.asWaterfall)

  return (
    <Dropdown
      overlay={
        <Space className="bg-white p-3 shadow-lg" direction="vertical">
          <Button
            className="w-full text-start"
            icon={<FontAwesomeIcon icon={faColumns3} color="black" height={16} width={16} />}
            type="text"
            onClick={() => {
              columnsDrawer.showModal()
            }}
          >
            {t(`カラム`)}
          </Button>
          {isSheetMoreActionsCopyVisible !== false && (
            <ButtonWithTooltip
              className="w-full text-start"
              disabled={!ability.canCreate || view.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)}
              showTooltip={!ability.canCreate}
              tooltipTitle={t(`権限がないため、複製はできません。`)}
              type="text"
              icon={<CopyOutlined />}
              onClick={async () => {
                try {
                  const { data: result } = await copy({
                    variables: {
                      organizationId: getOrganizationIdFromPath(),
                      id: view.id,
                    },
                  })
                  const savedView = result?.copyView
                  if (isSome(savedView)) {
                    void message.success(t(`「{{name}}」を作成しました。`, { name: savedView.name }))
                    await refetchViews()
                    pickView(savedView.id)
                  } else {
                    void message.warning(t(`エラーが発生しました`))
                  }
                } catch (error) {
                  if (error instanceof Error) {
                    void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
                  } else {
                    void message.error(t(`エラーが発生しました`))
                  }
                }
              }}
            >
              {t(`複製`)}
            </ButtonWithTooltip>
          )}
          {
            // 2024-09-15
            // 滝グラフからのCSVダウンロードは動作が不安定なことがあり、一旦導線を隠す対応とする
            !isWaterfallDrillDown && (
              <CsvDownloadWithTextButton
                filename={t(`シート`)}
                text={t(`CSVダウンロード`)}
                type="text"
                // 2023/06 いったん個社対応
                disabled={
                  ['23cbbd45-0993-4231-a624-cb449f89f3fa'].includes(organizationId)
                    ? !me.isAdminOrManager
                    : !ability.canDownloadCsv
                }
                onClick={() => {
                  posthogTrackClick(POSTHOG_EVENTS.click_sheet_csv_download)
                }}
                fetch={async () => {
                  const { data: result } = await client.query({
                    query: FetchViewQueryResultAsCsvDocument,
                    variables: {
                      organizationId,
                      viewQuery: queryWithAdditionalOption,
                      mustacheParameter,
                      viewUi,
                      viewId: view.id,
                    },
                  })
                  if (result.viewResultAsCsv === undefined) {
                    // eslint-disable-next-line unicorn/error-message
                    throw new Error(``)
                  }
                  if (isPresent(result.viewResultAsCsv.error)) {
                    void message.error(result.viewResultAsCsv.error)
                    // eslint-disable-next-line unicorn/error-message
                    throw new Error(``)
                  }
                  if (result.viewResultAsCsv.hasNext) {
                    void message.warning(t(`行が多すぎるため、全ての行を取得できませんでした`))
                  }
                  return result.viewResultAsCsv.csv
                }}
              />
            )
          }
        </Space>
      }
      // okText="実行"
      // cancelText="キャンセル"
    >
      <Button icon={<EllipsisOutlined />} type="text" />
    </Dropdown>
  )
}
