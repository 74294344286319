import platform from 'platform'
import type { KeyboardEvent } from 'react'

// mac,windowsの差異を吸収するためのラッパー
// eslint-disable-next-line complexity
export function wrapKeyboardEvent(event: KeyboardEvent<HTMLDivElement>) {
  const isWindows = platform.os?.family?.includes('Windows') ?? false // TODO
  const commandKey: boolean = isWindows ? event.ctrlKey : event.metaKey
  const macCtrlKey: boolean = isWindows ? false : event.ctrlKey

  return {
    isWindows,
    commandKey,
    macCtrlKey,
    shiftKey: event.shiftKey,
    key: event.key,
    code: event.code,
    original: event,
    platform: platform.toString(),
    os: platform.os?.toString(),
    osFamily: platform.os?.family,
  }
}
