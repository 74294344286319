import { FilterOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { isSome } from '@salescore/buff-common'
import type { ViewConfigTreeNode } from '@salescore/core'
import { addModelIdProperty, useBooleanState, useMessage } from '@salescore/frontend-common'
import { Modal, Space, Switch } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'

import { useNavigationModal } from '../../../../../recoil/navigation/hooks'
import { useChangesValue } from '../../../../../recoil/records/hooks'
import { useViewConfigSheet } from '../../../../../recoil/view/hooks'
import { useTreeMutations } from '../../../../../recoil/view/mutations/treeMutations'
import { useConnectionsSelector } from '../../../../../recoil/view/selectors/connectionsSelector'
import { applyAdvancedSettingsFlag, ViewFilterTreeForm } from './ViewFilterTreeForm'

export const ViewJoinFiltersSettingModal = () => {
  const { joinFiltersModal } = useNavigationModal()
  const changes = useChangesValue()
  const message = useMessage()

  useEffect(() => {
    if (joinFiltersModal.isModalVisible && changes.length > 0) {
      message.warning(t('設定を変更すると変更情報が失われます。'))
    }
  }, [joinFiltersModal.isModalVisible])

  return (
    <Modal
      open={joinFiltersModal.isModalVisible}
      onCancel={() => {
        joinFiltersModal.hideModal()
      }}
      width={'80%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{ top: '3%', maxWidth: 1000 }}
      footer={<></>}
      destroyOnClose
    >
      {joinFiltersModal.content !== undefined && (
        <ViewFiltersSetting
          node={joinFiltersModal.content}
          onAfterFinish={() => {
            joinFiltersModal.hideModal()
          }}
        />
      )}
    </Modal>
  )
}

// eslint-disable-next-line complexity
function ViewFiltersSetting({ onAfterFinish, node }: { onAfterFinish: () => void; node: ViewConfigTreeNode }) {
  const { editNode } = useTreeMutations()
  const config = useViewConfigSheet()
  const { getModel } = useConnectionsSelector()
  const isAdvancedModeState = useBooleanState(
    (node.additionalJoinOn?.children ?? []).isPresent() || node.additionalJoinOn?.logicalOperator === 'or',
  )
  const label = node.ui?.label ?? getModel(node.modelName)?.label ?? node.modelName
  const rootNode = config.tree
  const rootNodeLabel = isSome(rootNode) ? (rootNode.ui?.label ?? getModel(rootNode.modelName)?.label) : undefined
  const model = getModel(node.modelName)
  const propertiesWithNode =
    model?.properties.map((property) => ({
      node,
      model,
      property,
    })) ?? []
  const propertiesAndModelIdWithNode = addModelIdProperty({ node, model }, propertiesWithNode)

  return (
    <PageHeader
      title={
        <Space>
          <FilterOutlined />
          <span>
            「{label}」{t(`ブロックの絞り込み`)}
          </span>
        </Space>
      }
      extra={[
        <Space>
          {t(`高度なフィルタ条件`)}
          <Switch
            checked={isAdvancedModeState.isTrue}
            onChange={(value) => {
              if (value) {
                isAdvancedModeState.setTrue()
              } else {
                isAdvancedModeState.setFalse()
              }
            }}
          />
        </Space>,
      ]}
    >
      <div className="mb-4 text-xs text-gray-700">
        <p>※{t(`高度な設定項目です。通常は設定する必要はありません。`)}</p>
        {t(`絞り込みを実行した結果、「{{label}}」の行が見つからなかったとき、`, { label })}
        <br />
        {t(`・(通常の絞り込み) 「{{label}}」「{{rootNodeLabel}}」の行が両方とも表示されません。`, {
          label,
          rootNodeLabel,
        })}
        <br />
        {t(`・(ブロックの絞り込み) 「{{label}}」の行は表示されませんが、「{{rootNodeLabel}}」の行は表示されます。`, {
          label,
          rootNodeLabel,
        })}
      </div>
      <ViewFilterTreeForm
        isAdvancedMode={isAdvancedModeState.isTrue}
        filterTree={node.additionalJoinOn}
        propertiesWithNode={propertiesAndModelIdWithNode}
        onAfterFinish={(filterTree) => {
          editNode({
            node,
            f: (node) => {
              node.additionalJoinOn = applyAdvancedSettingsFlag(isAdvancedModeState.isTrue, filterTree)
            },
          })
          onAfterFinish()
        }}
      />
    </PageHeader>
  )
}
