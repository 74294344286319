import { isNull, isSome, isTruthy } from '@salescore/buff-common'

import type {
  ViewConfigField,
  ViewConfigFilterNode,
  ViewConfigKpiMeasure,
  ViewConfigKpiTimeSeries,
  ViewConfigSheet,
  WaterfallDimension,
} from '../../../../../schemas/view_config'
import { quotedFieldPattern } from './quotedFieldPattern'

/**
 * treeで指定されている全てのモデルの全てのプロパティを取得するためのフィールドを追加する
 */
// eslint-disable-next-line complexity
export function generateSheetWithAdditionalFields({
  sheet,
  config,
  options,
}: {
  sheet: ViewConfigSheet
  config: ViewConfigKpiTimeSeries
  options?: {
    shouldAddIdField?: boolean
  }
}): ViewConfigSheet {
  const dimensionFields = getDimensionFields(config.waterfall?.dimensions ?? [])
  const filterTreeFields = getFilterTreeFields(sheet.filterTree)
  const measureFields = getMeasureFields(config.kpiFragment?.measure)
  return {
    ...sheet,
    fields: [
      ...(sheet.fields ?? []),
      isSome(sheet.tree) && isTruthy(options?.shouldAddIdField)
        ? {
            type: 'property' as const,
            property: {
              modelName: sheet.tree.modelName,
              nodeName: sheet.tree.name,
              propertyName: 'id',
            },
          }
        : undefined,
      ...dimensionFields,
      ...filterTreeFields,
      ...measureFields,
    ]
      .compact()
      .uniqueBy((field) => `${field.property.nodeName}${field.property.propertyName}`),
  }
}

function getDimensionFields(dimensions: WaterfallDimension[]): ViewConfigField[] {
  return dimensions
    .map((dimension) => {
      if (isNull(dimension.property)) {
        return
      }
      return {
        type: 'property' as const,
        property: {
          modelName: dimension.property.modelName,
          nodeName: dimension.property.nodeName,
          propertyName: dimension.property.propertyName,
        },
      }
    })
    .compact()
}

function getFilterTreeFields(filterTree?: ViewConfigFilterNode): ViewConfigField[] {
  if (isNull(filterTree)) {
    return []
  }

  const fields = filterTree.leafs
    .map((leaf) => {
      if (leaf.type !== 'property') {
        return
      }
      return {
        type: 'property' as const,
        property: {
          modelName: leaf.property.modelName,
          nodeName: leaf.property.nodeName,
          propertyName: leaf.property.propertyName,
        },
      }
    })
    .compact()

  return [...fields, ...filterTree.children.flatMap((child) => getFilterTreeFields(child))]
}

function getMeasureFields(measure?: ViewConfigKpiMeasure): ViewConfigField[] {
  if (isNull(measure)) {
    return []
  }

  switch (measure.type) {
    case 'kpiPreset': {
      return [
        {
          type: 'property' as const,
          property: measure.property,
        },
      ]
    }
    case 'kpiCustom': {
      const fields = measure.sql
        .match(quotedFieldPattern)
        ?.map((x) => {
          // 正規表現で"modelName"."propertyName"の形式の文字列を抽出する
          const [modelName, propertyName] = x.replaceAll('"', '').split('.')
          if (isNull(modelName) || isNull(propertyName)) {
            return
          }
          return {
            type: 'property' as const,
            property: {
              modelName,
              nodeName: modelName,
              propertyName,
            },
          }
        })
        .compact()
      return fields ?? []
    }
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default: {
      const x: never = measure
      throw new Error(`Unexpected measure type`)
    }
  }
}
