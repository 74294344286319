import { isNull } from '@salescore/buff-common'

import type {
  NodePropertyName,
  ViewConfigField,
  ViewConfigFilterNode,
  ViewConfigSheet,
  ViewConfigTreeNode,
  ViewConfigUiComponent,
} from '../../../../schemas/view_config'
import { flatNodes } from '../../../query/executeViewQuery/util'
import type { CompileContext } from '../../common'

export function expandChildrenByReferenceField(
  tree: ViewConfigSheet['tree'],
  {
    fields,
    filterTree,
    components,
  }: {
    fields?: ViewConfigField[]
    filterTree?: ViewConfigFilterNode | undefined
    components?: ViewConfigUiComponent[]
  },
  context: CompileContext,
): ViewConfigTreeNode | undefined {
  if (tree === undefined) {
    return undefined
  }

  const nodeProperties = [
    ...(fields ?? []).map((x) => x.property),
    ...flatNodes(filterTree)
      .flatMap((x) => x.leafs)
      .map((x) => {
        if (x.type === 'property') {
          return x.property
        }
      })
      .compact(),
    ...(components ?? [])
      .flatMap((x) => flatNodes(x))
      .map((x) => x.property)
      .compact(),
  ]
  return rec(tree, nodeProperties, context)
}

function rec(
  node: ViewConfigTreeNode,
  nodeProperties: NodePropertyName[],
  context: CompileContext,
): ViewConfigTreeNode | undefined {
  const model = context.modelSearcher.searchModel(node.modelName)
  if (model === undefined) {
    context.logs.warn(
      `[expandChildrenByReferenceField] model not found. ${node.modelName}, current: ${context.modelSearcher.models
        .map((x) => x.name)
        .join(',')}`,
    )
    return undefined
  }

  const children = (node.children ?? []).map((child) => rec(child, nodeProperties, context)).compact()
  const additionalChildren = getAdditionalChildren(node, nodeProperties, context) // childrenに含まれるnodeは、getAdditionalChildren側で弾かれる

  return {
    ...node,
    children: [...children, ...additionalChildren],
  }
}

function getAdditionalChildren(
  parentConfigNode: ViewConfigTreeNode,
  nodeProperties: NodePropertyName[],
  context: CompileContext,
): ViewConfigTreeNode[] {
  const properties = nodeProperties
    .filter((nodeProperty) => nodeProperty.nodeName === parentConfigNode.name)
    .map((nodeProperty) => {
      const property = context.modelSearcher.searchProperty(nodeProperty.modelName, nodeProperty.propertyName)
      if (property === undefined) {
        return
      }
      return {
        property,
        nodeProperty,
      }
    })
    .compact()
    .uniqueBy((x) => x.property.name)

  return (
    properties
      // eslint-disable-next-line complexity
      .map(({ property, nodeProperty }): ViewConfigTreeNode | undefined => {
        if (isNull(property.referenceTo)) {
          return undefined
        }
        // TODO: 参照項目について、polymorphicに対応できていない
        // TODO: Salesforceの所有者項目は、UserとGroupを参照している。現状、常にUserを参照するようにしてこれを解決している。
        const reference = property.referenceTo.mySortBy((x) => x.modelName !== 'salesforce_user').first()
        if (reference === undefined) {
          return
        }

        // config側で既に追加済みであればスキップする
        const isAlreadyJoined = (parentConfigNode.children ?? []).some((childConfigNode) => {
          const x = childConfigNode.referenceToProperty
          const isUsingSameProperty =
            x?.propertyName === nodeProperty.propertyName && x.modelName === nodeProperty.modelName
          const isReferenceSameModle = childConfigNode.modelName === reference.modelName
          return isUsingSameProperty && isReferenceSameModle
        })
        if (isAlreadyJoined) {
          return
        }

        const model = context.modelSearcher.searchModel(reference.modelName)
        if (model === undefined) {
          return
        }
        // const name = ['r', nodeProperty.nodeName, nodeProperty.propertyName, nodeProperty.modelName].join('_') // TODO: uniqueにはなるものの、長すぎ?
        const name = [nodeProperty.nodeName, nodeProperty.propertyName].join('_') // TODO: 上記のようにrを明示したかったが、元のロジックとの互換性をとるため

        return {
          type: 'model',
          name,
          modelName: reference.modelName,
          referenceToProperty: {
            nodeName: nodeProperty.nodeName,
            modelName: nodeProperty.modelName,
            propertyName: nodeProperty.propertyName,
            referenceTo: reference,
          },
        }
      })
      .compact()
  )
}
