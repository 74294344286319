import { SearchOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import Editor from '@monaco-editor/react'
import { isPresent } from '@salescore/buff-common'
import { Alert, Button, Col, Form, Row, Space, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { format } from 'sql-formatter'

import { useMetaMutations } from '../../../recoil/view/mutations/metaMutations'
import { useConfigSheetSelector } from '../../../recoil/view/selectors/configSheetSelector'

interface FormValue {
  sql: string
}

export function CustomSearchQueryForm({ onAfterFinish }: { onAfterFinish: () => void }) {
  const [form] = useForm<FormValue>()
  const { config } = useConfigSheetSelector()
  const { setMeta } = useMetaMutations()

  const onFinish = (value: FormValue) => {
    setMeta({
      newMeta: {
        searchQuery: isPresent(value.sql) ? value.sql : undefined,
      },
    })
    onAfterFinish()
  }

  // 将来的にちゃんとしたGUIを用意するが、いったんSQLを直で書いてもらう
  return (
    <PageHeader
      title={
        <Space>
          <SearchOutlined />
          <span>{t(`検索条件設定`)}</span>
        </Space>
      }
    >
      <Alert
        className="mb-4"
        message={<div>{t(`開発中の機能のため、使用したい場合はSALESCORE担当者までお問合せください。`)}</div>}
        type="warning"
        showIcon
      />
      <div className="mb-8">
        {t(`設定方法`)}
        <br />・
        {t(
          `SQLのWHERE句に相当するクエリを記述してください。この条件設定を利用した場合、通常の検索項目設定は無視されます`,
        )}
        <br />
        <Trans>
          <div>
            ・mustache記法で<Typography.Text code>query</Typography.Text>
            という値を指定することで、検索クエリを埋め込めます
          </div>
          <div>
            （例）
            <Typography.Text code>{"phone ='{{ query }}'"}</Typography.Text>
            と書いた上で、検索フィールドに<Typography.Text code>08012345678</Typography.Text>と入力すると、
            <Typography.Text code>phone = &#39;08012345678&#39;</Typography.Text>という条件でSQLが発行されます。
          </div>
        </Trans>
      </div>

      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          sql: formatSqlWithMustache(config.meta?.searchQuery ?? ''),
        }}
      >
        <Row gutter={12}>
          <Col span={16}>
            <Form.Item
              name="sql"
              label={
                <div>
                  {t(`検索条件`)}(SQL){' '}
                  <Button
                    size="small"
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-type-assertion
                      const sql = form.getFieldValue(`sql`) as string
                      form.setFieldsValue({ sql: formatSqlWithMustache(sql) })
                    }}
                  >
                    {t(`フォーマット`)}
                  </Button>
                </div>
              }
              rules={[{ required: true, message: t(`検索条件を入力してください`) }]}
            >
              <Editor
                height={350}
                // width={500}
                theme="vs-dark"
                defaultLanguage="sql"
                options={{
                  minimap: {
                    enabled: false,
                  },
                }}
                defaultValue={`---(例)\nsalesforce_contact.phone = regexp_replace('{{ query }}', '-', '', 'g')`}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex flex-row-reverse">
          <Form.Item>
            <Button htmlType="submit">{t(`適用`)}</Button>
          </Form.Item>
        </div>
      </Form>
    </PageHeader>
  )
}

function formatSqlWithMustache(sql: string) {
  return format(sql ?? '', { language: 'postgresql' })
    .replace('{ {', '{{')
    .replace('} }', '}}')
}
