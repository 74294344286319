import { useMutation } from '@apollo/client'
import { isSome } from '@salescore/buff-common'
import {
  ArchiveViewDocument,
  CopyViewDocument,
  CreateViewDocument,
  type CreateViewInput,
  DeleteViewDocument,
  UpdateViewDocument,
  type ViewForSiderFieldsFragment,
  type ViewUpdateInputSingle,
} from '@salescore/client-api'
import { message } from 'antd'
import { t } from 'i18next'

import { useOrganizationId } from '../../global/atoms'
import { usePickedViewIds, useViewGroupTreeExpandedKeys } from '../hooks'
import { useRefetchViewGroups } from './refetchViewGroups'

export const useCreateView = () => {
  const [createViewMutation] = useMutation(CreateViewDocument)
  const organizationId = useOrganizationId()
  const refetchViews = useRefetchViewGroups()
  const { pickView } = usePickedViewIds()
  const [_expandedKeys, setExpandedKeys] = useViewGroupTreeExpandedKeys()

  // eslint-disable-next-line complexity
  return async (input: CreateViewInput, type?: ViewForSiderFieldsFragment['type'], option?: never) => {
    // TODO: shouldCopyCurrentView
    try {
      const result = await createViewMutation({
        variables: {
          organizationId,
          view: input,
        },
      })
      await refetchViews() // TODO: fetchしたくない
      switch (type) {
        case 'kpiPivot': {
          void message.success(t(`ダッシュボードを作成しました`))
          break
        }
        case 'kpi': {
          void message.success(t(`KPIを作成しました`))
          break
        }
        case 'sheet': {
          void message.success(t(`シートを作成しました`))
          break
        }
        case 'kpiTimeSeries': {
          void message.success(t(`案件管理を作成しました`))
          break
        }
        default: {
          void message.success(t(`ビューを作成しました`))
        }
      }
      const view = result.data?.createView
      // 作成後は必ず移動(現状、これでいいはず)
      if (isSome(view)) {
        pickView(view.id)
      }
      // 必ず親要素を開く
      setExpandedKeys((keys) => [...keys, view?.viewGroupId].compact().unique())
      return view
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  }
}

export const useUpdateView = () => {
  const [updateViewMutation] = useMutation(UpdateViewDocument)
  const organizationId = useOrganizationId()
  const refetchViews = useRefetchViewGroups()

  // eslint-disable-next-line complexity
  return async (
    input: ViewUpdateInputSingle,
    type?: ViewForSiderFieldsFragment['type'],
    option?: { refetch?: boolean },
  ) => {
    try {
      const result = await updateViewMutation({
        variables: {
          organizationId,
          view: input,
        },
      })
      if (option?.refetch !== false) {
        await refetchViews() // TODO: fetchしたくない
        switch (type) {
          case 'kpiPivot': {
            void message.success(t(`ダッシュボードを更新しました`))
            break
          }
          case 'kpi': {
            void message.success(t(`KPIを更新しました`))
            break
          }
          case 'sheet': {
            void message.success(t(`シートを更新しました`))
            break
          }
          case 'kpiTimeSeries': {
            void message.success(t(`案件管理を更新しました`))
            break
          }
          default: {
            void message.success(t(`ビューを更新しました`))
          } // TODO: どこでやる？
        }
      }
      return result.data?.updateView
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  }
}

export const useDeleteView = () => {
  const [deleteViewMutation] = useMutation(DeleteViewDocument)
  const organizationId = useOrganizationId()
  const refetchViews = useRefetchViewGroups()

  return async (id: string, type?: ViewForSiderFieldsFragment['type']) => {
    try {
      const result = await deleteViewMutation({
        variables: {
          organizationId,
          id,
        },
      })
      await refetchViews() // TODO: fetchしたくない
      switch (type) {
        case 'kpiPivot': {
          void message.success(t(`ダッシュボードを削除しました`))
          break
        }
        case 'kpi': {
          void message.success(t(`KPIを削除しました`))
          break
        }
        case 'sheet': {
          void message.success(t(`シートを削除しました`))
          break
        }
        case 'kpiTimeSeries': {
          void message.success(t(`案件管理を削除しました`))
          break
        }
        default: {
          void message.success(t(`ビューを削除しました`))
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  }
}

export const useArchiveView = () => {
  const [archiveViewMutation] = useMutation(ArchiveViewDocument)
  const organizationId = useOrganizationId()
  const refetchViews = useRefetchViewGroups()

  return async (id: string, type?: ViewForSiderFieldsFragment['type']) => {
    try {
      const result = await archiveViewMutation({
        variables: {
          organizationId,
          id,
        },
      })
      await refetchViews() // TODO: fetchしたくない
      switch (type) {
        case 'kpiPivot': {
          void message.success(t(`ダッシュボードをゴミ箱へ移動しました`))
          break
        }
        case 'kpi': {
          void message.success(t(`KPIをゴミ箱へ移動しました`))
          break
        }
        case 'sheet': {
          void message.success(t(`シートをゴミ箱へ移動しました`))
          break
        }
        case 'kpiTimeSeries': {
          void message.success(t(`案件管理をゴミ箱へ移動しました`))
          break
        }
        default: {
          void message.success(t(`ビューをゴミ箱へ移動しました`))
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  }
}

export const useCopyView = () => {
  const [copyViewMutation] = useMutation(CopyViewDocument)
  const organizationId = useOrganizationId()
  const refetchViews = useRefetchViewGroups()
  const { pickView } = usePickedViewIds()

  // eslint-disable-next-line complexity
  return async (id: string, type?: ViewForSiderFieldsFragment['type']) => {
    try {
      const result = await copyViewMutation({
        variables: {
          organizationId,
          id,
        },
      })
      await refetchViews() // TODO: fetchしたくない
      const view = result.data?.copyView
      // 作成後は必ず移動(現状、これでいいはず)
      if (isSome(view)) {
        pickView(view.id)
      }
      switch (type) {
        case 'kpiPivot': {
          void message.success(t(`ダッシュボードをコピーしました`))
          break
        }
        case 'kpi': {
          void message.success(t(`KPIをコピーしました`))
          break
        }
        case 'sheet': {
          void message.success(t(`シートをコピーしました`))
          break
        }
        case 'kpiTimeSeries': {
          void message.success(t(`案件管理をコピーしました`))
          break
        }
        default: {
          void message.success(t(`ビューをコピーしました`))
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  }
}
